import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, UserAgent, UserAgentList, Search, Filter } from '../../../Constant';
import { Controller, useForm } from 'react-hook-form';
import SearchList from '../../../CommonElements/Search';
import { Deleteagent, deleteCustomer, viewAgent } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import { conditionalRow } from '../../Common/Rowstyle/Rowstyle';
import Transparentspinner from '../../Common/Loader/Transparentspinner';
import { toast } from 'react-toastify';
import PasswordChange from './PasswordChange';
import Popup from '../../Common/Popup/popup';

const UserAgentListContainer = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const changepwdtitile = <span className='billerqColor bolder '><h3>Change Password</h3></span>;
    const [agentLists, setAgentList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [userValue, setUserValue] = useState('');
    const [modal, setModal] = useState(false);

    const AgentColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '75px',

        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '20px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            sortable: true,
            center: true,

        },
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,

        },
        {
            name: <H4>Email</H4>,
            selector: row => row.Email,
            sortable: true,

        },
        {
            name: <H4>Roles</H4>,
            selector: row => row.Roles,
            sortable: true,

        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,

            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}

                >
                    {row.Status.charAt(0).toUpperCase() + row.Status.slice(1).replace('_', ' ')}
                </span>
            ),
        },

    ];

    const handleModal = (id) => {
        setUserValue(id);
        setModal(!modal);
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}${process.env.PUBLIC_URL}/menu/user-agent-edit?id=${row.id}`;
            navigate(editFormURL);
        };

        const deleteCustomerList = (id) => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const deleteRes = { agent_id: id }
                    let customerDelete = await postClientUrlWithToken(authState.apiUrl, Deleteagent, deleteRes);
                    if (customerDelete.status === 200) {
                        if (customerDelete.data.status) {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            toast.success(customerDelete.data.message)
                            fetchAgent(1, perPage);
                        } else {
                            toast.error(customerDelete.data.message);
                        }

                    } else if (customerDelete.status >= 400 && customerDelete.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (customerDelete.status >= 500 && customerDelete.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                    setLoading(false);
                }
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => handleEditClick()}>Edit</DropdownItem>
                    <DropdownItem style={toggleStyle} onClick={() => handleModal(row.id)}>Change Password</DropdownItem>
                    <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={() => deleteCustomerList(row.id)}>Delete</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const fetchAgent = async (page, size = perPage) => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, viewAgent +
            `?page=${page}&page_length=${size}&search=${searchKeyword}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const SerialNo = (page - 1) * size + 1;
            setAgentList(response.data.data.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    Name: <span>{e.first_name} {e.last_name}</span>,
                    Email: e.email,
                    Roles: e.role_name,
                    Status: e.status,
                    id: e.id
                }
            }))
            setTotalRows(response.data.total);
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchAgent(1);
    }, [searchKeyword]);

    const handlePageChange = page => {
        fetchAgent(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchAgent(page, newPerPage);
        setPerPage(newPerPage);
    };
    const onSubmit = async (data) => {

    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={UserAgent} parent="Users" title={UserAgent} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{UserAgentList}</H3>
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/menu/user-agent-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>

                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>

                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} type="text"
                                        {...register('search')} />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={AgentColumns}
                            data={agentLists}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
            <Popup isOpen={modal} title={changepwdtitile} toggler={handleModal}>
                <PasswordChange setModal={setModal}  userValue={userValue}/>
            </Popup>
        </Fragment>
    );
};

export default UserAgentListContainer;
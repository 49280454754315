import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import Typeahead from "../../../CommonElements/TypeAhead";
import {

  AdduserAgent,
  City,
  ConfirmPassword,
  Country,
  Email,
  FirstName,
  Language,
  LastName,
  Locale,
  MobileNo,
  Password,
  Role,
  Save,
  State,
  UserAgent,
  zipcode,

} from "../../../Constant";
import { Postagent, getLanguage, getRole, } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import {
  getClientUrlDataToken,
  postClientUrlWithToken,
} from "../../../Utils/restUtils";
import { SetLanguage } from "../../Common/Language/Language"
import Areafield from "../../Common/Area Field/Areafield";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
  firstname: yup.string().required().label('First Name'),
  lastname: yup.string().required().label('Last Name'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  role: yup.mixed().required().label('Role'),
  area: yup.mixed().required().label('Area'),
  language: yup.mixed().required().label('Language'),
  password: yup.string().required('Password id required').min(8, 'Password must be at least 8 characters long'),
  password_repeat: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})


const UserAgentForm = () => {
  const { register, handleSubmit, control, formState: { errors }, watch, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const authState = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([])
  const [language, setlanguage] = useState([])
  const navigate = useNavigate();
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConPassword, setToggleConPassword] = useState(false);

  useEffect(() => {
    areaData()
  }, [])



  const areaData = async () => {
    let data = await getClientUrlDataToken(authState.apiUrl, getRole);

    if (data.status === true) {
      const mappedrole = data.data.map((items) => ({
        value: items.id,
        label: items.name
      }))
      setRoles(mappedrole);
    }
    else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }

    let lang_api = await getClientUrlDataToken(authState.apiUrl, getLanguage);
    if (data.status === true) {
      const reponse = lang_api.data;
      const mappedLang = Object.keys(reponse).map((key) => ({ label: reponse[key], value: key }));
      setlanguage(mappedLang);
    }
    else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }

  };

  const onSubmit = async (data) => {
    setLoading(true);
    const countryCode = data.countryCode;
    const phoneNumber = data.phoneNumber;

    if (phoneNumber.startsWith(countryCode)) {
      const b = phoneNumber.slice(countryCode.length);

      const postRequest = {
        first_name: data.firstname,
        last_name: data.lastname,
        mobile: b,
        email: data.email,
        role_id: data.role.value,
        area_id: data.area,
        country_code: data.countryCode,
        password: data.password,
        confirm_password: data.password_repeat,
        latitude: "4567886",
        longitude: "98876756",
        city: data.city,
        state: data.state,
        country: data.country,
        zip_code: data.zipcode,
        locale: data.language.value,
      };
      const addCustomerResponse = await postClientUrlWithToken(
        authState.apiUrl,
        Postagent,
        postRequest
      );
      if (addCustomerResponse.status === 200) {
        if (addCustomerResponse.data.status === true) {
          navigate(`${process.env.PUBLIC_URL}/menu/user`);

        } else {
          const errorMessage = addCustomerResponse.data.message;
          if (errorMessage) {
            toast.error(errorMessage)
            setLoading(false);
          }
        }
      } else if (addCustomerResponse.status >= 400 && addCustomerResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (addCustomerResponse.status >= 500 && addCustomerResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
      }
      setLoading(false);
    };
  };
  return (
    <Fragment>
      {loading && <Transparentspinner />}

      <Breadcrumbs mainTitle={UserAgent} parent="Customers" title={UserAgent} />
      <Card>
        <CardHeader className="pb-0">
          <H3>{AdduserAgent}</H3>
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">
                  {FirstName}
                </Label> <span className="requireStar">*</span>
                <input
                  className="form-control"
                  type="text"
                  {...register("firstname")}
                />
                <p className="text-danger">{errors.firstname?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {LastName} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  type="text"
                  {...register("lastname")}
                />
                <p className="text-danger">{errors.lastname?.message}</p>
              </Col>

            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">
                  {MobileNo} <span className="requireStar">*</span>
                </Label>

                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      country={"in"}
                      enableSearch={true}
                      onChange={(value, country) => {
                        setValue("countryCode", country.dialCode);
                        field.onChange(value);
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "46px",
                        padding: "10px",
                        paddingLeft: "50px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                      }}
                      searchClass="none"
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.phone && errors.phone.message}
                </span>
              </Col>
              <Col md="6">
                <Label className="form-label">
                  {Email} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  type="email"
                  {...register("email", { required: true })}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </Col>


            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" >
                  {Role} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={roles}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.role?.message}</p>
              </Col>

              <Col md="6">
                <Areafield
                  name="area"
                  control={control}
                />
                <p className="text-danger">{errors.area?.message}</p>
              </Col>

            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">
                  {Language} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="language"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={language}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.language?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label">
                  {City}
                </Label>
                <input
                  className="form-control"
                  type="text"
                  {...register("city", { required: true })}
                />
                <p className="text-danger">{errors.city?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">
                  {State}
                </Label>
                <input
                  className="form-control"
                  type="text"
                  {...register("state", { required: true })}
                />
                <p className="text-danger">{errors.state?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label">
                  {Country}
                </Label>
                <input
                  className="form-control"
                  type="text"
                  {...register("country", { required: true })}
                />
                <p className="text-danger">{errors.country?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">
                  {zipcode}
                </Label>
                <input
                  className="form-control"
                  type="text"
                  {...register("zipcode", { required: true })}
                />
                <p className="text-danger">{errors.zipcode?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {Locale}
                </Label>
                <input
                  className="form-control"
                  type="text"
                  {...register("local", { required: true })}
                />
                <p className="text-danger">{errors.local?.message}</p>
              </Col>
            </Row>

            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">
                  {Password} <span className="requireStar">*</span>
                </Label>
                <div className='form-input position-relative'>
                  <input
                    className="form-control"
                    type={togglePassword ? 'text' : 'password'}
                    {...register("password", { required: true })}
                  />
                  <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? '' : 'show'}></span>
                  </div>
                </div>

                <p className="text-danger">{errors.password?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {ConfirmPassword} <span className="requireStar">*</span>
                </Label>
                <div className='form-input position-relative'>
                  <input
                    className="form-control"
                    type={toggleConPassword ? 'text' : 'password'}
                    {...register("password_repeat", { required: true })}
                  />
                  <div className="show-hide" onClick={() => setToggleConPassword(!toggleConPassword)}>
                    <span className={toggleConPassword ? '' : 'show'}></span>
                  </div>
                </div>
                <p className="text-danger">{errors.password_repeat?.message}</p>
              </Col>
            </Row>
            <FormGroup></FormGroup>
            {/* <div className="text-end"> */}
            <Btn attrBtn={{ color: "primary" }} type="submit">
              {Save}
            </Btn>
            {/* 
            </div> */}
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default UserAgentForm;

import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NameError, Categories, Description, EditCategory, Name, Save, Type } from "../../../Constant";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { updateCategory, updateCategoryRole, viewCategory, viewCategoryRole } from "../../../CommonUrl";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { useSelector } from "react-redux";
import Typeahead from "../../../CommonElements/TypeAhead";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const CategoryEdit = () => {

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: {}
    });
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const authState = useSelector((state) => state.auth);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");

    const options = [
        { value: "expense", label: "Expense" },
        { value: "income", label: "Income" },
        { value: "item", label: "Item" },
        { value: "other", label: "Other" },
    ];
    const navigate = useNavigate();

    useEffect(() => {
        getCategory();
    }, []);

    const getCategory = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewCategory + `?category_id=${param1}`:viewCategoryRole+ `?category_id=${param1}`);
        if (data.status === true) {
            setValue('name', data.data[0].name);
            setValue('type', { label: data.data[0].type });
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            category_id: param1,
            name: data.name,
            type: data.type.value
        };
        const editCategoryResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? updateCategory: updateCategoryRole, postRequest);

        if (editCategoryResponse.status === 200) {
            if (editCategoryResponse.data.status === true) {
                toast.success('Success...!')
                navigate(`${process.env.PUBLIC_URL}/settings/categories`);
            } else {
                toast.error(editCategoryResponse.data.message)
                setLoading(false);
            }
        } else if (editCategoryResponse.status >= 400 && editCategoryResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (editCategoryResponse.status >= 500 && editCategoryResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}
            <Breadcrumbs mainTitle={Categories} parent="Settings" title={Categories} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditCategory}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label">{Type} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={options}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.industry && IndustryError}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3"
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default CategoryEdit;

import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import ProviderContainer from "./ProviderList";

const CasProvider = () => {
    return (
        <Fragment>
            <ProviderContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default CasProvider;
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Navbar } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { LogOut } from 'react-feather';
import { Submit, logout } from '../../Constant';
import { setLogout } from '../../Redux/Reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Btn, Image, LI, P, UL } from '../../AbstractElements';
import defaultUserImage from '../../assets/images/user-profile/default-users.png';

const NavSign = ({ showProfile }) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const UserName = useSelector((set) => set.auth.userName)
  const authState = useSelector((state) => state.auth);
  const authenticated = JSON.parse(localStorage.getItem('authenticated'));
  const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));
  const [profile, setProfile] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || defaultUserImage);
    setName(localStorage.getItem('Name') || UserName);
  }, [setProfile, setName]);

  const Logout = () => {
    localStorage.removeItem('profileURL');
    localStorage.removeItem('token');
    localStorage.removeItem('auth0_profile');
    localStorage.removeItem('Name');
    localStorage.setItem('authenticated', false);
    localStorage.setItem('login', false);
    dispatch(setLogout());
    Navigate('/SignIn');
  };
  return (
    <div>
      <Navbar collapseonselect='true' expand="lg" sticky = 'true' style={{ background: '#f3f3f3' }}>
        <Container>
          <Link to={'/'}><img src={require('../../assets/images/logoBillerq.png')} alt='' className="logo" /></Link>
          {showProfile &&
            <Col className="nav-right col-6 pull-right right-header p-0">
              <UL attrUL={{ className: `simple-list d-flex flex-row nav-menus`, style: { justifyContent: 'end' } }} >
                <LI attrLI={{ className: "profile-nav onhover-dropdown p-0" }} >
                  <div className="d-flex profile-media" style={{ height: '60px' }} onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                    <Image attrImage={{ className: 'b-r-50 m-0', src: authenticated ? auth0_profile.picture : profile, alt: '', style: { height: '50px' } }} />
                    <div className="flex-grow-1" style={{ marginLeft: '20px' }}><span style={{ fontWeight: '500', color: '#212529' }}>{authenticated ? auth0_profile.name : name}</span>
                      <P attrPara={{ className: "mb-0 font-roboto" }} >{authState.role} <i className="middle fa fa-angle-down"></i></P>
                    </div >
                  </div >
                  {showDropdown && (
                    <div className="dropdownMenu"
                      style={{ position: 'absolute', backgroundColor: '#ffffff', height: '40px', width: '150px', marginTop: '1px', borderRadius: '5px', display: 'flex', alignItems: 'center' }}
                      onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}
                      onClick={() => Logout()}
                    >
                      <Link to={`${process.env.PUBLIC_URL}/login`} style={{ display: 'flex', marginLeft: '20px' }}><LogOut style={{ marginRight: '8px', width: '20px', height: '20px' }} />{logout}</Link>
                    </div>
                  )}
                </LI >
              </UL>
            </Col>
          }
        </Container>
      </Navbar>
    </div>
  )
}

export default NavSign
import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../Constant";
import PaymentForm from "./PaymentsForm";

const PaymentMethodAdd = () => {
    return (
        <Fragment>
            <PaymentForm mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default PaymentMethodAdd;
import React, { Fragment, useState } from "react";
import { Col, Form, Label, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { Controller, useForm } from "react-hook-form";
import {
  Address,
  Email,
  Name,
  Next,
  Phone,
  AddressError,
  TAXIN,
  TAXinError,
  CompanyName,
  GSTTax,
} from "../../../../Constant";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from "react-phone-input-2";
const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required'),
  name: yup.string().required('Name is required'),
  phone: yup.string().required('Phone number is required'),
});

const Formstep1 = ({ setSteps, setFormdata, formdata }) => {
  const [logo, setLogo] = useState(formdata.logo || '')

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const handleLogoChange = (event) => {
    const selectedFile = event.target.files[0];
    setLogo(selectedFile)
  }
  const onSubmit = async (data) => {
    if (data) {
      setFormdata((prev) => ({ ...prev, ...data, logo }));
      setSteps((prev) => prev + 1);
    }
  };
  return (
    <Fragment>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="form-bookmark needs-validation"
      >
        <Row className="g-3 pt-3">
          <Col sm="12" md="6">
            <Label >{CompanyName} <span className="requireStar">*</span></Label>
            <input
              className='form-control'
              // id="name"
              type="text"
              // name="name"
              defaultValue={formdata.name || ""}
              {...register("name")}
            />
            <span className="text-danger pt-2">
              {errors.name && errors.name.message}
            </span>
          </Col>
          <Col sm="12" md="6">
            <Label htmlFor="email"> {Email} <span className="requireStar">*</span></Label>
            <input
              className='form-control'
              id="email"
              type="text"
              name="email"
              defaultValue={formdata.email || ""}
              {...register("email")}
            />

            <span className="text-danger">{errors.email && errors.email.message}</span>
          </Col>
        </Row>
        <Row className="g-3 pt-3">
          <Col md="6">
            <Label htmlFor="taxin">{GSTTax} </Label>
            <input
              className={`form-control ${errors.taxin && "is-invalid"}`}
              id="taxin"
              type="text"
              name="taxin"
              defaultValue={formdata.taxin || ""}
              {...register("taxin")}
            />
            <span className="text-danger">
              {errors.taxin && TAXinError}
            </span>
          </Col>
          <Col md="6">
            <Label >{Phone} <span className="requireStar">*</span></Label>
            <Controller
              name="phone"
              control={control}
              defaultValue={formdata.phone || ""}
              className='form-control'
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  country={"in"}
                  enableSearch={true}
                  onChange={(value, country) => {
                    setValue("countryCode", country.dialCode);  
                    field.onChange(value);
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "46px",
                    padding: "10px",
                    paddingLeft: "50px",
                    fontSize: "16px",
                    borderRadius: "8px",
                    borderColor: "rgba(83, 70, 134, 0.1019607843)",
                  }}
                  searchClass="none"
                />
              )}
            />

            <span className="text-danger">
              {errors.phone && errors.phone.message}
            </span>
          </Col>
        </Row>
        <Row className="g-3 pt-3">
          <Col md="6">
            <Label htmlFor="address">{Address}</Label>
            <textarea
              className={`form-control ${errors.address && "is-invalid"}`}
              id="address"
              type="text"
              name="address"
              defaultValue={formdata.address || ""}
              {...register("address")}
            />
            <span className="text-danger">
              {errors.address && AddressError}
            </span>
          </Col>
          <Col md="6">
            <Label className="form-label">Logo</Label>
            <Controller
              name="logo"
              control={control}
              render={({ field }) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Col md="8">
                    <input
                      {...register("logo")}
                      className="form-control fileInputStyle"
                      type="file"
                      onChange={handleLogoChange}
                      accept=".png, .jpg, .jpeg"
                    />
                  </Col>
                  <Col md='4'>
                    <span className="imagestyle">

                      {logo && <img src={URL.createObjectURL(logo)} alt="logo" width="40px" height="40px" />}
                    </span>
                  </Col>
                </div>
              )}
            />
            {/* <span className="text-danger">
              {errors.logo && "logo is required"}
            </span> */}
          </Col>
        </Row>
        <div className="text-end btn-mb">
          <Btn attrBtn={{ color: "primary" }}>{Next}</Btn>
        </div>
      </Form>
    </Fragment>
  );
};

export default Formstep1;

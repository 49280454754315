import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText,
} from 'reactstrap';
import Contact from './Contact';
import Footer from './Footer';
import { Link, useLocation } from 'react-router-dom';
import './Disclaimer.scss';
import { appUrl } from '../CommonUrl';

const Disclaimers = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const loginEncrypted = localStorage.getItem("login");
    let login = null;
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    if (loginEncrypted) {
        try {
            const bytes = CryptoJS.AES.decrypt(loginEncrypted, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
                login = JSON.parse(decryptedData);
            }
        } catch (e) {
            console.error('Error decrypting or parsing login data', e);
        }
    }

    const isLoggedIn = login && login.userToken;

    return (
        <div className='section1-containers'>
            <div style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
                <Navbar collapseonselect='true' expand="lg" className="bg-body-tertiary" container>
                    <NavbarBrand href="/"><img src={require('../assets/images/logoBillerq.png')} alt='' className="logo me-lg-5" /></NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="me-auto  align-items-lg-center" navbar>
                            <NavItem>
                                <NavLink href={appUrl} className='nav-link-bar'>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#aboutUs"} className='nav-link-bar'>About Us</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#feature"} className='nav-link-bar'>Features</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#usecase"} className='nav-link-bar'>Use Case</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#contact"} className='nav-link-bar'>Contact Us</NavLink>
                            </NavItem>
                            <NavbarText>
                                <Link className='ms-lg-5' to={isLoggedIn ? `${process.env.PUBLIC_URL}/dashboard/default` : '/SignIn'}>
                                    <div className='login-button'>{isLoggedIn ? 'Dashboard' : 'Get Started'}</div>
                                </Link>
                            </NavbarText>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
            <div style={{ width: '100%' }}>
                <div className='heading-main' >
                    <h1 className='disclaimer-heading'>Disclaimer</h1>
                </div>
            </div>


            <div className='container'>
                <div className='content-section'>
                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>1. General Information</h4>
                        <p className='disclaimer-text'>
                            The information provided by Quadleo Techno Solutions Pvt Ltd  on the BillerQ website (billerq.com) is for general informational purposes only. All information on the site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>2. Professional Advice</h4>
                        <p className='disclaimer-text'>
                            The information provided on BillerQ does not constitute professional advice. Users are encouraged to seek professional advice before taking any action based on the information provided on our site. Use of or reliance on any information contained on this site is solely at your own risk.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>3. Service Disclaimer</h4>
                        <p className='disclaimer-text'>
                            BillerQ is a SaaS-based subscriber management system, and while we strive to ensure the service's uninterrupted operation, we cannot guarantee that it will always be available, error-free, or secure. We reserve the right to suspend or terminate access to the service for scheduled maintenance, upgrades, or other reasons without prior notice.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>4. Third-Party Links</h4>
                        <p className='disclaimer-text'>
                            BillerQ may contain links to third-party websites or services that are not owned or controlled by Quadleo Techno Solutions Pvt Ltd. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>5. Limitation of Liability</h4>
                        <p className='disclaimer-text'>
                            To the fullest extent permitted by applicable law, in no event shall Quadleo Techno Solutions Pvt Ltd be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from
                        </p>
                        <ul className='disclaimer-list'>
                            <li className='disclaimer-list-item'>your use or inability to use the service.</li>
                            <li className='disclaimer-list-item'>any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                            <li className='disclaimer-list-item'>any interruption or cessation of transmission to or from the service.</li>
                            <li className='disclaimer-list-item'>any bugs, viruses, trojan horses, or the like that may be transmitted to or through our service by any third party.</li>
                            <li className='disclaimer-list-item'>any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the service.</li>
                            <li className='disclaimer-list-item'>the defamatory, offensive, or illegal conduct of any third party.</li>
                        </ul>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>6. Changes to This Disclaimer</h4>
                        <p className='disclaimer-text'>
                            We reserve the right to update this Disclaimer at any time. Any changes will be effective immediately upon posting on this page, and we will update the "Last Updated" date at the top of this Disclaimer.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>Contact Us</h4>
                        <p className='disclaimer-text'>
                            If you have any questions about our shipping and delivery policy or need further assistance, please reach out to us at <span style={{ fontWeight: '600' }}>info@billerq.com</span>.
                        </p>
                        <p className='disclaimer-text'>
                            Thank you for choosing BillerQ. We are committed to providing you with a seamless and efficient subscriber management experience.
                        </p>
                    </div>
                </div>
            </div>
            {/* <Contact /> */}
            <div className='gap'></div>
            <Contact />
            <Footer />
        </div>
    );
}

export default Disclaimers;

import React from 'react';
import CountUp from 'react-countup';
import './useCase.scss'

const useCase = () => {
    return (
        <div className='section5-container'>
            <div className='growth-section'>
                <h2>We Help Real People</h2>
                <div className='value-container'>
                    <div className='num-value'>
                        <CountUp
                            enableScrollSpy={true}
                            delay={5}
                            duration={5}
                            end={100} />%
                    </div>
                    <p>Efficiency</p>
                </div>
                <div className='value-container'>
                    <div className='num-value'>
                        <CountUp
                            enableScrollSpy={true}
                            delay={5}
                            duration={5}
                            end={100} />%
                    </div>
                    <p>Scalability</p>
                </div>
                <div className='value-container'>
                    <div className='num-value'>
                        <CountUp
                            enableScrollSpy={true}
                            delay={5}
                            duration={5}
                            end={100} />%
                    </div>
                    <p>Insights</p>
                </div>

            </div>
        </div>
    )
}

export default useCase
import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import CategoriesContainer from "./CategoriesList";

const Categories = () => {
    return (
        <Fragment>
            <CategoriesContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default Categories;
import axios from "axios";
import * as authservice from "./authServices";


//const masterURL = "https://admin.billerq.com/public/api/";
 const masterURL = "https://adminapi.billerq.com/api/";


function getToken() {
  if (authservice.isLoggedIn()) {
    const userToken = authservice.getUser().userToken;
    return {
      Authorization: "Bearer " + userToken,
    };
  } else {
    return null;
  }
}

function getMasterToken() {
  if (authservice.isLoggedIn()) {
    const masterToken = authservice.getUser().masterToken;
    return {
      Authorization: "Bearer " + masterToken,
    };
  } else {
    return null;
  }
}

export async function getData(url) {
  try {
    // const response = await axios.get(`${baseURL}${url}`, {
    //   headers: getToken(),
    // });
    const response = await axios.get(`${masterURL}${url}`);
   
    if (response.status === 200) {
      return response.data;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function getMasterDataToken(url) {
  try {
    const response = await axios.get(`${masterURL}${url}`, {
      headers: getMasterToken(),
    });
    // const response = await axios.get(`${masterURL}${url}`);
   
    if (response.status === 200) {
      return response.data;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function postMasterToken(url, data) {
  try {
    const reqHeader = getMasterToken();
    const response = await axios.post(`${masterURL}${url}`, data, {
      headers: reqHeader,
    });
    //console.log("ress", response);
    return response ? response : undefined;
  } catch (error) {
    console.error(error);
  }
}

export async function putData(url, data) {
  try {
    // const response = await axios.put(`${baseURL}${url}`, data, {
    //   headers: getToken(),
    // });
    const response = await axios.put(`${masterURL}${url}`, data);
    if (response.status === 200) {
      return response;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function deleteData(url) {
  try {
    const response = await axios.delete(`${masterURL}${url}`, {
      headers: getToken(),
    });
    // console.log("ress", response);
    if (response.status === 204) {
      return response;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function postDataWithToken(url, data) {
  try {
    const reqHeader = getToken();
    const response = await axios.post(`${masterURL}${url}`, data, {
      headers: reqHeader,
    });
    //console.log("ress", response);
    return response ? response : undefined;
  } catch (error) {
    console.error(error);
  }
}

export async function postDataWithoutResData(url, data) {
  try {
    //const reqHeader = getToken();
    const response = await axios.post(`${masterURL}${url}`, data, {
      // headers: reqHeader,
    });
    //console.log("ress", response);
    return response ? response : undefined;
  } catch (error) {
    console.error(error);
    return error
  }
}

export async function getClientUrlData(clientUrl, url) {
  try {
    // const response = await axios.get(`${baseURL}${url}`, {
    //   headers: getToken(),
    // });
    const response = await axios.get(`${clientUrl}${url}`);
    //console.log('response>>> ', response.status);
    if (response.status === 200) {
      return response.data;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function getClientUrlDataToken(clientUrl, url) {
  try {
    const response = await axios.get(`${clientUrl}${url}`, {
      headers: getToken(),
    });
    //console.log('response>>> ', response.status);
    if (response.status === 200) {
      return response.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return error.response;
  }
}

export async function postClientUrlWithToken(clientUrl, url, data) {
  try {
    const reqHeader = getToken();
    const response = await axios.post(`${clientUrl}${url}`, data, {
      headers: reqHeader,
    });
    //console.log("ress", response);
    return response ? response : undefined;
  } catch (error) {
    return error.response;
  }
}

export async function postClientUrlWithoutToken(url, data) {
  try {
    //const reqHeader = getToken();
    const response = await axios.post(`${url}`, data, {
      // headers: reqHeader,
    });
    //console.log("ress", response);
    return response ? response : undefined;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}
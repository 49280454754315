import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Btn, H3, H4 } from '../../../AbstractElements';
import DataTable from 'react-data-table-component';
import { deleteCustomerImportedLog, getCustomerImportedLog } from '../../../CommonUrl';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Search } from '../../../Constant';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const ImportCustomerLogList = () => {
  const authState = useSelector((state) => state.auth);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [customerList, setcustomerList] = useState([]);
  const [spinnerTrans, setspinnerTrans] = useState(false) 


  const CustomerColumns = [
    {
      name: <H4>Sl</H4>,
      selector: row => row.Si,
      sortable: true,
      width: '50px',
    },
    {
      name: <H4>Error</H4>,
      selector: row => row.Error,
      sortable: true,
      wrap: true,
      width: '100px',
    },
    {
      name: <H4>Comments</H4>,
      selector: row => row.Comments,
      sortable: true,
      wrap: true,
      width: '100px',
    },
    {
      name: <H4>Subscriber Id</H4>,
      selector: row => row.Sub,
      sortable: true,
      wrap: true,
      width: '100px',
    },
    {
      name: <H4>Name</H4>,
      selector: row => row.Name,
      sortable: true,

      width: '140px',
      wrap: true
    },
    {
      name: <H4>Status</H4>,
      selector: row => row.Date,
      sortable: true,
      width: '100px',

      cell: row => (
        <span
          className={`badge badge-${row.Status}`}

        >
          {row.Status}
        </span>
      ),
    },
    {
      name: <H4>Lco no</H4>,
      selector: row => row.Lco,
      sortable: true,
      width: '100px',
      wrap: true,
    },
    {
      name: <H4>Mobile No</H4>,
      selector: row => row.Mobile,
      sortable: true,
      width: '160px',

    },
    {
      name: <H4>Area</H4>,
      selector: row => row.Area,
      sortable: true,

    },
    {
      name: <H4>Address</H4>,
      selector: row => row.Address,
      sortable: true,
      width: '160px',
      wrap: true,
    },
  ];

  useEffect(() => {
    importCustomerlog();
  }, [searchKeyword]) 

  const importCustomerlog = async () => {
    setspinnerTrans(true)
    const response = await getClientUrlDataToken(authState.apiUrl, getCustomerImportedLog);
    if (response.status == true) {
      const filterLog = response.data.filter((item) => {

        const fullName = `${item.first_name}${item.last_name}`.toLowerCase().includes(searchKeyword.toLocaleLowerCase());
        const filterlco = item.lco_no && item.lco_no.toLowerCase().includes(searchKeyword.toLocaleLowerCase());
        const filterSubId = item.subscriber_id && item.subscriber_id.toLowerCase().includes(searchKeyword.toLocaleLowerCase());
        return fullName || filterlco || filterSubId;
      })
      setcustomerList(filterLog.map((e, index) => {
        return {
          Si: index + 1,
          Sub: <span>{e.subscriber_id}</span>,
          Lco: e.lco_no,
          Name: e.first_name + " " + e.last_name,
          Mobile: "+ " + e.mobile,
          Address: e.address.replace(/"/g, "" || ""),
          Route: e.route_no,
          Area: e.area_id,
          Type: e.customer_type,
          Date: e.join_date,
          id: e.id,
          Status: e.stb_status,
          Error: e.error,
          Comments: e.comments,
        }
      }))
    } else {
      setspinnerTrans(false)
      toast.success("Something went wrong while fetching data");

    }
    setspinnerTrans(false)
  }

  const handleRemoveImpoted = async () => {
    setspinnerTrans(true)

    const deleteLogList = await postClientUrlWithToken(authState.apiUrl, deleteCustomerImportedLog);
    if (deleteLogList.status === 200) {
      if (deleteLogList.data.status == true) {
        toast.success(deleteLogList.data.message);
        importCustomerlog()
      } else {
        toast.error(deleteLogList.data.message);
        setspinnerTrans(false)
      }
    } else if (deleteLogList.status >= 400 && deleteLogList.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (deleteLogList.status >= 500 && deleteLogList.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setspinnerTrans(false)

  }
  const handleSearchInputChange = (event) => {
    setSearchKeyword(event.target.value);
  };



  return (
    <>
      {spinnerTrans && <Transparentspinner />}

      <Breadcrumbs mainTitle="Customer Imported Log" parent="customers / customer" title="customer imported log" />
      <Card className='cardWidth'>
        <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <H3>Customer Imported Log</H3>
          <Btn attrBtn={{ color: 'danger', onClick: () => handleRemoveImpoted() }} ><i className="fa fa-trash-o "></i> Clear Log </Btn>
        </CardHeader>

        <CardBody>
          <span className='custom-span'>
            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
              <Col xs="6" sm="4" md="2" lg="2">
                <input className="form-control" placeholder={Search}  type="text" value={searchKeyword} onChange={handleSearchInputChange}
                />
              </Col>
            </Row>
          </span>



          <div className="order-history table-responsive">
            <DataTable
              columns={CustomerColumns}
              data={customerList}
              defaultSortAsc
              fixedHeader
              pagination
            />
          </div>
        </CardBody>
      </Card>


    </>
  )
}

export default ImportCustomerLogList
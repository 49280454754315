import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import AddonsContainer from "./AddonList";

const Addon = () => {
    return (
        <Fragment>
            <AddonsContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default Addon;
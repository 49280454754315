import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../Constant";
import ProviderForm from "./ProviderForm";

const ProviderAdd = () => {
    return (
        <Fragment>
            <ProviderForm mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default ProviderAdd;
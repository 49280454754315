import React, { Fragment } from 'react';

const SpinnerLoader = () => {
    return (
        <Fragment>
            <div className='loader-wrapper' style={{ display: 'flex' }}>
                <div className="loader"></div>
            </div>
        </Fragment>
    );
};

export default SpinnerLoader;
import React, { Fragment } from 'react';
import { Form, FormGroup, Input, InputGroup } from 'reactstrap';

const SearchList = ({ updateSearchKeyword }) => {
    const handleSearchKeyword = (keyword) => {
        updateSearchKeyword(keyword);
    };
    return (
        <Fragment>
            <div className="search">
                <Form className="theme-form">
                    <FormGroup >
                        <InputGroup>
                            <Input
                                className="form-control"
                                type="text"
                                placeholder="search"
                                onChange={(e) =>
                                    handleSearchKeyword(e.target.value)
                                }
                            />
                            <span className="input-group-text"><i className="fa fa-search"></i></span>
                        </InputGroup>
                    </FormGroup>
                </Form>
            </div>
        </Fragment>
    );
};

export default SearchList;
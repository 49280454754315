import React from 'react'
import Companyadd from './Companyadd'

const CompanyAddsecound = () => {
  return (
    <>
    <Companyadd/>
    </>
    
  )
}

export default CompanyAddsecound;
import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import Layout from '../Layout/Layout';
import ProtectedRoute from './ProtectRoute';

const LayoutRoutes = () => {

  return (
    <Fragment>
      <Routes>
        {routes.map(({ path, Component, protected: isProtected, requiredRole, requiredPermission, requiredPlanType, requireSubStatus }, i) => (
          <Route element={<Layout />} key={i}>
            <Route
              path={path}
              element={
                (isProtected || requiredPermission || requireSubStatus) ? (
                  <ProtectedRoute
                    component={Component}
                    requiredRole={requiredRole}
                    requiredPermission={requiredPermission}
                    requiredPlanType={requiredPlanType}
                    requireSubStatus={requireSubStatus}
                  />
                ) : (
                  Component
                )
              }
            />
          </Route>
        ))}
      </Routes>
    </Fragment>
  );
};

export default LayoutRoutes;

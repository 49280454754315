import React from 'react'
import ActivateSub from './ActivateSub'

const ActivateSubscription = () => {
  return (
    <>
    <ActivateSub/>
    </>
  )
}

export default ActivateSubscription
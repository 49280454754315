import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import { Btn, H4 } from '../../../../AbstractElements';
import { Invoice, Transactions, Reciept, ViewMore, PaymentHistory, Export } from '../../../../Constant';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../../Utils/restUtils';
import { getPaymentHistory, getPaymentHistoryRole, getRecentInvoice, getRecentInvoiceRole, getRecentPayment, getRecentPaymentRole, reversePayment } from '../../../../CommonUrl';
import { conditionalRowPayments } from '../../../Common/Rowstyle/Rowstyle';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';

const PayInvoiceTabs = (props) => {
    const personalInfo = props.personalDetails;
    const propsId = props.param;
    const style2 = { width: 85, fontSize: 13, padding: 5 };
    const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState('1');
    const authState = useSelector((state) => state.auth);
    const [invoiceList, setInvoiceList] = useState([]);
    const [transactionlist, setTransaction] = useState([])
    const [paymentHistorylist, setPaymentHistory] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [totalPaymentRows, setpaymentTotalRows] = useState(0);
    const [totalPaymentHistoryRows, setPaymentHistoryTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPaymentPage, setPaymentCurrentPage] = useState(1);
    const [currentPaymentHistoryPage, setPaymentHistoryCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const InvoiceColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '50px'
        },
        {
            name: <H4>Action</H4>,
            selector: row => row.action,
            sortable: true,
            width: '120px'

        },
        {
            name: <H4>Subscriptions</H4>,
            selector: row => row.Subscription,
            sortable: true,
            wrap: true,
            width: '120px'

        },
        {
            name: <H4>Invoice no</H4>,
            // selector: row => row.Invoice,
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${propsId}`}>
                    {row.Invoice}
                </Link>,
            sortable: true,
            center: true,

        },
        {
            name: <H4>Start Date</H4>,
            selector: row => row.InDate,
            sortable: true,
            wrap: true,
            width: '110px'

        },
        {
            name: <H4>Billing Date</H4>,
            selector: row => row.BillingDate,
            sortable: true,
            wrap: true,
            width: '110px'

        },
        {
            name: <H4>Amount Due</H4>,
            selector: row => row.AmountDue,
            sortable: true,

        },
        {
            name: <H4>Total</H4>,
            selector: row => row.Total,
            sortable: true,
            width: '110px',
            wrap: true

        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,
            center: true,
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}
                >
                    {row.Status.charAt(0).toUpperCase() + row.Status.slice(1)}
                </span>
            ),
        },
        {
            name: <H4>Unit Price</H4>,
            selector: row => row.UnitPrice,
            sortable: true,
            center: true,
            width: '110px',
            wrap: true
        },
        {
            name: <H4>Tax</H4>,
            selector: row => row.Tax,
            sortable: true,
        },

    ];

    const paymentColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '50px'
            // center: true,
        },
        {
            name: <H4>Action</H4>,
            selector: row => row.Action,
            cell: row => <ActionCell row={row} />,
            sortable: true,
            center: true,
            width: '100px',
        },

        {
            name: <H4>Paid On</H4>,
            selector: row => row.Paidat,
            sortable: true,
            // center: true,
            wrap: true
        },
        {
            name: <H4>Txn Id</H4>,
            selector: row => row.txnId,
            sortable: true,
            // center: true,
            wrap: true


        },
        {
            name: <H4>Amount Collected</H4>,
            selector: row => row.Amount,
            sortable: true,
            // center: true,
            wrap: true,
            width: '110px'
        },
        {
            name: <H4>To Invoice</H4>,
            selector: row => row.toInvoice,
            sortable: true,
            center: true,
            width: '165px'
        },
        {
            name: <H4>To Wallet</H4>,
            selector: row => row.toWallet,
            sortable: true,
            center: true,
            width: '135px'
        },
        {
            name: <H4>Payment Type</H4>,
            selector: row => row.payType,
            sortable: true,
            center: true,
        },
    ];

    const paymentHistoryColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '50px'
            // center: true,
        },
        {
            name: <H4>Action</H4>,
            selector: row => row.Action,
            sortable: true,
            width: '120px'

        },
        {
            name: <H4>Paid On</H4>,
            selector: row => row.Paidat,
            sortable: true,
            // center: true,
            wrap: true
        },
        {
            name: <H4>Txn Id</H4>,
            selector: row => row.txnId,
            sortable: true,
            // center: true,
            wrap: true


        },
        {
            name: <H4>Amount</H4>,
            selector: row => row.Amount,
            sortable: true,
            // center: true,
            wrap: true,
            width: '100px'
        },
        {
            name: <H4>Reflected Invoice</H4>,
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${propsId}`}>
                    {row.RefInvoice}
                </Link>,
            // selector: row => row.RefInvoice,
            sortable: true,
            center: true,
            width: '165px'
        },
        {
            name: <H4>Payment Type</H4>,
            selector: row => row.payType,
            sortable: true,
            center: true,
        },
    ];

    useEffect(() => {
        invoiceData(1); // do not put await here
        transactionData(1);
        paymentHistoryData(1);
    }, []);
    const handlePageChange = page => {
        invoiceData(page);
        setCurrentPage(page);
    };

    const handlePaymentPageChange = page => {
        transactionData(page);
        setPaymentCurrentPage(page);
    };

    const handlePaymentHistoryPageChange = page => {
        paymentHistoryData(page);
        setPaymentHistoryCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        invoiceData(page, newPerPage);
        transactionData(page, newPerPage);
        paymentHistoryData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const redirectToInvoice = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${data.order_id}&customer_id=${propsId}`;
        navigate(editFormURL);
    };

    const redirectToReciept = (id) => {
        const toReciept = `${process.env.PUBLIC_URL}/billing/thermal-print?payment_id=${id}`;
        navigate(toReciept);
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const deleteTransaction = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const deleteRes = { payment_id: row.payment_id }
                    let transactionDelete = await postClientUrlWithToken(authState.apiUrl, reversePayment, deleteRes);
                    if (transactionDelete.status === 200) {
                        if (transactionDelete.data.status === true) {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            window.location.reload();
                            setLoading(false);
                        }
                        else {
                            toast.error(transactionDelete.data.message);
                        }
                    }
                    else if (transactionDelete.status >= 400 && transactionDelete.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (transactionDelete.status >= 500 && transactionDelete.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                }
            });
        };
        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => redirectToReciept(row.payment_id)}>Reciept</DropdownItem>
                    {authState.userRole === 1 &&
                        <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={() => deleteTransaction(row.payment_id)}>Delete</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };


    const invoiceData = async (page, size = perPage) => {
        setLoading(true)
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getRecentInvoice + `?customer_id=${propsId}&page=${page}&page_length=${size}` :
            getRecentInvoiceRole + `?customer_id=${propsId}&page=${page}&page_length=${size}`);
        if (data.status === true) {
            setInvoiceList(data.data.map((e, index) => {
                const tax = e.tax.replace(/\.?0+$/, '');
                return {
                    Si: index + 1,
                    Subscription: e.subscription_name,
                    Invoice: e.invoice_number,
                    InDate: e.start_date,
                    BillingDate: e.billing_date,
                    AmountDue: <span className='text-danger' style={{ fontWeight: 'bold' }}>{authState.currency + " " + e.amount_due}</span>,
                    Total: authState.currency + " " + e.total,
                    Customer: e.customer_name,
                    Status: e.payment_status,
                    UnitPrice: e.unit_price !== null ? authState.currency + " " + e.unit_price : e.unit_price,
                    order_id: e.order_id,
                    id: e.id,
                    customerid: e.customer_id,
                    Tax: authState.currency + " " + tax,
                    action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToInvoice(e) }}>{ViewMore}</Btn>
                            </span>
                        </div>
                }
            }))
            setTotalRows(data.data.total);
            setLoading(false);
        } else if (data.status >= 400 && data.status <= 405) {
            // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    };

    const transactionData = async (page, size = perPage) => {
        setLoading(true)
        const transaction_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getRecentPayment + `?customer_id=${propsId}&page=${page}&page_length=${size}`
            : getRecentPaymentRole + `?customer_id=${propsId}&page=${page}&page_length=${size}`)
        if (transaction_api.status === true) {
            const paymentData = transaction_api.data.data;
            setTransaction(paymentData.map((e, index) => {
                return {
                    Si: index + 1,
                    payment_id: e.id,
                    Name: e.customer_name,
                    Paidat: e.paid_at,
                    txnId: e.transaction_id,
                    payType: e.payment_type,
                    Amount: authState.currency + " " + e.paid_amount,
                    toInvoice: authState.currency + " " + e.to_invoice,
                    toWallet: authState.currency + " " + e.to_wallet,
                }
            }))
            setpaymentTotalRows(transaction_api.data.total);
            setLoading(false)
        } else if (transaction_api.status >= 400 && transaction_api.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (transaction_api.status >= 500 && transaction_api.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    }

    const paymentHistoryData = async (page, size = perPage) => {
        setLoading(true)

        const payment_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getPaymentHistory + `?customer_id=${propsId}&page=${page}&page_length=${size}` :
            getPaymentHistoryRole + `?customer_id=${propsId}&page=${page}&page_length=${size}`)
        if (payment_api.status === true) {
            const paymentData = payment_api.data.data;
            setPaymentHistory(paymentData.map((e, index) => {
                return {
                    Si: index + 1,
                    payment_id: e.id,
                    Name: e.customer_name,
                    Paidat: e.date,
                    txnId: e.transaction_id,
                    payType: e.payment_type,
                    Amount: authState.currency + e.collected_amount,
                    RefInvoice: e.invoice_number,
                    order_id: e.order_id,
                    Action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToReciept(e.payment_id) }}>{Reciept}</Btn>
                            </span>
                        </div >
                }
            }))
            setPaymentHistoryTotalRows(payment_api.data.total);
            setLoading(false)
        } else if (payment_api.status >= 400 && payment_api.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (payment_api.status >= 500 && payment_api.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    }

    const exportTransactionExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getRecentPayment + `?customer_id=${propsId}&export=transaction` :
            getRecentPaymentRole + `?customer_id=${propsId}&export=transaction`)
        const dataToExport = response.data.map(({ paid_at, transaction_id, amount, to_invoice, to_wallet, payment_type }) => ({ Paid_at: paid_at, Transaction: transaction_id, Amount: amount, To_Invoice: to_invoice, To_Wallet: to_wallet, Payment_Type: payment_type }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Payment Data');
            XLSX.writeFile(wb, 'Transaction_data.xlsx');
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };



    const exportTransactionPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getRecentPayment + `?customer_id=${propsId}&export=transaction` :
            getRecentPaymentRole + `?customer_id=${propsId}&export=transaction`)
        const dataToExport = response.data.map(({ paid_at, transaction_id, amount, to_invoice, to_wallet, payment_type }, index) => ({ Sl: index + 1, paid_at, transaction_id, amount, to_invoice, to_wallet, payment_type }));
        if (response.status === true) {
            const doc = new jsPDF();
            const pageWidth = doc.internal.pageSize.getWidth();
            const heading = "Transactions";
            doc.setFontSize(18);
            doc.setFont("helvetica", "bold");
            const headingWidth = doc.getTextWidth(heading);
            const headingX = (pageWidth - headingWidth) / 2;
            doc.text(heading, headingX, 22);

            const custHeading = `Customer name : ${personalInfo.customer_name}`;
            const CustAddress = `Address : ${personalInfo.customer_details.address}`;
            const subId = `Sub id : ${personalInfo.customer_details.subscriber_id}`;
            const phone = `Phone : + ${personalInfo.customer_details.country_code} ${personalInfo.customer_details.mobile}`;
            doc.setFontSize(9);
            doc.text(subId, 15, 32);
            doc.text(custHeading, 15, 37);
            doc.text(CustAddress, 15, 42);
            doc.text(phone, 15, 47);
            const columns = ['SI', "Paid On", "Txn Id", "Amount Collected", "To invoice", "To Wallet", "Payment Type"];
            const rows = dataToExport.map(({ paid_at, transaction_id, amount, to_invoice, to_wallet, payment_type }, index) => [index + 1, paid_at, transaction_id, amount, to_invoice, to_wallet, payment_type]);
            doc.autoTable({
                head: [columns],
                body: rows,
                startY: 50
            });
            doc.save("Transaction_data.pdf");
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };



    const exportPaymentExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getPaymentHistory + `?customer_id=${propsId}&export=transaction` :
            getPaymentHistoryRole + `?customer_id=${propsId}&export=transaction`)
        const dataToExport = response.data.map(({ date, transaction_id, collected_amount, invoice_number, payment_type }) => ({ Date: date, Transaction_id: transaction_id, Amount: collected_amount, Reflected_Invoice: invoice_number, Payment_Type: payment_type }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'History Data');
            XLSX.writeFile(wb, 'Payment_history.xlsx');
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    const exportPaymentPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getPaymentHistory + `?customer_id=${propsId}&export=transaction` :
            getPaymentHistoryRole + `?customer_id=${propsId}&export=transaction`);
        const dataToExport = response.data.map(({ date, transaction_id, collected_amount, invoice_number, payment_type }, index) => ({ Sl: index + 1, date, transaction_id, collected_amount, invoice_number, payment_type }));
        if (response.status === true) {
            const doc = new jsPDF();
            const pageWidth = doc.internal.pageSize.getWidth();
            const heading = "Payment history";
            doc.setFontSize(18);
            doc.setFont("helvetica", "bold");
            const headingWidth = doc.getTextWidth(heading);
            const headingX = (pageWidth - headingWidth) / 2;
            doc.text(heading, headingX, 22);

            const custHeading = `Customer name : ${personalInfo.customer_name}`;
            const CustAddress = `Address : ${personalInfo.customer_details.address}`;
            const subId = `Sub id : ${personalInfo.customer_details.subscriber_id}`;
            const phone = `Phone : + ${personalInfo.customer_details.country_code} ${personalInfo.customer_details.mobile}`;
            doc.setFontSize(9);
            doc.text(subId, 15, 32);
            doc.text(custHeading, 15, 37);
            doc.text(CustAddress, 15, 42);
            doc.text(phone, 15, 47);

            const columns = ["Paid On", "Txn Id", "AmountCollected", "Reflected Invoice", "Payment Type"];
            const rows = dataToExport.map(({ date, transaction_id, collected_amount, invoice_number, payment_type }, index) => [index + 1, date, transaction_id, collected_amount, invoice_number, payment_type]);
            doc.autoTable({
                head: [columns],
                body: rows,
                startY: 50,
            });
            doc.save("Payment_history.pdf");
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <>
            <Col sm="12" xl="6 xl-100">
                <Card>
                    <CardBody>
                        {PrimarycolorLineTab === '2' && (
                            <Dropdown className='ms-2' isOpen={dropdownOpen} toggle={toggleDropdown} style={{ float: 'right', marginRight: '10px' }}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportTransactionExcel()}><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportTransactionPdf()}><i className="fa fa-file-excel-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        )}
                        {PrimarycolorLineTab === '3' && (
                            <Dropdown className='ms-2' isOpen={dropdownOpen} toggle={toggleDropdown} style={{ float: 'right', marginRight: '10px' }}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportPaymentExcel()}><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportPaymentPdf()}><i className="fa fa-file-excel-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        )}
                        <Nav className="border-tab nav-primary" tabs>
                            <NavItem>
                                <NavLink className={PrimarycolorLineTab === '1' ? 'active' : ''} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('1')}>{Invoice}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={PrimarycolorLineTab === '2' ? 'active' : ''} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('2')}>{Transactions}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={PrimarycolorLineTab === '3' ? 'active' : ''} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('3')}>{PaymentHistory}</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={PrimarycolorLineTab}>
                            <TabPane className="fade show" tabId="1">
                                <div className="order-history table-responsive" style={{ marginTop: '-15px' }}>
                                    <DataTable
                                        data={invoiceList}
                                        columns={InvoiceColumns}
                                        pagination
                                        progressPending={loading}
                                        paginationServer
                                        defaultSortAsc
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        conditionalRowStyles={conditionalRowPayments}
                                        style={{ border: '1px solid' }}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="order-history table-responsive" style={{ marginTop: '-15px' }}>
                                    <DataTable
                                        data={transactionlist}
                                        columns={paymentColumns}
                                        pagination
                                        progressPending={loading}
                                        paginationServer
                                        defaultSortAsc
                                        paginationTotalRows={totalPaymentRows}
                                        paginationDefaultPage={currentPage}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePaymentPageChange}
                                        style={{ border: '1px solid' }}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div className="order-history table-responsive" style={{ marginTop: '-15px' }}>
                                    <DataTable
                                        data={paymentHistorylist}
                                        columns={paymentHistoryColumns}
                                        pagination
                                        progressPending={loading}
                                        paginationServer
                                        defaultSortAsc
                                        paginationTotalRows={totalPaymentHistoryRows}
                                        paginationDefaultPage={currentPage}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePaymentHistoryPageChange}
                                        style={{ border: '1px solid' }}
                                    />
                                </div>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default PayInvoiceTabs;
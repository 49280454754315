import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Media, Label, Input, Form, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, CasProvider, CasProviderList, Category, CategoryList, Search, Type, Filter } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { deleteCategory, deleteCategoryRole, statusCategory, statusCategoryRole, viewCategory, viewCategoryRole } from '../../../CommonUrl';
import { toast } from 'react-toastify';
import { CategoryColumns } from '../../../Data/MockTable/SettingsTableHeader';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const CategoriesContainer = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const authState = useSelector((state) => state.auth);
    const [categoriesList, setCategoryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        if (searchKeyword == '') {
            categoriesData();
        }
    }, [searchKeyword]);

    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        categoriesData();
    };

    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { category_id: itemId }
        } else {
            reqSwitch = { category_id: itemId }
        }
        const statusChange = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusCategory : statusCategoryRole, reqSwitch);
        if (statusChange.status === 200) {
            toast.success(statusChange.data.message);
        } else if (statusChange.status >= 400 && statusChange.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (statusChange.status >= 500 && statusChange.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    }

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/settings/categories-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const deleteCategoryList = (userId) => {
        SweetAlert.fire({
            title: 'Confirm deletion?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                const deleteRes = { category_id: userId }
                let categoryDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteCategory : deleteCategoryRole, deleteRes);
                if (categoryDelete.status === 200) {
                    if (categoryDelete.data.status === true) {
                        SweetAlert.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                        categoriesData();
                    }
                } else if (categoryDelete.status >= 400 && categoryDelete.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (categoryDelete.status >= 500 && categoryDelete.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                }
            }
            setLoading(false);
        });
    };

    const categoriesData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewCategory : viewCategoryRole);
        if (data.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            setLoading(true);
            if (Array.isArray(data.data)) {
                const filteredCategories = data.data.filter((item) => {
                    const nameMatch = item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase());
                    const TypeMatch = item.type && item.type.toLowerCase().includes(searchKeyword.toLowerCase());
                    return nameMatch || TypeMatch;
                });

                setCategoryList(filteredCategories.map((e, index) => {
                    return {
                        Si: index + 1,
                        Name: <span>{e.name}</span>,
                        Type: <span>{e.type}</span>,
                        Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                        action:
                            <div>
                                <span>
                                    <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                                </span>
                                <span>
                                    <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteCategoryList(e.id) } }}>Delete</Btn>
                                </span>
                            </div >
                    }
                }))
                setLoading(false);
            } else {
                setCategoryList(filteredCategories.map((e, index) => {
                    return {
                        Si: index + 1,
                        Name: <span>{e.name}</span>,
                        Description: <span>{e.description}</span>,
                        Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                        action:
                            <div>
                                <span>
                                    <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                                </span>
                                <span>
                                    <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteCategoryList(e.id) } }}>Delete</Btn>
                                </span>
                            </div >
                    }
                }))
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Category} parent="Settings" title={Category} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{CategoryList}</H3>
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/settings/categories-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>

                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} type="text" value={searchKeyword} onChange={handleSearchInputChange}
                                    />
                                </Col>
                                {/* <Col sm="12" md="5" lg='2'>
                                    <select className="form-select" id="validationCustom04" {...register('type')}>
                                        <option value="">{Type}</option>
                                        {Options.map((option, index) => (
                                            <option key={index} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </Col> */}
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={categoriesList}
                            columns={CategoryColumns}
                            pagination
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment >
    );
};

export default CategoriesContainer;
import React, { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import './billerqStyle.scss'

const FAQ = () => {
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return (
        <div className='FAQ-container'>
            <div className='FAQ-title'>
                <h2>Frequently Asked Questions</h2>
                <div className="buy-or-sell">
                <h3 >Elevate Your Bussiness With Just One-Click</h3>
                    <p>BillerQ is an Industry fit customizable subscriber/subscription <br/>management system designed by an Indian tech startup Quadleo, That can manage</p>
                    </div>
            </div>
            <div className='header-design-elements6'>
                <div className='design-element6'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="91" height="38" viewBox="0 0 91 50" fill="none">
                        <path d="M77.3738 0.972153C62.1838 0.125538 47.9238 10.8276 39.1238 17.4377C34.2225 21.0995 28.8962 24.0412 23.2838 26.1861C21.8222 26.7709 20.321 27.2318 18.7938 27.5646C13.6438 28.3606 8.3955 27.9121 3.42378 26.2512C2.77378 26.0341 2.08378 25.9473 1.42378 25.6651C0.763778 25.3829 0.52378 25.2092 0.25378 25.3937C-0.0162204 25.5783 -0.00622035 26.2187 0.00377965 26.3055C0.22378 28.5414 9.69378 30.4951 18.2838 30.1152C22.4396 29.9124 26.5649 29.2431 30.5938 28.1181C34.0838 27.1195 34.8838 26.7288 42.5338 23.8308C45.7438 22.626 50.3038 20.5745 53.6838 22.1809C55.4938 23.0601 56.9238 25.7628 56.3438 27.7599C55.8338 29.4532 53.9138 30.3106 52.7438 30.5169C51.5872 30.8062 50.3743 30.6608 49.3038 30.1044C48.8538 29.8222 48.3038 29.3772 47.8038 29.5617C47.6346 29.616 47.4817 29.7178 47.3605 29.857C47.2393 29.9962 47.1542 30.1676 47.1138 30.354C47.0238 31.3309 49.2038 32.286 49.4538 32.3946C50.1038 32.6768 54.1638 34.4569 57.1838 31.5914C57.9223 30.8548 58.5022 29.9507 58.8825 28.9433C59.2627 27.9359 59.4339 26.8501 59.3838 25.7628C59.0038 21.4212 54.6538 19.3806 54.3838 19.2504C51.5238 17.937 47.2138 18.903 39.2738 22.5066C36.1451 23.9276 32.9351 25.1277 29.6638 26.0993C38.5638 21.1064 46.9138 15.115 55.7238 9.9593C61.8933 6.15837 68.8231 4.04603 75.9138 3.80504C82.2738 3.71821 88.0238 7.06127 89.5938 14.2032C90.0217 16.0429 89.9807 17.9742 89.4752 19.7905C88.9696 21.6069 88.0186 23.2398 86.7238 24.5146C83.8438 27.3366 80.5238 27.3366 76.9138 27.7708C76.6438 27.7708 75.9138 27.9662 75.9138 28.3135C75.9138 28.6608 76.3038 28.9322 76.4538 29.0407C77.6438 29.8982 86.2838 30.8099 91.2038 24.1564C93.0979 21.6599 94.1274 18.5227 94.1138 15.2886C94.0331 12.2823 93.0381 9.3894 91.2838 7.06126C87.3038 1.63423 80.4638 1.14582 77.3738 0.972153Z" fill="black" />
                    </svg>
                </div>
            </div>
            <div className='row d-md-flex col-md-12'>   
                <div className='col-sm-12 col-md-4'>
                    <img src='/Services/complaints.png' alt='FAQ Image' className='FAQ-image' />
                    {/* <div style={{ height: '100px', width: '100px', background: '#6B4C9F' }}></div> */}
                </div>
                <div className='col-md-8'>
                 
                    <Accordion flush open={open} toggle={toggle}>
                        <AccordionItem>
                            <AccordionHeader  className= "accordion-head"  targetId="1">What is a subscription management system?
                            </AccordionHeader>
                            <AccordionBody accordionId="1">
                                A subscription management system is a software solution that helps businesses handle various aspects of subscription-based services or products. It typically includes features for customer management, billing, invoicing, analytics, and automation of subscription-related processes.

                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className= "accordion-head" targetId="2">Why do businesses need a subscription management system?</AccordionHeader>
                            <AccordionBody accordionId="2">
                                Businesses use subscription management systems to streamline subscription processes, improve customer experience, automate billing, and gain insights into subscription metrics. It helps manage recurring revenue models efficiently.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader  className= "accordion-head"targetId="3">What industries can benefit from subscription management systems?
                            </AccordionHeader>
                            <AccordionBody accordionId="3">
                                Subscription management systems are versatile and can be used in various industries, including media and entertainment, SaaS, e-commerce, telecommunications, healthcare, education, and more.

                            </AccordionBody>
                        </AccordionItem>


                        <AccordionItem>
                            <AccordionHeader className= "accordion-head" targetId="4">What features should I look for in a subscription management system?

                            </AccordionHeader>
                            <AccordionBody accordionId="4">
                            Essential features include customer management, flexible subscription plans, billing and invoicing automation, analytics and reporting, integration capabilities, and support for various payment gateways.


                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader className= "accordion-head" targetId="5">How does a subscription management system handle billing and invoicing?

                            </AccordionHeader>
                            <AccordionBody accordionId="5">
                            Subscription management systems automate billing cycles, generate invoices based on subscription plans, and integrate with payment gateways to facilitate secure online transactions. They may also handle failed payments and provide tools for revenue recognition.


                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionHeader  className= "accordion-head" targetId="6"> Can a subscription management system handle different subscription models?

                            </AccordionHeader>
                            <AccordionBody accordionId="6">
                            Yes, a good subscription management system should support various subscription models, including monthly, yearly, tiered plans, and customizable options. It should be flexible enough to accommodate different billing frequencies and pricing structures.


                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader  className= "accordion-head" targetId="7">Is a subscription management system suitable for small businesses?
                            </AccordionHeader>
                            <AccordionBody accordionId="7">
                            Yes, subscription management systems come in various sizes and functionalities, making them suitable for small to large businesses. Small businesses can benefit from automating subscription processes, improving customer management, and ensuring accurate billing.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader  className= "accordion-head" targetId="8">How does a subscription management system help with customer retention?

                            </AccordionHeader>
                            <AccordionBody accordionId="8">
                            Subscription management systems often include churn prediction tools and features for implementing customer retention strategies. Businesses can use data analytics to identify at-risk customers and offer targeted promotions or personalized incentives to reduce churn.
                            </AccordionBody>

                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader  className= "accordion-head" targetId="9">Is data security a concern with subscription management systems?
                            </AccordionHeader>
                            <AccordionBody accordionId="9">
                            A reputable subscription management system should prioritize data security. Look for systems that comply with industry standards, implement data encryption, and provide secure access controls to protect customer information and financial data.

                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader  className= "accordion-head" targetId="10">Can subscription management systems integrate with other business tools?

                            </AccordionHeader>
                            <AccordionBody accordionId="10">
                            Yes, many subscription management systems offer APIs and support integrations with other business tools, such as CRM systems, accounting software, and marketing platforms. This ensures a seamless flow of data across different aspects of the business.
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default FAQ
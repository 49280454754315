import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import { H3, H5, Image, P } from "../../../AbstractElements";
import { Connections } from "../../../Constant";
import { ActivityData } from "../../../Data/Dashboard/Default/Data";
import { Link } from 'react-router-dom';

const ActivityReview = (connections, { error }) => {
    const connnection = connections.connections.connections;
    return (
        <Fragment>
            <Col xl="3" md="6" lg="4">
                <Card className="activity-review" style={{ height: '265px' }}>
                    <CardHeader className="pb-0">
                        <H3> {Connections} </H3>
                    </CardHeader>
                    <CardBody>
                        {error ? (<span>{error}</span>
                        ) : (
                            <div className="table-responsive connection-div" >
                                <Table className="table table-bordernone" >
                                    <tbody>
                                        {connnection.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="d-flex justify-content-between" >
                                                            <div className="" >
                                                                <H5>{data.connection_type}</H5>
                                                                <P>{Connections}</P>
                                                            </div>
                                                            <div className="me-3">
                                                                <P>{data.stb_count}</P>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody >
                                </Table>
                            </div >
                        )}


                    </CardBody>
                </Card>
            </Col>
        </Fragment >
    )
}
export default ActivityReview;
import React, { Fragment, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../Layout/SpinnerLoader";
import { Vendor, VendorAdd, Title, Save, TaxIn, MobileNo, Email, Address } from "../../Constant";
import { addVendor, addVendorRole } from "../../CommonUrl";
import { useSelector } from "react-redux";
import { postClientUrlWithToken } from "../../Utils/restUtils";
import { toast } from 'react-toastify';
import PhoneInput from "react-phone-input-2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    title: yup.string().required().label('Title'),
})

const VendorForm = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const countryCode = data?.countryCode ?? "";
        const phoneNumber = data?.phonenumber ?? "";

        const b = phoneNumber.slice(countryCode.length);
        const postRequest = {
            title: data.title,
            tax_in: data.tax_in,
            country_code: countryCode,
            mobile: b,
            email: data.email,
            address: data?.address ?? ''
        };

        const addCustomerResponse = await postClientUrlWithToken(
            authState.apiUrl, authState.userRole === 1 ? addVendor : addVendorRole, postRequest
        );

        if (addCustomerResponse.status === 200) {
            if (addCustomerResponse.data.status === true) {
                navigate(`${process.env.PUBLIC_URL}/Expenses/Vendor`);
                toast.success(addCustomerResponse.data.message);
            } else {
                toast.error(addCustomerResponse.data.message);
                setLoading(false);
            }
        } else if (addCustomerResponse.status >= 400 && addCustomerResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addCustomerResponse.status >= 500 && addCustomerResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={Vendor} parent="Settings" title={Vendor} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{VendorAdd}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label">
                                    {Title} <span className="requireStar">*</span>
                                </Label>
                                <input
                                    name="title"
                                    className="form-control"
                                    type="text"
                                    {...register("title")}
                                />
                                <p className="text-danger">{errors.title?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="orderNo">
                                    {TaxIn}
                                </Label>
                                <input
                                    name="tax_in"
                                    className="form-control"
                                    type="text"
                                    {...register("tax_in", { required: true })}
                                />
                                <p className="text-danger">{errors.taxin?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" >
                                    {MobileNo}
                                </Label>
                                <Controller
                                    name="phonenumber"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <PhoneInput
                                            {...field}
                                            country={"in"}
                                            enableSearch={true}
                                            onChange={(value, country) => {
                                                setValue("countryCode", country.dialCode);
                                                field.onChange(value);
                                            }}
                                            inputStyle={{
                                                width: "100%",
                                                height: "46px",
                                                padding: "10px",
                                                paddingLeft: "50px",
                                                fontSize: "16px",
                                                borderRadius: "8px",
                                                borderColor: "rgba(83, 70, 134, 0.1019607843)",
                                            }}
                                            searchClass="none"
                                        />
                                    )}
                                />
                                {/* <p className="text-danger">{errors.phonenumber?.message}</p> */}
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >
                                    {Email}
                                </Label><input
                                    className="form-control"
                                    id="validationCustom01"
                                    type="email"
                                    {...register("email", { required: true })}
                                />
                                <p className="text-danger">{errors.email?.message}</p>
                            </Col>
                            <Col>
                                <Label className="form-label">{Address}</Label>
                                <textarea className="form-control"
                                    {...register("address", { required: true })}

                                />
                                <p className="text-danger">{errors.address?.message}</p>
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default VendorForm;



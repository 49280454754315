import React, { useState } from 'react'
import { Save, Title, TaxIn, MobileNo, Email, Address } from '../../Constant'
import { Col, Form, Label, Row, FormGroup } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import { Btn } from '../../AbstractElements'
import { postClientUrlWithToken } from '../../Utils/restUtils'
import { useSelector } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import { addVendor, addVendorRole } from '../../CommonUrl'
import { toast } from 'react-toastify'
import PhoneInput from "react-phone-input-2";
import { useNavigate } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from '../Common/Loader/Transparentspinner'

const schema = yup.object().shape({
    title: yup.string().required().label('Title'),
})

const VendorAdd = ({ setModal, handleVendorAdded }) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const authState = useSelector((state) => state.auth);


    const onSubmit = async (data) => {
        setLoading(true)
        const countryCode = data?.countryCode ?? '';
        const phoneNumber = data?.phonenumber ?? '';
        const b = phoneNumber.slice(countryCode.length);
        const postRequest = {
            title: data.title,
            tax_in: data.taxin,
            country_code: countryCode,
            mobile: b,
            email: data.email,
            address: data?.address
        };

        const vendorAdd = await postClientUrlWithToken(
            authState.apiUrl, authState.userRole === 1 ? addVendor : addVendorRole, postRequest
        );

        if (vendorAdd.status === 200) {
            if (vendorAdd.data.status === true) {
                handleVendorAdded(data.title); // Call the callback function
                setModal(false);
                toast.success(vendorAdd.data.message);
            } else {
                toast.error(vendorAdd.data.message);
                setLoading(false);
            }
        } else if (vendorAdd.status >= 400 && vendorAdd.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (vendorAdd.status >= 500 && vendorAdd.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);

    };
    return (
        <>
            {loading && <Transparentspinner />}
            <Form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >

                <Row className="g-3 pt-3">
                    <Col md="6">
                        <Label className="form-label" >
                            {Title} <span className="requireStar">*</span>
                        </Label>
                        <input
                            name="title"
                            className="form-control"
                            type="text"
                            {...register("title")}
                        />
                        <p className="text-danger">{errors.title?.message}</p>
                    </Col>
                    <Col md="6">
                        <Label className="form-label" >
                            {TaxIn}
                        </Label>
                        <input
                            name="taxin"
                            className="form-control"
                            type="text"
                            {...register("taxin")}
                        />

                    </Col>
                </Row>
                <Row className="g-3 pt-3">
                    <Col md="6">
                        <Label className="form-label" >
                            {MobileNo}
                        </Label>
                        <Controller
                            name="phonenumber"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <PhoneInput
                                    {...field}
                                    country={"in"}
                                    enableSearch={true}
                                    onChange={(value, country) => {
                                        setValue("countryCode", country.dialCode);
                                        field.onChange(value);
                                    }}
                                    inputStyle={{
                                        width: "100%",
                                        height: "46px",
                                        padding: "10px",
                                        paddingLeft: "50px",
                                        fontSize: "16px",
                                        borderRadius: "8px",
                                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                                    }}
                                    searchClass="none"
                                />
                            )}
                        />

                    </Col>
                    <Col md="6">
                        <Label className="form-label" >
                            {Email}
                        </Label>
                        <input
                            className="form-control"
                            id="validationCustom01"
                            type="email"
                            {...register("email", { required: true })}
                        />
                        <p className="text-danger">{errors.email?.message}</p>
                    </Col>
                    <Col>
                        <Label className="form-label">{Address}</Label>
                        <textarea className="form-control"
                            {...register("address", { required: true })}

                        />
                        <p className="text-danger">{errors.address?.message}</p>
                    </Col>


                </Row>
                <FormGroup></FormGroup>
                <span className='ps-2 spinner-enable'>
                    <Btn attrBtn={{ color: "primary", className: "btn" }} type="submit">

                        {Save}
                    </Btn>
                </span>

            </Form>
        </>
    )
}

export default VendorAdd
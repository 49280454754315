import React from 'react'
import OopsImage from '../../../assets/images/error/ops-white.png'
import { Spinner } from '../../../AbstractElements'

const OopserrorImageWhite = () => {
  return (
    <>
      <div className='d-flex justify-content-center' style={{ height: '100%' }}>

        {/* <img src={OopsImage} alt='error' width='160px' height='160px' /> */}
        <div className="loader-box ">
          <Spinner attrSpinner={{ className: "loader-38" }} />
        </div>
      </div>
    </>
  )
}

export default OopserrorImageWhite
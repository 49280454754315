import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, Label, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import {
  PriceName,
  Next,
  ItemName,
  QuantityName,
  Items,
  Products,
  Services,
  Invoicesiffix,
  Invoiceprefix,
  DueDate,
  DefaultDueDays,
} from "../../../../Constant";
import { duedateOptions } from "../../../Company/Duedate";
import Typeahead from "../../../../CommonElements/TypeAhead/index";


const Formstep3 = ({ setSteps, setFormdata, formdata }) => {
  const [icon, setIcon] = useState(formdata.icon || '')

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();


  const priceOption = [
    {value:"price",label:"Price"},
    {value:"rate",label:"Rate"},
  ]
  const qtyOption = [
    {value:"quantitiy",label:"Quantity"},
  ]

  const itemOption = [
    {value:"items",label:Items},
    {value:"products",label:Products},
    {value:"services",label:Services},
  ]

  useEffect(() =>{
    const inputElement = document.querySelectorAll('input[type="number"]');
    
    const preventScrollEvent = (event) =>{
      event.preventDefault();
    };
    
    inputElement.forEach((inputElement) => {
        inputElement.addEventListener('wheel', preventScrollEvent);
  
        return () => {
          inputElement.removeEventListener('wheel', preventScrollEvent);
        };
      });        
    },[]);


  const handleIconChange = (event) => {
    const selectedFile = event.target.files[0];
    setIcon(selectedFile)

  }


  const onSubmit = async (data) => {
    if (data) {
      setFormdata((prev) => ({ ...prev, ...data, icon }));
      setSteps((prev) => prev + 1);

    }
  };

  return (
    <Fragment>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="form-bookmark needs-validation"
      >
        {/* <Row className="g-3 mt-3">
          <Col md="6">
            <Label >{PriceName}</Label>
            <Controller
                name="PriceName"
                control={control}
                defaultValue={formdata.PriceName || ""}

                render={({ field }) => (
                  <Typeahead
                    options={priceOption}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    value={field.value}

                  />
                )}
              />

          </Col>
          <Col md="6">
            <Label >{ItemName}</Label>
            <Controller
                name="ItemName"
                control={control}
                defaultValue={formdata.ItemName || ""}

                render={({ field }) => (
                  <Typeahead
                    options={itemOption}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    value={field.value}

                  />
                )}
              />

          </Col>
        </Row> */}
        <Row className="g-3 pt-3">
          <Col md="6">
            <Label htmlFor="prefix">{Invoiceprefix}</Label>
            <input
            id="prefix"
              className="form-control"
              type="text"
              name="inv-prefix"
              defaultValue={formdata.invprefix || ""}

              {...register("invprefix")}
            />
          </Col>
          <Col md="6">
            <Label htmlFor="suffix">{Invoicesiffix}</Label>
            <input
            id="suffix"
              className="form-control"
              type="number"
              name="inv-suffix"
              defaultValue={formdata.invsuffix || ""}
              min={1}
              {...register("invsuffix")}
            />
          </Col>
        </Row>

        <Row className="g-3 pt-3">
          <Col md="6">
            <Label >{QuantityName}</Label>
            <Controller
                name="QuantityName"
                control={control}
                defaultValue={formdata.QuantityName || ""}

                render={({ field }) => (
                  <Typeahead
                    options={qtyOption}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    value={field.value}

                  />
                )}
              />

          </Col>
          <Col md="6">
            <Label >{DefaultDueDays}</Label>
            <Controller
                name="duedate"
                control={control}
                defaultValue={formdata.duedate || ""}

                render={({ field }) => (
                  <Typeahead
                    options={duedateOptions}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    value={field.value}

                  />
                )}
              />

          </Col>

        </Row>
        <Row>
          <Col md="6 pt-3">
            <Label className="form-label">Icon</Label>
            <Controller
              name="icon"
              control={control}
              render={({ field }) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Col md='8'>
                    <input
                      {...register("icon")}
                      className="form-control fileInputStyle"
                      type="file"
                      onChange={handleIconChange}
                      accept=".png, .jpg, .jpeg"
                    />
                  </Col>
                  <Col md='4'>
                    <span className="imagestyle">

                      {icon && <img src={URL.createObjectURL(icon)} alt="icon" width="40px" height="40px" />}
                    </span>

                  </Col>
                </div>
              )}
            />
            {/* <span className="text-danger">
              {errors.icon && "icon is required"}
            </span> */}
          </Col>
        </Row>
        <div className="text-end">
          <Btn attrBtn={{ color: "primary", type: "submit" }}>{Next}</Btn>
        </div>
      </Form>
    </Fragment>
  );
};

export default Formstep3;

import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import DataTable from 'react-data-table-component';
import { StbColumns, stbPackageplandata } from '../../../Data/MockTable/UserTableHeader';
import { StbModem, currentSubcription } from '../../../Constant';
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { getPackage, getSingletbplans, getSingletbplansRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const SubscribedTable = (props) => {
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const stbId = props.data;
    const setSpinner = props.setSpinner

    const [isStb, setisStb] = useState(null);
    useEffect(() => {
        const getsubData = async () => {

            if (stbId) {
                setSpinner(true);
                let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getSingletbplans + `?stb_id=${stbId}` : getSingletbplansRole + `?stb_id=${stbId}`);
                if (data.status === true) {
                    setisStb(data.data.map((e, index) => {
                        return {
                            Sl: index + 1,
                            package_name: e.package_name,
                            type: e.type,
                            recur_status: e.recurring_status,
                            start_date: e.start_date,
                            end_date: e.end_date,

                        }
                    })
                    )

                }
                else if (data.status >= 400 && data.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (data.status >= 500 && data.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
                else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

                }
                setSpinner(false);
            }

        }
        getsubData();
        // };
    }, [stbId])



    return (
        <>
            {isStb != null && isStb.length > 0 && (
                <Row className="justify-content-center ">
                    <div style={{ paddingTop: '25px', paddingBottom: '5px' }}>
                        <H3 attrH3={{ style: { paddingLeft: '0px' } }}>{currentSubcription}</H3>
                    </div>
                    <Col className='bg-subscription' >
                        <CardBody style={{ padding: '0px' }}>
                            <div className="order-history table-responsive " style={{ height: 'auto' }}>
                                <DataTable
                                    data={isStb}
                                    columns={stbPackageplandata}
                                    fixedHeader
                                    fixedHeaderScrollHeight='150px'
                                />
                            </div>
                        </CardBody>
                    </Col>

                </Row>
            )}

        </>
    )
}

export default SubscribedTable
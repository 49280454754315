import React, { Fragment, useEffect, useState } from 'react';
import { Image, LI, P, UL } from '../../../AbstractElements';
import { User, Mail, FileText, LogOut } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import man from '../../../assets/images/dashboard/profile.png';
import { Account, Inbox, logout, Taskboard } from '../../../Constant';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../../Redux/Reducer';
import defaultUserImage from '../../../assets/images/user-profile/default-users.png';


const LogoutClass = () => {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const UserName = useSelector((set) => set.auth.userName)
    const authState = useSelector((state) => state.auth);
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));
    const [profile, setProfile] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || defaultUserImage);
        setName(localStorage.getItem('Name') || UserName);
    }, [setProfile, setName]);

    const Logout = () => {
        localStorage.removeItem('profileURL');
        localStorage.removeItem('token');
        localStorage.removeItem('auth0_profile');
        localStorage.removeItem('Name');
        localStorage.setItem('authenticated', false);
        localStorage.setItem('login', false);
        dispatch(setLogout());
        Navigate('/SignIn');
    };

    return (
        <Fragment>
            <LI attrLI={{ className: "profile-nav onhover-dropdown p-0" }} >
                <div className="d-flex profile-media">
                    <Image attrImage={{ className: 'b-r-50 m-0', src: authenticated ? auth0_profile.picture : profile, alt: '' }} />
                    <div className="flex-grow-1"><span>{authenticated ? auth0_profile.name : name}</span>
                        <P attrPara={{ className: "mb-0 font-roboto" }} >{authState.role} <i className="middle fa fa-angle-down"></i></P>
                    </div >
                </div >
                <UL attrUL={{ className: "profile-dropdown onhover-show-div" }}>
                    <LI>
                        {authState.userRole === 1 &&
                            <Link to={`${process.env.PUBLIC_URL}/masterProfile`}><User />{Account}</Link>
                        }
                        {/* <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}><User />{Account}</Link> */}
                    </LI>
                    {/* <LI>
                        <Link to={`${process.env.PUBLIC_URL}/app/email/mailbox`}><Mail />{Inbox}</Link>
                    </LI>
                    <LI>
                        <Link to={`${process.env.PUBLIC_URL}/app/todo`}><FileText />{Taskboard}</Link>
                    </LI> */}
                    <LI attrLI={{ onClick: Logout }}>
                        <Link to={`${process.env.PUBLIC_URL}/login`}><LogOut />{logout}</Link>
                    </LI>
                </UL>
            </LI >
        </Fragment >
    );
};

export default LogoutClass;
import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../Layout/SpinnerLoader";
import { AddComplaint, AreaName, Complaint, Customer, Image, Phone, ProblemDetails, ProblemType, Save, Area, StbModemType, StbModem, Priority, MobileNo } from "../../Constant";
import { addComplaint, addComplaintRole, getArea, getAreaRole, getCustomers, getCustomersRole, getProblemType, getProblemTypeRole, selectCustStb, selectCustStbRole } from "../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../CommonElements/TypeAhead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../Common/Loader/Transparentspinner";
import PhoneInput from "react-phone-input-2";


const schema = yup.object().shape({
    phone: yup.string().required().label('Phone'),
    stb: yup.mixed().required().label('Stb'),
    customer: yup.mixed().required().label('Customer'),
    problemType: yup.mixed().required().label('Problem Type'),
    areaName: yup.mixed().required().label('Area'),
    details: yup.string().required().label('Problem Details'),
    priority: yup.mixed().required().label('priority'),
})

const ComplaintsForm = () => {

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const authState = useSelector((state) => state.auth);
    const [logo, setLogo] = useState('');
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [customerOption, setCustomerOption] = useState([]);
    const [area, setArea] = useState([]);
    const [areaOption, setAreaOption] = useState([]);
    const [problemType, setProblemType] = useState([]);
    const [stbOption, setStbOption] = useState([]);
    const [problemTypeOption, setProblemTypeOption] = useState([]);
    const [selectedCust, setselectedCust] = useState({})

    const navigate = useNavigate();

    const form = new FormData();

    const priorityOptions = [
        { value: "high", label: "High" },
        { value: "medium", label: "Medium" },
        { value: "low", label: "Low" },
    ];

    useEffect(() => {
        customerData();
        problemTypeData();
        areaData();
    }, []);

    useEffect(() => {
        setCustomerOption(
            customer.map((item) => ({
                value: item.id,
                label: item.name,
            }))
        );
        setProblemTypeOption(
            problemType.map((item) => ({
                value: item.type,
                label: item.type,
            }))
        );
        setAreaOption(
            area.map((item) => ({
                value: item.id,
                label: item.name,
            }))
        );
    }, [customer, problemType, area]);
    const selectCustomerStb = async (e) => {
        setLoading(true);
        const selectedStbcust = e.value;
        let stb_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? selectCustStb + `?customer_id=${selectedStbcust}`: selectCustStbRole + `?customer_id=${selectedStbcust}`);
        if (stb_api.status === true) {
            const single_stb = stb_api.data.stb;
            setStbOption(
                single_stb.map((item) => ({
                    value: item.stb_no,
                    label: item.stb_no + '(' + item.connection_type + ')',
                }))
            );
            setValue("phone", stb_api.data.customer.country_code + stb_api.data.customer.mobile);
            setValue("countryCode", stb_api.data.customer.country_code)

        }
        else if (stb_api.status >= 400 && stb_api.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (stb_api.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    }


    const customerData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
        if (data.status === true) {
            setCustomer(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    const areaData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
        if (data.status === true) {
            setArea(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false);
    };

    const problemTypeData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getProblemType : getProblemTypeRole);
        if (data.status === true) {
            setProblemType(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false);
    };

    const handleLogoChange = (event) => {
        const selectedFile = event.target.files[0];
        setLogo(selectedFile);
    }
    const onSubmit = async (data) => {
        setLoading(true);
        form.append("customer_id", data.customer.value);
        form.append("problem_type", data.problemType.value);
        form.append("stb_no", data.stb.value);
        form.append("problem_detail", data.details);
        form.append("phone", data.phone);
        form.append("area_id", data.areaName.value);
        form.append("priority", data.priority.value);
        if (data.logo) {
            form.append("image", data.logo[0]);
        }
        const addPaymentResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addComplaint : addComplaintRole, form);
        if (addPaymentResponse.status === 200) {
            if (addPaymentResponse.data.status === true) {
                toast.success(addPaymentResponse.data.message);
                navigate(`${process.env.PUBLIC_URL}/complaints`);
            } else {
                toast.error(addPaymentResponse.data.message);
            }
        } else if (addPaymentResponse.status >= 400 && addPaymentResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addPaymentResponse.status >= 500 && addPaymentResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }

        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (<Transparentspinner />)}


            <Breadcrumbs mainTitle={Complaint} parent="Billing" title={Complaint} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddComplaint}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label">{Customer} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="customer"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={customerOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                // setselectedCust(value);
                                                selectCustomerStb(value)
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.customer?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label">{StbModem} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="stb"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            placeholder="Select..."
                                            options={stbOption}
                                            onChange={(value) => {
                                                field.onChange(value);

                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.stb?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" >
                                    {MobileNo} <span className="requireStar">*</span>
                                </Label>
                                <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <PhoneInput
                                            {...field}
                                            country={"in"}
                                            enableSearch={true}
                                            onChange={(value, country) => {
                                                setValue("countryCode", country.dialCode);
                                                field.onChange(value);
                                            }}
                                            inputStyle={{
                                                width: "100%",
                                                height: "46px",
                                                padding: "10px",
                                                paddingLeft: "50px",
                                                fontSize: "16px",
                                                borderRadius: "8px",
                                                borderColor: "rgba(83, 70, 134, 0.1019607843)",
                                            }}
                                            searchClass="none"
                                        />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors.phone && errors.phone.message}
                                </span>
                            </Col>

                            <Col md="6">
                                <Label className="form-label" >{ProblemType} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="problemType"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={problemTypeOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.problemType?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" >{Area} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="areaName"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={areaOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.areaName?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{Priority} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="priority"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={priorityOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.priority?.message}</p>
                            </Col>

                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6 ">
                                <Label className="form-label" for="details">{ProblemDetails} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="details" type="text" {...register('details', { required: true })} />
                                <p className="text-danger">{errors.details?.message}</p>
                            </Col>

                            <Col md="6 ">
                                <Label className="form-label">{Image}</Label>
                                <Controller
                                    name="image"
                                    control={control}
                                    render={({ field }) => (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Col md="8">
                                                <input
                                                    {...register("logo")}
                                                    className="form-control fileInputStyle"
                                                    type="file"
                                                    onChange={handleLogoChange}
                                                    accept=".png, .jpg, .jpeg"
                                                />
                                            </Col>
                                            <Col md='4'>
                                                <span className="imagestyle">
                                                    {logo && <img src={URL.createObjectURL(logo)} alt="logo" width="40px" height="40px" />}
                                                </span>
                                            </Col>
                                        </div>
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default ComplaintsForm;
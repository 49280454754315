import React from 'react';
import NavSign from './NavSign';
import { useLocation,useNavigate} from 'react-router-dom';
import './otpSuccess.scss';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { postDataWithoutResData } from '../../Utils/restUtils';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';

const phoneOtpSuccess = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {form ,selectedItemId} = location.state;

  const handleAccount = async () => {
          const response = await postDataWithoutResData('register', { 
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            mobile: form.phone,
            password: form.password,
            confirm_password: form.confirm_pass,
            state: form.state,
            zip_code:form.zipcode,
            country: form.country,
           country_code:form.country_code,
           industry_type_id:form.selectedItemId
         }); 
       
          if (response && response.status === 200) {
            navigate('/emailOtp', { state: { form: form, selectedItemId: selectedItemId } });

          } else {
            toast.error("Error  while creating account");
          }
      };

  return (
    <div className='otp-main-container'>
      <NavSign />
      <div className="otp-container">
        <div className='otp-background'>
          <BsFillCheckCircleFill color='white' size={50} />
          <div style={{ color: 'white', width: '70%', fontSize: '22px', paddingTop: '20px', paddingBottom: '20px' }}>
            <h3 className='head-text'>Your mobile OTP has been successfully verified.</h3>
          </div>
          <div style={{ color: 'white', width: '60%', paddingBottom: '20px' }}>
            <p>To enhance the security of your account, we have also sent an OTP to your registered email address.</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button  onClick={handleAccount}  className='button-signup text-link'>Next</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default phoneOtpSuccess
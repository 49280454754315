import { Btn, H6, H2, H3, LI, P, UL, H4 } from '../../../../AbstractElements';
import { MarginBottom, Subscribe, month } from '../../../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, CardBody, Card, Row } from 'reactstrap';
import { getMasterDataToken, postMasterToken } from '../../../../Utils/restUtils';
import { getPackageDet, postSubscription } from '../../../../CommonUrl';
import { useNavigate } from 'react-router';
import HandleHomePageError from '../../../Common/ErrorImport/SignError';
import Transparentspinner from '../../../Common/Loader/Transparentspinner';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setSubStatus, setSubscriptionDetails } from '../../../../Redux/Reducer';

const Standards = (props) => {
    const priceDetails = props.details;
    const authState = props.auth;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const [color, setcolor] = useState('');

    useEffect(() => {

        if (priceDetails?.days_left >= -10 && priceDetails?.days_left <= 1) {
            setcolor('red')
        } else if (priceDetails?.days_left <= 10) {
            setcolor('orange')
        } else {
            setcolor('blue')
        }
    }, [])


    const submitSubscription = async (item) => {
        setloading(true);
        const integerNumber = Math.floor(item.price);
        if (integerNumber === 0) {
            const postRequest = { package_id: item.id, industry_type_id: authState.industryId, company_id: props.companyId, company: '' }
            const response = await postMasterToken(postSubscription, postRequest);
            if (response.status === 200) {
                const response = await getMasterDataToken(getPackageDet);
                if (response.status === true) {
                    const packagetDetails = { packageName: response.data.package_name, packageExp: response.data.package_expire_in }
                    dispatch(setSubscriptionDetails(packagetDetails));
                    dispatch(setSubStatus(packagetDetails));
                    if(authState.subStatus !== null || authState.subStatus.length !== 0){
                    navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
                    }
                    toast.success(response.data.message);
                }
                else {
                    HandleHomePageError(navigate, response.status)
                    setloading(false)

                }
                setloading(false)
            }
            else {
                HandleHomePageError(navigate, response.status)
            }
        } else {
            navigate('/applyCoupon', { state: { package_id: item, company_id: props.companyId } });
        }
        setloading(false);
    }

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <CardBody className='pt-2'>
                {priceDetails?.current_plan != 'None' && (

                    <div>
                        <div className={`mb-3 p-3 text-white bg-subscription-${color} `} >
                            {props.upgradeText && <div><strong>{props.upgradeText}</strong><br/></div>}
                            <strong>Note:</strong> Your current package ends on <span className="note-date">{priceDetails?.end_date ?? ''}</span>.{priceDetails?.days_left > 0 && <span className="note-days-left"> Only {priceDetails?.days_left ?? ''} days left.</span>}
                        </div>
                    </div>
                )}

                <Row className="pricing-content pricing-col ">

                    {
                        priceDetails?.packages.map((item, index) => {
                            const integerNumber = Math.floor(item.price);
                            return (
                                <Col md='4' key={index}>
                                    <Card className="pricing-block mt-3">
                                        <div className="pricing-header text-center">
                                            <H2>{item.name}</H2>
                                            <div className="price-box">
                                                <div>
                                                    {integerNumber !== 0 &&
                                                        <span style={{ textDecoration: 'line-through', color: 'white' }}>{item.price}</span>
                                                    }
                                                    <H3>{authState.currency + item.mrp}</H3>
                                                    <P>{item.price_calculation === 'per_unit' ? 'Per connection' : item.price_calculation}{integerNumber !== 0 && month}</P>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-list subscription-features d-flex flex-column"  >
                                            {integerNumber !== 0 && <H4 attrH4={{ style: { color: '#534686' } }}>Setup Cost {authState.currency + item.setup_cost}</H4>}
                                            <UL className='simple-list pricing-inner'>
                                                <li><H6 attrH6={{ style: { display: 'flex' } }}>1. <span > {item.features} </span></H6></li>
                                                <li><H6 attrH6={{ style: { display: 'flex' } }}>2. <span>Duration&nbsp;</span> {item.duration} <span>days</span></H6></li>
                                                <li><H6 attrH6={{ style: { display: 'flex' } }}>3. {item.sms === 'off' ? <span style={{ textDecoration: 'line-through' }}>SMS</span> : ' SMS'}</H6></li>
                                                {integerNumber === 0 &&
                                                    <li><H6 attrH6={{ style: { display: 'flex' } }}>4.<span>Max connection&nbsp;</span>{item.max_connection}</H6></li>
                                                }
                                            </UL>
                                            {/* <Btn attrBtn={{ className: 'btn btn-lg ', color: 'primary', size: 'lg',onClick: () => submitSubscription(item) }}>{Subscribe}</Btn> */}
                                        </div>
                                        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                                            <button className='btn btn-lg btn-primary' onClick={() => submitSubscription(item)} disabled={priceDetails?.current_plan != 'Free Trial' && priceDetails?.days_left >= 5}>{Subscribe}</button>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </CardBody>
        </Fragment>
    );
};
export default Standards;
import { H6, H2, H3, P, UL } from '../../AbstractElements';
import { Subscribe, month } from '../../Constant';
import React, { Fragment, useState } from 'react';
import { Col, CardBody, Card, Row } from 'reactstrap';
import { postMasterToken } from '../../Utils/restUtils';
import { storeMultiComp } from '../../CommonUrl';
import { useNavigate } from 'react-router';
import HandleHomePageError from '../../Components/Common/ErrorImport/SignError';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';

const SubCard = (props) => {
    const priceDetails = props.details;
    const authState = props.auth;
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);

    const submitSubscription = async (item) => {
        setloading(true);
        const postRequest = { package_id: item.id, industry_type_id: authState.industryId, company_id: props.companyId, company: 'multi_company' }
        const response = await postMasterToken(storeMultiComp, postRequest);
        if (response.status === 200) {
            navigate('/dashboard/company-list');
        } else {
            HandleHomePageError(navigate, response.status)
        }
        setloading(false);
    }

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <CardBody className='pt-2'>
                <Row className="pricing-content pricing-col ">

                    {
                        priceDetails?.packages
                        .filter((item) => Math.floor(item.price) !== 0)
                        .map((item, index) => {
                          const integerNumber = Math.floor(item.price);
                            return (
                                <Col md='6' sm='6' key={index}>
                                    <Card className="pricing-block mt-3">
                                        <div className="pricing-header text-center">
                                            <H2>{item.name}</H2>
                                            <div className="price-box">
                                                <div>
                                                    {integerNumber !== 0 &&
                                                        <span style={{ textDecoration: 'line-through', color: 'white' }}>{item.price}</span>
                                                    }
                                                    <H3>{authState.currency + item.mrp}</H3>
                                                    <P>{item.price_calculation === 'per_unit' ? 'Per connection' : item.price_calculation}{integerNumber !== 0 && month}</P>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pricing-list subscription-features d-flex flex-column"  >
                                            <UL className='simple-list pricing-inner'>
                                                <li><H6 attrH6={{ style: { display: 'flex' } }}>1. <span > {item.features} </span></H6></li>
                                                <li><H6 attrH6={{ style: { display: 'flex' } }}>2. <span>Duration&nbsp;</span> {item.duration} <span>days</span></H6></li>
                                                <li><H6 attrH6={{ style: { display: 'flex' } }}>3. {item.sms === 'off' ? <span style={{ textDecoration: 'line-through' }}>SMS</span> : ' SMS'}</H6></li>
                                                {integerNumber === 0 &&
                                                    <li><H6 attrH6={{ style: { display: 'flex' } }}>4.<span>Max connection&nbsp;</span>{item.max_connection}</H6></li>
                                                }
                                            </UL>
                                            {/* <Btn attrBtn={{ className: 'btn btn-lg ', color: 'primary', size: 'lg',onClick: () => submitSubscription(item) }}>{Subscribe}</Btn> */}
                                        </div>
                                        <div style={{ textAlign: 'center', marginBottom:'20px' }}>
                                            <button className='btn btn-lg btn-primary' onClick={() => submitSubscription(item)}>{Subscribe}</button>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </CardBody>
        </Fragment>
    );
};
export default SubCard;
import { Home, Airplay, Box, FolderPlus, Command, Cloud, BarChart, Users, ShoppingBag, List, Mail, MessageCircle, GitPullRequest, DollarSign, Heart, Clock, Zap, CheckSquare, Calendar, Image, HelpCircle, Radio, Map, Edit, Sunrise, Package, Sliders, Layout, Server, Database, Search, File, Layers, UserCheck, Bookmark, AlertCircle, Settings, Archive } from 'react-feather';

export const DROPDOWNITEMS = [
    {
        menutitle: 'Applications',
        Items: [
            {
                title: 'Users', icon: Users, path: `${process.env.PUBLIC_URL}/menu/user`, type: 'sub', bookmark: true, active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/menu/user`, type: 'link', title: 'Users' },
                ]
            },
        ]
    },
];
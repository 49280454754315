import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { useSelector } from 'react-redux';
import { expenseDetails, expenseDetailsRole } from '../../CommonUrl';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const ExpenseDetails = ({ deactivateValue }) => {
    const [spinnerTrans, setspinnerTrans] = useState(false)
    const dataValue = deactivateValue;
    const { handleSubmit } = useForm()
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [personalDetails, setPersonalDetails] = useState({});

    useEffect(() => {
        getExpenseDetails();
    }, [])

    const getExpenseDetails = async () => {
        setspinnerTrans(true)
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? expenseDetails + `?expense_id=${dataValue.id}` : expenseDetailsRole + `?expense_id=${dataValue.id}`);
        if (data.status === true) {
            setPersonalDetails(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)

    }


    const onSubmit = async (data) => {

    }
    return (
        <>
            {spinnerTrans && <Transparentspinner />}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="activity-media ">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Vendor</h3>
                                    <span><h4>{personalDetails.vendor_name}<br />{personalDetails.customer_address}</h4></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Header</h3>
                                    <span><h4>{personalDetails.header_name}</h4></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Amount</h3>
                                    <span><h4>{personalDetails.amount}</h4></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Total Paid</h3>
                                    <span><h4>{personalDetails.paid_amount}</h4></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Balance</h3>
                                    <span><h4>{personalDetails.balance}</h4></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Type</h3>
                                    <span><h4>{personalDetails.type}</h4></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Status</h3>
                                    <span><h4>{personalDetails.payment_status}</h4></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Payment Date</h3>
                                    <span><h4>{personalDetails.payment_date}</h4></span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='row'>

                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Bill Date</h3>
                                    <span><h4>{personalDetails.bill_date}</h4></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Due Date</h3>
                                    <span><h4>{personalDetails.due_date}</h4></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>

        </>
    )
}

export default ExpenseDetails

import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Form, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, Area, AreaList, Search, Filter } from '../../../Constant';
import { useSelector } from 'react-redux';
import { deleteArea, deleteAreaRole, viewArea, viewAreaRole } from '../../../CommonUrl';
import { AreaColumns } from '../../../Data/MockTable/SettingsTableHeader';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const AreaContainer = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const authState = useSelector((state) => state.auth);
    const [areaList, setAreaList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        if (searchKeyword == '') {
            setLoading(true);
            areaData();
        }
    }, [searchKeyword]);


    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        areaData();
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/settings/area-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const deleteAreaList = (userId) => {
        SweetAlert.fire({
            title: 'Confirm deletion?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true);
            if (result.value) {
                const deleteRes = { area_id: userId }
                let areaDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteArea : deleteAreaRole, deleteRes);
                if (areaDelete.status === 200) {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    areaData();
                } else if (areaDelete.status >= 400 && areaDelete.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (areaDelete.status >= 500 && areaDelete.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                }
            }
            setLoading(false);
        });
    };


    const areaData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewArea : viewAreaRole);

        if (data.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            // setLoading(true);
            if (Array.isArray(data.data)) {
                const filteredArea = data.data.filter((item) => {
                    const nameMatch = item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase());
                    const AreaMatch = item.area_code && item.area_code.toString().toLowerCase().includes(searchKeyword.toLowerCase());
                    return nameMatch || AreaMatch;
                });

                setAreaList(filteredArea.map((e, index) => {
                    return {
                        Si: index + 1,
                        Area: <span>{e.name}</span>,
                        AreaCode: <span>{e.area_code}</span>,
                        action:
                            <div>
                                <span>
                                    <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                                </span>
                                <span>
                                    <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteAreaList(e.id) } }}>Delete</Btn>
                                </span>
                            </div >
                    }
                }))
                setLoading(false);
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Area} parent="Settings" title={Area} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{AreaList}</H3>
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/settings/area-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                </CardHeader>

                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} type="text" value={searchKeyword} onChange={handleSearchInputChange}
                                    />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={areaList}
                            columns={AreaColumns}
                            pagination
                            defaultSortAsc
                            fixedHeader
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default AreaContainer;
import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Media, Label, Input, Form, Row, Col } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, H4, Btn, Breadcrumbs, Image } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, Item, ItemList, Search, Category, Filter, Export, importFile, Import, importItem } from '../../../Constant';
import { viewItem, deleteItem, viewItemRole, statusItem, statusItemRole, getCategory, postImportItemApi, publicSampleDownloaditem, deleteItemRole, postImportItemApiRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { exportToExcel } from '../../Common/Export';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PopupImport from '../../Common/Popup/popupImport';
import ImportPopTax from '../../Common/Import/ImportPopTax';
import Transparentspinner from '../../Common/Loader/Transparentspinner';


const ItemListContainer = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const authState = useSelector((state) => state.auth);
    const [itemList, setItemList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [filteredItemData, setFilteredItemData] = useState([]);
    const navigate = useNavigate();
    const [modalImport, setModalImport] = useState(false);
    const handleModalImport = () => {
        setModalImport(!modalImport);
    }
    const fileImport = <span className='billerqColor bolder '>
        <h3>{importFile}</h3>
    </span>

    useEffect(() => {
        if (searchKeyword == '') {
            fetchItems();
        }
    }, [searchKeyword]);

    const ItemColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: "50px"

        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            sortable: true,
            width: "50px"

        },
        {
            name: <H4>Images</H4>,
            selector: row => row.Image,
            sortable: true,
            width: '90px'


        },
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Category</H4>,
            selector: row => row.Category,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Stock</H4>,
            selector: row => row.Stock,
            sortable: true,

        },
        {
            name: <H4>Tax %</H4>,
            selector: row => row.Tax,
            sortable: true,

        },
        {
            name: <H4>Price</H4>,
            selector: row => row.Price,
            sortable: true,

        },
        {
            name: <H4>Sub Total</H4>,
            selector: row => row.SubTotal,
            sortable: true,

        },
        {
            name: <H4>Purchase Price</H4>,
            selector: row => row.Purchase_price,
            sortable: true,

        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,

        },

    ];

    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;

        if (checked === true) {
            reqSwitch = { item_id: itemId, status: 'inactive' }
        } else {
            reqSwitch = { item_id: itemId, status: 'active' }
        }

        const statusChange = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusItem : statusItemRole, reqSwitch);
        if (statusChange.status === 200) {
            if (statusChange.data.status === true) {
                toast.success(statusChange.data.message);
            }
            else {
                toast.error(statusChange.data.message);
            }

        } else if (statusChange.status >= 400 && statusChange.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (statusChange.status >= 500 && statusChange.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    }

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}/Services/item-edit?id=${row.id}`;
            navigate(editFormURL);
        };

        const deleteItemList = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const deleteRes = { item_id: row.id }
                    let areaDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteItem : deleteItemRole, deleteRes);
                    if (areaDelete.status === 200) {
                        if (areaDelete.data.status === true) {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            fetchItems()
                            setLoading(false);
                        }
                        else {
                            toast.error(areaDelete.data.message);
                        }
                    }
                    else if (areaDelete.status >= 400 && areaDelete.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (areaDelete.status >= 500 && areaDelete.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                }
            });
        };
        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {(authState.userRole === 1 || authState.permissions.includes('edit-item')) &&
                        <DropdownItem style={toggleStyle} onClick={() => handleEditClick()}>Edit</DropdownItem>
                    }
                    {(authState.userRole === 1 || authState.permissions.includes('remove-item')) &&
                        <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={() => deleteItemList(row.id)}>Delete</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };
    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        fetchItems();
    };

    const fetchItems = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewItem : viewItemRole);
        if (data.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            // setLoading(true);
            if (Array.isArray(data.data)) {
                const filteredItems = data.data.filter((item) => {
                    const nameMatch = item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase());
                    const categoryName = item.category_name && item.category_name.toLowerCase().includes(searchKeyword.toLowerCase());
                    return nameMatch || categoryName;
                });
                setFilteredItemData(filteredItems);
                setItemList(filteredItems.map((e, index) => {
                    return {
                        Si: index + 1,
                        Image: <Image attrImage={{ src: e.item_image, alt: '' }} />,
                        Name: e.name,
                        Category: e.category_name,
                        Quantity: e.quantity,
                        Tax: e.tax_percentage + '%',
                        Price: authState.currency + e.sale_price,
                        SubTotal: authState.currency + e.sub_total,
                        Purchase_price: authState.currency + e.purchase_price,
                        Stock: e.stock,
                        id: e.id,
                        Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                        action:
                            <div>
                                <span>
                                    <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                                </span>
                                <span>
                                    <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteItemList(e.id) } }}>Delete</Btn>
                                </span>
                            </div >
                    }
                }))
                setLoading(false);
            } 
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    const exportItemExcel = () => {
        setLoading(true);
        const exportData = filteredItemData.map(({ name, category_name, quantity, tax_percentage, sale_price, sub_total, purchase_price, status }) => ({ Name: name, Category: category_name, Stock: quantity, Tax_Percentage: tax_percentage + '%', Price: authState.currency + sale_price, Sub_Total: authState.currency + sub_total, Purchase_Price: authState.currency + purchase_price, Status: status }));
        exportToExcel(exportData, "item_data");
        setLoading(false);
    };
    const exportItemPdf = () => {
        setLoading(true);
        const filteredData = filteredItemData.map(({ name, category_name, quantity, tax_percentage, sale_price, sub_total, purchase_price, status }, index) => {
            return { Sl: index + 1, name, category_name, quantity, tax_percentage, sale_price, sub_total, purchase_price, status };
        });

        const doc = new jsPDF();
        const columns = ["Sl", "Name", "Category", "Stock", "Tax %", "Price", " Sub Total", "Purchase Price", "Status"];
        const rows = filteredData.map(entry => Object.values(entry));
        doc.autoTable({
            head: [columns],
            body: rows
        });
        doc.save("item_data.pdf");
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Item} parent="Services" title={Item} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{ItemList}</H3>
                    <div >
                        <CardBody className="dropdown-basic">
                            {(authState.userRole === 1 || authState.permissions.includes('store-item')) &&
                                <Link to={`${process.env.PUBLIC_URL}/Services/item-add`} className="btn btn-primary me-1" >{Add}</Link>
                            }
                            {(authState.userRole === 1 || authState.permissions.includes('import-item')) &&
                                <Btn attrBtn={{ color: 'primary m-l-5', onClick: () => handleModalImport() }} >{Import} <i className="icofont icofont-arrow-down"></i></Btn>
                            }
                            {(authState.userRole === 1 || authState.permissions.includes('item-export')) &&
                                <Dropdown className='ms-2' isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4 button-spacing">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportItemExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportItemPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            }
                            {/* <Link to={`${process.env.PUBLIC_URL}/Services/item-add`} className="btn btn-primary" style={{ marginLeft: '10px' }}>{Add}</Link> */}
                        </CardBody>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} id="validationCustom01" type="text" value={searchKeyword} onChange={handleSearchInputChange}
                                    />
                                </Col>

                                {/* <Col sm="12" md="5" lg='2'>
                                    <select className="form-select" id="validationCustom04" {...register('category')}>
                                        <option value="">{Category}</option>
                                        {apiCategory.map((values, index) => (

                                            <option key={index} value={values.id}>{values.name}</option>
                                        ))}
                                    </select>
                                </Col> */}

                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="table-responsive ">
                        <div id='basic-1_wrapper' className='dataTables_wrapper no-footer'>
                            <DataTable
                                className='display dataTable no-footer'
                                noHeader
                                pagination
                                defaultSortAsc
                                fixedHeader
                                // paginationServer
                                columns={ItemColumns}
                                data={itemList}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>
            <PopupImport isOpen={modalImport} title={fileImport} toggler={handleModalImport} >
                <ImportPopTax
                    setModal={setModalImport}
                    sampleImportApi={publicSampleDownloaditem}
                    postImportApi={authState.userRole === 1 ? postImportItemApi : postImportItemApiRole}
                    imporTitle={importItem}
                    tablerenderFunc={fetchItems}
                />
            </PopupImport>

        </Fragment>
    );


};

export default ItemListContainer;  
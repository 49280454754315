import React from 'react';
import { Col, Modal, ModalBody, ModalHeader } from 'reactstrap';


const PopupImport = (props) => {
    return (
        <Col >
            <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered style={{ maxWidth: '40%' }}>
                <ModalHeader toggle={props.toggler}>
                    {props.title}
                </ModalHeader>
                <ModalBody className={props.bodyClass}>
                    {props.children}
                </ModalBody>
            </Modal>
        </Col>
    )
}

export default PopupImport
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Btn } from '../../../AbstractElements';
import { Deactivate, } from '../../../Constant';
import { useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { useSelector } from 'react-redux';
import { deactivateDetails, deactivateDetailsRole, postdeactivateStb, postdeactivateStbRole } from '../../../CommonUrl';
import { toast } from 'react-toastify';
import moment from "moment";
import DatePicker from 'react-datepicker';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const DeactivateModal = ({ deactivateValue, stbLists, handleModalDeactivate, perPagelist, currentPageNo }) => {
    const [spinnerTrans, setspinnerTrans] = useState(false)
    const dataValue = deactivateValue;
    const { handleSubmit } = useForm()
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [personalDetails, setPersonalDetails] = useState({});
    const [deactivateDate, setDeactivateDate] = useState(new Date());

    useEffect(() => {
        getDeactivateDetails();
    }, [])

    const getDeactivateDetails = async () => {
        setspinnerTrans(true)
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? deactivateDetails + `?stb_id=${dataValue.id}` : deactivateDetailsRole + `?stb_id=${dataValue.id}`);
        if (data.status === true) {
            setPersonalDetails(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)

    }

    const handleChangeDate = date => {
        setDeactivateDate(date);
    };


    const onSubmit = async (data) => {
        setspinnerTrans(true)
        const postRequest = {
            stb_id: dataValue.id,
            status_date: moment(deactivateDate).format("DD-MM-YYYY"),
        }
        const addDeactivateResponce = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? postdeactivateStb : postdeactivateStbRole, postRequest);
        if (addDeactivateResponce.status === 200) {
            if (addDeactivateResponce.data.status === true) {
                toast.success(addDeactivateResponce.data.message);
                handleModalDeactivate();
                stbLists(currentPageNo, perPagelist, '', '', '', '', '');
            } else {
                toast.error(addDeactivateResponce.data.message);
                handleModalDeactivate();
                stbLists(currentPageNo, perPagelist, '', '', '', '', '');
                setspinnerTrans(false)


            }
        } else if (addDeactivateResponce.status >= 400 && addDeactivateResponce.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addDeactivateResponce.status >= 500 && addDeactivateResponce.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)

    }
    return (
        <>
            {spinnerTrans && <Transparentspinner />}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="activity-media ">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Customer</h3>
                                    <span><h4>{personalDetails.customer_name}<br />{personalDetails.customer_address}</h4></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Mac id/serial No</h3>
                                    <span><h4>{personalDetails.mac_id}</h4></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {/* <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>CAF No</h3>
                                    <span><h4>Active </h4></span>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>Install DATE</h3>
                                    <span><h4>{personalDetails.install_date}</h4></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>STB Modem Status</h3>
                                    <span><h4>{personalDetails.stb_status}</h4></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className='recent-circle bg-primary'></div>
                                <div className="flex-grow-1">
                                    <h3>STB No</h3>
                                    <span><h4>{personalDetails.stb_no}</h4></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="d-flex" >
                                <div className="flex-grow-1">
                                    <h3>Deactivation Date</h3>
                                    <DatePicker
                                        style={{ padding: '12px 100px' }}
                                        className="form-control "
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="dd/mm/yyyy"
                                        selected={deactivateDate}
                                        onChange={handleChangeDate}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-11'>
                            <div className="d-flex" >
                                <div className="flex-grow-1">
                                    <h3>Note:</h3>
                                    <span><h5 >Active recurring plan on this STB/Modem will be remain active. If you want to remove recurring plan please use disconnect option.</h5></span>
                                    <div className='pt-3'>
                                        <span className='ps-2 spinner-enable-stb'>
                                            <Btn attrBtn={{ color: "primary", className: 'bttn' }} type="submit">
                                                Deactivate
                                            </Btn>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </>
    )
}

export default DeactivateModal
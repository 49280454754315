// dashbaord
import Default from '../Components/Dashboard/Default';
import Customer from '../Components/Customers/Customer/Customer';

import UsersProfileContain from '../Components/App/Users/UsersProfile';
import Invoice from '../Components/App/Ecommerce/Invoice';

//sample page
import CustomerAdd from '../Components/Customers/Customer/CustomerAdd';
import StbModem from '../Components/Customers/STB/StbModem';
import StbAdd from '../Components/Customers/STB/StbAdd';
import Area from '../Components/Settings/Area/Area';
import AreaAdd from '../Components/Settings/Area/AreaAdd';
import AreaEdit from '../Components/Settings/Area/AreaEdit';
import CustomerEdit from '../Components/Customers/Customer/CustomerEdit';
import CasProvider from '../Components/Settings/CasProvider/CasProvider';
import ProviderAdd from '../Components/Settings/CasProvider/ProviderAdd';
import ProviderEdit from '../Components/Settings/CasProvider/ProviderEdit';
import StbEdit from '../Components/Customers/STB/StbEdit';
import Account from '../Components/Banking/Accounts/Accounts';
import PaymentsMethod from '../Components/Settings/OfflinePayment/PaymentsMethod';
import AccountAdd from '../Components/Banking/Accounts/AccountAdd';
import PaymentMethodAdd from '../Components/Settings/OfflinePayment/PaymentsMethodAdd';
import AccountEdit from '../Components/Banking/Accounts/AccountEdit';
import Wallet from '../Components/Customers/Wellet/Wallet';
import WalletAdd from '../Components/Customers/Wellet/WalletAdd';
import UserAgent from '../Components/Users/UserAgent/UserAgent';
import Packages from '../Components/Service&Product/Packages/Packages';
import UserAgentAdd from '../Components/Users/UserAgent/UserAgentAdd';
import UserAgentEdit from '../Components/Users/UserAgent/UserAgentEdit';
import Categories from '../Components/Settings/Categories/Categories';
import CategoryAdd from '../Components/Settings/Categories/CategoriesAdd';
import CategoryEdit from '../Components/Settings/Categories/CategoriesEdit';
import PackagesAdd from '../Components/Service&Product/Packages/PackagesAdd';
import PackagesEdit from '../Components/Service&Product/Packages/PackagesEdit';
import PaymentsMethodEdit from '../Components/Settings/OfflinePayment/PaymentsMethodEdit';
import TaxRates from '../Components/Settings/TaxGroup/TaxRates';
import TaxGroupAdd from '../Components/Settings/TaxGroup/TaxRatesAdd';
import TaxGroupEdit from '../Components/Settings/TaxGroup/TaxRatesEdit';
import Complaints from '../Components/Complaints/Complaints';
import ComplaintsAdd from '../Components/Complaints/ComplaintsAdd';
import Subscription from '../Components/Billing/Subsciption/Subscription';
import Item from '../Components/Service&Product/Items/Items';
import ItemAdd from '../Components/Service&Product/Items/ItemsAdd';
import ItemEdit from '../Components/Service&Product/Items/ItemEdit';
import SubscriptionAdd from '../Components/Billing/Subsciption/SubscriptionAdd';
import CompanyList from '../Layout/Header/CompanyList';
import OrderAdd from '../Components/Billing/Invoice/OrderAdd';
import InvoiceTable from '../Components/Billing/Invoice/Invoice';
import InvoicePaymentAdd from '../Components/App/Ecommerce/Invoice/InvoicePaymentAdd';
import Role from '../Components/Users/Role/Role';
import RoleForm from '../Components/Users/Role/RoleForm';
import TaxAdd from '../Components/Settings/TaxGroup/TaxAdd';
import OnlinePaymentReport from '../Components/Report/OnlinePaymentReport';
import PaymentCollectionReport from '../Components/Report/PaymentCollectionReport';
import PaymentDueReport from '../Components/Report/PaymentDueReport';
import PackageSummary from '../Components/Report/PackageSummary';
import WalletBalenceReport from '../Components/Report/WalletBalenceReport';
import IncomeVsExpence from '../Components/Report/IncomeVsExpence';
import GstReport from '../Components/Report/GstReport';
import SubscriptionSummary from '../Components/Report/SubscriptionSummary';
import AddonSummary from '../Components/Report/AddonSummary';
import ExpenseSummary from '../Components/Report/ExpenseSummary';
import ComplaintShow from '../Components/Complaints/ComplainShow';
import CompanyEdit from '../Layout/Header/Company Edit/CompanyEdit';
import RoleEdit from '../Components/Users/Role/RoleEdit';
import CompanyAddsecound from '../Components/Company';
import Qpay from '../Components/quickpay';
import ActivateSubscription from '../Components/Billing/Activatesub/Index';
import Taxedit from '../Components/Settings/TaxGroup/TaxEdit';
import RenewSub from '../Components/Billing/ThermalPrint/ThermalPrint-demo';
import Addon from '../Components/Service&Product/Addon/Addon';
import AddonEdit from '../Components/Service&Product/Addon/AddonEdit';
import AddonsAdd from '../Components/Service&Product/Addon/AddonsAdd';
import MasterProfileContain from '../Components/App/Users/MasterProfile';
import CustomerPaymentReport from '../Components/Report/CustomerPayment';
import Error_page1 from '../Components/Pages/Error/Error_page1';
import Error_page2 from '../Components/Pages/Error/Error_page2';
import Error_page3 from '../Components/Pages/Error/Error_page3';
import Error_page4 from '../Components/Pages/Error/Error_page4';
import Error_page5 from '../Components/Pages/Error/Error_page5';
import IncomeSummary from '../Components/Report/IncomeSummary';
import ImportCustomerLogList from '../Components/Customers/Customer/ImportLogList';
import ExpensesList from '../Components/Expenses/ExpensesList';
import ExpensesForm from '../Components/Expenses/ExpensesAdd';
import UnpaidCustomer from '../Components/Report/UnpaidCustomer';
// expenses
import HeaderContainer from '../Components/Header/HeaderList';
import HeaderForm from '../Components/Header/HeaderForm';
import VendorContainer from '../Components/Vendor/VendorContainer';
import VendorForm from '../Components/Vendor/VendorForm';
import VendorEdit from '../Components/Vendor/VendorEdit';
import HeaderEdit from '../Components/Header/HeaderEdit';
import IncomeList from '../Components/Incomes/IncomeList';
import IncomeForm from '../Components/Incomes/IncomeForm';
import ExpensesEdit from '../Components/Expenses/ExpensesEdit';
import IncomeEdit from '../Components/Incomes/IncomeEdit';
import ImportDueList from '../Components/Customers/Customer/ImportDueList';
import CompanyProfileContain from '../Components/App/Users/CompanyProfile';
import CancelInvoiceContainer from '../Components/Billing/CancelledInvoice/CancelInvoice';
import Transaction from '../Components/Banking/Transactions/Transaction';

export const routes = [
  //dashboard
  { path: `${process.env.PUBLIC_URL}/dashboard/default/`, Component: <Default />, requireSubStatus: true },

  //Customer
  { path: `${process.env.PUBLIC_URL}/customers/customer`, Component: <Customer />, protected: true, requiredPermission: 'show-customer', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/customer-add`, Component: <CustomerAdd />, protected: true, requiredPermission: 'store-customer', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/customer-edit`, Component: <CustomerEdit />, protected: true, requiredPermission: 'edit-customer', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/stb-modem`, Component: <StbModem />, protected: true, requiredPermission: 'show-stb', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/stb-modem-add`, Component: <StbAdd />, protected: true, requiredPermission: 'store-stb', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/stb-modem-edit`, Component: <StbEdit />, protected: true, requiredPermission: 'edit-stb', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/wallet`, Component: <Wallet />, protected: true, requiredRole: 1, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/wallet-add`, Component: <WalletAdd />, protected: true, requiredRole: 1, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/customer/userProfile`, Component: <UsersProfileContain />, protected: true, requiredPermission: 'show-customer-profile', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice`, Component: <Invoice />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/customer/invoice/invoice-payment-add`, Component: <InvoicePaymentAdd />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/customer/imported-log-list`, Component: <ImportCustomerLogList />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/customers/customer/imported-due-log-list`, Component: <ImportDueList />, requireSubStatus: true },

  //Billing
  { path: `${process.env.PUBLIC_URL}/billing/invoice`, Component: <InvoiceTable />, protected: true, requiredPermission: 'show-invoice', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/billing/invoice-cancel`, Component: <CancelInvoiceContainer />, protected: true, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/billing/subscription-add`, Component: <SubscriptionAdd />, protected: true, requiredPermission: 'store-subscription', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/billing/subscription`, Component: <Subscription />, protected: true, requiredPermission: 'show-subscription', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/billing/order-add`, Component: <OrderAdd />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/billing/activate-subscription`, Component: <ActivateSubscription />, protected: true, requiredPermission: 'activate-subscription', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/billing/thermal-print`, Component: <RenewSub />, requireSubStatus: true },

  //Service
  { path: `${process.env.PUBLIC_URL}/Services/packages`, Component: <Packages />, protected: true, requiredPermission: 'show-package', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Services/packages-add`, Component: <PackagesAdd />, protected: true, requiredPermission: 'store-package', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Services/packages-edit`, Component: <PackagesEdit />, protected: true, requiredPermission: 'edit-package', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Services/addon`, Component: <Addon />, protected: true, requiredPermission: 'show-add-on', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Services/addon-add`, Component: <AddonsAdd />, protected: true, requiredPermission: 'store-add-on', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Services/addon-edit`, Component: <AddonEdit />, protected: true, requiredPermission: 'edit-add-on', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Services/item`, Component: <Item />, protected: true, requiredPermission: 'show-item', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Services/item-add`, Component: <ItemAdd />, protected: true, requiredPermission: 'store-item', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Services/item-edit`, Component: <ItemEdit />, protected: true, requiredPermission: 'edit-item', requireSubStatus: true },

  //Expenses
  { path: `${process.env.PUBLIC_URL}/Expenses/Expenses`, Component: <ExpensesList />, protected: true, requiredPermission: 'show-expense', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Expenses/Expenses-add`, Component: <ExpensesForm />, protected: true, requiredPermission: 'store-expense', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Expenses/Expenses-edit`, Component: <ExpensesEdit />, protected: true, requiredPermission: 'edit-expense', requireSubStatus: true },

  //Income
  { path: `${process.env.PUBLIC_URL}/Expenses/Income`, Component: <IncomeList />, protected: true, requiredPermission: 'show-income', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Expenses/Income-add`, Component: <IncomeForm />, protected: true, requiredPermission: 'store-income', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Expenses/Income-edit`, Component: <IncomeEdit />, protected: true, requiredPermission: 'edit-income', requireSubStatus: true },

  //Header
  { path: `${process.env.PUBLIC_URL}/Expenses/Header`, Component: <HeaderContainer />, protected: true, requiredPermission: 'show-header', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Header/Header-add`, Component: <HeaderForm />, protected: true, requiredPermission: 'store-header', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Header/Header-edit`, Component: <HeaderEdit />, protected: true, requiredPermission: 'edit-header', requireSubStatus: true },

  //Vendor
  { path: `${process.env.PUBLIC_URL}/Expenses/Vendor`, Component: <VendorContainer />, protected: true, requiredPermission: 'show-vendor', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Vendor/Vendor-add`, Component: <VendorForm />, protected: true, requiredPermission: 'store-vendor', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/Vendor/Vendor-edit`, Component: <VendorEdit />, protected: true, requiredPermission: 'edit-vendor', requireSubStatus: true },

  //Banking
  { path: `${process.env.PUBLIC_URL}/banking/account`, Component: <Account />, protected: true, requiredRole: 1, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/banking/account-add`, Component: <AccountAdd />, protected: true, requiredRole: 1, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/banking/account-edit`, Component: <AccountEdit />, protected: true, requiredRole: 1, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/banking/transaction`, Component: <Transaction />, protected: true, requiredRole: 1, requireSubStatus: true },

  //Reports
  { path: `${process.env.PUBLIC_URL}/report/online-payment`, Component: <OnlinePaymentReport />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/customer-payment`, Component: <CustomerPaymentReport />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/payment-collection`, Component: <PaymentCollectionReport />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/unpaid-customer`, Component: <UnpaidCustomer />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/payment-due`, Component: <PaymentDueReport />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/package-summary`, Component: <PackageSummary />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/wallet-balance`, Component: <WalletBalenceReport />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/income-vs-expence`, Component: <IncomeVsExpence />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/tax-report`, Component: <GstReport />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/subscription-summary`, Component: <SubscriptionSummary />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/addon-summary`, Component: <AddonSummary />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/income-summary`, Component: <IncomeSummary />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/report/expense-summary`, Component: <ExpenseSummary />, requireSubStatus: true },

  //Complaints
  { path: `${process.env.PUBLIC_URL}/complaints`, Component: <Complaints />, protected: true, requiredPermission: 'show-complaint', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/complaints-add`, Component: <ComplaintsAdd />, protected: true, requiredPermission: 'store-complaint', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/complaints/complaint-show`, Component: <ComplaintShow />, protected: true, requiredPermission: 'show-complaint', requireSubStatus: true },

  //user
  { path: `${process.env.PUBLIC_URL}/menu/user`, Component: <UserAgent />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/menu/user-agent-add`, Component: <UserAgentAdd />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/menu/user-agent-edit`, Component: <UserAgentEdit />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/menu/role`, Component: <Role />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/menu/role-add`, Component: <RoleForm />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/menu/role-edit`, Component: <RoleEdit />, protected: true, requiredRole: 1, requireSubStatus: true, },

  //Settings
  { path: `${process.env.PUBLIC_URL}/settings/area`, Component: <Area />, protected: true, requiredPermission: 'show-area', requireSubStatus: true,},
  { path: `${process.env.PUBLIC_URL}/settings/area-add`, Component: <AreaAdd />, protected: true, requiredPermission: 'store-area', requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/area-edit`, Component: <AreaEdit />, protected: true, requiredPermission: 'edit-area', requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/casProvider`, Component: <CasProvider />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/provider-add`, Component: <ProviderAdd />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/provider-edit`, Component: <ProviderEdit />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/payment`, Component: <PaymentsMethod />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/payment-add`, Component: <PaymentMethodAdd />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/payment-edit`, Component: <PaymentsMethodEdit />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/categories`, Component: <Categories />, protected: true, requiredPermission: 'show-category', requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/categories-add`, Component: <CategoryAdd />, protected: true, requiredPermission: 'store-category', requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/categories-edit`, Component: <CategoryEdit />, protected: true, requiredPermission: 'edit-category', requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/tax-group`, Component: <TaxRates />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/tax-group-add`, Component: <TaxGroupAdd />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/tax-group-edit`, Component: <TaxGroupEdit />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/tax-add`, Component: <TaxAdd />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/settings/tax-rate-edit`, Component: <Taxedit />, protected: true, requiredRole: 1, requireSubStatus: true, },

  //RightBar
  { path: `${process.env.PUBLIC_URL}/payment/quick-pay`, Component: <Qpay />, requireSubStatus: true },

  //Error
  { path: `${process.env.PUBLIC_URL}/pages/error/error-page1`, Component: <Error_page1 />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/pages/error/error-page2`, Component: <Error_page2 />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/pages/error/error-page3`, Component: <Error_page3 />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/pages/error/error-page4`, Component: <Error_page4 />, requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/pages/error/error-page5`, Component: <Error_page5 />, requireSubStatus: true },


  //Company
  { path: `${process.env.PUBLIC_URL}/dashboard/company-list`, Component: <CompanyList />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/dashboard/company-list/company-profile`, Component: <CompanyProfileContain />, protected: true, requiredRole: 1, requireSubStatus: true, },
  { path: `${process.env.PUBLIC_URL}/dashboard/managecompany/company-add`, Component: <CompanyAddsecound />, protected: true, requiredRole: 1, requiredPlanType: 'paid', requireSubStatus: true },
  { path: `${process.env.PUBLIC_URL}/dashboard/company-edit`, Component: <CompanyEdit />, protected: true, requiredRole: 1, requireSubStatus: true, },

  //Other
  { path: `${process.env.PUBLIC_URL}/masterProfile`, Component: <MasterProfileContain />, requireSubStatus: true },

];

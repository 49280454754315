import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import PackagesListContainer from "./PackagesList";

const Packages = () => {
    return (
        <Fragment>
            <PackagesListContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default Packages;
import { createSlice } from '@reduxjs/toolkit';
import { Action } from '../Constant';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    userName: null,
    email: null,
    apiUrl: null,
    companyName: null,
    industryId: null,
    subscription: null,
    subscriDetails: null,
    subStatus: null,
    role: null,
    userRole: null,
    currency: null,
    permissions: null,
    decimal:null,
  },
  reducers: {
    signIn: (state, action) => {
      state.user = action.payload;
    },
    SetUserName: (state, action) => {
      state.userName = action.payload;
    },
    SetEmail: (state, action) => {
      state.email = action.payload;
    },
    setApiUrl: (state, action) => {
      state.apiUrl = action.payload;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setIndustryId: (state, action) => {
      state.industryId = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    setSubscriptionDetails: (state, action) => {
      state.subscriDetails = action.payload;
    },
    setSubStatus: (state, action) => {
      state.subStatus = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setDecimal: (state, action) => {
      state.decimal = action.payload;
    },
    setLogout: (state) => {
      // Clear the state when logging out
      state.user = null;
      state.apiUrl = null;
      state.companyName = null;
      state.subscription = null;
      state.subStatus = null;
      state.userRole = null;
      state.role = null;
      state.userName = null;
      state.email = null;
      state.currency = null;
      state.permissions = null;
      state.decimal = null;
    },
  },
});

export const { 
  signIn, setApiUrl, setLogout, setCompanyName, setIndustryId, setSubscription, setSubStatus, setSubscriptionDetails, 
  setUserRole, setRole, SetUserName, setCurrency, setPermissions,setDecimal, SetEmail
} = authSlice.actions;
export const authReducer = authSlice.reducer;
import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Area, AreaCode, AreaCodeError, AreaEditTxt, AreaName, AreaNameError, Save, SubmitForm } from "../../../Constant";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { editArea, editAreaRole, viewArea, viewAreaRole } from "../../../CommonUrl";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { useSelector } from "react-redux";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const AreaEdit = () => {
    const location = useLocation();
    const authState = useSelector((state) => state.auth);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {}
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getAreaData();
    }, []);

    const getAreaData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewArea + `?area_id=${param1}` : viewAreaRole + `?area_id=${param1}`);
        if (data.status === true) {
            setValue('areaname', data.data[0].name);
            setValue('areacode', data.data[0].area_code);
      }
      else if (data.status >= 400 && data.status <= 405) {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (data.status >= 500 && data.status <= 505) {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      }
      else {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    
      }
      setLoading(false);


    }

    const onSubmit = async (data) => {
        setLoading(true)
        const postRequest = {
            area_id: param1,
            name: data.areaname,
            area_code: data.areacode
        };
        const editAreaResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? editArea : editAreaRole, postRequest);
        if (editAreaResponse.data.status === true) {
            if (editAreaResponse.status === 200) {
            toast.success('Success...!')
            navigate(`${process.env.PUBLIC_URL}/settings/area`);
        }  else {
            setLoading(false);
            toast.error(editAreaResponse.data.message)
        }
    }else if (editAreaResponse.status >= 400 && editAreaResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (editAreaResponse.status >= 500 && editAreaResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setLoading(false);
};
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}


            <Breadcrumbs mainTitle={Area} parent="Settings" title={Area} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AreaEditTxt}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{AreaName} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('areaname', { required: true })} />
                                <span className="text-danger">{errors.areaname && AreaNameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{AreaCode} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom02" type="text" {...register('areacode', { required: true })} />
                                <span className="text-danger">{errors.areacode && AreaCodeError}</span>
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default AreaEdit;

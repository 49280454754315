import React from 'react';
import Carousel from 'better-react-carousel';
import './billerqStyle.scss';

const Testimonial = () => {
    const autoplay = 2000;
    const responsive = [
        {
            breakpoint: 1250,
            cols: 1,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 2000
        },
        // {
        //     breakpoint: 950,
        //     cols: 3,
        //     rows: 1,
        //     gap: 10,
        //     loop: true,
        //     autoplay: 2000
        // },
        {
            breakpoint: 750,
            cols: 1,
            rows: 1,
            gap: 10,
            loop: true,
            autoplay: 1000
        },
    ];
    return (
        <div className='testimonial-container'>
            <h2>What Our Valued Customers Say</h2>
            <div className='carousel-Image'>
                <div className='col-md-8' >
                <Carousel
                    cols={1}
                    rows={1}
                    gap={2}
                    // showDots={true}
                    responsiveLayout={responsive}
                    // autoplay={autoplay}
                    mobileBreakpoint={550}
                    loop>
                    <Carousel.Item>
                        <div className='testimonial-carousal'>
                            <div className='testimonial-carousal-style'>
                                <p className='feedback'>"Implementing a subscription management system has been a game-changer for our business. The efficiency gains are incredible, automating billing cycles and giving us the flexibility to offer diverse subscription plans. Our customer satisfaction has soared as they now have user-friendly portals to manage their subscriptions, and our team can focus on delivering exceptional services rather than dealing with manual processes. The insights from the analytics features have empowered our decision-making, and the system's scalability ensures we're ready for future growth. It's more than just software; it's a catalyst for our success!"</p>
                                <div className='client-detail'>
                                    {/* <img src={require('../assets/images/Icons/testimonial.png')} alt='"' className="partners-logo" />
                                    <div style={{ paddingLeft: '30px' }}>
                                        <p className='client-name'>John James</p>
                                        <p className='client-designation'>CEO Of James</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* <Carousel.Item>
                        <div className='testimonial-carousal'>
                            <div className='testimonial-carousal-style'>
                                <p className='feedback'>“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <div className='client-detail'>
                                    <img src={require('../assets/images/Icons/testimonial.png')} alt='"' className="partners-logo" />
                                    <div style={{ paddingLeft: '30px' }}>
                                        <p className='client-name'>John James</p>
                                        <p className='client-designation'>CEO Of James</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item> */}
                    {/* <Carousel.Item>
                        <div className='testimonial-carousal'>
                            <div className='testimonial-carousal-style'>
                                <p className='feedback'>“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <div className='client-detail'>
                                    <img src={require('../assets/images/Icons/testimonial.png')} alt='"' className="partners-logo" />
                                    <div style={{ paddingLeft: '30px' }}>
                                        <p className='client-name'>John James</p>
                                        <p className='client-designation'>CEO Of James</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item> */}
                    {/* <Carousel.Item>
                        <div className='testimonial-carousal'>
                            <div className='testimonial-carousal-style'>
                                <p className='feedback'>“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <div className='client-detail'>
                                    <img src={require('../assets/images/Icons/testimonial.png')} alt='"' className="partners-logo" />
                                    <div style={{ paddingLeft: '30px' }}>
                                        <p className='client-name'>John James</p>
                                        <p className='client-designation'>CEO Of James</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item> */}
                    {/* <Carousel.Item>
                        <div className='testimonial-carousal'>
                            <div className='testimonial-carousal-style'>
                                <p className='feedback'>“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <div className='client-detail'>
                                    <img src={require('../assets/images/Icons/testimonial.png')} alt='"' className="partners-logo" />
                                    <div style={{ paddingLeft: '30px' }}>
                                        <p className='client-name'>John James</p>
                                        <p className='client-designation'>CEO Of James</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item> */}
                </Carousel>
                </div>
                <div className='col-md-4'>
                    <img src='/Services/devices.svg' alt='testimonial-image' className='testimonial-image' />
                </div>
            </div>
        </div>
    )
}

export default Testimonial
import { H2, H3 } from '../../../AbstractElements';
import React, { Fragment, useEffect, useState } from 'react';
import { CardHeader } from 'reactstrap';

const HeaderCard = (props) => {
    const e = props.details;

    return (
        <Fragment>
            <CardHeader className='pb-0'>
                <div className=''>
                    <div className='primary'>

                        <h2 className='billerqColor'>{props.title}</h2>
                    </div>
                </div>
            </CardHeader>

        </Fragment>
    );
};

export default HeaderCard;
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMasterDetails, getMasterProfile } from '../../../../CommonUrl';
import { getMasterDataToken } from '../../../../Utils/restUtils';
import MasterProfile from './MasterProfile';
import AboutMaster from './AboutMaster';
import InvoicePayment from './InvoicePayment';
import SubTabs from './Subscription';
import Transparentspinner from '../../../Common/Loader/Transparentspinner';


const MasterProfileContain = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const [widgetDetails, setWidgetDetails] = useState({});
    const [personalDetails, setPersonalDetails] = useState({});

    useEffect(() => {
        masterWidgetData();
        masterUserData();
    }, [])

    const masterWidgetData = async () => {
        setLoading(true);
        const response = await getMasterDataToken(getMasterDetails);
        if (response.status === true) {
            setWidgetDetails(response.data);
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false);
        
    }

    const masterUserData = async () => {
        setLoading(true);
        const response = await getMasterDataToken(getMasterProfile);
        if (response.status === true) {
            setPersonalDetails(response.data);
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false);
    }
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle="User Profile" parent="Users" title="User Profile" />
            <Container fluid={true}>
                <div className="user-profile">
                    <Row>
                        {Object.keys(widgetDetails).length > 0 && (
                            <MasterProfile details={widgetDetails} personalData={personalDetails}/>
                        )}
                        <Col xl="3" md="5" className="xl-35 box-col-30">
                            <div className="default-according style-1 faq-accordion job-accordion">
                                <Row>
                                    <Col xl='12'>
                                        {Object.keys(personalDetails).length > 0 && (
                                            <AboutMaster details={personalDetails} />
                                        )}
                                    </Col>
                                    {/* <Col xl="12" md="12" sm="6">
                                        <FollowersProfile />
                                    </Col>
                                    <Col xl="12" md="12" sm="6">
                                        <Followingsprofile />
                                    </Col>
                                    <Col xl="12" md="12" sm="6">
                                        <LatestPhotos />
                                    </Col>
                                    <Col xl="12" md="12" sm="6">
                                        <FriendsProfile />
                                    </Col> */}
                                </Row>
                            </div>
                        </Col>
                        <Col xl="9" md="7" className="xl-65 box-col-70">
                            <Row>
                                <SubTabs param={param1} />
                            </Row>
                        </Col>
                        <InvoicePayment param={param1} />
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default MasterProfileContain;
import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText,
} from 'reactstrap';
import Contact from './Contact';
import Footer from './Footer';
import { Link, useLocation } from 'react-router-dom';
import './Disclaimer.scss';
import { appUrl } from '../CommonUrl';

const ShipAndDelivery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const loginEncrypted = localStorage.getItem("login");
    let login = null;
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    if (loginEncrypted) {
        try {
            const bytes = CryptoJS.AES.decrypt(loginEncrypted, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
                login = JSON.parse(decryptedData);
            }
        } catch (e) {
            console.error('Error decrypting or parsing login data', e);
        }
    }

    const isLoggedIn = login && login.userToken;

    return (
        <div className='section1-containers'>
            <div style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
                <Navbar collapseonselect='true' expand="lg" className="bg-body-tertiary" container>
                    <NavbarBrand href="/"><img src={require('../assets/images/logoBillerq.png')} alt='' className="logo me-lg-5" /></NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="me-auto  align-items-lg-center" navbar>
                            <NavItem>
                                <NavLink href={appUrl} className='nav-link-bar'>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#aboutUs"} className='nav-link-bar'>About Us</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#feature"} className='nav-link-bar'>Features</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#usecase"} className='nav-link-bar'>Use Case</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#contact"} className='nav-link-bar'>Contact Us</NavLink>
                            </NavItem>
                            <NavbarText>
                                <Link className='ms-lg-5' to={isLoggedIn ? `${process.env.PUBLIC_URL}/dashboard/default` : '/SignIn'}>
                                    <div className='login-button'>{isLoggedIn ? 'Dashboard' : 'Get Started'}</div>
                                </Link>
                            </NavbarText>
                        </Nav>
                    </Collapse>
                    {/* </div> */}
                </Navbar>
            </div>
            <div style={{ width: '100%' }}>
                <div className='heading-main' >
                    <h1 className='disclaimer-heading'>Shipping and Delivery Policy</h1>
                </div>
            </div>


            <div className='container'>
                <div className='content-section'>

                    <p className='disclaimer-text'>
                        Thank you for choosing BillerQ! We provide a cloud-based SaaS (Software as a Service) subscriber management system. As our services are digital and do not involve the sale or physical delivery of goods, our shipping and delivery policy is as follows:
                    </p>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>Digital Service Delivery</h4>
                        <h4 className='sub-heading'>I. Immediate Access Upon Activation:</h4>
                        <p className='disclaimer-text'>
                            Signup and Trial Period: Upon signing up for BillerQ, you will receive immediate access to our platform. The signup process is free and includes a 14-days trial period during which you can explore all the features of BillerQ without any charge.
                        </p>
                        <p className='disclaimer-text'>
                            Post-Trial Access: After the trial period, continued access to BillerQ will be provided on a monthly postpaid basis. Your account will remain active as long as you are current with your subscription payments.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>II. No Physical Goods</h4>
                        <p className='disclaimer-text'>
                            Digital Service Only: BillerQ is a cloud-based software service, and we do not sell or ship any physical goods.
                            All services and features provided by BillerQ are delivered digitally via the internet. There are no shipping or physical delivery requirements.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>III. Access and Availability</h4>
                        <p className='disclaimer-text'>
                            24/7 Online Access:
                            Once your account is set up and activated, you can access BillerQ online at any time, from anywhere, using your login credentials.
                            Our platform is available 24/7, ensuring that you have uninterrupted access to your subscriber management tools and data.

                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>IV. Support and Assistance</h4>
                        <p className='disclaimer-text'>
                            Customer Support:
                            If you encounter any issues or require assistance with accessing or using BillerQ, our support team is available to help.
                            Please contact us at <span style={{ fontWeight: '600' }}>info@billerq.com</span> for any support-related inquiries.
                        </p>

                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>Contact Us</h4>
                        <p className='disclaimer-text'>
                            If you have any questions about our shipping and delivery policy or need further assistance, please reach out to us at <span style={{ fontWeight: '600' }}>info@billerq.com</span>.
                        </p>
                        <p className='disclaimer-text'>
                            Thank you for choosing BillerQ. We are committed to providing you with a seamless and efficient subscriber management experience.
                        </p>
                    </div>
                </div>
            </div>
            <div className='gap'></div>
            <Contact />
            <Footer />
        </div>
    );
}

export default ShipAndDelivery;

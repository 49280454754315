import React, { Fragment } from 'react';
import { H3, H4, H5, H6, LI, UL } from '../../../../AbstractElements';
import { Card, CardBody, Col } from 'reactstrap';
import { Items, Itemtotal, Price, TaxPercentage, setup_cost } from '../../../../Constant';
import { useSelector } from 'react-redux';

const PackageDetail = ({ details, features_list }) => {
    const authState = useSelector((state) => state.auth);

    return (
        <Fragment>
            <Col xl="7" sm="12" >
                <Col xl="10" sm="12" >
                    <div className="border font-size-fourteen">
                        <CardBody>
                            <div className="d-flex border-bottom">
                                <>
                                    <H4>Package : </H4>
                                </>
                                <>
                                    <H4>&nbsp;{details.name}</H4>
                                </>
                            </div>
                            <ul className="mt-3">
                                <LI>1.<span>{details.features}</span></LI>
                                <LI>2. Duration {details.duration} days</LI>
                                <LI>3.{details.sms === 'off' ? <span style={{ textDecoration: 'line-through' }}>SMS</span> : ' SMS'}</LI>
                            </ul>
                        </CardBody>
                    </div>
                </Col>
                <Col xl="10" sm="12">
                    <div className='border border-top-0 p-4 pt-2 font-size-fourteen' >
                        <ul className="list-group list-group-flush mt-2">
                            <li className="d-flex justify-content-between ">
                                {Items}
                                <span className="text-end ">{setup_cost}</span>
                            </li>
                            <li className="d-flex justify-content-between ">
                                {Price}
                                <span className="text-end ">{authState.currency} {features_list.setup_cost}</span>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Col>
        </Fragment>
    );
};
export default PackageDetail;
import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { Description, Name, NameError, Save, TaxRate, AddTax, TaxPercentage, SortOrder } from "../../../Constant";
import { addTax, getTaxitems, getTaxitemsRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { postClientUrlWithToken, getClientUrlDataToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import * as yup from "yup";
import Typeahead from "../../../CommonElements/TypeAhead";
import { yupResolver } from "@hookform/resolvers/yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.mixed().required().label('Name '),
    tax_percentage: yup.string().required().label('Percentage'),
    sort_order: yup.string().required().label('Sort order'),
});


const TaxForm = ({ data }) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(schema) });
    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    // const searchParams = location.search;
    const params = new URLSearchParams(location.search);
    const param1 = params.get("id");
    const [name, setName] = useState([])
    const [nameOption, setNameOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const title = params.get("title");


    useEffect(() => {
        taxNameData();
    }, []);

    useEffect(() => {
        const inputElement = document.querySelectorAll('input[type="number"]');

        const preventScrollEvent = (event) => {
            event.preventDefault();
        };

        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

            return () => {
                inputElement.removeEventListener('wheel', preventScrollEvent);
            };
        });
    }, []);



    useEffect(() => {
        setNameOption(name.map(data => data));
    }, [name]);

    const taxNameData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getTaxitems : getTaxitemsRole);
        if (data.status === true) {
            setName(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            tax_group_id: param1,
            name: data.name.value,
            tax_percentage: data.tax_percentage,
            sort_order: data.sort_order,
            description: data.message
        }
        const addTaxGroupResponse = await postClientUrlWithToken(authState.apiUrl, addTax, postRequest);

        if (addTaxGroupResponse.status === 200) {
            if (addTaxGroupResponse.data.status === true) {
                toast.success(addTaxGroupResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/settings/tax-group`);
            } else {
                toast.error(addTaxGroupResponse.data.message)
                setLoading(false);
            }
        } else if (addTaxGroupResponse.status >= 400 && addTaxGroupResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addTaxGroupResponse.status >= 500 && addTaxGroupResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}
            <Breadcrumbs mainTitle={TaxRate} parent="Settings" title={TaxRate} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddTax} {title && `- ${title}`}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">

                            <Col md="6">
                                <Label className="form-label">
                                    {Name} <span className="requireStar">*</span>
                                </Label>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={nameOption.map(option => ({ label: option, value: option }))}
                                            onChange={(selectedOption) => field.onChange(selectedOption)} // Use selectedOption directly
                                            value={field.value} // Make sure the value is correctly assigned
                                        />
                                    )}
                                />


                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>

                            <Col md='6'>
                                <Label className="form-label" for="tax_percentage">{TaxPercentage} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="tax_percentage" type="number" {...register('tax_percentage', { required: true })} />
                                <span className="text-danger">{errors.tax_percentage?.message}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="sort_oreder">{SortOrder} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="sort_oreder" type="number" defaultValue={1} {...register('sort_order', { required: true })} />
                                <span className="text-danger">{errors.sort_order?.message}</span>
                            </Col>

                            <Col md="6">
                                <Label className="form-label">{Description}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="2"
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default TaxForm;
import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import TransactionContainer from "./TransactionList";

const Transaction = () => {
    return (
        <Fragment>
            <TransactionContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default Transaction;
import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import {
  City,
  CityErr,
  ConfirmPassword,
  Country,
  EdituserAgent,
  Email,
  EmailError,
  FirstName,
  FirstNameError,
  Language,
  LanguageErr,
  LastName,
  LastNameError,
  Locale,
  Localerr,
  MobileNo,
  Password,
  Role,
  Save,
  State,
  StateErr,
  UserAgent,
  zipcode,
  zipcodeerr,
} from "../../../Constant";
import { Postagent, Updateagent, getRole, viewAgent, } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import {
  getClientUrlDataToken,
  postClientUrlWithToken,
} from "../../../Utils/restUtils";
import { SetLanguage } from "../../Common/Language/Language"
import AreaEdit from "../../Common/Area Field/AreaEdit";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
  firstname: yup.string().required().label('First Name'),
  lastname: yup.string().required().label('Last Name'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  role: yup.mixed().required().label('Role'),
  area: yup.mixed().required().label('Area'),
  language: yup.mixed().required().label('Language'),
  phoneNumber: yup.string().required('Phone number is required'),
  // password: yup.string().required('Password id required').min(8, 'Password must be at least 8 characters long'),
  // password_repeat: yup.string()
  //   .oneOf([yup.ref('password'), null], 'Passwords must match')
  //   .required('Confirm Password is required'),

})

const UserAgentEdit = () => {

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({ resolver: yupResolver(schema) });
  const authState = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [area, setArea] = useState([]);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);
  const param1 = params.get("id");
  const [togglePassword, setTogglePassword] = useState(false);


  useEffect(() => {
    singleApiId()
  }, [])


  const singleApiId = async () => {
    setLoading(true);
    let api_id = await getClientUrlDataToken(authState.apiUrl, viewAgent + `?agent_id=${param1}`);

    const singleId = api_id.data.data[0];
    setValue("firstname", singleId.first_name);
    setValue("lastname", singleId.last_name);
    setValue("phoneNumber", singleId.country_code + singleId.mobile);
    setValue("countryCode", singleId.country_code);
    setValue("email", singleId.email);

    let data = await getClientUrlDataToken(authState.apiUrl, getRole);
    if (data.status === true) {
      const mappedrole = data.data.map((items) => ({
        value: items.id,
        label: items.name
      }
      ));
      setRoles(mappedrole);
      const equalRole = mappedrole.find((item) => item.value === singleId.role_id);
      setValue("role", { value: equalRole?.value, label: equalRole?.label });

      setValue("city", singleId.city);
      setValue("state", singleId.state);
      setValue("country", singleId.country);
      setValue("zipcode", singleId.zip_code);
      setArea(singleId?.area_id);
      const language = SetLanguage.find(item => item.value == singleId.locale);
      setValue("language", language)
      setValue("area", singleId.area_id)

    } else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }
    setLoading(false);
  }

  const onSubmit = async (data) => {
    setLoading(true);
    const countryCode = data.countryCode;
    const phoneNumber = data.phoneNumber;
    if (phoneNumber.startsWith(countryCode)) {
      const b = phoneNumber.slice(countryCode.length);

      const postRequest = {
        agent_id: param1,
        first_name: data.firstname,
        last_name: data.lastname,
        mobile: b,
        email: data.email,
        role_id: data.role.value,
        area_ids: data.area,
        country_code: data.countryCode,
        city: data.city,
        state: data.state,
        country: data.country,
        zip_code: data.zipcode,
        locale: data.language.value,
      };
      const addCustomerResponse = await postClientUrlWithToken(
        authState.apiUrl,
        Updateagent,
        postRequest
      );
      if (addCustomerResponse.status === 200) {
        if (addCustomerResponse.data.status === true) {
          navigate(`${process.env.PUBLIC_URL}/menu/user`);
          toast.success(addCustomerResponse.data.message);
        } else {
          setLoading(false);
          toast.error(addCustomerResponse.data.message)
        }
        setLoading(false);
      } else if (addCustomerResponse.status >= 400 && addCustomerResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (addCustomerResponse.status >= 500 && addCustomerResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
      }
    };
  };

  return (
    <Fragment>
      {loading && <Transparentspinner />}


      <Breadcrumbs mainTitle={UserAgent} parent="Customers" title={EdituserAgent} />
      <Card>
        <CardHeader className="pb-0">
          <H3>{EdituserAgent}</H3>
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom01">
                  {FirstName} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="text"
                  {...register("firstname", { required: true })}
                />
                <p className="text-danger">{errors.firstname?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {LastName} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="validationCustom02"
                  type="text"
                  {...register("lastname", { required: true })}
                />
                <p className="text-danger">{errors.lastname?.message}</p>
              </Col>

            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {MobileNo} <span className="requireStar">*</span>
                </Label>

                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      country={"in"}
                      enableSearch={true}
                      onChange={(value, country) => {
                        setValue("countryCode", country.dialCode);
                        field.onChange(value);
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "46px",
                        padding: "10px",
                        paddingLeft: "50px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                      }}
                      searchClass="none"
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </span>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom01">
                  {Email} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="email"
                  {...register("email", { required: true })}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </Col>


            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {Role} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={roles}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.role?.message}</p>
              </Col>
              <Col md="6">
                <AreaEdit control={control} area={area} setValue={setValue} />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {Language} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="language"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={SetLanguage}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.language?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom03">
                  {City}
                </Label>
                <input
                  className="form-control"
                  id="validationCustom03"
                  type="text"
                  {...register("city")}
                />
                {/* <span className="text-danger">
                  {errors.city && CityErr}
                </span> */}
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom01">
                  {State}
                </Label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="text"
                  {...register("state")}
                />
                {/* <span className="text-danger">
                  {errors.state && StateErr}
                </span> */}
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom01">
                  {Country}
                </Label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="text"
                  {...register("country")}
                />
                {/* <span className="text-danger">
                  {errors.country && FirstNameError}
                </span> */}
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom01">
                  {zipcode}
                </Label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="text"
                  {...register("zipcode")}
                />
                {/* <span className="text-danger">
                  {errors.zipcode && zipcodeerr}
                </span> */}
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom01">
                  {Locale}
                </Label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="text"
                  {...register("local")}
                />
                {/* <span className="text-danger">
                  {errors.Local && Localerr}
                </span> */}
              </Col>
            </Row>

            {/* <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">
                  {Password} <span className="requireStar">*</span>
                </Label>
                <div className='form-input position-relative'>

                <input
                  className="form-control"
                  type={togglePassword ? 'text' : 'password'} 
                  {...register("password", { required: true })}
                />
                <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                  <span className={togglePassword ? '' : 'show'}></span>
                </div>
                </div>

                <p className="text-danger">{errors.password?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {ConfirmPassword} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  type="password"
                  {...register("password_repeat", { required: true })}
                />
                <p className="text-danger">{errors.password_repeat?.message}</p>
              </Col>
            </Row> */}
            <FormGroup></FormGroup>
            <div className="text-start">
              <Btn attrBtn={{ color: "primary" }} type="submit">
                {Save}
              </Btn>

            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default UserAgentEdit
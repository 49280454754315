import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn } from '../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { Add, OnlinePaymentRep, OnlinePaymentList, Search, Filter, Area, Export, Category } from '../../Constant';
import { getAreaRole, getCategoryRole, getOnlinePayment, getOnlinePaymentRole } from '../../CommonUrl';
import { getArea, getCategory} from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { OnlinePaymentColumns } from '../../Data/MockTable/ReportTableHeader';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const OnlinePaymentReport = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [customerLists, setCustomerList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const [apiArea, setapiArea] = useState([]);
    const [apiCategory, setapiCategory] = useState([]);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [areaId, setAreaId] = useState('');
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    useEffect(() => {
        fetchOnlinePay(1);
        areaData();
        // categoryData()
    }, []);
    
    const areaData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl,authState.userRole === 1 ? getArea:getAreaRole);
        if (data.status === true) {
            setapiArea(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    // const categoryData = async () => {
    //     let data = await getClientUrlDataToken(authState.apiUrl,authState.userRole === 1 ? getCategory:getCategoryRole);
    //     if (data.status === true) {
    //         setapiCategory(data.data);
    //     }
    //     else if (data.status >= 400 && data.status <= 405) {
    //         navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    //     } else if (data.status >= 500 && data.status <= 505) {
    //         navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    //     }
    //     else {
    //         navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    //     }
    // };
    const handlePageChange = page => {
        fetchOnlinePay(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchOnlinePay(page, newPerPage);
        setPerPage(newPerPage);
    };

    const onSubmit = async (data) => {
        let search = data.search;
        let areaId = data.area;
        let catergoryId = data.categories;

        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';

        setSearchKeyword({ searches: data.search,area:areaId,start: start_date, end: end_date,category :catergoryId });
        fetchOnlinePay(1, perPage,search, start_date, end_date, areaId, catergoryId);
    };



    const fetchOnlinePay = async (page, size = perPage,search='', startDate = '', endDate = '', areaId = '', categoryId='') => {
        
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getOnlinePayment + 
            `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&area_id=${areaId}&category_id=${categoryId}` :
            getOnlinePaymentRole + `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&area_id=${areaId}&category_id=${categoryId}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const api_data = response.data.data;
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(api_data.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    SubId: e.subscriber_id,
                    InvoiceNo: e.invoice_no,
                    Name: e.customer_name,
                    Address: e.billing_address,
                    Area: e.area_name,
                    Account: e.account_name,
                    AmountPaid: authState.currency + " " + e.paid_amount,
                    PaidDate: e.paid_date,
                    PaidBy: e.paid_by,
                    id: e.order_id,
                    customer_id: e.customer_id,
                }
            }
            ))

            setTotalRows(response.data.total);

        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    const exportOnlinePayExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getOnlinePayment +
             `?export=online_payment_data&search=${searchKeyword?.searches ?? ''}&area_id=${searchKeyword?.area ?? ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&category_id=${searchKeyword?.category ?? ''}` :
              getOnlinePaymentRole + `?export=online_payment_data&search=${searchKeyword?.searches ?? ''}&area_id=${searchKeyword?.area ?? ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&category_id=${searchKeyword?.category ?? ''}`);
        const dataToExport = response.data.map(({ subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by }) => ({ Subscriber_Id: subscriber_id, Invoice_No: invoice_no, Name: customer_name, Address: billing_address, Area: area_name, Account: account_name, Amount_Paid: authState.currency + paid_amount, Paid_Date: paid_date, Paid_By: paid_by }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'online_report');
            XLSX.writeFile(wb, 'online_payment_report_data.xlsx');
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    const exportOnlinePayPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getOnlinePayment + 
            `?export=online_payment_data&search=${searchKeyword?.searches ?? ''}&area_id=${searchKeyword?.area ?? ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&category_id=${searchKeyword?.category ?? ''}` :
            getOnlinePaymentRole + `?export=online_payment_data&search=${searchKeyword?.searches ?? ''}&area_id=${searchKeyword?.area ?? ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&category_id=${searchKeyword?.category ?? ''}`);
        const dataToExport = response.data.map(({ subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by }, index) => ({ Sl: index + 1, subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Subscriber Id", "Invoice No", "Name", "Address", "Area", "Account", "Amount Paid", "Paid Date", "Paid By"];
            const rows = dataToExport.map(({ subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by }, index) => [index + 1, subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("online_payment_report.pdf");
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={OnlinePaymentRep} parent="Reports" title={OnlinePaymentRep} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{OnlinePaymentList}</H3>
                    <div>
                        <CardBody className="dropdown-basic">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportOnlinePayExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportOnlinePayPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search}  type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element" >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'

                                />
                            </div>

                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('area')}>
                                <option key='areaa' value="">{Area}</option>
                                {apiArea.map((values, index) => (
                                    <option key={values.id} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>

                        <Col sm="12" md="5" lg='1'className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>


                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={OnlinePaymentColumns}
                            data={customerLists}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default OnlinePaymentReport;
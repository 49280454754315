import React from 'react';
import Carousel from 'better-react-carousel';
import './style.scss';

const Features = () => {
    const autoplay = 2000;
    const responsiveFeature = [
        {
            breakpoint: 1250,
            cols: 1,
            rows: 1,
            gap: 5,
            loop: true,
            autoplay: 2000
        }
    ];
    return (
        <div>
            <Carousel
                cols={1}
                rows={3}
                gap={1}
                showDots={true}
                responsiveLayout={responsiveFeature}
                autoplay={autoplay}
                hideArrow={true}
                mobileBreakpoint={550}
                loop>
                <Carousel.Item>
                    <div className='single-service-container'>
                        <img src={require('../../assets/images/Icons/Task.png')} alt='' style={{ height: '50px' }} />
                        <div className='service-details'>
                            <h3>Task</h3>
                            <p>Work through your task efficiently and intuitively</p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='single-service-container'>
                        <img src={require('../../assets/images/Icons/invoice.png')} alt='' style={{ height: '50px' }} />
                        <div className='service-details'>
                            <h3>Invoicing</h3>
                            <p>Invoice your clients and accept payments easily</p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='single-service-container'>
                        <img src={require('../../assets/images/Icons/budget.png')} alt='' style={{ height: '50px' }} />
                        <div className='service-details'>
                            <h3>Estimates</h3>
                            <p>Quickly send your clients estimates and proposals</p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='single-service-container'>
                        <img src={require('../../assets/images/Icons/management.png')} alt='' style={{ height: '50px' }} />
                        <div className='service-details'>
                            <h3>User Roles</h3>
                            <p>Control the resources your team members can access</p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='single-service-container'>
                        <img src={require('../../assets/images/Icons/work-from-home.png')} alt='' style={{ height: '50px' }} />
                        <div className='service-details'>
                            <h3>Work From Anywhere</h3>
                            <p>Your team can now easily connect and work from anywhere</p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='single-service-container'>
                        <img src={require('../../assets/images/Icons/time-tracking.png')} alt='' style={{ height: '50px' }} />
                        <div className='service-details'>
                            <h3>Time Tracking</h3>
                            <p>Advanced time tracking so you never miss a minute of time spent</p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='single-service-container'>
                        <img src={require('../../assets/images/Icons/support.png')} alt='' style={{ height: '50px' }} />
                        <div className='service-details'>
                            <h3>Help Desk</h3>
                            <p>Use the helpdesk to provide support for your clients</p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='single-service-container'>
                        <img src={require('../../assets/images/Icons/light-bulb.png')} alt='' style={{ height: '50px' }} />
                        <div className='service-details'>
                            <h3>Knowledgebase</h3>
                            <p>Create engaging articles easily share information with all your stakeholders</p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='single-service-container'>
                        <img src={require('../../assets/images/Icons/subscription-model.png')} alt='' style={{ height: '50px' }} />
                        <div className='service-details'>
                            <h3>Subscriptions</h3>
                            <p>Accept recurring payments via Stripe, using the subscription feature</p>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Features
export default function HandleApiError(navigate,status) {
    if (status >= 400 && status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (status >= 500 && status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
};


//error for login signupn pages
 
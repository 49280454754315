import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, requiredRole, requiredPermission, requiredPlanType, requireSubStatus }) => {
    const authState = useSelector((state) => state.auth);
    // Check if subStatus is null
    if (
        requireSubStatus && 
        (authState.subStatus === null || (Array.isArray(authState.subStatus) && authState.subStatus.length === 0))
    ) {
        return <Navigate to={`/error`} replace />;
    }

    // Check role if specified
    if (requiredRole && authState.userRole !== requiredRole) {
        return <Navigate to={`${process.env.PUBLIC_URL}/pages/error/error-page5`} replace />;
    }

    // Check permission if required
    if (requiredPermission && authState.userRole !== 1 && !authState.permissions.includes(requiredPermission)) {
        return <Navigate to={`${process.env.PUBLIC_URL}/pages/error/error-page5`} replace />;
    } 
    
    // Check plan type if required
    if (requiredPlanType && authState.subStatus.plan_type !== requiredPlanType) {
        return <Navigate to={`${process.env.PUBLIC_URL}/pages/error/error-page5`} replace />;
    }

    return Component;
};

export default ProtectedRoute;

import React, { useEffect, useState } from 'react'
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { getArea } from '../../../CommonUrl';
import { Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Area } from '../../../Constant';
import { useSelector } from 'react-redux';
import Transparentspinner from '../Loader/Transparentspinner';

const AreaEdit = ({ control, area }) => {
    const [spinnerTrans, setspinnerTrans] = useState(true)
    const [options, setOptions] = useState([]);
    const [apiArea, setapiArea] = useState([]);
    const authState = useSelector((state) => state.auth);
    const [togglePassword, setTogglePassword] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {

        const areaData = async () => {
            let data = await getClientUrlDataToken(authState.apiUrl, getArea);
            if (data.status === true) {
                const mapedData = data.data;
                setapiArea(mapedData);

                const filteredData = mapedData.filter((item) => area?.includes(item.id));
                setOptions(filteredData);
                setspinnerTrans(false);
            } else if (data.status >= 400 && data.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (data.status >= 500 && data.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

            }
        };
        areaData();
    }, [area]);


    return (
        <>
            {spinnerTrans && <Transparentspinner />}

            <Label className="form-label" for="validationCustom02">
                {Area} <span className="requireStar">*</span>
            </Label>
            <Controller
                name="area"
                control={control}
                render={({ field }) => (
                    <Typeahead
                        id="multiple-typeahead"
                        clearButton
                        labelKey={'name'}
                        onChange={(value) => {
                            const selectedIds = value.map((options) => (options.id))
                            setOptions(value);
                            field.onChange(selectedIds);
                        }}
                        value={options}
                        options={apiArea}
                        selected={options}
                        multiple
                        placeholder="Choose area..."
                    />
                )}
            />


        </>
    )
}

export default AreaEdit;
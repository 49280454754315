import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import { Btn, H4 } from '../../../../AbstractElements';
import { Invoice, Transactions, Reciept, ViewMore, PaymentHistory, Export, Payment } from '../../../../Constant';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getClientUrlDataToken, getMasterDataToken } from '../../../../Utils/restUtils';
import { getMakePayment, getMasterInvoice, getMasterPayment, getPaymentHistory, getPaymentHistoryRole, getRecentInvoice, getRecentInvoiceRole, getRecentPayment, getRecentPaymentRole, getShareApi } from '../../../../CommonUrl';
import { InvoiceColumns, paymentColumns, paymentsColumns } from '../../../../Data/MockTable/UserTableHeader';
import { conditionalRowPayments } from '../../../Common/Rowstyle/Rowstyle';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';


const InvoicePayment = (props) => {
    const style2 = { width: 85, fontSize: 13, padding: 5 };
    const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState('1');
    const authState = useSelector((state) => state.auth);
    const [invoiceList, setInvoiceList] = useState([]);
    const [paymentslist, setPayments] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [totalPaymentRows, setpaymentTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPaymentPage, setPaymentCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const InvoiceColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '50px'
        },
        {
            name: <H4>Action</H4>,
            selector: row => row.action,
            sortable: true,
            width: '120px'

        },
        {
            name: <H4>Company</H4>,
            selector: row => row.CompName,
            sortable: true,
            width: '150px'
        },
        {
            name: <H4>Invoice no</H4>,
            selector: row =>
                <div style={{ textDecoration: 'underline', color: '#534686', cursor: 'pointer' }} onClick={() => redirectToInvoice(row)}>
                    {row.Invoice}
                </div>,
            sortable: true,
            center: true,

        },
        {
            name: <H4>Invoice Type</H4>,
            selector: row => row.InType,
            sortable: true,
            wrap: true

        },
        {
            name: <H4>Billing Date</H4>,
            selector: row => row.BillingDate,
            sortable: true,
            wrap: true

        },
        {
            name: <H4>Total</H4>,
            selector: row => row.Total,
            sortable: true,

        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,
            center: true,
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}
                >
                    {row.Status}
                </span>
            ),
        }

    ];

    useEffect(() => {
        invoiceData(1); // do not put await here
        paymentsData(1);
    }, []);
    const handlePageChange = page => {
        invoiceData(page);
        setCurrentPage(page);
    };

    const handlePaymentPageChange = page => {
        paymentsData(page);
        setPaymentCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        invoiceData(page, newPerPage);
        paymentsData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const redirectToInvoice = async (data) => {
        let response = await getMasterDataToken(getShareApi + `?order_id=${data.order_id}`);
        if (response.status === true) {
            const toReciept = `${process.env.PUBLIC_URL}/invoice?order_id=${response.data}`;
            navigate(toReciept);
        }
    };

    const redirectToReciept = (id) => {
        const toReciept = `${process.env.PUBLIC_URL}/billing/thermal-print?payment_id=${id}`;
        navigate(toReciept);
    };


    const invoiceData = async (page, size = perPage) => {
        setLoading(true)
        const data = await getMasterDataToken(getMasterInvoice);
        if (data.status === true) {
            setInvoiceList(data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    CompName: e.company_name,
                    Invoice: e.invoice_number,
                    InType: e.invoice_type,
                    BillingDate: e.billing_date,
                    Total: authState.currency + " " + e.total_amount,
                    Customer: e.customer_name,
                    Status: e.payment_status,
                    order_id: e.order_id,
                    id: e.id,
                    customerid: e.customer_id,
                    action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToInvoice(e) }}>{ViewMore}</Btn>
                            </span>
                        </div>
                }
            }))
            setTotalRows(data.data.total);
            setLoading(false);
        } else if (data.status >= 400 && data.status <= 405) {
            // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    };

    const paymentsData = async (page, size = perPage) => {
        setLoading(true)
        const payment_api = await getMasterDataToken(getMasterPayment);
        if (payment_api.status === true) {
            const paymentData = payment_api.data;
            setPayments(paymentData.map((e, index) => {
                return {
                    Si: index + 1,
                    CompName: e.company_name,
                    payment_id: e.id,
                    Name: e.customer_name,
                    Paidat: e.payment_date,
                    txnId: e.transaction_id,
                    payType: e.payment_method_name,
                    Amount: authState.currency + " " + e.paid_amount,
                    IndustryName: e.industry_name,
                    Status: e.payment_status,

                    Action:
                        <div>
                            <span>
                                <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToReciept(e.id) }}>{Reciept}</Btn>
                            </span>
                        </div >
                }
            }))
            setpaymentTotalRows(payment_api.data.total);
            setLoading(false)
        } else if (payment_api.status >= 400 && payment_api.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (payment_api.status >= 500 && payment_api.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    }

    const exportPaymentExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.userRole === 1 ? getMasterPayment + `?export=payment` :
            getMasterPayment + `?export=payment`)
        // const dataToExport = response.payment.map(({ date, transaction_id, collected_amount, invoice_number, payment_type }) => ({ Date: date, Transaction_id: transaction_id, Amount: collected_amount, Reflected_Invoice: invoice_number, Payment_Type: payment_type }));
        // if (response.status === true) {
        //     const ws = XLSX.utils.json_to_sheet(dataToExport);
        //     const wb = XLSX.utils.book_new();
        //     XLSX.utils.book_append_sheet(wb, ws, 'Payment Data');
        //     XLSX.writeFile(wb, 'payment_data.xlsx');
        // } else if (response.status >= 400 && response.status <= 405) {
        //     navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        // } else if (response.status >= 500 && response.status <= 505) {
        //     navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        // }
        // else {
        //     navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        // }
        setLoading(false);
    };
    const exportPaymentPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.userRole === 1 ? getMasterPayment + `?export=payment` :
            getMasterPayment + `?export=payment`);
        // const dataToExport = response.payment.map(({ date, transaction_id, collected_amount, invoice_number, payment_type }, index) => ({ Sl: index + 1, date, transaction_id, collected_amount, invoice_number, payment_type }));
        // if (response.status === true) {
        //     const doc = new jsPDF();
        //     const columns = ["Paid On", "Txn Id", "AmountCollected", "Reflected Invoice", "Payment Type"];
        //     const rows = dataToExport.map(({ date, transaction_id, collected_amount, invoice_number, payment_type }, index) => [index + 1, date, transaction_id, collected_amount, invoice_number, payment_type]);
        //     doc.autoTable({
        //         head: [columns],
        //         body: rows
        //     });
        //     doc.save("payment_data.pdf");
        // } else if (response.status >= 400 && response.status <= 405) {
        //     navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        // } else if (response.status >= 500 && response.status <= 505) {
        //     navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        // }
        // else {
        //     navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        // }
        setLoading(false);
    };

    return (
        <>
            <Col sm="12" xl="6 xl-100">
                <Card>
                    <CardBody>
                        {PrimarycolorLineTab === '2' && (
                            <Dropdown className='ms-2' isOpen={dropdownOpen} toggle={toggleDropdown} style={{ float: 'right', marginRight: '10px' }}>
                                {/* <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle> */}
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportPaymentExcel()}><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportPaymentPdf()}><i className="fa fa-file-excel-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        )}
                        <Nav className="border-tab nav-primary" tabs>
                            <NavItem>
                                <NavLink className={PrimarycolorLineTab === '1' ? 'active' : ''} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('1')}>{Invoice}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={PrimarycolorLineTab === '2' ? 'active' : ''} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('2')}>{Payment}</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={PrimarycolorLineTab}>
                            <TabPane className="fade show" tabId="1">
                                <div className="order-history table-responsive" style={{ marginTop: '-15px' }}>
                                    <DataTable
                                        data={invoiceList}
                                        columns={InvoiceColumns}
                                        pagination
                                        progressPending={loading}
                                        paginationServer
                                        defaultSortAsc
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        conditionalRowStyles={conditionalRowPayments}
                                        style={{ border: '1px solid' }}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="order-history table-responsive" style={{ marginTop: '-15px' }}>
                                    <DataTable
                                        data={paymentslist}
                                        columns={paymentsColumns}
                                        pagination
                                        progressPending={loading}
                                        paginationServer
                                        defaultSortAsc
                                        paginationTotalRows={totalPaymentRows}
                                        paginationDefaultPage={currentPage}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePaymentPageChange}
                                        style={{ border: '1px solid' }}
                                    />
                                </div>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default InvoicePayment;
import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../Layout/SpinnerLoader";
import { Header, HeaderAdd, Description, Title, TitleError, Save } from "../../Constant";
import { addArea, addHeaders, addHeaderRole } from "../../CommonUrl";
import { useSelector } from "react-redux";
import { postClientUrlWithToken } from "../../Utils/restUtils";
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    title: yup.string().required().label('Title'),
})

const HeaderForm = () => {
    const { register, handleSubmit, control, formState: { errors } , setValue} = useForm({
        resolver: yupResolver(schema),
    });
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { title: data.title,
             description: data.description }

        const addHeaderResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addHeaders : addHeaderRole, postRequest);

        if (addHeaderResponse.status === 200) {
            if (addHeaderResponse.data.status === true) {
                toast.success(addHeaderResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/Expenses/Header`);
            } else {
                toast.error(addHeaderResponse.data.message)
                setLoading(false);
            }
        } else if (addHeaderResponse.status >= 400 && addHeaderResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addHeaderResponse.status >= 500 && addHeaderResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={Header} parent="Settings" title={Header} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{HeaderAdd}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" >{Title} <span className="requireStar">*</span></Label>
                                <input className="form-control"  type="text" {...register('title', { required: true })} style={{ width: '100%' }} />
                                <p className="text-danger">{errors.title?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" >{Description}</Label>
                                <input className="form-control"  type="text" {...register('description')} style={{ width: '100%' }} />
                                <p className="text-danger">{errors.description?.message}</p>
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default HeaderForm;


import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { Btn, H3, P } from '../../../../AbstractElements';
import { StbModem, Subscription, SubscriptionHistory } from '../../../../Constant';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getClientUrlDataToken } from '../../../../Utils/restUtils';
import { getUserStb, getUserStbRole } from '../../../../CommonUrl';
import { StbColumns } from '../../../../Data/MockTable/UserTableHeader';
import { conditionalRowStyles } from '../../../Common/Rowstyle/Rowstyle';
import { useNavigate } from 'react-router-dom';

const StbTable = (props) => {
    const propsId = props.param;
    const authState = useSelector((state) => state.auth);
    const [stbList, setStbList] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        stbData();
    }, []);

    const stbData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? 
            getUserStb + `?page=&page_length=&search=&customer_id=${propsId}`:getUserStbRole + `?page=&page_length=&search=&customer_id=${propsId}`);
        if (data.status === true) {
            setStbList(data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    StbNo:e.stb_no,
                    SerialNo: e.serial_no,
                    StbType: e.connection_type,
                    StbMake: e.device_make,
                    Provider: e.provider_name,
                    InstallDate: e.installed_date,
                    Status: e.stb_status,
                }
            }))
        }else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };
    return (
        <Col sm="12" xl="6 xl-100">
            <Card>
                <CardHeader>
                    <H3 attrH3={{ style: { paddingLeft: '10px' } }}>{StbModem}</H3>
                </CardHeader>
                <CardBody>
                    <div className="order-history table-responsive" style={{ marginTop: '-40px', height: '200px' }}>
                        <DataTable
                            data={stbList}
                            columns={StbColumns}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default StbTable;
import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../Constant";
import CategoryForm from "./CategoriesForm";

const CategoryAdd = () => {
    return (
        <Fragment>
            <CategoryForm mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default CategoryAdd;
import React, { Fragment, useEffect, useState } from 'react';
import { LogOut, Pricing } from '../../Constant';
import { Card, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import { postClientUrlWithToken, postMasterToken } from '../../Utils/restUtils';
import { changeCompany, getCompany, packageSubscription } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import HandleHomePageError from '../../Components/Common/ErrorImport/SignError';
import { useLocation, useNavigate } from 'react-router';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';
import NavSign from '../Sign/NavSign';
import SubCard from './SubCard';
import { toast } from 'react-toastify';

const CompSubscription = () => {
    const authState = useSelector((state) => state.auth);
    const [priceDetails, setPriceDetails] = useState([]);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const location = useLocation();
    const { id } = location.state || {};
    const [loading, setloading] = useState(false);
    const [company, setCompany] = useState('');
    const [importDrpodown, setimportDrpodown] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        packageApi();
        companyData();
    }, []);
    const toggleImport = () => setimportDrpodown(!importDrpodown);

    const packageApi = async () => {
        setloading(true)
        const response = await postMasterToken(packageSubscription, { industry_id: authState.industryId });
        if (response.status === 200) {
            setPriceDetails(response.data.data);
        }
        else {
            // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            HandleHomePageError(navigate, response.status)
            setloading(false)

        }
        setloading(false)
    }

    const companyData = async () => {
        let data = await postClientUrlWithToken(authState.apiUrl, getCompany);
        if (data.status === 200) {
            const companyData = data.data.data;
            if (companyData.length > 0) {
                setCompany(companyData);
            } else {
                setCompany("");
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const companyChangeData = async (id) => {
        setloading(true);
        let data = await postClientUrlWithToken(authState.apiUrl, changeCompany, { company_id: id });
        if (data.status === 200) {
            if (data.data.status === true) {
                window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/`;
                const newActiveCompany = company.find(company => company.id === id);
                if (newActiveCompany) {
                    toast.success(data.data.message)
                    companyData();
                }
            } else {
                toast.error(data.data.message);
                setloading(false);
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setloading(false);
    };

    return (
        <div>
            {loading && <Transparentspinner />}
            <NavSign showProfile={true} buttonText={LogOut} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#eff3f9",
                    height: "100%",
                }}
            >
                <Fragment>
                    {loading && <Transparentspinner />}
                    <Container fluid={true} style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '35px' }}>

                        <Card>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: '30px' }}>
                                <HeaderCard title={Pricing} details={priceDetails} />
                                <Dropdown className='ms-2' isOpen={importDrpodown} toggle={toggleImport} style={{ marginTop: '15px' }}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        Change Company <i className="icofont icofont-arrow-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        {Array.isArray(company) && company.length > 0 ? (
                                            company.map((item, index) => (
                                                <DropdownItem key={index} style={toggleStyle} onClick={() => companyChangeData(item.id)}>{item.name}</DropdownItem>
                                            ))
                                        ) : <p>Loading...</p>
                                        }
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            {priceDetails?.packages?.length > 0 &&
                                <SubCard details={priceDetails} auth={authState} companyId={id} />
                            }
                        </Card>
                    </Container>
                </Fragment>
            </div>
        </div>
    );
};

export default CompSubscription;

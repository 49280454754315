import React, { Fragment } from 'react';
import { LI, UL } from '../../AbstractElements';
import { Col } from 'reactstrap';
import { InvoiceTotalNo, Subtotal } from '../../Constant';

const InvoiceTotal = (props) => {
    const invoiceValue = props.item;
  return (
    <Fragment>
    <Col >
        <div className="checkout-details d-flex justify-content-end " >
            <div className="order-box ">
                <UL>
                    <LI >
                        {Subtotal} : <span>₹ {invoiceValue.sub_total}</span>
                    </LI>
                    {invoiceValue.total_tax > 0 && (
                        invoiceValue.orderTaxDetails.map((e) => (
                            e.total_amount != 0 && (
                                <LI key={e.id}>{e.tax_name} {e.tax_percentage}% : <span>₹ {e.total_amount}</span></LI>
                            )
                        ))
                    )}
                    <LI >
                        {InvoiceTotalNo} : <span>₹ {invoiceValue.order_total}</span>
                    </LI>
                </UL>
            </div>
        </div>
    </Col>
</Fragment >
)
}

export default InvoiceTotal
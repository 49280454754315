import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import {
  BillingNameError,
  CasProvider,
  ConnectionType,
  Customer,
  EditscripHeader,
  InstallDate,
  SerialNo,
  StbModemMake,
  StbModemType,
  StbNo,
  EditsubscriptionHeader,
  SubscriptionHeader,
  Save,
} from "../../../Constant";
import { editStb, editStbRole, getCustomers, getCustomersRole, getProvider, getProviderRole, updateStb, updateStbRole, viewStb } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import {
  getClientUrlDataToken,
  postClientUrlWithToken,
} from "../../../Utils/restUtils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import moment from "moment";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
  customer: yup.mixed().required().label('Customer'),
  stbNo: yup.string().required().label('STB No'),
  serialNo: yup.string().required().label('Mac Id/Serial No'),
  stbType: yup.mixed().required().label('STB/Modem Type'),
  stbMake: yup.string().required().label('STB/Modem Make'),
  stbStatus: yup.mixed().required().label('STB/Modem Status '),
  connectionType: yup.mixed().required().label('Connection Type'),
})

const StbEdit = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema), });
  const [provider, setProvider] = useState([]);
  const [customerOption, setCustomerOption] = useState([]);
  const location = useLocation();
  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);
  const param1 = params.get("id");
  const [installDate, setInstallDate] = useState(null);
  const authState = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [spinnerTrans, setspinnerTrans] = useState(true)
  const navigate = useNavigate();


  const connectionOptions = [
    { value: "cable", label: "Cable" },
    { value: "broadband", label: "Broadband" },
    { value: "ip_tv", label: "IPTV" },
    { value: "ott", label: "OTT" },
    { value: "other", label: "Others" },

  ];
  const stbTypeOptions = [
    { value: "HD", label: "HD" },
    { value: "SD", label: "SD" },
    { value: "Other", label: "Other" },
  ];

  const handleChangeDate = date => {
    setInstallDate(date);
  };

  const getSingleCustomerData = async () => {
    let apiData = await getClientUrlDataToken(
      authState.apiUrl, authState.userRole === 1 ?
      editStb + `?stb_id=${param1}` : editStbRole + `?stb_id=${param1}`
    );
    if (apiData.status === true) {
      const getIDSetting = apiData?.data?.customer_id;
      const singleId = apiData.data;
      const provider_cas = singleId.provider_id;

      setValue("stbNo", singleId.stb_no);
      setValue("serialNo", singleId.serial_no);
      setValue("stbMake", singleId.device_make);
      const activated_date = singleId.activated_at;
      if (activated_date) {
        setInstallDate(new Date(activated_date));
      }

      setValue("stbType", { value: singleId.type, label: singleId.type });
      setValue("stbStatus", {
        value: singleId.stb_status,
        label: singleId.stb_status,
      });
      setValue("connectionType", {
        value: singleId.connection_type,
        label: singleId.connection_type,
      });

      let selectedOptions = await getClientUrlDataToken(
        authState.apiUrl,
        authState.userRole === 1 ? getCustomers : getCustomersRole
      );
      if (selectedOptions.status === true) {
        const CustomerOptions = selectedOptions.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        setCustomerOption(CustomerOptions);
        const selectedCustomer = CustomerOptions.find(
          (items) => items.value === getIDSetting
        );
        setValue("customer", {
          value: selectedCustomer?.value,
          label: selectedCustomer?.label,
        });


      } else if (selectedOptions.status >= 400 && selectedOptions.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (selectedOptions.status >= 500 && selectedOptions.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      }
      else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

      }

      let casProvider_API = await getClientUrlDataToken(
        authState.apiUrl,
        authState.userRole === 1 ? getProvider : getProviderRole
      );
      if (casProvider_API.status === true) {
        const map_cas_provider = casProvider_API.data.map((cas) => ({
          value: cas.id,
          label: cas.name,
        }));

        setProvider(map_cas_provider);

        const cas_equal_id = map_cas_provider.find(
          (cas) => cas.value === provider_cas
        );
        setValue("casProvider", {
          value: cas_equal_id?.value,
          label: cas_equal_id?.label,
        });
      } else if (casProvider_API.status >= 400 && casProvider_API.status <= 405) {
        // setspinnerTrans(false)
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (casProvider_API.status >= 500 && casProvider_API.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      }
      else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

      }

      setspinnerTrans(false)
    } else if (apiData.status >= 400 && apiData.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (apiData.status >= 500 && apiData.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }

  }

  useEffect(() => {
    getSingleCustomerData();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const postRequest = {
      stb_id: param1,
      customer_id: data.customer.value,
      stb_no: data.stbNo,
      serial_no: data.serialNo,
      type: data.stbType.value,
      device_make: data.stbMake,
      activated_at: moment(installDate).format("DD-MM-YYYY"),
      provider_id: data.casProvider.value,
      connection_type: data.connectionType.value,
    };
    const editStbResponse = await postClientUrlWithToken(
      authState.apiUrl,
      authState.userRole === 1 ? updateStb : updateStbRole,
      postRequest
    );
    if (editStbResponse.status === 200) {
      if (editStbResponse.data.status === true) {
        navigate(`${process.env.PUBLIC_URL}/customers/stb-modem`);
        toast.success(editStbResponse.data.message);
      } else {
        errors.showMessages();
        editStbResponse.data.message.map((e) => {
          toast.error(e);
        });
      }
    } else if (editStbResponse.status >= 400 && editStbResponse.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (editStbResponse.status >= 500 && editStbResponse.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setLoading(false);
  };
  return (
    <Fragment>
      {loading && <SpinnerLoader />}
      {spinnerTrans && <Transparentspinner />}


      <Breadcrumbs
        mainTitle={EditsubscriptionHeader}
        parent="Customers"
        title={EditsubscriptionHeader}
      />
      <Card>
        <CardHeader className="pb-0">
          <H3>{EditscripHeader}</H3>
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="g-3">
              <Col md="6">
                <Label className="form-label" >
                  {Customer} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      id="customer"
                      options={customerOption}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.customer?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="stbno">
                  {StbNo} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="stbno"
                  type="text"
                  {...register("stbNo", { required: true })}
                />
                <p className="text-danger">{errors.stbNo?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="serialno">
                  {SerialNo} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="serialno"
                  type="text"
                  {...register("serialNo", { required: true })}
                />
                <p className="text-danger">{errors.serialNo?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {StbModemType} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="stbType"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      id="stb_modm_type"
                      options={stbTypeOptions}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.stbType?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="stb_modam_make">
                  {StbModemMake} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="stb_modam_make"
                  type="text"
                  {...register("stbMake", { required: true })}
                />
                <p className="text-danger">{errors.stbMake?.message}</p>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-label" for="install_date">
                    {InstallDate}
                  </Label>
                  <DatePicker
                      id="install_date"
                      style={{ padding: '12px 100px' }}
                      className="form-control "
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      selected={installDate}
                      onChange={handleChangeDate}
                      showMonthDropdown
                      showYearDropdown

                      dropdownMode="select"

                      required
                    />
                  {/* <span className="text-danger">
                    {errors.installDate && BillingNameError}
                  </span> */}
                </FormGroup>
              </Col>
            </Row>
            <Row className="g-3 ">
              <Col md="6">
                <Label className="form-label">
                  {CasProvider}
                </Label>
                <Controller
                  name="casProvider"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      id="cas_provider"
                      options={provider}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />

              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {ConnectionType}
                </Label>
                <Controller
                  name="connectionType"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      id="connection_type"
                      options={connectionOptions}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.connectionType?.message}</p>
              </Col>
            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">
              {Save}
            </Btn>
          </Form>
        </CardBody>
      </Card>
    </Fragment >
  );
};
export default StbEdit;

import React, { useState } from 'react';
import { useFormik } from "formik";
import { FormGroup, Input, Label } from 'reactstrap';
import * as Yup from "yup";
import './billerqStyle.scss';
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail, IoMdCall } from "react-icons/io";
import { postDataWithoutResData } from '../Utils/restUtils';
import { SendEmail } from '../CommonUrl';
import { toast } from 'react-toastify';
import Transparentspinner from '../Components/Common/Loader/Transparentspinner';


const Contact = () => {
    const [spinnerTrans, setspinnerTrans] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: "",
            phone: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Required'),
            phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid')
        }),
        onSubmit: async(values) => {
            setspinnerTrans(true);
            const loginResponse = await postDataWithoutResData(SendEmail, values);
            if(loginResponse.status===200){
                toast.success(loginResponse.data.message);
                setspinnerTrans(false);
            } else {
                toast.error('Please try again');
            }
        },
    })
    return (
        <div className='contact-container' id='contact'>
            {spinnerTrans && <Transparentspinner />}
            <div className='connect-container'>
                <div className='contact-detals'>
                    <p className='contact-text'>Contact Us</p>
                    <h2>Enquire</h2>
                    <div className='contact-section'>
                        <FaLocationDot size={22} />
                        <p>2nd floor, Kerala Startup Mission Center, UL CyberPark, Nellikode P O, Kozhikode, Kerala 673016</p>
                    </div>
                    <div className='contact-section'>
                        <IoMdMail size={22} />
                        <p>info@billerQ.com</p>
                    </div>
                    <div className='contact-section'>
                        <IoMdCall size={22} />
                        <p>+91 70127 33393</p>
                    </div>
                </div>
                <div className='connect-details'>
                    <h2>Get in touch with us</h2>
                    <div>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <FormGroup className='form-group2'>
                                    <Label className="col-form-label">Enter you full name*</Label>
                                    <Input className="form-control"
                                        type="text"
                                        name="full_name"
                                        onChange={formik.handleChange}
                                        value={formik.values.full_name} required />
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup className='form-group2'>
                                    <Label className="col-form-label">Enter your email id*</Label>
                                    <Input className="form-control"
                                        type="email"
                                        name="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email} />
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup className='form-group2'>
                                    <Label className="col-form-label">Enter your phone no*</Label>
                                    <Input className="form-control"
                                        type="tel"
                                        name="phone"
                                        onChange={formik.handleChange}
                                        value={formik.values.phone} required />
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup className='form-group2'>
                                    <Label className="col-form-label">Enter your message*</Label>
                                    <Input className="form-control"
                                        type="text"
                                        name="message"
                                        onChange={formik.handleChange}
                                        value={formik.values.message} required />
                                </FormGroup>
                            </div>
                            <button className='button-submit' type="submit">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../Constant";
import AddonForm from "./AddonsForm";

const AddonsAdd = () => {
    return (
        <Fragment>
            <AddonForm mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default AddonsAdd;
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompanyDetails, getCompanyProfile } from '../../../../CommonUrl';
import { getClientUrlDataToken } from '../../../../Utils/restUtils';
import InvoicePayment from './InvoicePayment';
import SubTabs from './Subscription';
import Transparentspinner from '../../../Common/Loader/Transparentspinner';
import CompanyProfile from './CompanyProfile';
import AboutCompany from './AboutCompany';
import { useSelector } from 'react-redux';


const CompanyProfileContain = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const authState = useSelector((state) => state.auth);
    const [widgetDetails, setWidgetDetails] = useState({});
    const [personalDetails, setPersonalDetails] = useState({});

    useEffect(() => {
        masterWidgetData();
        masterUserData();
    }, [])

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
      };

    const masterWidgetData = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, getCompanyDetails+`?company_id=${param1}`);
        if (response.status === true) {
            setWidgetDetails(response.data);
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    }

    const masterUserData = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, getCompanyProfile+`?company_id=${param1}`);
        if (response.status === true) {
            setPersonalDetails(response.data);
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    }
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle="Company Profile" parent="Users" title="Company Profile" />
            <Container fluid={true}>
                <div className="user-profile">
                    <Row>
                        {Object.keys(widgetDetails).length > 0 && (
                            <CompanyProfile details={widgetDetails} personalData={personalDetails}/>
                        )}
                        <Col xl="3" md="5" className="xl-35 box-col-30">
                            <div className="default-according style-1 faq-accordion job-accordion">
                                <Row>
                                    <Col xl='12'>
                                        {Object.keys(personalDetails).length > 0 && (
                                            <AboutCompany details={personalDetails} />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xl="9" md="7" className="xl-65 box-col-70">
                            <Row>
                                <SubTabs param={param1} />
                            </Row>
                        </Col>
                        <InvoicePayment param={param1} />
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default CompanyProfileContain;
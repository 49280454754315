import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText,
} from 'reactstrap';
import Contact from './Contact';
import Footer from './Footer';
import { Link, useLocation } from 'react-router-dom';
import './Disclaimer.scss';
import { appUrl } from '../CommonUrl';

const CancelAndRefund = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const loginEncrypted = localStorage.getItem("login");
    let login = null;
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    if (loginEncrypted) {
        try {
            const bytes = CryptoJS.AES.decrypt(loginEncrypted, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
                login = JSON.parse(decryptedData);
            }
        } catch (e) {
            console.error('Error decrypting or parsing login data', e);
        }
    }

    const isLoggedIn = login && login.userToken;

    return (
        <div className='section1-containers'>
            <div style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
                <Navbar collapseonselect='true' expand="lg" className="bg-body-tertiary" container>
                    <NavbarBrand href="/"><img src={require('../assets/images/logoBillerq.png')} alt='' className="logo me-lg-5" /></NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="me-auto  align-items-lg-center" navbar>
                            <NavItem>
                                <NavLink href={appUrl} className='nav-link-bar'>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#aboutUs"} className='nav-link-bar'>About Us</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#feature"} className='nav-link-bar'>Features</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#usecase"} className='nav-link-bar'>Use Case</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#contact"} className='nav-link-bar'>Contact Us</NavLink>
                            </NavItem>
                            <NavbarText>
                                <Link className='ms-lg-5' to={isLoggedIn ? `${process.env.PUBLIC_URL}/dashboard/default` : '/SignIn'}>
                                    <div className='login-button'>{isLoggedIn ? 'Dashboard' : 'Get Started'}</div>
                                </Link>
                            </NavbarText>
                        </Nav>
                    </Collapse>
                    {/* </div> */}
                </Navbar>
            </div>
            <div style={{ width: '100%' }}>
                <div className='heading-main' >
                    <h1 className='disclaimer-heading'>Cancellation and Refund Policy</h1>
                </div>
            </div>


            <div className='container'>
                <div className='content-section'>

                    <p className='disclaimer-text'>
                        Welcome to BillerQ! We are committed to providing you with a seamless and efficient subscriber and subscription management experience. Please read our Cancellation and Refund Policy carefully.
                    </p>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>I. Free Signup and Trial Period</h4>
                        <p className='disclaimer-text'>
                            Signup: Signing up for BillerQ is completely free of cost.
                        </p>
                        <p className='disclaimer-text'>
                            Trial Period: We offer a 14-days free trial period for all new users. During this period, you can explore and utilize all features of BillerQ without any charge.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>II. Subscription and Billing</h4>
                        <p className='disclaimer-text'>
                            Initial Setup Cost: A one-time initial setup cost is required to configure and personalize BillerQ for your needs. This setup cost is non-refundable once the setup process has been initiated.
                        </p>
                        <p className='disclaimer-text'>
                            Monthly Postpaid Billing: After the trial period, you will be billed on a monthly postpaid basis. Invoices will be generated at the end of each billing cycle.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>III. Cancellation Policy</h4>
                        <p className='disclaimer-text'>
                            Trial Period:  You can cancel your account at any time during the trial period without incurring any charges.
                        </p>
                        <p className='disclaimer-text'>
                            Post-Trial Period: If you wish to cancel your subscription after the trial period, you can do so by contacting our support team at [info@billerq.com]. Cancellation requests must be submitted before the end of the current billing cycle to avoid being charged for the next month.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>IV. Refund Policy</h4>
                        <p className='disclaimer-text'>
                            Initial Setup Cost:The initial setup cost is non-refundable once the setup process has begun.
                        </p>
                        <p className='disclaimer-text'>
                            Monthly Postpaid Charges: We do not offer refunds for monthly postpaid charges already incurred. However, if you believe there has been an error in billing, please contact our support team immediately, and we will review your case.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>How to Cancel Your Subscription</h4>
                        <p className='disclaimer-text'>
                            To cancel your subscription, please follow these steps:
                        </p>
                        <p className='disclaimer-text'>
                            1. Contact our support team at <span style={{ fontWeight: '600' }}>info@billerq.com</span> with your cancellation request.<br />
                            2. Provide your account details and the reason for cancellation.<br />
                            3. Our support team will process your request and confirm the cancellation.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>Contact Us</h4>
                        <p className='disclaimer-text'>
                            If you have any questions or need assistance with cancellation and refunds, please contact us at <span style={{ fontWeight: '600' }}>info@billerq.com</span>.
                        </p>
                        <p className='disclaimer-text'>
                            Thank you for choosing BillerQ. We value your business and are here to help you with any questions or concerns.
                        </p>
                    </div>
                </div>
            </div>
            <div className='gap'></div>
            <Contact />
            <Footer />
        </div>
    );
}

export default CancelAndRefund;


import { createContext, useContext, useState } from 'react';

const TypeaheadContext = createContext();

export const TypeaheadProvider = ({ children }) => {
    const [selectedAccountValues, setSelectedAccountValues] = useState([]);
    const [selectedCurrencyValues, setSelectedCurrencyValues] = useState([]);
    const [selectedTaxValues, setSelectedTaxValues] = useState([]);
    const [selectedLanguageValues, setSelectedLanguageValues] = useState([]);
  
    const updateSelectedValues = (values, type) => {
      switch (type) {
        case 'account':
          setSelectedAccountValues(values);
          break;
        case 'currency':
          setSelectedCurrencyValues(values);
          break;
        case 'taxrate':
          setSelectedTaxValues(values);
          break;
        case 'language':
          setSelectedLanguageValues(values);
          break;
        default:
          break;
      }
    };
  
    const contextValue = {
      selectedAccountValues,
      selectedCurrencyValues,
      selectedTaxValues,
      selectedLanguageValues,
      updateSelectedValues,
    };
  return (  
    <TypeaheadContext.Provider value={ contextValue }>
      {children}
    </TypeaheadContext.Provider>
  );
};

export const useTypeaheadContext = () => {
  return useContext(TypeaheadContext);
};

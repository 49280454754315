import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../AbstractElements";
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AreaNameError, Header, EditHeader, Description, Title, Save } from "../../Constant";
import SpinnerLoader from "../../Layout/SpinnerLoader";
import { editArea, updateHeaders, updateHeadersRole, editHeaders } from "../../CommonUrl";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../Utils/restUtils";
import { useSelector } from "react-redux";
import Transparentspinner from "../Common/Loader/Transparentspinner";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    title: yup.string().required().label('Title'),
})

const HeaderEdit = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const location = useLocation();
    const authState = useSelector((state) => state.auth);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getHeaderData();
    }, []);

    const getHeaderData = async () => {
        setLoading(false);
        let data = await getClientUrlDataToken(authState.apiUrl, editHeaders + `?header_id=${param1}`);
        if (data.status === true) {
            setValue('title', data.data.title);
            setValue('description', data.data.description);
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {

            header_id: param1,
            title: data.title,
            description: data?.description ?? ''
        };
        const editHeaderResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? updateHeaders : updateHeadersRole, postRequest);
        if (editHeaderResponse.data.status === true) {
            if (editHeaderResponse.status === 200) {
                toast.success('Success...!')
                navigate(`${process.env.PUBLIC_URL}/Expenses/Header`);
                setLoading(false);
            } else {
                toast.error(editHeaderResponse.data.message)
                setLoading(false);
            }
        } else if (editHeaderResponse.status >= 400 && editHeaderResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (editHeaderResponse.status >= 500 && editHeaderResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}
            <Breadcrumbs mainTitle={Header} parent="Settings" title={Header} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditHeader}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Title} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('title', { required: true })} />
                                <p className="text-danger">{errors.title?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Description}</Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('description')} style={{ width: '100%' }} />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default HeaderEdit;

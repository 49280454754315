import React from 'react';
import { H4 } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';

export const OnlinePaymentColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '55px',
        wrap: true
    },
    {
        name: <H4>Subscriber Id</H4>,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                {row.SubId}
            </Link>,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Invoice No.</H4>,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.id}&customer_id=${row.id}`}>
                {row.InvoiceNo}
            </Link>,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Address</H4>,
        selector: row => row.Address,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Area</H4>,
        selector: row => row.Area,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Account</H4>,
        selector: row => row.Account,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Amount Paid</H4>,
        selector: row => row.AmountPaid,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>Paid Date</H4>,
        selector: row => row.PaidDate,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Paid By</H4>,
        selector: row => row.PaidBy,
        sortable: true,
        center: false,
        wrap: true,
    },

];

export const PaymentCollectionColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '55px'
    },

    {
        name: <H4>Subscriber Id</H4>,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                {row.Sub}
            </Link>,

        sortable: true,
        width: '105px',

    },
    {
        name: <H4>Invoice No.</H4>,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.id}&customer_id=${row.customer_id}`}>
                {row.InvoiceNo}
            </Link>,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Txn Id</H4>,
        selector: row => row.txnDate,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Address</H4>,
        selector: row => row.Address,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Area</H4>,
        selector: row => row.Area,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Amount Collected</H4>,
        selector: row => row.AmountCollected,
        sortable: true,
        center: false,
        wrap: true

    },
    {
        name: <H4>Paid Date</H4>,
        selector: row => row.PaidDate,
        sortable: true,
        center: false,
        wrap: true
    },

    {
        name: <H4>Collected By</H4>,
        selector: row => row.CollectedBy,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Account</H4>,
        selector: row => row.Account,
        sortable: true,
        center: false,
        wrap: true,
    },

];
export const PaymentDueColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '55px'
    },
    {
        name: <H4>Subscriber Id</H4>,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                {row.SubId}
            </Link>,
        // row.SubId,
        sortable: true,
        center: true,
        width: '160px'
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: false,
        cell: row => (
            <span
                className={`badge badge-${row.Status}`}>
                {row.Status.charAt(0).toUpperCase() + row.Status.slice(1)}
            </span>
        ),
    },

    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: false,
        wrap: true,
    },
    // {
    //     name: <H4>Route no.</H4>,
    //     selector: row => row.Address,
    //     sortable: true,
    //     center: false,
    //     wrap: true,
    // },
    {
        name: <H4>Area</H4>,
        selector: row => row.Area,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Balance Due</H4>,
        selector: row => row.Balance,
        sortable: true,
        center: false,
        width: '120px'
    },
    {
        name: <H4>No.of Invoices</H4>,
        selector: row => row.InvoiceNo,
        sortable: true,
        center: true,
    },

];

export const PackageSummaryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '50px'
    },
    {
        name: <H4>Packages</H4>,
        selector: row => row.Packages,
        sortable: true,
        center: false,
        wrap: true,
    },

    {
        name: <H4>Unit Price</H4>,
        selector: row => row.unitPrice,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Unit Tax</H4>,
        selector: row => row.unitTax,
        sortable: true,
        center: false,
        wrap: true,

    },
    {
        name: <H4>Total Price</H4>,
        selector: row => row.totalPrice,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Total Tax</H4>,
        selector: row => row.totalTax,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Grand Total</H4>,
        selector: row => row.grandTotal,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Number of invoices</H4>,
        selector: row => row.invoiceNo,
        sortable: true,
        center: true,
    },
];

export const WalletBalenceColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '150px'
    },
    {
        name: <H4>Subscriber Id</H4>,
        selector: row => <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
            {row.SubId}
        </Link>,
        // row.SubId,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Txn Id</H4>,
        selector: row => row.TxnId,
        sortable: true,
        center: false,
        wrap: true
    },

    {
        name: <H4>Date</H4>,
        selector: row => row.Date,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Amount</H4>,
        selector: row => row.Amount,
        sortable: true,
        center: false,
    },

    {
        name: <H4>Customer</H4>,
        selector: row => row.Customer,
        sortable: true,
        center: false,
        wrap: true
    },
];
export const GstColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        wrap: true,
    },
    {
        name: <H4>Invoice Date</H4>,
        selector: row => row.invoiceRate,
        sortable: true,
        center: false,
        width: "130px",
        wrap: true,
    },
    {
        name: <H4>Invoice No.</H4>,
        selector: row => row.invoiceNo,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Subscriber Id</H4>,
        selector: row => row.SubId,
        sortable: true,
        center: false,
    },

    {
        name: <H4>Customer</H4>,
        selector: row => row.Customer,
        sortable: true,
        center: false,
        wrap: true
    },

    {
        name: <H4>Address</H4>,
        selector: row => row.Address,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>SAC</H4>,
        selector: row => row.Sac,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Rate</H4>,
        selector: row => row.rate,
        sortable: true,
        center: false,
    },
    {
        name: <H4>DISC</H4>,
        selector: row => row.Disc,
        sortable: true,
        center: false,
    },
    {
        name: <H4>CGST</H4>,
        selector: row => row.Cgst,
        sortable: true,
        center: false,
    },
    {
        name: <H4>SGST</H4>,
        selector: row => row.Sgst,
        sortable: true,
        center: false,
    },
    {
        name: <H4>IGST</H4>,
        selector: row => row.Igst,
        sortable: true,
        center: false,
    },
    {
        name: <H4>CESS</H4>,
        selector: row => row.Cess,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Total</H4>,
        selector: row => row.Total,
        sortable: true,
        center: false,
    }, {
        name: <H4>GST In</H4>,
        selector: row => row.GstIn,
        sortable: true,
        center: false,
    },
];

export const SubscriptionSummaryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '50px'
    },
    {
        name: <H4>Subscriber Id</H4>,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                {row.SubId}
            </Link>,
        // row.SubId,
        sortable: true,
        center: true,
        width: '160px'
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: false,
        width: '80px',
        cell: row => (
            <span
                className={`badge badge-${row.Status}`}
            // style={{
            //     fontSize: '13px',
            //     fontWeight: '500',
            //     padding:'3px',
            //     borderRadius:'6px',
            // }}
            >
                {row.Status ? row.Status.charAt(0).toUpperCase() + row.Status.slice(1) : ''}

            </span>
        ),

    },
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: false,
        wrap: true,
        width: '120px',
    },
    {
        name: <H4>Phone No.</H4>,
        selector: row => row.Phone,
        sortable: true,
        center: false,
        wrap: true,
        width: '140px',
    },
    {
        name: <H4>Area</H4>,
        selector: row => row.Area,
        sortable: true,
        center: false,
        width: '120px',
        wrap: true,
    },

    {
        name: <H4>Current Package</H4>,
        selector: row => row.CurrentPackage,
        sortable: true,
        center: false,
        wrap: true,
        width: '140px',
    },

    {
        name: <H4>Active Date</H4>,
        selector: row => row.ActiveDate,
        sortable: true,
        center: false,
        wrap: true,
        width: '150px',
    },
    {
        name: <H4>Expire Date</H4>,
        selector: row => row.ExpireDate,
        sortable: true,
        center: false,
        wrap: true,
        width: '140px',
    },
    {
        name: <H4>Days Left</H4>,
        selector: row => row.DaysLeft,
        sortable: true,
        center: true,
        width: '65px',
    },


];

export const AddonSummaryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '70px',
    },
    {
        name: <H4>Packages</H4>,
        selector: row => row.Packages,
        sortable: true,
        center: false,
    },

    {
        name: <H4>Unit Price</H4>,
        selector: row => row.unitPrice,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Unit Tax</H4>,
        selector: row => row.unitTax,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Total Price</H4>,
        selector: row => row.totalPrice,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Total Tax</H4>,
        selector: row => row.totalTax,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Grand Total</H4>,
        selector: row => row.grandTotal,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Number of Invoices</H4>,
        selector: row => row.invoiceNo,
        sortable: true,
        // center: true,
    },
];



export const ExpenseSummaryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Category</H4>,
        selector: row => row.Category,
        sortable: true,
        center: false,
    },

    {
        name: <H4>January</H4>,
        selector: row => row.January,
        sortable: true,
        center: false,
    },
    {
        name: <H4>February</H4>,
        selector: row => row.February,
        sortable: true,
        center: false,
    },
    {
        name: <H4>March</H4>,
        selector: row => row.March,
        sortable: true,
        center: false,
    },
    {
        name: <H4>April</H4>,
        selector: row => row.April,
        sortable: true,
        center: false,
    },
    {
        name: <H4>May</H4>,
        selector: row => row.May,
        sortable: true,
        center: false,
    },
    {
        name: <H4>June</H4>,
        selector: row => row.June,
        sortable: true,
        center: false,
    },
    {
        name: <H4>July</H4>,
        selector: row => row.July,
        sortable: true,
        center: false,
    },
    {
        name: <H4>August</H4>,
        selector: row => row.August,
        sortable: true,
        center: false,
    },
    {
        name: <H4>September</H4>,
        selector: row => row.September,
        sortable: true,
        center: false,
    },
    {
        name: <H4>October</H4>,
        selector: row => row.October,
        sortable: true,
        center: false,
    },
    {
        name: <H4>November</H4>,
        selector: row => row.November,
        sortable: true,
        center: false,
    },
    {
        name: <H4>December</H4>,
        selector: row => row.December,
        sortable: true,
        center: false,
    },
];

export const IncomeVsExpenseColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Category</H4>,
        selector: row => row.Category,
        sortable: true,
        center: false,
    },

    {
        name: <H4>January</H4>,
        selector: row => row.January,
        sortable: true,
        center: false,
    },
    {
        name: <H4>February</H4>,
        selector: row => row.February,
        sortable: true,
        center: false,
    },
    {
        name: <H4>March</H4>,
        selector: row => row.March,
        sortable: true,
        center: false,
    },
    {
        name: <H4>April</H4>,
        selector: row => row.April,
        sortable: true,
        center: false,
    },
    {
        name: <H4>May</H4>,
        selector: row => row.May,
        sortable: true,
        center: false,
    },
    {
        name: <H4>June</H4>,
        selector: row => row.June,
        sortable: true,
        center: false,
    },
    {
        name: <H4>July</H4>,
        selector: row => row.July,
        sortable: true,
        center: false,
    },
    {
        name: <H4>August</H4>,
        selector: row => row.August,
        sortable: true,
        center: false,
    },
    {
        name: <H4>September</H4>,
        selector: row => row.September,
        sortable: true,
        center: false,
    },
    {
        name: <H4>October</H4>,
        selector: row => row.October,
        sortable: true,
        center: false,
    },
    {
        name: <H4>November</H4>,
        selector: row => row.November,
        sortable: true,
        center: false,
    },
    {
        name: <H4>December</H4>,
        selector: row => row.December,
        sortable: true,
        center: false,
    },
];

export const CustomerPaymentColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '55px'
    },

    {
        name: <H4>Subscriber Id</H4>,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                {row.Sub}
            </Link>,

        sortable: true,
        width: '125px',

    },

    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: false,
        wrap: true
    },
    {
        name: <H4>Paid On</H4>,
        selector: row => row.Paidat,
        sortable: true,
        // center: true,
        wrap: true
    },
    {
        name: <H4>Txn Id</H4>,
        selector: row => row.txnId,
        sortable: true,
        // center: true,
        wrap: true


    },
    {
        name: <H4>Amount</H4>,
        selector: row => row.Amount,
        sortable: true,
        // center: true,
        wrap: true,
        width: '100px'
    },
    {
        name: <H4>Reflected Invoice</H4>,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${propsId}`}>
                {row.RefInvoice}
            </Link>,
        // selector: row => row.RefInvoice,
        sortable: true,
        center: true,
        width: '145px'
    },
    {
        name: <H4>Payment Type</H4>,
        selector: row => row.payType,
        sortable: true,
        center: true,
    },
];

export const UnpaidCustomerColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '75px'
    },

    {
        name: <H4>Subscriber Id</H4>,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                {row.Sub}
            </Link>,
        sortable: true,
        width: '145px',

    },

    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: false,
        wrap: true,
        width: '170px',
    },
    {
        name: <H4>Address</H4>,
        selector: row => row.Address,
        sortable: true,
        // center: true,
        wrap: true,
        width: '250px',
    },
    {
        name: <H4>Area</H4>,
        selector: row => row.Area,
        sortable: true,
        wrap: true,
        width: '140px',

    },
    {
        name: <H4>Dues</H4>,
        selector: row => row.Dues,
        sortable: true,
        wrap: true,
        width: '140px',
    },
    {
        name: <H4>Last Payment Date</H4>,
        selector: row => row.paymentDate,
        sortable: true,
        center: true,
        width: '145px'
    },
];






import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { Area, AreaAdd, AreaCode, AreaCodeError, AreaName, AreaNameError, Save, SubmitForm } from "../../../Constant";
import { addArea, addAreaRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const AreaForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { name: data.areaname, area_code: data.areacode }
        const addAreaResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addArea : addAreaRole, postRequest);
        if (addAreaResponse.status === 200) {
            if (addAreaResponse.data.status === true) {
                toast.success(addAreaResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/settings/area`);
            } else {
                toast.error(addAreaResponse.data.message)
                setLoading(false);
            }
        } else if (addAreaResponse.status >= 400 && addAreaResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addAreaResponse.status >= 500 && addAreaResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={Area} parent="Settings" title={Area} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AreaAdd}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="area-name">{AreaName}<span className="requireStar">*</span></Label>
                                <input className="form-control" id="area-name" type="text" {...register('areaname', { required: true })} />
                                <span className="text-danger">{errors.areaname && AreaNameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="area_code">{AreaCode} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="area_code" type="text" {...register('areacode', { required: true })} />
                                <span className="text-danger">{errors.areacode && AreaCodeError}</span>
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default AreaForm;
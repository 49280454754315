import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { EditItem, Description, ItemName, Item, PurchasePrice, Save, Tax, hsn_no, sku, SalesPrice, Quantity, Category, Image } from "../../../Constant";
import { viewItem, getCategoryRole, getcategoryitems, editItem, statusTax, viewItemRole, getcategoryitemsRole, statusTaxRole, editItemRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../../CommonElements/TypeAhead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.string().required().label('Item Name'),
    sku: yup.string().required().label('Sku'),
    sales_price: yup.string().required().label('Sales Price'),
    purchase_price: yup.string().required().label('Purchase Price'),
    quantity: yup.string().required().label('Quantity'),
    tax: yup.mixed().required().label('Tax'),
    category: yup.mixed().required().label('Category'),
})

const ItemEdit = () => {

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    })
    const location = useLocation();
    const authState = useSelector((state) => state.auth);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const [loading, setLoading] = useState(false);
    const [categoryOption, setCategoryOption] = useState([]);
    const [taxOption, setTaxOption] = useState([]);
    const [image, setImage] = useState(null);
    const [apiImage, setapiImage] = useState(null)

    const navigate = useNavigate();
    const form = new FormData()

    useEffect(() => {
        categoryData();
    }, []);

    const categoryData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewItem + `?item_id=${param1}` : viewItemRole + `?item_id=${param1}`);
        if (data.status === true) {
            const single_Api = data.data[0];
            let dataCategory = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getcategoryitems : getcategoryitemsRole);
            if (dataCategory.status === true) {
                let formattedNumberString = single_Api.sale_price.replace(/,/g, '');
                let formattedPurchase = single_Api.purchase_price.replace(/,/g, '');
                setValue('id', single_Api.id);
                setValue('hsn_no', single_Api.hsn_no);
                setValue('name', single_Api.name);
                setValue('sku', single_Api.sku);
                setValue('sales_price', formattedNumberString);
                setValue('quantity', single_Api.stock);
                setValue('purchase_price', formattedPurchase);
                setValue('tax_group_id', single_Api.tax_group_id);
                setValue('duration', single_Api.duration)
                setapiImage(single_Api.item_image || null);
                const optionsList = dataCategory.data.map((data) => ({ value: data.id, label: data.name }));
                setCategoryOption(optionsList);

                let taxData = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? statusTax : statusTaxRole);
                if (taxData.status === true) {
                    const optionList = taxData.data.map((data) => ({ value: data.id, label: data.title }))
                    setTaxOption(optionList);
                    const same_id = optionList.find((set_id) => set_id.value === single_Api.tax_group_id);
                    setValue("tax", { value: same_id?.value, label: same_id?.label })

                } else if (taxData.status >= 400 && taxData.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (taxData.status >= 500 && taxData.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                }
                else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                }

                const equal_id = optionsList.find((set_id) => set_id.value === single_Api.category_id);
                setValue("category", { value: equal_id?.value, label: equal_id?.label })
            } else if (dataCategory.status >= 400 && dataCategory.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (dataCategory.status >= 500 && dataCategory.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    const handleLogoChange = (event) => {
        const selectedFile = event.target.files[0];
        setImage(selectedFile);
    }


    useEffect(() => {
        const inputElement = document.querySelectorAll('input[type="number"]');

        const preventScrollEvent = (event) => {
            event.preventDefault();
        };

        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

            return () => {
                inputElement.removeEventListener('wheel', preventScrollEvent);
            };
        });
    }, []);


    const onSubmit = async (data) => {
        setLoading(true);
        form.append("item_id", param1);
        form.append("hsn_no", data.hsn_no);
        form.append("name", data.name);
        form.append("sku", data.sku);
        form.append("sale_price", data.sales_price);
        form.append("quantity", data.quantity);
        form.append("purchase_price", data.purchase_price);
        form.append("description", data.description);
        form.append("tax_group_id", data.tax.value);
        form.append("category_id", data.category.value);
        form.append("image", data.image[0]);
        const editPackageResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? editItem : editItemRole, form, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        if (editPackageResponse.status === 200) {
            if (editPackageResponse.data.status === true) {
                toast.success(editPackageResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/Services/item`);
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(editPackageResponse.data.message)
            }
        } else if (editPackageResponse.status >= 400 && editPackageResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (editPackageResponse.status >= 500 && editPackageResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }

        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={Item} parent="Settings" title={Item} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditItem}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{ItemName} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="hsn_no">{hsn_no}</Label>
                                <input className="form-control" id="hsn_no" type="number" min="0"{...register('hsn_no')} />

                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="sku">{sku} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="sku" type="text" {...register('sku', { required: true })} />
                                <p className="text-danger">{errors.sku?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="sales_price">{SalesPrice} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="sales_price" type="number" min="0" {...register('sales_price', { required: true })} />
                                <p className="text-danger">{errors.sales_price?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="purchase_price">{PurchasePrice} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="purchase_price" type="number" min="0" {...register('purchase_price', { required: true })} />
                                <p className="text-danger">{errors.purchase_price?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="qty">{Quantity} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="qty" type="number" min="0" {...register('quantity', { required: true })} />
                                <p className="text-danger">{errors.quantity?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" >{Tax}  <span className="requireStar">*</span></Label>
                                <Controller
                                    name="tax"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={taxOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.tax?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{Category} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={categoryOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.category?.message}</p>
                            </Col>

                        </Row>
                        <Row className="g-3 pt-3">

                            <Col md="6">
                                <Label className="form-label">{Image}</Label>
                                <Controller
                                    name="image"
                                    control={control}
                                    render={({ field }) => (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Col md="8">
                                                <input
                                                    {...register("image")}
                                                    className="form-control fileInputStyle"
                                                    type="file"
                                                    onChange={handleLogoChange}
                                                    accept=".png, .jpg, .jpeg"
                                                />
                                            </Col>
                                            <Col md='4'>
                                                <span className="imagestyle">
                                                    {image == null ?
                                                        apiImage && <img src={apiImage} alt="image" width="40px" height="40px" />
                                                        :
                                                        <img src={URL.createObjectURL(image)} alt="image" width="40px" height="40px" />
                                                    }
                                                </span>
                                            </Col>
                                        </div>
                                    )}
                                />
                                <span className="text-danger">{errors.logo && "logo is required"}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">

                        </Row>
                        <Row>
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3"
                                            {...register('description')}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default ItemEdit;
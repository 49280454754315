import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, FormGroup, Input, Label } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import { getData, postDataWithoutResData } from '../../Utils/restUtils';
import 'react-phone-input-2/lib/style.css';
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';
import { Country, State } from "country-state-city";
import Select from "react-select";
import Typeahead from '../../CommonElements/TypeAhead';
import ReCAPTCHA from "react-google-recaptcha";

const personalRegistration = ({ onNext, setForm, form }) => {
    const [loading, setLoading] = useState(false);
    const url = "get-industry";
    const [transpSpinner, setTranspSpinner] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleConPassword, setToggleConPassword] = useState(false);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState('');
    const [error, setError] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);

    const navigate = useNavigate();
    useEffect(() => {
        getServiceDetails();
    }, []);

    const getServiceDetails = async () => {
        const service = await getData(url);
        const serviceOptions = service.data.map((e) => ({
            value: e.id,
            label: e.name,
        }));
        setServiceDetails(serviceOptions);
    };

    const formik = useFormik({
        initialValues: {
            phone: '',
            first_name: '',
            last_name: '',
            email: '',
            state: '',
            zipcode: '',
            country: '',
            password: '',
            confirm_pass: '',
        },
        validationSchema: Yup.object().shape({
            phone: Yup.string().required('Phone number is required').matches(/^[0-9]+$/, 'Must be only digits').min(5, 'Must be between 5 to 15 digits').max(15, 'Must be between 5 to 15 digits'),
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            email: Yup.string().email('Invalid email').required('Email is required'),
            state: Yup.string().required('State is required'),
            zipcode: Yup.string().matches(/^\d{6}$/, 'Invalid zip code').required('Zip code is required'),
            country: Yup.string().required('Country is required'),
            password: Yup.string()
                .min(8, 'Password must have at least 8 characters')
                .max(20, 'Too Long!')
                .required('Password is required'),
            confirm_pass: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords does not match').required('Confirm your password'),
        }),
        async onSubmit(values) {
            if (!captchaValue) {
                toast.error("Please complete the CAPTCHA");
                return;
            }
            setTranspSpinner(true);

            if (selectedItemId === '') {
                setError(true);
                setTranspSpinner(false);
                formik.setSubmitting(false);
                return;
            }

            const response = await postDataWithoutResData('register', {
                first_name: formik.values.first_name,
                last_name: formik.values.last_name,
                email: formik.values.email,
                mobile: formik.values.phone, // this will now be without the country code
                password: formik.values.password,
                confirm_password: formik.values.confirm_pass,
                state: formik.values.state,
                zip_code: formik.values.zipcode,
                country: formik.values.country,
                country_code: formik.values.country_code,
                industry_type_id: selectedItemId,
            });

            if (response && response.data.status == true) {
                const updatedForm = {
                    ...form,
                    first_name: formik.values.first_name,
                    last_name: formik.values.last_name,
                    email: formik.values.email,
                    phone: formik.values.phone,
                    password: formik.values.password,
                    confirm_pass: formik.values.confirm_pass,
                    state: formik.values.state,
                    zipcode: formik.values.zipcode,
                    country: formik.values.country,
                    country_code: formik.values.country_code,
                };
                setForm(updatedForm);
                onNext(formik.isValid, { updatedForm });
                handleFormSubmit(values, form.selectedItemId);
                toast.success(response.data.message);
                navigate('/emailOtp', { state: { form: updatedForm, selectedItemId: selectedItemId } });
                setTranspSpinner(false);
            } else {
                toast.error(response.data.message);
                setTranspSpinner(false);
            }
        },
    });

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    return (
        <div>
            {transpSpinner && <Transparentspinner />}
            <div className='form-section'>
                <p className='step-text'>STEP 1 of 2</p>
                <form onSubmit={async (e) => {
                    e.preventDefault();
                    setError(selectedItemId === '');
                    await formik.validateForm();
                    formik.handleSubmit();
                }}>
                    <div>
                        <p className='select-industry'>Select the industry which you belong to<span className="requireStar">*</span></p>
                    </div>
                    <Typeahead
                        options={serviceDetails}
                        onChange={(selected) => {
                            if (selected) {
                                formik.setFieldValue('industry', selected);
                                setSelectedItemId(selected.value);
                                setError(false);
                            }
                        }}
                    />
                    {error && <p className="text-danger">Please select an industry</p>}
                    <FormGroup className='form-group2'>
                        <Label htmlFor='first_name' className="col-form-label">First Name <span className="requireStar">*</span></Label>
                        <Input className="form-control"
                            type="text"
                            id="first_name"
                            name="first_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.first_name} />
                        <span className="text-danger">{formik.touched.first_name && formik.errors.first_name}</span>
                    </FormGroup>
                    <FormGroup className='form-group2'>
                        <Label htmlFor='last_name' className="col-form-label">Last Name <span className="requireStar">*</span></Label>
                        <Input className="form-control"
                            type="text"
                            id="last_name"
                            name="last_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.last_name} />
                        <span className="text-danger">{formik.touched.last_name && formik.errors.last_name}</span>
                    </FormGroup>
                    <FormGroup className='form-group2'>
                        <Label htmlFor='email' className="col-form-label">Email <span className="requireStar">*</span></Label>
                        <Input className="form-control"
                            type="text"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email} />
                        <span className="text-danger">{formik.touched.email && formik.errors.email}</span>
                    </FormGroup>
                    <FormGroup className='form-group2'>
                        <Label htmlFor='country' className="col-form-label">Country <span className="requireStar">*</span></Label>
                        <Select
                            options={Country.getAllCountries()}
                            getOptionLabel={(options) => options["name"]}
                            getOptionValue={(options) => options["name"]}
                            value={selectedCountry}
                            onChange={(item) => {
                                setSelectedCountry(item);
                                formik.setFieldValue('country', item.name);
                                formik.setFieldTouched('country', true);
                                // Clear country error if it exists
                                formik.setFieldError('country', undefined);
                            }}
                            onBlur={() => formik.setFieldTouched('country', true)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                }),
                                menu: (baseStyles) => ({
                                    ...baseStyles,
                                    zIndex: 1000,
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: 'rgba(83, 70, 134, 0.474)',
                                    primary: '#534686',
                                    primary50: 'rgba(83, 70, 134, 0.3)'
                                },
                            })}
                        />
                        {formik.touched.country && formik.errors.country && (
                            <span className="text-danger">{formik.errors.country}</span>
                        )}
                    </FormGroup>

                    <FormGroup className='form-group2'>
                        <Label htmlFor='state' className="col-form-label">State <span className="requireStar">*</span></Label>
                        <Select
                            options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                            getOptionLabel={(options) => options["name"]}
                            getOptionValue={(options) => options["name"]}
                            value={selectedState}
                            onChange={(item) => {
                                setSelectedState(item);
                                formik.setFieldValue('state', item.name);
                                formik.setFieldTouched('state', true);
                                // Clear state error if it exists
                                formik.setFieldError('state', undefined);
                            }}
                            onBlur={() => formik.setFieldTouched('state', true)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                }),
                                menu: (baseStyles) => ({
                                    ...baseStyles,
                                    zIndex: 1000,
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: 'rgba(83, 70, 134, 0.474)',
                                    primary: '#534686',
                                    primary50: 'rgba(83, 70, 134, 0.3)'
                                },
                            })}
                        />
                        {formik.touched.state && formik.errors.state && (
                            <span className="text-danger">{formik.errors.state}</span>
                        )}
                    </FormGroup>
                    <FormGroup className='form-group2'>
                        <Label htmlFor='zipcode' className="col-form-label">Zip code <span className="requireStar">*</span></Label>
                        <Input className="form-control"
                            type="text"
                            id="zipcode"
                            name="zipcode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.zipcode} />
                        <span className="text-danger">{formik.touched.zipcode && formik.errors.zipcode}</span>
                    </FormGroup>
                    <FormGroup className='form-group2'>
                        <Label className="col-form-label">Phone No. <span className="requireStar">*</span></Label>
                        <PhoneInput
                            country="in"
                            enableSearch={true}
                            onChange={(value, country) => {
                                const countryCode = country?.dialCode || '';
                                const phoneNumber = value.startsWith(countryCode) ? value.slice(countryCode.length) : value;
                                formik.setFieldValue('country_code', countryCode);
                                formik.setFieldValue('phone', phoneNumber);
                            }}
                            inputStyle={{
                                width: '100%',
                                height: '46px',
                                padding: '10px',
                                paddingLeft: '50px',
                                fontSize: '16px',
                                borderRadius: '8px',
                                borderColor: 'rgba(83, 70, 134, 0.1019607843)'
                            }}
                            searchClass="none"
                        />
                        {formik.touched.phone && formik.errors.phone && (
                            <span className="text-danger">{formik.touched.phone && formik.errors.phone}</span>)}
                    </FormGroup>
                    <FormGroup className='form-group2'>
                        <Label className="col-form-label">Password <span className="requireStar">*</span></Label>
                        <div className='position-relative'>
                            <Input className="form-control"
                                type={togglePassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password} />
                            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                <span className={togglePassword ? '' : 'show'}></span>
                            </div>
                        </div>
                        <span className="text-danger">{formik.touched.password && formik.errors.password}</span>
                    </FormGroup>
                    <FormGroup className='form-group2'>
                        <Label className="col-form-label">Confirm Password <span className="requireStar">*</span></Label>
                        <div className='position-relative'>
                            <Input className="form-control"
                                type={toggleConPassword ? 'text' : 'password'}
                                // type="password"
                                id="confirm_pass"
                                name="confirm_pass"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirm_pass} />
                            <div className="show-hide" onClick={() => setToggleConPassword(!toggleConPassword)}>
                                <span className={toggleConPassword ? '' : 'show'}></span>
                            </div>
                        </div>
                        <span className="text-danger">{formik.touched.confirm_pass && formik.errors.confirm_pass}</span>
                    </FormGroup>
                    <ReCAPTCHA
                        sitekey="6Lf2jgMqAAAAAN3O_Jz9ZqsarHXF4ceFRd46Gbby"
                        secretKey="6Lf2jgMqAAAAACyRDVxBwemO3J5uxCMKyvzIvNbV"
                        onChange={handleCaptchaChange}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                        <button type="submit" className='btn w-50 signup-bg-color'>
                            {loading ?
                                <CgSpinner size={20} className="mt-1 animate-spin" />
                                : <span>Next</span>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default personalRegistration;

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, CardHeader } from 'reactstrap';
import { Subscription } from '../../../../Constant';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { getMasterDataToken } from '../../../../Utils/restUtils';
import { getCompanySubscription, getMasterSubscription } from '../../../../CommonUrl';
import { SubscriptionUserColumns } from '../../../../Data/MockTable/UserTableHeader';
import { conditionalRow } from '../../../Common/Rowstyle/Rowstyle';
import { H3 } from '../../../../AbstractElements';

const SubTabs = (props) => {
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        subscriptionData();
    }, []);

    const handlePageChange = page => {
        fetchCustomer(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchCustomer(page, newPerPage);
        setPerPage(newPerPage);
    };

    const subscriptionData = async () => {
        setLoading(true);
        const response = await getMasterDataToken(getCompanySubscription+`?company_id=${props.param}`);
        if (response.status === true) {
            setSubscriptionList(response.data.map((e, index) => {
                return {
                    Si: index + 1,
                    PackName: <span>{e.package_name}</span>,
                    InvoiceNo: e.invoice_no,
                    StartDate: e.start_date,
                    EndDate: e.end_date,
                    PayStatus: e.payment_status,
                    CreateBy: e.created_by_name,
                    order_id: e.order_id,
                    id: e.id,
                    customerid: e.customer_id,
                    Status: e.status,
                    Stb_no: e.stb_no
                }
            }))
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };


    return (
        <Col sm="12" xl="6 xl-100">
            <Card>
                <CardHeader>
                    <H3 attrH3={{ style: { paddingLeft: '10px' } }}>{Subscription}</H3>
                </CardHeader>
                <CardBody>
                    <div className="order-history table-responsive" style={{ marginTop: '-35px', height: '220px' }}>
                        <DataTable
                            data={subscriptionList}
                            columns={SubscriptionUserColumns}
                            pagination
                            progressPending={loading}
                            paginationServer
                            defaultSortAsc
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default SubTabs;
import React, { Fragment, useEffect, useState } from "react";
import SpinnerLoader from "../../SpinnerLoader";
import { Controller, useForm } from "react-hook-form";
import { Breadcrumbs, H3 } from "../../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
    Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import {
    Address,
    Company,
    Email,
    MobileNo,
    Name,
    TAXIN,
    AfterNo,
    BeforeNo,
    Comma,
    Dash,
    DateFive,
    DateFormate,
    DateFour,
    DateOne,
    DateSix,
    DateThree,
    DateTwo,
    Dot,
    FinancialYearStart,
    Next,
    PerPosition,
    Separator,
    Slash,
    Space,
    TimeZone,
    companyEdit,
    PriceName,
    ItemName,
    QuantityName,
    DefaultCurrency,
    DefualtAcc,
    DefaultLang,
    DefaultTax,
    Localisation,
    Invoice,
    Defaults,
    Invoicesiffix,
    Invoiceprefix,
    DueDate,
    Decimal,
    DefaultDueDays,
    GSTTax,
    CompanyName,
} from "../../../Constant";
import PhoneInput from "react-phone-input-2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    getClientUrlDataToken,
    postClientUrlWithToken,
} from "../../../Utils/restUtils";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyUpdate, EditCompany, statusTax } from "../../../CommonUrl";
import { toast } from "react-toastify";
import Typeahead from "../../../CommonElements/TypeAhead/index";
import asiaTimezones from "../../../Components/Common/Timezone/Timezone";
import { duedateOptions } from "../../../Components/Company/Duedate";
import DatePicker from 'react-datepicker';
import moment from "moment";
import Transparentspinner from "../../../Components/Common/Loader/Transparentspinner";

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Invalid email format")
        .required("Email is required"),
    name: yup.string().required("Name is required"),
    phone: yup.string().required("Phone number is required"),
});

const CompanyEdit = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchparams = location.search;
    const params = new URLSearchParams(searchparams);
    const param1 = params.get("id");
    const [loading, setLoading] = useState(false);
    const [apilogo, setapilogo] = useState(null);
    const [logo, setLogo] = useState(apilogo);
    const [iconapi, seticonapi] = useState(null);
    const [icon, setIcon] = useState(iconapi);
    const [IconWithTab, setIconWithTab] = useState('1');
    const [taxOption, setTaxOption] = useState([]);
    const [birthDate, setBirthDate] = useState(null);

    const form = new FormData();

    const authState = useSelector((state) => state.auth);


    const dateformatOptions = [
        { value: "d M Y", label: DateOne },
        { value: "M d Y", label: DateTwo },
        { value: "Y M d", label: DateThree },
        { value: "d m Y", label: DateFour },
        { value: "m d Y", label: DateFive },
        { value: "Y m d", label: DateSix },
    ];

    const specialCharacterOptions = [
        { value: "-", label: Dash },
        { value: "/", label: Slash },
        { value: ".", label: Dot },
        { value: ",", label: Comma },
        { value: "space;", label: Space },
    ];

    const positionOptions = [
        { value: "after", label: AfterNo },
        { value: "before", label: BeforeNo },
    ];
    const decimalOptions = [
        { value: "2", label: "0.00" },
        { value: "3", label: "0.000" },
        { value: "4", label: "0.0000" },
    ];

    const priceOptions = [
        { value: "price", label: "Price" },
        { value: "rate", label: "Rate" },
    ];
    const productsOptions = [
        { value: "items", label: "Items" },
        { value: "products", label: "Products" },
        { value: "services", label: "Services" },
    ];

    const quantityOptions = [{ value: "quantitiy", label: "Quantitiy" }];
    const accountOptions = [
        { value: "cash", label: "cash" },
        { value: "credit", label: "credit" },
    ];
    const currencyOptions = [
        { value: "INR", label: "Indian Rupee" },
        { value: "USD", label: "US Dollar" },
    ];
    const languageOptions = [{ value: "english", label: "ENGLISH (GB)" }];


    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    useEffect(() => {
        companydetails();
    }, [setValue]);


    const handleLogoChange = (event) => {
        const selectedFile = event.target.files[0];
        setLogo(selectedFile);
    };
    const handleChangeDate = date => {
        setBirthDate(date);
    };


    const handleIconChange = (event) => {
        const selectedFile = event.target.files[0];
        setIcon(selectedFile);
    };


    const nextTab = () => {
        const nextTab = String(Number(IconWithTab) + 1);
        if (nextTab <= '4') {
            setIconWithTab(nextTab)
        }
    }


    const companydetails = async () => {
        setLoading(true);
        const responce = await getClientUrlDataToken(
            authState.apiUrl,
            EditCompany + `?company_id=${param1}`
        );
        if (responce.status === true) {
            const responce_data = responce.data;
            setValue("name", responce_data?.name);
            setValue("email", responce_data?.email);
            setValue("taxin", responce_data?.tax_in || "");
            setValue("phone", responce_data?.country_code + responce_data?.phone);
            setValue("countryCode", responce_data?.country_code);
            setValue("address", responce_data?.address.replace(/"/g, "") || "");
            const initalLogo = responce_data?.logo;
            setValue("logo", initalLogo);
            setapilogo(initalLogo);


            setBirthDate(new Date(responce_data?.financial_year))
            const selectedTimezone = asiaTimezones.find(
                (e) => e.value == responce_data?.time_zone
            );
 
            setValue("timezone", selectedTimezone);

            const selectedFormat = dateformatOptions.find(
                (e) => e.value == responce_data?.date_format
            );
            setValue("dateFormated", selectedFormat);

            const selectedseparator = specialCharacterOptions.find(
                (e) => e.value == responce_data?.separator
            );
            setValue("separator", selectedseparator);

            const setPosition = positionOptions.find(
                (e) => e.value === responce_data?.position
            );
            setValue("position", setPosition);

            const setDecimal = decimalOptions.find(
                (e) => e.value == responce_data?.decimal
            );
            setValue("decimal", setDecimal);


            const setPrice = priceOptions.find(
                (e) => e.value === responce_data?.price_name
            );
            setValue("pricename", setPrice);

            const setItemname = productsOptions.find(
                (e) => e.value === responce_data?.item_name
            );
            setValue("Itemname", setItemname);

            const setQantitiy = quantityOptions.find(
                (e) => e.value === responce_data?.quantity_name
            );

            setValue("quantityname", setQantitiy);

            setValue("invprefix", responce_data.invoice_prefix);
            setValue("invsuffix", responce_data.invoice_suffix);

            const setduedate = duedateOptions.find(
                (e) => e.value === String(responce_data?.due_date)
            );
            setValue("duedate", setduedate);

            const initalIcon = responce_data?.icon;
            setValue("icon", initalIcon);
            seticonapi(initalIcon);

            const setCurrency = currencyOptions.find(
                (e) => e.value === responce_data?.currency
            );
            setValue("currency", setCurrency);

            const setLanguage = languageOptions.find(
                (e) => e.value === responce_data?.language
            );
            setValue("language", setLanguage);

            const setAccount = accountOptions.find(
                (e) => e.value === responce_data?.account
            );

            setValue("defaultaccount", setAccount || "");

            let Taxdata = await getClientUrlDataToken(authState.apiUrl, statusTax);
            if (Taxdata.status === true) {
                const taxSetvalue = Taxdata.data;
                const setTaxApi = taxSetvalue.map((item) => ({
                    value: item.id,
                    label: item.title,
                }));

                setTaxOption(setTaxApi);
                const setDefaultTax = setTaxApi.find((e) => e.value == responce_data?.tax_rate);
                setValue("defaulttax", setDefaultTax || "");


            }
            else if (Taxdata.status >= 400 && Taxdata.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (Taxdata.status >= 500 && Taxdata.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

            }

        } else if (responce.status >= 400 && responce.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (responce.status >= 500 && responce.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false)

    };

    const onSubmit = async (data) => {
        setLoading(true)
        const countryCode = data.countryCode;
        const mobile = data.phone;
        if (mobile && countryCode) {
            const phoneno = mobile.slice(countryCode.length)

            form.append("company_id", param1);
            form.append("name", data.name);
            form.append("email", data.email);
            form.append("phone", phoneno);
            form.append("country_code", countryCode);

            form.append("address", data.address || "");
            form.append("tax_in", data.taxin);
            if (data.logo) {
                form.append("logo", data.logo[0]);
            }

            form.append("financial_year", moment(birthDate).format("DD-MM-YYYY") || "");
            form.append("time_zone", data?.timezone?.value || "");
            form.append("date_format", data.dateFormate?.value || "");
            form.append("separator", data.separator?.value || "");
            form.append("position", data.position?.value || "");
            form.append("decimal", data.decimal?.value || "");

            form.append("item_name", data.Itemname?.value || "");
            form.append("price_name", data.pricename?.value || "");
            form.append("quantity_name", data.quantityname?.value || "");
            form.append("invoice_prefix", data.invprefix || "");
            form.append("invoice_suffix", data.invsuffix || "");
            form.append("due_date", data.duedate?.value || "");
            if (data.icon) {
                form.append("icon", data.icon[0]);
            }

            form.append("currency", data.currency?.value || "");
            form.append("language", data.language?.value || "");
            form.append("account", data.defaultaccount?.value || "");
            form.append("tax_rate", data.defaulttax?.value || "");

            const postResponce = await postClientUrlWithToken(
                authState.apiUrl,
                CompanyUpdate,
                form,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (postResponce.status === 200) {
                if (postResponce.data.status === true) {
                    toast.success(postResponce.data.message);
                    navigate('/dashboard/company-list')
                } else {
                    toast.error(postResponce.data.message);
                    setLoading(false)
                }
            } else if (postResponce.status >= 400 && postResponce.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (postResponce.status >= 500 && postResponce.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            } else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }
            setLoading(false)
        }
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}

            <Form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <Breadcrumbs
                    mainTitle={companyEdit}
                    parent="Company"
                    title={companyEdit}
                />
                <Card className="tab-company-edit">
                    <Nav className="nav-primary" tabs>
                        <NavItem >
                            <NavLink className={IconWithTab === '1' ? 'active' : ''} onClick={() => setIconWithTab('1')}><span className="fs-6 fw-bold cursor ">{Company}</span></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={IconWithTab === '2' ? 'active' : ''} onClick={() => setIconWithTab('2')}><span className="fs-6 fw-bold cursor">{Localisation}</span></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={IconWithTab === '3' ? 'active' : ''} onClick={() => setIconWithTab('3')}><span className="fs-6 fw-bold cursor ">{Invoice}</span></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={IconWithTab === '4' ? 'active' : ''} onClick={() => setIconWithTab('4')}><span className="fs-6 fw-bold cursor">{Defaults}</span></NavLink>
                        </NavItem>

                    </Nav>
                    <TabContent activeTab={IconWithTab}>

                        <TabPane className="fade show" tabId="1">
                            <CardHeader className="pb-0">
                                <H3>{Company}</H3>
                            </CardHeader>
                            <CardBody>
                                <Row className="g-3">
                                    <Col sm="12" md="6">
                                        <Label>
                                            {CompanyName} <span className="requireStar">*</span>
                                        </Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register("name")}
                                        />
                                        <span className="text-danger pt-2">
                                            {errors.name && errors.name.message}
                                        </span>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="email">
                                            {" "}
                                            {Email} <span className="requireStar">*</span>
                                        </Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="email"
                                            {...register("email")}
                                        />

                                        <span className="text-danger">
                                            {errors.email && errors.email.message}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="g-3 pt-3">
                                    <Col md="6">
                                        <Label htmlFor="gst">{GSTTax} </Label>
                                        <input
                                            id="gst"
                                            className="form-control"
                                            type="text"
                                            name="taxin"
                                            {...register("taxin")}
                                        />
                                        <span className="text-danger">
                                            {errors.taxin && errors.taxin.message}
                                        </span>
                                    </Col>
                                    <Col md="6">
                                        <Label className="form-label">
                                            {MobileNo} <span className="requireStar">*</span>
                                        </Label>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <PhoneInput
                                                    {...field}
                                                    country={"in"}
                                                    enableSearch={true}
                                                    onChange={(value, country) => {
                                                        setValue("countryCode", country.dialCode);
                                                        field.onChange(value);
                                                    }}
                                                    inputStyle={{
                                                        width: "100%",
                                                        height: "46px",
                                                        padding: "10px",
                                                        paddingLeft: "50px",
                                                        fontSize: "16px",
                                                        borderRadius: "8px",
                                                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                                                    }}
                                                    searchClass="none"
                                                />
                                            )}
                                        />
                                        <span className="text-danger">
                                            {errors.phone && errors.phone.message}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="g-3 pt-3">
                                    <Col md="6">
                                        <Label htmlFor="address">{Address}</Label>
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            {...register("address")}
                                        />
                                        <span className="text-danger">
                                            {errors.address && errors.address.message}
                                        </span>
                                    </Col>
                                    <Col md="6">
                                        <Label className="form-label">Logo</Label>
                                        <Controller
                                            name="logo"
                                            control={control}
                                            render={({ field }) => (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Col md="8">
                                                        <input
                                                            {...register("logo")}
                                                            className="form-control fileInputStyle"
                                                            type="file"
                                                            onChange={handleLogoChange}
                                                            accept=".png, .jpg, .jpeg"
                                                        />
                                                    </Col>
                                                    <Col md="4">

                                                        <span className="imagestyle">
                                                            {logo == null ?
                                                                apilogo && <img
                                                                    src={apilogo}
                                                                    alt="logo"
                                                                    width="40px"
                                                                    height="40px"
                                                                />
                                                                :
                                                                <img
                                                                    src={URL.createObjectURL(logo)}
                                                                    alt="logo"
                                                                    width="40px"
                                                                    height="40px"
                                                                />
                                                            }
                                                        </span>
                                                    </Col>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <div className="text-end  ">
                                        <Button className="tab-button" onClick={nextTab}>{Next}</Button>
                                    </div>

                                </Row>
                            </CardBody>
                        </TabPane>

                        <TabPane tabId="2">

                            <CardHeader className="card-text-color pb-0">
                                <H3>{Localisation}</H3>
                            </CardHeader>

                            <CardBody>
                                <Row className="g-3">
                                    <Col md="6">
                                        <Label htmlFor="financial">{FinancialYearStart}</Label>
                                        <Col xl='12' md="12" sm='12'>
                                            <DatePicker
                                                id="financial"
                                                style={{ padding: '12px 100px' }}
                                                className="form-control "
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="dd/mm/yyyy"
                                                selected={birthDate}
                                                onChange={handleChangeDate}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"

                                            />

                                        </Col>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="timezone">{TimeZone}</Label>
                                            <Controller
                                                id="timezone"
                                                name="timezone"
                                                control={control}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        options={asiaTimezones}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="dateFormated">{DateFormate}</Label>
                                            <Controller
                                                name="dateFormated"
                                                control={control}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        id="dateFormated"
                                                        options={dateformatOptions}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="separator">{Separator}</Label>
                                        <Controller
                                            name="separator"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={specialCharacterOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />

                                    </Col>
                                </Row>
                                <Row className="g-3">
                                    <Col md="6">
                                        <Label htmlFor="confirmPW">{PerPosition}</Label>
                                        <Controller
                                            name="position"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={positionOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />

                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="decimal">{Decimal}</Label>
                                        <Controller
                                            name="decimal"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={decimalOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />

                                    </Col>

                                </Row>
                                <div className="text-end  mt-2">
                                    <Button className="tab-button" onClick={nextTab}>{Next}</Button>
                                </div>


                            </CardBody>
                        </TabPane>
                        <TabPane tabId="3">

                            <CardHeader className="card-text-color pb-0">
                                <H3>{Invoice}</H3>
                            </CardHeader>

                            <CardBody>
                                {/* <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="pricename">{PriceName}</Label>
                                            <Controller
                                                name="pricename"
                                                control={control}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        id="pricename"
                                                        options={priceOptions}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="Itemname">{ItemName}</Label>
                                        <Controller
                                            name="Itemname"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={productsOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />

                                    </Col>
                                </Row> */}
                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="prefix">{Invoiceprefix}</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="inv-prefix"
                                                {...register("invprefix")}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="suffix">{Invoicesiffix}</Label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="inv-suffix"
                                                {...register("invsuffix")}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="quantityname">{QuantityName}</Label>
                                            <Controller
                                                name="quantityname"
                                                control={control}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        id="quantityname"
                                                        options={quantityOptions}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="duedate">{DefaultDueDays}</Label>
                                        <Controller
                                            name="duedate"
                                            control={control}
                                            defaultValue={duedateOptions[0]}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={duedateOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />

                                    </Col>

                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Label className="form-label">Icon</Label>
                                        <Controller
                                            name="icon"
                                            control={control}
                                            render={({ field }) => (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Col md="8">
                                                        <input
                                                            {...register("icon")}
                                                            className="form-control fileInputStyle"
                                                            type="file"
                                                            onChange={handleIconChange}
                                                            accept=".png, .jpg, .jpeg"
                                                        />
                                                    </Col>
                                                    <Col md="4">

                                                        <span className="imagestyle">
                                                            {icon == null ?
                                                                iconapi && <img
                                                                    src={iconapi}
                                                                    alt="icon"
                                                                    width="40px"
                                                                    height="40px"
                                                                />
                                                                :
                                                                <img
                                                                    src={URL.createObjectURL(icon)}
                                                                    alt="icon"
                                                                    width="40px"
                                                                    height="40px"
                                                                />
                                                            }
                                                        </span>
                                                        {/* )} */}
                                                    </Col>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <div className="text-end  ">
                                    <Button className="tab-button" onClick={nextTab}>{Next}</Button>
                                </div>


                            </CardBody>
                        </TabPane>
                        <TabPane tabId="4">

                            <CardHeader className="card-text-color pb-0">
                                <H3>{Defaults}</H3>
                            </CardHeader>

                            <CardBody>
                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="language">{DefaultLang}</Label>
                                            <Controller
                                                name="language"
                                                control={control}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        id="language"
                                                        options={languageOptions}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="currency">{DefaultCurrency}</Label>
                                        <Controller
                                            name="currency"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={currencyOptions}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />

                                    </Col>
                                </Row>
                                <Row className="g-3">
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="defaultaccount">{DefualtAcc}</Label>
                                            <Controller
                                                name="defaultaccount"
                                                control={control}
                                                render={({ field }) => (
                                                    <Typeahead
                                                        id="defaultaccount"
                                                        options={accountOptions}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                        value={field.value}
                                                    />
                                                )}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor="defaulttax">{DefaultTax}</Label>
                                        <Controller
                                            name="defaulttax"
                                            control={control}
                                            render={({ field }) => (
                                                <Typeahead
                                                    options={taxOption}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />

                                    </Col>
                                </Row>

                                <div className="pt-3 text-end ">
                                    <span className="ps-2 spinner-enable">
                                        <Button
                                            className=" btn"
                                            type="submit"
                                        >
                                            Update
                                        </Button>

                                    </span>
                                </div>
                            </CardBody>
                        </TabPane>

                    </TabContent>
                </Card>
            </Form>
        </Fragment>
    );
};

export default CompanyEdit;

import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { H3, H5, LI, P, UL } from "../../../AbstractElements";
import CountUp from 'react-countup';
import { Collection, Connection, ConnectionMeter, Due, Invoice, RevenueMeter, Total } from "../../../Constant";
import { useSelector } from "react-redux";

const TotalCollMeter = (collection) => {
    const authState = useSelector((state) => state.auth)
    const collectionMeter = collection.collection;
    return (
        <Fragment>
            <Col xl="6">
                <Row>
                    <Col xl="12" md="12" className="box-col-12">
                        <Card className="order-widget">
                            <CardHeader className="pb-0">
                                <H3> {RevenueMeter} </H3>
                            </CardHeader>
                            <CardBody>
                                <Row className="heder-widget">
                                    <Col className="col-5">
                                        <span>{Total}</span>
                                        <H3 attrH3={{ className: "total-num counter" }}>
                                            <CountUp end={collectionMeter.percentage} duration={0.8} decimals={2} className="mb-0 counter" style={{ color: 'var(--default-primary)' }} />%
                                        </H3>
                                    </Col>
                                    <Col className="col-7">
                                        <div className="text-end">
                                            <UL attrUL={{ className: "simple-list" }}>
                                                <LI>{Invoice}
                                                    <span className="product-stts font-primary m-l-10">{authState.currency} {collectionMeter.total_amount}
                                                    </span>
                                                </LI>
                                                <LI>{Due}
                                                    <span className="product-stts font-primary m-l-10">{authState.currency} {collectionMeter.dues}
                                                    </span>
                                                </LI>
                                                <LI>{Collection}
                                                    <span className="product-stts font-primary m-l-10">{authState.currency} {collectionMeter.collection}
                                                    </span>
                                                </LI>
                                            </UL>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="new-order">
                                    <div>
                                        <div className="progress sm-progress-bar">
                                            <div className="progress-bar bg-secondary" role="progressbar" style={{ width: `${collectionMeter.percentage}%` }} aria-valuenow={collectionMeter.percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Fragment>
    );
};

export default TotalCollMeter;
import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Btn, H3, H4 } from '../../../AbstractElements';
import DataTable from 'react-data-table-component';
import { deleteCustomerDueImportedLog, deleteCustomerImportedLog, getCustomerDueImportedLog, getCustomerImportedLog } from '../../../CommonUrl';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Search } from '../../../Constant';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const ImportDueList = () => {
  const authState = useSelector((state) => state.auth);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [customerList, setcustomerList] = useState([]);
  const [spinnerTrans, setspinnerTrans] = useState(false) 


  const CustomerColumns = [
    {
      name: <H4>Sl</H4>,
      selector: row => row.Si,
      sortable: true,
      width: '50px',
    },
    {
      name: <H4>Comment</H4>,
      selector: row => row.Comment,
      sortable: true,
      wrap: true,
    //   width: '100px',
    },
    {
      name: <H4>Subscriber Id</H4>,
      selector: row => row.Sub,
      sortable: true,
      wrap: true,
    //   width: '100px',
    },
    {
      name: <H4>Amount</H4>,
      selector: row => row.Amount,
      sortable: true,
      wrap: true,
    //   width: '100px',
    },
  ];

  useEffect(() => {
    importCustomerlog();
  }, [searchKeyword]) 

  const importCustomerlog = async () => {
    setspinnerTrans(true)
    const response = await getClientUrlDataToken(authState.apiUrl, getCustomerDueImportedLog);
    if (response.status == true) {
      setcustomerList(response.data.map((e, index) => {
        return {
          Si: index + 1,
          Sub: <span>{e.subscriber_id}</span>,
          Amount: e.amount,
          Comment: e.comments?.replace(/^\["|"]$/g, ''),
        }
      }))
    } else {
      setspinnerTrans(false)
      toast.success("Something went wrong while fetching data");

    }
    setspinnerTrans(false)
  }

  const handleRemoveImpoted = async () => {
    setspinnerTrans(true)

    const deleteLogList = await postClientUrlWithToken(authState.apiUrl, deleteCustomerDueImportedLog);
    if (deleteLogList.status === 200) {
      if (deleteLogList.data.status == true) {
        toast.success(deleteLogList.data.message);
        importCustomerlog();
      } else {
        toast.error(deleteLogList.data.message);
        setspinnerTrans(false)
      }
    } else if (deleteLogList.status >= 400 && deleteLogList.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (deleteLogList.status >= 500 && deleteLogList.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setspinnerTrans(false)

  }
  const handleSearchInputChange = (event) => {
    setSearchKeyword(event.target.value);
  };



  return (
    <>
      {spinnerTrans && <Transparentspinner />}

      <Breadcrumbs mainTitle="Customer Due Imported Log" parent="customers / customer" title="customer due imported log" />
      <Card className='cardWidth'>
        <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <H3>Customer Due Imported Log</H3>
          <Btn attrBtn={{ color: 'danger', onClick: () => handleRemoveImpoted() }} ><i className="fa fa-trash-o "></i> Clear Log </Btn>
        </CardHeader>

        <CardBody>
          <span className='custom-span'>
            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
              <Col xs="6" sm="4" md="2" lg="2">
                <input className="form-control" placeholder={Search}  type="text" value={searchKeyword} onChange={handleSearchInputChange}
                />
              </Col>
            </Row>
          </span>



          <div className="order-history table-responsive">
            <DataTable
              columns={CustomerColumns}
              data={customerList}
              defaultSortAsc
              fixedHeader
              pagination
            />
          </div>
        </CardBody>
      </Card>


    </>
  )
}

export default ImportDueList
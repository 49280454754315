import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import CustomerListContainer from "./CustomerList";

const Customer = () => {
    return (
        <Fragment>
            <CustomerListContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default Customer;
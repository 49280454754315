import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Row, } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn } from '../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { Add, WalletBalanceRep, TotalWalletMoney, Export, Filter, Search, Account, Customer } from '../../Constant';
import SearchList from '../../CommonElements/Search';
import { Controller, useForm } from 'react-hook-form';
import { getWalletBalance, viewWalletReport, viewWalletReportRole, getAccount, getCustomers, getAccountRole, getCustomersRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { WalletBalenceColumns } from '../../Data/MockTable/ReportTableHeader';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';
import HandleApiError from '../Common/ErrorImport/ErrorImport';

const WalletBalenceReport = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const authState = useSelector((state) => state.auth);
    const [customerLists, setCustomerList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [apiAccount, setapiAccount] = useState([]);
    const [apiCustomer, setapiCustomer] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    // const handleHandleApiError = (status) => {
    //     if (status >= 400 && status <= 405) {
    //         navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    //     } else if (status >= 500 && status <= 505) {
    //         navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    //     } else {
    //         navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    //     }
    // };


    useEffect(() => {
        fetchWalletRep(1);
        accountData()
        customerData()
    }, []);

    const handlePageChange = page => {
        fetchWalletRep(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchWalletRep(page, newPerPage);
        setPerPage(newPerPage);
    };
    const accountData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getAccount : getAccountRole);
        if (data.status === true) {
            setapiAccount(data.data);
        } else {
            HandleApiError(navigate,response.status)
        }
    };

    const customerData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
        if (data.status === true) {
            setapiCustomer(data.data);
        }  else {
            HandleApiError(navigate,response.status)
        }
    };

    const onSubmit = async (data) => {
        let search = data.search;
        let account = data.account;
        let customer = data.customer;
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setSearchKeyword({ searches: search, start: start_date, end: end_date, account: account, customer: customer });
        fetchWalletRep(1, perPage, search, start_date, end_date, account, customer);
    };

    const fetchWalletRep = async (page, size = perPage, search = '', startDate = '', endDate = '', accountId = '', customerId = '') => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewWalletReport +
            `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&account_id=${accountId}&customer_id=${customerId}` :
            viewWalletReportRole + `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&account_id=${accountId}&customer_id=${customerId}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const valueWallets = response.data.wallet.data;
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(Object.keys(valueWallets).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    TxnId: valueWallets[e].transaction_id,
                    Date: valueWallets[e].date,
                    Amount: <span style={{ fontWeight: 'bold', color: '#534686' }}>{authState.currency}{valueWallets[e].amount}</span>,
                    SubId: valueWallets[e].subscriber_id,
                    Customer: valueWallets[e].customer_name,
                    customer_id: valueWallets[e].customer_id,
                }
            }
            ))
            setTotalRows(response.data.total);
        } else {
            HandleApiError(navigate,response.status)
        }
        setLoading(false);
    };

    const exportWalletBalanceExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewWalletReport +
            `?export=wallet_balance_report&search=${searchKeyword?.searches ? searchKeyword.searches : ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&account_id=${searchKeyword?.account ?? ''}&customer_id=${searchKeyword?.customer ?? ''}` :
            viewWalletReport + `?export=wallet_balance_report&search=${searchKeyword?.searches ? searchKeyword.searches : ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&account_id=${searchKeyword?.account ?? ''}&customer_id=${searchKeyword?.customer ?? ''}`);
        const dataToExport = response.data.wallet.map(({ transaction_id, date, amount, subscriber_id, customer_name }) => ({ Transaction_id: transaction_id, Date: date, Amount: authState.currency + amount, Subscriber_Id: subscriber_id, Customer: customer_name }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Wallet Balance report');
            XLSX.writeFile(wb, 'wallet_balance_report.xlsx');
        } else {
            HandleApiError(navigate,response.status)
        }
        setLoading(false);
    };
    const exportWalletBalancePdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewWalletReport +
            `?export=wallet_balance_report&search=${searchKeyword?.searches ? searchKeyword.searches : ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&account_id=${searchKeyword?.account ?? ''}&customer_id=${searchKeyword?.customer ?? ''}` :
            viewWalletReport + `?export=wallet_balance_report&search=${searchKeyword?.searches ? searchKeyword.searches : ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&account_id=${searchKeyword?.account ?? ''}&customer_id=${searchKeyword?.customer ?? ''}`);
        const dataToExport = response.data.wallet.map(({ transaction_id, date, amount, subscriber_id, customer_name }, index) => ({ Sl: index + 1, transaction_id, date, amount, subscriber_id, customer_name }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Transaction id", "Date", "Amount", "Subscriber Id", "Customer"];
            const rows = dataToExport.map(({ transaction_id, date, amount, subscriber_id, customer_name }, index) => [index + 1, transaction_id, date, amount, subscriber_id, customer_name]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("wallet_report_data.pdf");
        } else {
            HandleApiError(navigate,response.status)
        }
        setLoading(false);
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={WalletBalanceRep} parent="Reports" title={WalletBalanceRep} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{TotalWalletMoney}</H3>
                    <div>
                        <CardBody className="dropdown-basic">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportWalletBalanceExcel()} ><i className="fa fa-file-excel-o me-2"></i>Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportWalletBalancePdf()}><i className="fa fa-file-pdf-o me-2"></i>Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>
                </CardHeader>
                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element" >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'

                                />
                            </div>

                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('account')}>
                                <option value="">{Account}</option>
                                {apiAccount.map((values, index) => (

                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('customer')}>
                                <option value="">{Customer}</option>
                                {apiCustomer.map((values, index) => (

                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>

                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={WalletBalenceColumns}
                            data={customerLists}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default WalletBalenceReport;
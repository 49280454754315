import React from 'react';
import { Container, Row, Col, NavLink } from 'reactstrap';
import { FaFacebookF } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail, IoMdCall } from "react-icons/io";
import { AiFillLinkedin } from "react-icons/ai";
import './billerqStyle.scss';
import { appUrl } from '../CommonUrl';

const Footer = () => {
    const handleFacebook = () =>{
      
        window.open('https://www.facebook.com/billerq/', '_blank');
    }
    const handleLinkedIn = () =>{
        window.open('https://www.linkedin.com/company/quadleo-techno-solutions/', '_blank');
    }


    return (
        <div className='footer-container'>
            <div className='footer-sections'>
                <Container>
                    <Row>
                        <Col lg={5} md={5}>
                            <div className='footer-section1'>
                                <img src='/Services/billerq_logo.svg' alt='' className="logo-footer" />
                                <p>A Complete Subscriber Management Solution with CRM</p>
                                <div className='social-media'>
                                    <FaFacebookF className='icon' size={21} onClick={handleFacebook}/>
                                    <AiFillLinkedin className='icon' size={22} onClick={handleLinkedIn}/>
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} md={3}>
                            <div className='footer-section2'>
                                <h3>Quick Links</h3>
                                <p>About</p>
                                <p>Services</p>
                                <p>Contact us</p>
                            </div>
                        </Col>
                        <Col lg={2} md={3}>
                            <div className='footer-section2'>
                                <h3>Others</h3>
                                <p><NavLink href={appUrl+"disclaimer"}>Disclaimer</NavLink></p>
                                <p><NavLink href={appUrl+"terms-conditions"}>Terms & Conditions</NavLink></p>
                                <p><NavLink href={appUrl+"privacy-policy"}>Privacy Policy</NavLink></p>
                                <p><NavLink href={appUrl+"cancellation-and-refund-policy"}>Cancellation and Refund Policy</NavLink></p>
                                <p><NavLink href={appUrl+"shipping-and-delivery-policy"}>Shipping and Delivery Policy</NavLink></p>
                            </div>
                        </Col>
                        <Col lg={3} md={3}>
                            <div className='footer-section3'>
                                <h3 className='footer-section2'>Get in touch</h3>
                                <div className='footer-contact-location' >
                                    <FaLocationDot size={32} />
                                    <p>2nd floor, Kerala Startup Mission Center, UL CyberPark, Nellikode P.O, Kozhikode, Kerala 673016</p>
                                </div>
                                <div className='footer-contact'>
                                    <IoMdMail size={18} />
                                    <p>info@billerQ.com</p>
                                </div>
                                <div className='footer-contact'>
                                    <IoMdCall size={18} />
                                    <p>+91 70127 33393</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='copyright'>
                <hr
                className='footer-line'
                />
                Copyright <span>&copy;</span> 2024 Quadleo | All rights reserved.
            </div>
        </div>
    )
}

export default Footer
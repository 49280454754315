import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, Media, Label, Input, DropdownItem, Form, Row, Col } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { Controller, useForm } from 'react-hook-form';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4, Image } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, Addon, Addons, AddonList, Export, Search, Filter, importFile, Import, importAddon } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import { viewAddon, deleteAddon, viewAddonRole, statusAddon, statusAddonRole, postImportAddonApi, publicSampleDownloadaddon, deleteAddonRole, postImportAddonApiRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { exportToExcel } from '../../Common/Export';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PopupImport from '../../Common/Popup/popupImport';
import ImportPopUp from '../../Common/Import/ImportPop';
import ImportPopTax from '../../Common/Import/ImportPopTax';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const AddonsContainer = () => {
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };
    const toggleStyleDelete = { padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' };
    const [addonLists, setAddonList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filteredAddonData, setFilteredAddonData] = useState([]);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [modalImport, setModalImport] = useState(false);
    const handleModalImport = () => {
        setModalImport(!modalImport);
    }
    const fileImport = <span className='billerqColor bolder '>
        <h3>{importFile}</h3>
    </span>

    useEffect(() => {
        if (searchKeyword == '') {
            setLoading(true);
            fetchAddon(1);
        }
    }, [searchKeyword]);


    const addonColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '50px',

        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            sortable: true,
            center: true,
            width: '60px',

        },
        {
            name: <H4>Image</H4>,
            selector: row => <span style={{ width: "20px", height: "20px" }}>{row.Image}</span>,
            sortable: true,
            width: '80px'

        },
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,
            wrap: true,
            width: '130px'


        },
        {
            name: <H4>Category name</H4>,
            selector: row => row.category_type,
            sortable: true,
            wrap: true,
            width: '100px'


        },
        {
            name: <H4>Tax %</H4>,
            selector: row => row.Tax,
            sortable: true,
            center: true,
            wrap: true,
            width: '70px'


        },
        {
            name: <H4>Price</H4>,
            selector: row => row.Price,
            sortable: true,
            center: true,
            width: '150px'
        },
        {
            name: <H4>Total</H4>,
            selector: row => row.Total,
            sortable: true,
            center: true,
            width: '150px'
        },
        {
            name: <H4>Description</H4>,
            selector: row => row.Description,
            sortable: true,
            wrap: true,
            width: '190px'

        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,

        },

    ];

    const handleSwitchToggle = async (event, itemId) => {
        const checked = event.target.checked;
        let reqSwitch = null;
        if (checked === true) {
            reqSwitch = { add_on_id: itemId, status: 'inactive' }
        } else {
            reqSwitch = { add_on_id: itemId, status: 'active' }
        }

        const statusChange = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusAddon : statusAddonRole, reqSwitch);
        if (statusChange.status === 200) {
            if (statusChange.data.status === true) {
                toast.success(statusChange.data.message);
            }
            else {
                toast.error(statusChange.data.message);
            }

        } else if (statusChange.status >= 400 && statusChange.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (statusChange.status >= 500 && statusChange.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    }

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}${process.env.PUBLIC_URL}/Services/addon-edit?id=${row.id}`;
            navigate(editFormURL);
        };

        const deleteAddonList = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                if (result.value) {
                    const deleteRes = { add_on_id: row.id }
                    let addonDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteAddon : deleteAddonRole, deleteRes);
                    if (addonDelete.status === 200) {
                        if (addonDelete.data.message === 'success') {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            fetchAddon();
                        }
                    } else if (addonDelete.status >= 400 && addonDelete.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (addonDelete.status >= 500 && addonDelete.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                }
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '6px 12px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    {(authState.userRole === 1 || authState.permissions.includes('edit-add-on')) &&
                        <DropdownItem style={toggleStyle} onClick={() => handleEditClick()}>Edit</DropdownItem>
                    }
                    {(authState.userRole === 1 || authState.permissions.includes('remove-add-on')) &&
                        <DropdownItem style={toggleStyleDelete} onClick={() => deleteAddonList()}>Delete</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };


    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await fetchAddon(); // Fetch addon data
        setLoading(false);
    };


    const fetchAddon = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAddon : viewAddonRole);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            if (Array.isArray(response.data)) {
                const filteredAddons = response.data.filter((item) => {
                    const nameMatch = item.title && item.title.toLowerCase().includes(searchKeyword.toLowerCase());
                    const CategoryMatch = item.category_name && item.category_name.toString().toLowerCase().includes(searchKeyword.toLowerCase());
                    return nameMatch || CategoryMatch;
                });
                setFilteredAddonData(filteredAddons);
                setAddonList(filteredAddons.map((e, index) => {
                    return {
                        Si: index + 1,
                        Name: e.title,
                        Description: e.description,
                        Tax: e.total_tax_percentage + '%',
                        category_type: e.category_name,
                        Price: authState.currency + e.price,
                        Total: authState.currency + e.sub_total,
                        Image: <Image attrImage={{ src: e.add_on_image, alt: '' }} />,
                        id: e.id,
                        Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                            <Label className="switch">
                                <Input type="checkbox"
                                    defaultChecked={e.status === "active" ? true : false}
                                    onChange={(event) => handleSwitchToggle(event, e.id)}
                                />
                                <span className="switch-state" ></span>
                            </Label>
                        </Media>,
                        status: e.status,

                    }

                }))

                setLoading(false)
            }
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }

        setLoading(false);
    };

    const exportAddonExcel = () => {
        const exportData = filteredAddonData.map(({ title, category_name, total_tax_percentage, price, sub_total, description, status }) => ({ Name: title, Category_name: category_name, Tax_Percentage: total_tax_percentage + '%', Price: authState.currency + price, Sub_Total: authState.currency + sub_total, Description: description, Status: status }));
        exportToExcel(exportData, "addon_data");
    };

    const exportAddonPdf = () => {
        const filteredData = filteredAddonData.map(({ title, category_name, total_tax_percentage, price, sub_total, description, status }, index) => {
            return { Sl: index + 1, title, category_name, total_tax_percentage, price, sub_total, description, status };
        });

        const doc = new jsPDF();
        const columns = ["Sl", "Name", "Category Name", "Tax%", "Price", "Total", "Description", "Status"];
        const rows = filteredData.map(entry => Object.values(entry));
        doc.autoTable({
            head: [columns],
            body: rows
        });
        doc.save("addon_data.pdf");
    };





    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Addons} parent="Services" title={Addon} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{AddonList}</H3>
                    <div>

                        <CardBody className="dropdown-basic" >
                            {(authState.userRole === 1 || authState.permissions.includes('store-add-on')) &&
                                <Link to={`${process.env.PUBLIC_URL}/Services/addon-add`} className="btn btn-primary button-spacing " style={{ marginRight: '4px' }}>{Add}</Link>
                            }
                            {(authState.userRole === 1 || authState.permissions.includes('import-add-on')) &&
                                <Btn attrBtn={{ color: 'primary m-l-5', onClick: () => handleModalImport() }} >{Import} <i className="icofont icofont-arrow-down"></i></Btn>
                            }
                            {(authState.userRole === 1 || authState.permissions.includes('add-on-export')) &&
                                <Dropdown className='ms-2' isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary button-spacing">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportAddonExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportAddonPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            }
                            {/* <Link to={`${process.env.PUBLIC_URL}/Services/addon-add`} className="btn btn-primary" style={{ marginLeft: '10px' }}>{Add}</Link> */}
                        </CardBody>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} id="validationCustom01" type="text" value={searchKeyword} onChange={handleSearchInputChange}
                                    />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={addonColumns}
                            data={addonLists}
                            pagination
                            defaultSortAsc
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
            <PopupImport isOpen={modalImport} title={fileImport} toggler={handleModalImport} >
                <ImportPopTax
                    setModal={setModalImport}
                    sampleImportApi={publicSampleDownloadaddon}
                    postImportApi={authState.userRole === 1 ? postImportAddonApi : postImportAddonApiRole}
                    imporTitle={importAddon}
                    tablerenderFunc={fetchAddon}
                />
            </PopupImport>

        </Fragment>
    );
};

export default AddonsContainer;
import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText
} from 'reactstrap';
import Footer from './Footer';
import { Link, useLocation } from 'react-router-dom';
import Contact from './Contact';
import './Disclaimer.scss';
import { appUrl } from '../CommonUrl';

const PrivacyPolicy = () => {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const loginEncrypted = localStorage.getItem("login");
    let login = null;
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    if (loginEncrypted) {
        try {
            const bytes = CryptoJS.AES.decrypt(loginEncrypted, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
                login = JSON.parse(decryptedData);
            }
        } catch (e) {
            console.error('Error decrypting or parsing login data', e);
        }
    }

    const isLoggedIn = login && login.userToken;

    return (
        <div className='section1-containers'>
            <div style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
                <Navbar collapseonselect='true' expand="lg" className="bg-body-tertiary" container>
                    <NavbarBrand href="/"><img src={require('../assets/images/logoBillerq.png')} alt='' className="logo me-lg-5" /></NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="me-auto  align-items-lg-center" navbar>
                        <NavItem>
                                <NavLink href={appUrl} className='nav-link-bar'>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl+"#aboutUs"} className='nav-link-bar'>About Us</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl+"#feature"} className='nav-link-bar'>Features</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl+"#usecase"} className='nav-link-bar'>Use Case</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl+"#contact"} className='nav-link-bar'>Contact Us</NavLink>
                            </NavItem>
                            <NavbarText>
                                <Link className='ms-lg-5' to={isLoggedIn ? `${process.env.PUBLIC_URL}/dashboard/default` : '/SignIn'}>
                                    <div className='login-button'>{isLoggedIn ? 'Dashboard' : 'Get Started'}</div>
                                </Link>
                            </NavbarText>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
            <div style={{ width: '100%' }}>
                <div className='heading-main' >
                    <h1 className='disclaimer-heading'>Privacy Policy</h1>
                </div>
            </div>

            <div className='container'>
                <div className='content-section'>
                    <p className='disclaimer-text'>
                        Quadleo Techno Solutions Pvt Ltd  we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our SaaS-based subscriber management system, BillerQ. Please read this privacy policy carefully.
                    </p>
                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>1. Information We Collect</h4>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Personal Information<br /></span>Account Information: When you sign up for BillerQ, we collect information such as your name, email address, phone number, and company details.</p>
                        <p className='disclaimer-text'>Billing Information: We collect billing details such as your billing address and payment information when you subscribe to our services.</p>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Usage Data<br /></span>Log Information: We collect information about your use of BillerQ, including IP addresses, browser types, operating systems, pages viewed, and the dates/times of access.</p>
                        <p className='disclaimer-text'>Device Information: We collect information about the device you use to access BillerQ, including hardware model, operating system, and device identifiers.</p>
                    </div>


                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>2. How We Use Your Information</h4>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>To Provide and Manage Our Services<br /></span>Account Management: We use your personal information to create and manage your account.</p>
                        <p className='disclaimer-text'>Service Delivery: We use your information to provide and maintain the BillerQ service.</p>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>To Communicate with You<br /></span>Customer Support: We use your contact information to provide customer support and respond to your inquiries.</p>
                        <p className='disclaimer-text'>Notifications: We send you service-related notifications, updates, and promotional messages.</p>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>To Improve Our Services<br /></span>Usage Analysis: We analyze usage data to improve the functionality and performance of BillerQ.</p>
                        <p className='disclaimer-text'>Feedback: We may use your feedback to make enhancements to our services.</p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>3. How We Share Your Information</h4>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>With Third Parties<br /></span>Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis.</p>
                        <p className='disclaimer-text'>Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>With Your Consent<br /></span>We may share your information with third parties if you give us your explicit consent to do so.</p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>4. Data Security</h4>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Protection Measures<br /></span>We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure.</p>
                        <p className='disclaimer-text'>Encryption: We use encryption to protect sensitive information transmitted online.</p>
                        <p className='disclaimer-text'>Access Controls: We restrict access to personal information to employees, contractors, and agents who need to know that information to process it for us.</p>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>No Guarantee<br /></span>While we strive to protect your information, we cannot guarantee its absolute security. You are responsible for maintaining the confidentiality of your account credentials.</p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>5. Your Rights</h4>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Access and Update<br /></span>You have the right to access and update your personal information. You can do so by logging into your account or contacting us at <span style={{ fontWeight: '600' }}>info@billerq.com</span>.</p>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Deletion<br /></span>You have the right to request the deletion of your personal information. Please note that we may need to retain certain information for legal or administrative purposes.</p>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Opt-Out<br /></span>You can opt out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications or by contacting us directly.</p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>6. Cookies and Tracking Technologies</h4>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Cookies<br /></span>We use cookies and similar tracking technologies to track activity on our service and hold certain information.</p>
                        <p className='disclaimer-text'>Types of Cookies: We use session cookies to operate our service and preference cookies to remember your preferences.</p>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Your Choices<br /></span>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.</p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>7. Changes to This Privacy Policy</h4>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Updates<br /></span>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this policy.</p>
                        <p className='disclaimer-text'><span style={{ fontWeight: '600' }}>Continued Use<br /></span>Your continued use of BillerQ after any changes to this Privacy Policy will constitute your acknowledgment of the changes and your consent to abide and be bound by the modified Privacy Policy.</p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>Contact Us</h4>
                        <p className='disclaimer-text'>
                            If you have any questions about our shipping and delivery policy or need further assistance, please reach out to us at <span style={{ fontWeight: '600' }}>info@billerq.com</span>.
                        </p>
                        <p className='disclaimer-text'>
                            Thank you for choosing BillerQ. We are committed to providing you with a seamless and efficient subscriber management experience.
                        </p>
                    </div>
                </div>
            </div>
            {/* <Contact /> */}
            <div className='gap'></div>
            <Contact />
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;

import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { CasProvider, CasProviderAdd, Description, Name, NameError, Save } from "../../../Constant";
import { addArea, addProvider, addProviderRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const ProviderForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { name: data.name, description: data.message }

        const addProviderResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addProvider : addProviderRole, postRequest);
        if (addProviderResponse.status === 200) {
            if (addProviderResponse.data.status === true) {
                toast.success(addProviderResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/settings/casProvider`);
            } else {
                toast.error(addProviderResponse.data.message)
                setLoading(false);
            }
        } else if (addProviderResponse.status >= 400 && addProviderResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addProviderResponse.status >= 500 && addProviderResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={CasProvider} parent="Settings" title={CasProvider} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{CasProviderAdd}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('name', { required: true })} style={{ width: '100%' }} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Description}</Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('message')} style={{ width: '100%' }} />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default ProviderForm;



// Login 
import LoginSimple from '../Components/Pages/Auth/Login';
import LoginOne from '../Components/Pages/Auth/LoginOne';
import LoginTwo from '../Components/Pages/Auth/LoginTwo';
import LoginValidation from '../Components/Pages/Auth/LoginValidation';
import LoginTooltip from '../Components/Pages/Auth/LoginTooltip';
import LoginSweetalert from '../Components/Pages/Auth/LoginSweetalert';

//Register pages
import RegisterSimple from '../Components/Pages/Auth/RegisterSimple';
import RegisterBgImg from '../Components/Pages/Auth/RegisterBgImg';
import RegisterVideo from '../Components/Pages/Auth/RegisterVideo';
import UnlockUser from '../Components/Pages/Auth/UnlockUser';
import ForgotPwd from '../Components/Pages/Auth/ForgetPwd';
import ResetPwd from '../Components/Pages/Auth/ResetPassword';
import Maintenance from '../Components/Pages/Auth/Maintenance';
import EmailOtp from "../Billerq/Sign/EmailOtp";

//ComingSoon Pages
import Comingsimple from '../Components/Pages/CommingSoon/ComingSimple';
import Comingwithbgvideo from '../Components/Pages/CommingSoon/ComingwithbgVideo';
import ComingbgImage from '../Components/Pages/CommingSoon/ComingSoonbgImg';
import Error_page1 from '../Components/Pages/ErrorForLogin/Error_page1';
import Error_page2 from '../Components/Pages/ErrorForLogin/Error_page2';
import Error_page3 from '../Components/Pages/ErrorForLogin/Error_page3';
import Error_page4 from '../Components/Pages/ErrorForLogin/Error_page4';
import Error_page5 from '../Components/Pages/ErrorForLogin/Error_page5';

export const authRoutes = [  
  //Login and Register pages
  { path: `${process.env.PUBLIC_URL}/pages/auth/login-simple`, Component: <LoginSimple /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/login-bg-img`, Component: <LoginOne /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/login-img`, Component: <LoginTwo /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/login-validation`, Component: <LoginValidation /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/login-tooltip`, Component: <LoginTooltip /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/login-sweetalert`, Component: <LoginSweetalert /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/register-simple`, Component: <RegisterSimple /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/register-bg-img`, Component: <RegisterBgImg /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/sign-up-two`, Component: <RegisterVideo /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/unlock-user`, Component: <UnlockUser /> },
  { path: `${process.env.PUBLIC_URL}/forget-pwd`, Component: <ForgotPwd /> },
  { path: `${process.env.PUBLIC_URL}/email-otp`, Component: <EmailOtp /> },
  { path: `${process.env.PUBLIC_URL}/pages/auth/reset-pwd`, Component: <ResetPwd /> },  
  { path: `${process.env.PUBLIC_URL}/pages/auth/maintenance`, Component: <Maintenance /> },
 
  // coming soon
  { path: `${process.env.PUBLIC_URL}/pages/comingsimple/coming-bg-video`, Component: <Comingwithbgvideo /> },  
  { path: `${process.env.PUBLIC_URL}/pages/comingsimple/coming-bg-img`, Component: <ComingbgImage /> },
  // { path: `${process.env.PUBLIC_URL}/pages/comingsimple/comingsoon`, Component: <Comingsimple /> },

  //Error
  { path: `${process.env.PUBLIC_URL}/login/pages/error/error-page1`, Component: <Error_page1/> },
  { path: `${process.env.PUBLIC_URL}/login/pages/error/error-page2`, Component: <Error_page2/> },
  { path: `${process.env.PUBLIC_URL}/login/pages/error/error-page3`, Component: <Error_page3/> },
  { path: `${process.env.PUBLIC_URL}/login/pages/error/error-page4`, Component: <Error_page4/> },
  { path: `${process.env.PUBLIC_URL}/login/pages/error/error-page5`, Component: <Error_page5/> },

];

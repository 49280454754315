import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { Description, EditTaxGroup, Name, NameError, Save, TaxGroup, TaxGroupAdd, Type } from "../../../Constant";
import { addArea, addCategory, addProvider, updateTaxGroup, viewTaxGroup } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const TaxGroupEdit = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const location = useLocation();
    const authState = useSelector((state) => state.auth);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getTaxGroup();
    }, []);

    const getTaxGroup = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, viewTaxGroup + `?tax_group_id=${param1}`);
        if (data.status === true) {
            setValue('id', data.data[0].id);
            setValue('name', data.data[0].title);
            setValue('message', data.data[0].description);
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false);

    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            tax_group_id: data.id,
            title: data.name,
            description: data.message
        }
        const addTaxGroupResponse = await postClientUrlWithToken(authState.apiUrl, updateTaxGroup, postRequest);
        if (addTaxGroupResponse.status === 200) {
            if (addTaxGroupResponse.data.status === true) {
                toast.success(addTaxGroupResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/settings/tax-group`);
            } else {
                toast.error(addTaxGroupResponse.data.message)
                setLoading(false);
            }
        } else if (addTaxGroupResponse.status >= 400 && addTaxGroupResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addTaxGroupResponse.status >= 500 && addTaxGroupResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}


            <Breadcrumbs mainTitle={TaxGroup} parent="Settings" title={TaxGroup} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditTaxGroup}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default TaxGroupEdit;
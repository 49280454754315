import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { Account, CodeError, NameError, Code, Description, Name, OfflinePayment, OfflinePaymentEdit, Order, Save, Type, OrderError } from "../../../Constant";
import { UpdatePayment, UpdatePaymentRole, getAccount, getAccountRole, viewPayment, viewPaymentRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../../CommonElements/TypeAhead";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const PaymentsMethodEdit = () => {
    const [spinnerTrans, setspinnerTrans] = useState(true)

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const typeOptions = [
        { value: "Online", label: "Online" },
        { value: "Offline", label: "Offline" },
    ];



    useEffect(() => {
        accountData();
    }, []);

    const accountData = async () => {
        setLoading(true)
        let apiSingleID = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPayment + `?payment_method_id=${param1}` : viewPaymentRole + `?payment_method_id=${param1}`)
        if (apiSingleID.status === true) {
            const api_Single = apiSingleID.data[0];

            let dataAccount = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getAccount : getAccountRole);

            setValue("name", api_Single.name);
            setValue("type", { value: api_Single.type, label: api_Single.type });
            setValue("code", api_Single.code);
            setValue("order", api_Single.order);
            const optionsList = dataAccount.data.map((items) => ({ value: items.id, label: items.name }));
            setAccount(optionsList);

            const equalid = optionsList.find((setid) => setid.value === api_Single.account_id);
            setValue("account", { value: equalid.value, label: equalid.label })


        }
        else if (apiSingleID.status >= 400 && apiSingleID.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (apiSingleID.status >= 500 && apiSingleID.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false)

    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { payment_method_id: param1, name: data.name, account_id: data.account.value, code: data.code, order: data.order, type: data.type.value }

        const updatepaymentResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? UpdatePayment : UpdatePaymentRole, postRequest);
        if (updatepaymentResponse.status === 200) {
            if (updatepaymentResponse.data.status === true) {
                toast.success(updatepaymentResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/settings/payment`);
            } else {
                toast.error(updatepaymentResponse.data.message)
                setLoading(false)
            }
        } else if (updatepaymentResponse.status >= 400 && updatepaymentResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (updatepaymentResponse.status >= 500 && updatepaymentResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}


            <Breadcrumbs mainTitle={OfflinePayment} parent="Settings" title={OfflinePayment} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{OfflinePaymentEdit}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="code">{Code} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="code" type="text" {...register('code', { required: true })} />
                                <span className="text-danger">{errors.code && CodeError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="order">{Order} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="order" type="number" min={0} {...register('order', { required: true })} />
                                <span className="text-danger">{errors.order && OrderError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label">{Account} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="account"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={account}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors.customer && CustomerTypeError}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Row className=" pt-3">

                                <Col md="6">
                                    <Label className="form-label" >{Type} <span className="requireStar">*</span></Label>
                                    <Controller
                                        name="type"
                                        control={control}
                                        render={({ field }) => (
                                            <Typeahead
                                                options={typeOptions}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                                value={field.value}
                                            />
                                        )}
                                    />
                                    <span className="text-danger">
                                        {errors.customer && CustomerTypeError}
                                    </span>
                                </Col>
                            </Row>

                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3"
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default PaymentsMethodEdit;
import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Media, Label, Input, Form, Row, Col } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { H4, H3, Btn, Breadcrumbs } from '../../AbstractElements';
import { getClientUrlDataToken, getMasterDataToken, postClientUrlWithToken } from '../../Utils/restUtils';
import { Add, Company, Companylist, Search, Filter } from '../../Constant';
import { CompanyRemove, CompanySubView, CompanyView, changeCompany } from '../../CommonUrl';
import { toast } from 'react-toastify';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';

const CompanyList = () => {
    const authState = useSelector((state) => state.auth);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [companyList, setcompanyList] = useState([]);
    const [companySubList, setCompanySubList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        if (searchKeyword == '') {
            setLoading(true);
            companyData();
            companySubData();
        }
    }, [searchKeyword]);

    const Comapnycolumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            center: false,
            width: '90px'
        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '20px' }}></i>,
            selector: row => row.action,
            cell: row => <div style={{ width: '0px' }}><ActionCell row={row} /></div>,
            sortable: true,
            width: '140px',
        },
        {
            name: <H4>Name</H4>,
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/dashboard/company-list/company-profile?id=${row.id}`}>
                    {row.Name}
                </Link>,
            sortable: true,
            center: false,
            wrap: true,
            width: '160px',

        },
        {
            name: <H4>Email</H4>,
            selector: row => row.Email,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Created</H4>,
            selector: row => row.Created,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Switch To</H4>,
            selector: row => row.Status,
            sortable: true,
            center: false,
        },
    ];


    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        companyData();
    };

    const handleAddClick = () => {
        let path = `${process.env.PUBLIC_URL}/dashboard/managecompany/company-add`;
        if (authState.subStatus.plan_type === 'free') {
            path = `/subscription`;
            navigate(path, { state: { fromDashboard: true, id: authState.subStatus.company_id, text: 'Upgrade your plan to add company' } });
        } else if (authState.subStatus.plan_type === 'pending') {
            path = `/subscriptionAddition`;
            navigate(path);
        } else {
            navigate(path);
        }
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        const handleEditClick = () => {
            const editFormURL = `${process.env.PUBLIC_URL}/dashboard/company-edit?id=${row.id}`;
            navigate(editFormURL);
        };

        const deleteCompanyList = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const deleteRes = { company_id: row.id }
                    let companyDelete = await postClientUrlWithToken(authState.apiUrl, CompanyRemove, deleteRes);
                    if (companyDelete.status === 200) {
                        if (companyDelete.data.status === true) {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            companyData();
                        } else {
                            setLoading(false)
                            toast.error(companyDelete.data.message);
                        }
                    } else if (companyDelete.status >= 400 && companyDelete.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (companyDelete.status >= 500 && companyDelete.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                }
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ padding: '14px', fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={toggleStyle} onClick={() => handleEditClick()}>Edit</DropdownItem>
                    {companySubList.some(
                        (item) => item.company_id === row.id && item.subscription === 'no') &&
                        <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={() => deleteCompanyList()}>Delete</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        );
    };

    const handleSwitchToggle = async (id) => {
        setLoading(true);
        const data = await postClientUrlWithToken(authState.apiUrl, changeCompany, { company_id: id });
        if (data.status === 200) {
            if (data.data.status === true) {
                window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/`;
                toast.success(data.data.message)
            } else {
                toast.error(data.data.message);
                setLoading(false);
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    const companySubData = async () => {
        setLoading(true);
        const company_api = await getMasterDataToken(CompanySubView);
        if (company_api.status === true) {
            setCompanySubList(company_api.data);
        } else if (company_api.status >= 400 && company_api.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (company_api.status >= 500 && company_api.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };


    const companyData = async () => {
        setLoading(true);
        let company_api = await getClientUrlDataToken(authState.apiUrl, CompanyView);
        if (company_api.status === true) {
            const filteredCompany = company_api.data.filter((item) => {
                const nameMatch = item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase());
                const EmailMatch = item.email && item.email.toString().toLowerCase().includes(searchKeyword.toLowerCase());
                return nameMatch || EmailMatch;
            });
            setResetPaginationToggle(!resetPaginationToggle);
            setcompanyList(filteredCompany.map((e, index) => {
                return {
                    id: e.id,
                    Si: index + 1,
                    Name: e.name,
                    Email: e.email,
                    Created: e.created_date,
                    Status: <Media body className={`flex-grow-1 text-end icon-state`}>
                        <Label className="switch">
                            <Input type="checkbox"
                                defaultChecked={e.status === "active" ? true : false}
                                onChange={() => handleSwitchToggle(e.id)}
                            />
                            <span className="switch-state" ></span>
                        </Label>
                    </Media>,
                }
            })
            )
        } else if (company_api.status >= 400 && company_api.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (company_api.status >= 500 && company_api.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={Company} parent="Companies" title={Companylist} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{Companylist}</H3>
                    <div>
                        <Btn className="custom-filter-btn" attrBtn={{ color: "primary", onClick: () => handleAddClick() }} >{Add}</Btn>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} type="text" value={searchKeyword} onChange={handleSearchInputChange}
                                    />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn" attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={companyList}
                            columns={Comapnycolumns}
                            pagination
                            defaultSortAsc
                            fixedHeader
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default CompanyList;
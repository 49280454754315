import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../Constant";
import ComplaintsListContainer from "./ComplaintsList";

const Complaints = () => {
    return (
        <Fragment>
            <ComplaintsListContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default Complaints;
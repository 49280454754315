import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { Col, Container, Form, FormGroup, Row } from 'reactstrap';
import { Btn, H2, H3, P, Image } from '../../AbstractElements';
import { ConfirmOtp, EnterOTP, GetBackDashboard, SuccessOTP } from '../../Constant';
import { postDataWithoutResData } from '../../Utils/restUtils';
import { resendUrlEmail, verifyEmailOTP } from '../../CommonUrl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';
import { useSelector } from 'react-redux';

const emailOtp = () => {
    const authState = useSelector((state) => state.auth);
    const [spinnerTrans, setspinnerTrans] = useState(false);
    const [OTP, setOTP] = useState("");
    const [error, setError] = useState('');
    const [loading, setloading] = useState(false)
    const [step, setstep] = useState(1);
    const { handleSubmit, setValue } = useForm();
    const navigate = useNavigate();
    const [timer, setTimer] = useState(60);
    const [otpSent, setOtpSent] = useState(false);


    useEffect(() => {
        let intervalId;

        if (otpSent && timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [otpSent, timer]);

    const handleEmail = async (data) => {
        setspinnerTrans(true)
        const postRequest = { email: authState.email };
        const responce = await postDataWithoutResData(resendUrlEmail, postRequest)
        if (responce.status === 200) {
            if (responce.data.status == true) {
                setOtpSent(true);
                setTimer(60);
                setstep(1);
                toast.success(responce.data.message);
                setspinnerTrans(false)

            } else {
                setspinnerTrans(false)
                toast.error(responce.data.message);
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)
    }

    const handleOTPsubmit = async () => {
        setspinnerTrans(true)
        if (OTP) {
            const postRequest = { email: authState.email, otp: OTP }
            const responce = await postDataWithoutResData(verifyEmailOTP, postRequest)
            if (responce.status == 200) {
                if (responce.data.status == true) {
                    setValue("OtpAccesssNumber", responce.data.data);
                    toast.success(responce.data.message);
                    setstep(3)
                    setspinnerTrans(false)
                } else {

                    toast.error(responce.data.message);
                    setspinnerTrans(false)

                }
            } else if (data.status >= 400 && data.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (data.status >= 500 && data.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }

        }

    }

    const handleSubmitPwd = () => {
        navigate(`${process.env.PUBLIC_URL}/dashboard/default/`);
    }


    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}

            <Container className='p-0' fluid={true}>
                <Row>
                    <Col className="">
                        <div className='login-card'>
                            <div>
                                <div className='logo'>
                                    <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../assets/images/logo/logo.png')}` }} />
                                </div>

                                <div className='login-main'>
                                    {step === 1 && (
                                        <>
                                            <FormGroup className="form-group">
                                                <Form onSubmit={handleSubmit(handleOTPsubmit)}>
                                                    <Row xs={12}>
                                                        <Col>
                                                            <H2 attrH2={{ className: 'text-start mt-3 mb-3' }}>{EnterOTP}</H2>
                                                            <P>Enter the otp send to your email <strong>{authState.email}</strong></P>
                                                            <Col >
                                                                <div className='otp-input'>
                                                                    <OTPInput
                                                                        value={OTP}
                                                                        onChange={setOTP}
                                                                        autoFocus
                                                                        OTPLength={6}
                                                                        otpType="number"
                                                                        disabled={false}
                                                                        inputStyles={{ borderRadius: '5px', border: 'solid 1.5px #74737380', height: '40px', width: '30px' }}
                                                                        style={{ display: 'flex', justifyContent: 'center', paddingTop: '3px', paddingBottom: '10px', marginLeft: '20px' }}
                                                                    />
                                                                    {error && <span className="text-danger">{error}</span>}
                                                                    <ResendOTP onResendClick={handleEmail} className='resendOtpClass' />
                                                                </div>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <FormGroup className=''>
                                                        <div className='d-flex  justify-content-between'>
                                                            <div>
                                                                {/* <span className='cursor btn-link' onClick={handleChangeEmail}>{ChnageEmail}</span> */}
                                                                {/* <button className="btn p-0 m-0 text-underline border-0" onClick={handleEmail} disabled={timer > 0}>  {Resend}</button> */}
                                                            </div>
                                                            <div>

                                                                {/* <span className="text-muted" > 00 : {timer}</span> */}
                                                            </div>
                                                        </div>
                                                        <div className=""  >
                                                        </div>
                                                        <div className=''>
                                                            <Btn attrBtn={{ color: 'primary', className: 'btn btn-block w-100 mt-3', type: 'submit', disabled: loading }} >{ConfirmOtp}</Btn>

                                                        </div>
                                                    </FormGroup>
                                                </Form>

                                            </FormGroup>
                                        </>
                                    )}
                                    {step === 3 && (
                                        <>
                                            <Form onSubmit={handleSubmit(handleSubmitPwd)}>
                                                <Row style={{ textAlign: 'center' }}>
                                                    <H3 attrH3={{ className: 'mt-4' }}>{SuccessOTP}</H3>
                                                    <P>You now have full access to our services and features. Thank you for taking the extra step to secure your account with an OTP verification.</P>
                                                    <FormGroup className="form-group">
                                                        <Btn attrBtn={{ color: 'primary', className: 'btn btn-block w-100 mt-3', type: 'submit', disabled: loading }} >{GetBackDashboard}</Btn>
                                                    </FormGroup>
                                                </Row>
                                            </Form>
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default emailOtp;
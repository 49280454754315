import React, { Fragment } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Payment, Qpay, pay } from '../../Constant';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

const NavCustomizer = ({ callbackNav, selected }) => {
    const authState = useSelector(state => state.auth);
    const navigate = useNavigate();
    const handleNvaigate = () =>{
        navigate(`${process.env.PUBLIC_URL}/payment/quick-pay`);
    }
    return (
        <Fragment>           
            <Nav className="flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
                <NavItem>
                {/* <NavLink className={selected === 'color-picker' ? 'active' : ''} onClick={() => callbackNav('color-picker', true)}> */}

                    <NavLink className={selected === 'color-picker' ? 'active' : ''} onClick={() => handleNvaigate()}>
                        <div className="settings "><i className="text-center">
                        <div className='qpay-rightbar pt-1 fw-bold'>
                       <b> {authState.currency} {pay}</b>
                            </div>
                          </i> </div>
                    </NavLink>
                </NavItem>
                <NavItem>
                    {/* <NavLink className={selected === 'sidebar-type' ? 'active' : ''} onClick={() => callbackNav('sidebar-type', true)}>
                        <div className="settings"><i className="icon-settings"></i></div>
                    </NavLink> */}
                </NavItem>
            </Nav>
        </Fragment>
    );
};

export default NavCustomizer;
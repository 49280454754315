import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText,
} from 'reactstrap';
import Contact from './Contact';
import Footer from './Footer';
import { Link, useLocation } from 'react-router-dom';
import './Disclaimer.scss';
import { appUrl } from '../CommonUrl';

const TermsCondition = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const loginEncrypted = localStorage.getItem("login");
    let login = null;
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    if (loginEncrypted) {
        try {
            const bytes = CryptoJS.AES.decrypt(loginEncrypted, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
                login = JSON.parse(decryptedData);
            }
        } catch (e) {
            console.error('Error decrypting or parsing login data', e);
        }
    }

    const isLoggedIn = login && login.userToken;

    return (
        <div className='section1-containers'>
            <div style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
                <Navbar collapseonselect='true' expand="lg" className="bg-body-tertiary" container>
                    <NavbarBrand href="/"><img src={require('../assets/images/logoBillerq.png')} alt='' className="logo me-lg-5" /></NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="me-auto  align-items-lg-center" navbar>
                            <NavItem>
                                <NavLink href={appUrl} className='nav-link-bar'>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#aboutUs"} className='nav-link-bar'>About Us</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#feature"} className='nav-link-bar'>Features</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#usecase"} className='nav-link-bar'>Use Case</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={appUrl + "#contact"} className='nav-link-bar'>Contact Us</NavLink>
                            </NavItem>
                            <NavbarText>
                                <Link className='ms-lg-5' to={isLoggedIn ? `${process.env.PUBLIC_URL}/dashboard/default` : '/SignIn'}>
                                    <div className='login-button'>{isLoggedIn ? 'Dashboard' : 'Get Started'}</div>
                                </Link>
                            </NavbarText>
                        </Nav>
                    </Collapse>
                    {/* </div> */}
                </Navbar>
            </div>
            <div style={{ width: '100%' }}>
                <div className='heading-main' >
                    <h1 className='disclaimer-heading'>Terms & Conditions</h1>
                </div>
            </div>
            <div className='container'>
                <div className='content-section'>
                    <p className='disclaimer-text'>
                        Welcome to BillerQ! By accessing or using our SaaS-based subscriber management system, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
                    </p>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>1. Acceptance of Terms</h4>
                        <p className='disclaimer-text'>
                            By using BillerQ, you agree to these Terms and Conditions, which constitute a binding agreement between you and Quadleo Techno Solutions Pvt Ltd. If you do not agree to these terms, please do not use our services.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>2. Description of Service</h4>
                        <p className='disclaimer-text'>
                            BillerQ is a cloud-based SaaS (Software as a Service) platform that provides subscriber and subscription management solutions. Our services include but are not limited to billing, customer management, package management, and automated notifications.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>3. Free Signup and Trial Period</h4>
                        <p className='disclaimer-text'>
                            Signup: Signing up for BillerQ is free of cost.
                            Trial Period: We offer a 14-days free trial period for all new users. During this period, you can explore and utilize all features of BillerQ without any charge.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>4. Billing and Payments</h4>
                        <p className='disclaimer-text'>
                            Initial Setup Cost: A one-time setup fee is required to configure and personalize BillerQ for your needs.
                        </p>
                        <p className='disclaimer-text'>
                            Monthly Postpaid Billing: After the trial period, you will be billed monthly on a postpaid basis. Invoices will be generated at the end of each billing cycle.
                            Payment Method: Payments can be made via the methods specified in your account settings. All fees are non-refundable once incurred.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>5. Cancellation and Termination</h4>
                        <p className='disclaimer-text'>
                            Trial Period Cancellation: You can cancel your account at any time during the trial period without incurring any charges.
                        </p>
                        <p className='disclaimer-text'>
                            Post-Trial Cancellation: To cancel your subscription after the trial period, you must notify us before the end of the current billing cycle to avoid charges for the next month. Contact <span style={{ fontWeight: '600' }}>support@billerq.com</span> to initiate cancellation.
                        </p>
                        <p className='disclaimer-text'>
                            Termination by Us: We reserve the right to suspend or terminate your account if you violate these terms or engage in any activity that may harm BillerQ or its users.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>6.User Responsibilities</h4>
                        <p className='disclaimer-text'>
                            Account Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                        </p>
                        <p className='disclaimer-text'>
                            Compliance: You agree to use BillerQ in compliance with all applicable laws and regulations.
                        </p>
                        <p className='disclaimer-text'>
                            Prohibited Activities: You agree not to use BillerQ for any unlawful purposes or to engage in any activity that could damage, disable, or impair the functionality of our services.
                        </p>

                    </div>
                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>7. Data Privacy</h4>
                        <p className='disclaimer-text'>
                            Data Collection: We collect and use your data in accordance with our Privacy Policy. By using BillerQ, you consent to such data collection and use.
                        </p>
                        <p className='disclaimer-text'>
                            Data Security: We implement reasonable security measures to protect your data, but we cannot guarantee absolute security. You are responsible for securing your own devices and data.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>8. Intellectual Property</h4>
                        <p className='disclaimer-text'>
                            Ownership: All content, trademarks, and other intellectual property associated with BillerQ are owned by Quadleo Techno Solutions Pvt Ltd.
                        </p>
                        <p className='disclaimer-text'>
                            License: We grant you a limited, non-exclusive, non-transferable license to use BillerQ in accordance with these terms.
                        </p>
                    </div>
                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>9. Limitation of Liability</h4>
                        <p className='disclaimer-text'>No Warranty: BillerQ is provided "as is" without any warranties, express or implied. We do not guarantee that the service will be uninterrupted or error-free.</p>
                        <p className='disclaimer-text'>Liability Cap: Our liability for any claims arising out of or related to your use of BillerQ is limited to the amount you have paid us in the last 1 month.</p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>10. Indemnification</h4>
                        <p className='disclaimer-text'>
                            You agree to indemnify and hold harmless Quadleo Techno Solutions Pvt Ltd and its affiliates from any claims, damages, or expenses arising out of your use of BillerQ or your violation of these terms.
                        </p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>11. Changes to Terms</h4>
                        <p className='disclaimer-text'>We may update these terms from time to time. We will notify you of any significant changes by posting the new terms on our website and/or sending an email to your registered address. Continued use of BillerQ after such changes constitutes your acceptance of the new terms.</p>
                    </div>

                    <div className='user-agreement-section'>
                        <h4 className='sub-heading'>12. Governing Law</h4>
                        <p className='disclaimer-text'>These terms are governed by and construed in accordance with the laws of INDIA. Any disputes arising out of or related to these terms will be subject to the exclusive jurisdiction of the courts of Calicut/Kozhikode.</p>
                    </div>

                    <div className='user-agreement-section'>
                        <h2 className='sub-heading'>Contact Information</h2>
                        <p className='disclaimer-text'>If you have any questions about these terms, please contact us at <span style={{ fontWeight: '600' }}>info@billerq.com</span>.<br />
                            Thank you for choosing BillerQ!</p>
                    </div>

                </div>
            </div>
            <div className='gap'></div>
            <Contact />
            <Footer />
        </div>
    );
}

export default TermsCondition;

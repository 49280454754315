import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import UserAgentListContainer from "./UserAgentList";

const UserAgent = () => {
    return (
        <Fragment>
            <UserAgentListContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default UserAgent;
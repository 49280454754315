import React from "react";
import Formwizard2 from "../../Components/Forms/FormLayout/FormWizard2";
import { TypeaheadProvider } from "../../Components/Context/TypaheadContext";

function CompanySection() {
  return (
    <>
      <TypeaheadProvider>
        <Formwizard2 />
      </TypeaheadProvider>
    </>
  );
}

export default CompanySection;

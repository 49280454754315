import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import WalletListContainer from "./WalletList";

const Wallet = () => {
    return (
        <Fragment>
            <WalletListContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default Wallet;
import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import CustomContext from '../../../../_helper/Customizer';
import { useSelector } from 'react-redux';

const ListOfMenu = ({ searchToggle, searchBar, searchValue, removeFix, loading, searchResult }) => {
    const { IsOpen } = useContext(CustomContext);
    const authState = useSelector(state => state.auth);

    const handleClick = (removeFix) => {
        removeFix(); // Clear the search value when a link is clicked
    };
    
    return (
        <Fragment>
            <div className={`${searchBar ? `Typeahead-menu custom-scrollbar ${searchToggle ? 'is-open' : ''}` : `filled-bookmark Typeahead-menu ${IsOpen ? ' is-open' : ''} custom-scrollbar`} `} id="search-outer">
                {searchValue &&
                    (searchResult.length > 0 ? (
                        searchResult.map((data, index) => (
                            <div className="ProfileCard u-cf" key={index}>
                                <div className="ProfileCard-details">
                                    <div className="ProfileCard-realName">
                                        {authState.userRole === 1 || authState.permissions.includes('show-customer-profile') ?
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${data.id}`}
                                                className="realname"
                                                onClick={() => handleClick(removeFix)}
                                            >
                                                {data.customer_name}
                                            </Link> :
                                            data.customer_name
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="not-found-message">{loading ? <div className='search-bar-loader'></div> : "Not found"}</div>
                    ))}
            </div>
        </Fragment>
    );
};

export default ListOfMenu;
import React, { Fragment, useState } from "react";
import { ArrowRight } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { H1, Image, P } from "../../../AbstractElements";
import { AddCustomer, AddPackage, AddStbText, ContinueBilling, DefaultdashDiscription, GenerateRecurring, Welcome } from "../../../Constant";
import greeting from "../../../assets/images/Greetings/image 1.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { recurringApiConnection, recurringApiConnectionRole } from "../../../CommonUrl";
import { postClientUrlWithToken } from "../../../Utils/restUtils";
import SpinnerLoader from "../../../Layout/SpinnerLoader";


const ProfileGreeting = ({ companyName, checks, dataRecurring }) => {
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const recurringApi = async () => {
        setLoading(true);
        const postRecurringApi = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? recurringApiConnection : recurringApiConnectionRole);
        if (postRecurringApi.status === 200) {
            if (postRecurringApi.data.status === true) {
                toast.success(postRecurringApi.data.message);
            } else {
                toast.error(postRecurringApi.data.message);
            }
        } else if (postRecurringApi.status >= 400 && postRecurringApi.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (postRecurringApi.status >= 500 && postRecurringApi.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }

        setLoading(false);
    }
    return (
        <Fragment>
            <Col lg={5}>
                {loading &&
                    <SpinnerLoader />
                }
                <Card className="profile-greeting">
                    <CardBody>
                        <>
                            <div>
                                <H1>{Welcome} {companyName}</H1>
                                <P>{DefaultdashDiscription}</P>
                                {checks?.customers === 0 &&
                                    <Link to='/customers/customer-add' className="btn">
                                        {AddCustomer}<ArrowRight />
                                    </Link>
                                }
                                {checks?.customers !== 0 && checks?.stb === 0 &&
                                    <Link to='/customers/stb-modem-add' className="btn">
                                        {AddStbText}<ArrowRight />
                                    </Link>
                                }
                                {checks?.customers !== 0 && checks?.stb !== 0 && checks?.packages === 0 &&
                                    <Link to='/Services/packages-add' className="btn">
                                        {AddPackage}<ArrowRight />
                                    </Link>
                                }
                                {checks?.customers !== 0 && checks?.stb !== 0 && checks?.packages !== 0 &&
                                    <div>
                                        <Link to='/billing/invoice' className="btn">
                                            {ContinueBilling}<ArrowRight />
                                        </Link>
                                        {dataRecurring > 0 &&
                                            <div className="btn mt-3" onClick={() => recurringApi()}>
                                                {GenerateRecurring} ({dataRecurring})<ArrowRight />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="greeting-img" >
                                <Image attrImage={{ className: "img-fluid", src: greeting, alt: "" }} />
                            </div >
                        </>



                    </CardBody>
                </Card>
            </Col>
        </Fragment >
    );
};

export default ProfileGreeting;
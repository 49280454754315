import { Home, Users, DollarSign, Server, UserCheck, Bookmark, AlertCircle, Settings, Archive, Target, Activity, TrendingUp } from 'react-feather';
import { useSelector } from 'react-redux';

export const generateMenuItems = () => {
    const authState = useSelector((state) => state.auth);
    return [
        {
            menutitle: 'General',
            Items: [
                {
                    title: 'Dashboard', path: `${process.env.PUBLIC_URL}/dashboard/default`, icon: Home, type: 'link', active: false,
                },
                (authState.userRole === 1 ||
                    authState.permissions.includes('show-customer') ||
                    authState.permissions.includes('show-stb') ||
                    authState.permissions.includes('show-wallet')) &&
                ({

                    title: 'Customers', icon: Users, type: 'sub', active: false, children: [
                        (authState.userRole === 1 || authState.permissions.includes('show-customer')) && (
                            { path: `${process.env.PUBLIC_URL}/customers/customer`, title: 'Customer', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('show-stb')) && (
                            { path: `${process.env.PUBLIC_URL}/customers/stb-modem`, title: 'STB/Modem', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('show-wallet')) && (
                            { path: `${process.env.PUBLIC_URL}/customers/wallet`, title: 'Wallet', type: 'link' }
                        ),
                    ]

                }),
                (authState.userRole === 1 ||
                    authState.permissions.includes('show-subscription') ||
                    authState.permissions.includes('activate-subscription') ||
                    authState.permissions.includes('show-invoice')) &&
                ({
                    title: 'Billing', icon: DollarSign, type: 'sub', active: false, children: [
                        (authState.userRole === 1 || authState.permissions.includes('show-subscription')) && (
                            { path: `${process.env.PUBLIC_URL}/billing/subscription`, title: 'Subscription', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('activate-subscription')) && (
                            { path: `${process.env.PUBLIC_URL}/billing/activate-subscription`, title: 'Activate Subscription', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('show-invoice')) && (
                            { path: `${process.env.PUBLIC_URL}/billing/invoice`, title: 'Invoice', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('show-invoice-cancel')) && (
                            { path: `${process.env.PUBLIC_URL}/billing/invoice-cancel`, title: 'Cancelled Invoice', type: 'link' }
                        )
                    ]
                }),
                (authState.userRole === 1 ||
                    authState.permissions.includes('show-package') ||
                    authState.permissions.includes('show-add-on') ||
                    authState.permissions.includes('show-item')) &&
                ({
                    title: 'Services/Products', icon: Server, type: 'sub', active: false, children: [
                        (authState.userRole === 1 || authState.permissions.includes('show-package')) && (
                            { path: `${process.env.PUBLIC_URL}/Services/packages`, title: 'Packages', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('show-add-on')) && (
                            { path: `${process.env.PUBLIC_URL}/Services/addon`, title: 'Addons', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('show-item')) && (
                            { path: `${process.env.PUBLIC_URL}/Services/item`, title: 'Items', type: 'link' }
                        )
                    ]
                }),
                (authState.userRole === 1 ||
                    authState.permissions.includes('show-expense') ||
                    authState.permissions.includes('show-income') ||
                    authState.permissions.includes('show-header') ||
                    authState.permissions.includes('show-vendor')) &&
                ({
                    title: 'Expenses & Income', icon: TrendingUp, type: 'sub', active: false, children: [
                        (authState.userRole === 1 || authState.permissions.includes('show-expense')) && (
                            { path: `${process.env.PUBLIC_URL}/Expenses/Expenses`, title: 'Expense', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('show-income')) && (
                            { path: `${process.env.PUBLIC_URL}/Expenses/Income`, title: 'income', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('show-header')) && (
                            { path: `${process.env.PUBLIC_URL}/Expenses/Header`, title: 'Header', type: 'link' }
                        ),
                        (authState.userRole === 1 || authState.permissions.includes('show-vendor')) && (
                            { path: `${process.env.PUBLIC_URL}/Expenses/Vendor`, title: 'Vendor', type: 'link' }
                        ),
                    ]
                }),

                authState.userRole === 1 &&
                {
                    title: 'Banking', icon: Archive, type: 'sub', active: false, children: [
                        { path: `${process.env.PUBLIC_URL}/banking/account`, title: 'Account', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/banking/transaction`, title: 'Transaction', type: 'link' },
                    ]
                },
                authState.userRole === 1 &&
                {
                    title: 'Reports', icon: Bookmark, type: 'sub', active: false, children: [
                        { path: `${process.env.PUBLIC_URL}/report/online-payment`, title: 'Online Payment', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/report/customer-payment`, title: 'Customer Payment', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/report/payment-collection`, title: 'Payment Collection', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/report/unpaid-customer`, title: 'Unpaid Customer', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/report/payment-due`, title: 'Payment Due', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/report/subscription-summary`, title: 'Subscription Summary', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/report/package-summary`, title: 'Package Summary', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/report/addon-summary`, title: 'Addon Summary', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/report/tax-report`, title: 'Tax Report', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/report/wallet-balance`, title: 'Wallet Balance', type: 'link' },
                        // { path: `${process.env.PUBLIC_URL}/report/income-summary`, title: 'Income Summary', type: 'link' },
                        // { path: `${process.env.PUBLIC_URL}/report/income-vs-expence`, title: 'Income Vs Expense', type: 'link' },
                        // { path: `${process.env.PUBLIC_URL}/report/expense-summary`, title: 'Expense Summary', type: 'link' },
                    ]
                },

                (authState.userRole === 1 || authState.permissions.includes('show-complaint')) && (
                    { title: 'Complaints', path: `${process.env.PUBLIC_URL}/complaints`, icon: AlertCircle, type: 'link' }
                ),

                authState.userRole === 1 &&
                {
                    title: 'Users', icon: UserCheck, type: 'sub', active: false, children: [
                        { path: `${process.env.PUBLIC_URL}/menu/user`, title: 'Users/Staff', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/menu/role`, title: 'Role', type: 'link' },
                    ]
                },

                (authState.userRole === 1 ||
                    authState.permissions.includes('show-area') ||
                    authState.permissions.includes('show-category')) &&
                (
                    {
                        title: 'Settings', icon: Settings, type: 'sub', active: false, children: [
                            (authState.userRole === 1 || authState.permissions.includes('show-area')) && (
                                { path: `${process.env.PUBLIC_URL}/settings/area`, title: 'Area', type: 'link' }
                            ),
                            authState.userRole === 1 &&
                            { path: `${process.env.PUBLIC_URL}/settings/casProvider`, title: 'Cas Provider', type: 'link' },
                            authState.userRole === 1 &&
                            { path: `${process.env.PUBLIC_URL}/settings/payment`, title: 'Payment Method', type: 'link' },
                            (authState.userRole === 1 || authState.permissions.includes('show-category')) && (
                                { path: `${process.env.PUBLIC_URL}/settings/categories`, title: 'Categories', type: 'link' }
                            ),
                            authState.userRole === 1 &&
                            { path: `${process.env.PUBLIC_URL}/settings/tax-group`, title: 'Tax Class', type: 'link' },
                        ]
                    }),
            ]
        },
    ]
}
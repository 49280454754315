import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OTPInput, { ResendOTP } from 'otp-input-react';

import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H2, H3, P, Image } from '../../../AbstractElements';
import { AlreadyhavePassword, ChnageEmail, ConfirmOtp, CreatenewPassword, EnterEmailAddrs, EnterOTP, Ifdontrecieveotp, NewPassword, Resend, ResetPassword, RetypePassword, Sendotp, SignIn, Submit } from '../../../Constant';
import { postDataWithoutResData } from '../../../Utils/restUtils';
import { Createnewpassword, GetemailOtp, verifyemailOtp } from '../../../CommonUrl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Transparentspinner from '../../Common/Loader/Transparentspinner';


const ForgetPwd = () => {
    const [spinnerTrans, setspinnerTrans] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [OTP, setOTP] = useState("");
    const [error, setError] = useState('');
    const [loading, setloading] = useState(false)
    const [step, setstep] = useState(1);
    const { register, handleSubmit, watch, getValues, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const watchPassword = watch('password');
    const [timer, setTimer] = useState(60);
    const [otpSent, setOtpSent] = useState(false);




    useEffect(() => {
        let intervalId;

        if (otpSent && timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [otpSent, timer]);


    const handleChangeEmail = async (e) => {
        setstep(1)
    }

    const handleEmail = async (data) => {
        setspinnerTrans(true)
        const postRequest = {
            email: getValues("email")
        };

        const responce = await postDataWithoutResData(GetemailOtp, postRequest)
        if (responce.status === 200) {
            if (responce.data.status == true) {
                setOtpSent(true);
                setTimer(60);
                setstep(2);
                toast.success(responce.data.message);
                setspinnerTrans(false)

            } else {
                setspinnerTrans(false)
                toast.error(responce.data.message);
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }

        setspinnerTrans(false)
    }

    const handleOTPsubmit = async () => {
        setspinnerTrans(true)
        if (OTP) {
            const postRequest = {
                email: getValues("email"),
                otp: OTP,
            }

            const responce = await postDataWithoutResData(verifyemailOtp, postRequest)

            if (responce.status == 200) {
                if (responce.data.status == true) {
                    setValue("OtpAccesssNumber", responce.data.data);
                    toast.success(responce.data.message);
                    setstep(3)
                    setspinnerTrans(false)
                } else {

                    toast.error(responce.data.message);
                    setspinnerTrans(false)

                }
            } else if (data.status >= 400 && data.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (data.status >= 500 && data.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }

        }

    }

    const handleSubmitPwd = async (data) => {
        setspinnerTrans(true)
        const postRequest = {
            email: getValues("email"),
            otp: getValues("OtpAccesssNumber"),
            new_password: data.password,
            confirm_password: data.password_repeat,
        }

        const responce = await postDataWithoutResData(Createnewpassword, postRequest)

        if (responce.status == 200) {
            if (responce.data.status == true) {
                setValue("OtpAccesssNumber", responce.data.data);
                toast.success(responce.data.message);
                navigate(`${process.env.PUBLIC_URL}/SignIn`);
                setspinnerTrans(false)
            }
            else {
                toast.error(responce.data.message);
                setspinnerTrans(false)
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }

    }


    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}

            <Container className='p-0' fluid={true}>
                <Row>
                    <Col className="">
                        <div className='login-card'>
                            <div>
                                <div className='logo'>
                                    <Image attrImage={{ className: 'img-fluid for-light', src: `${require('../../../assets/images/logo/logo.png')}` }} />
                                </div>

                                <div className='login-main'>
                                    {step === 1 && (
                                        <Form className="theme-form" onSubmit={handleSubmit(handleEmail)}>
                                            <H2 attrH2={{ className: 'text-start' }}>{ResetPassword}</H2>
                                            <FormGroup className="form-group">
                                                <Label className='col-form-label'>{EnterEmailAddrs}</Label>
                                                <Row>
                                                    <Col xs="12" sm="12">
                                                        <input className="form-control"
                                                            type="email"
                                                            id="email"
                                                            name="email"
                                                            placeholder='Email address'
                                                            {...register("email", { required: true })}

                                                            required
                                                        />
                                                        <span className="text-danger">
                                                            {errors.email && errors.email.message}
                                                        </span>

                                                    </Col>
                                                    <Col xs='12'>
                                                        <div className='text-end'>
                                                            <Btn attrBtn={{ color: 'primary', className: 'btn btn-block w-100 mt-3', type: 'submit', disabled: loading }} >{Sendotp}</Btn>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <P attrPara={{ className: 'mt-4 mb-0 text-center' }}>{AlreadyhavePassword}
                                                <Link className='ms-2 text-underline' to={`${process.env.PUBLIC_URL}/SignIn`}>{SignIn}</Link>
                                            </P>

                                        </Form>
                                    )}

                                    {step === 2 && (
                                        <>
                                            <FormGroup className="form-group">
                                                <Form onSubmit={handleSubmit(handleOTPsubmit)}>
                                                    <Row xs={12}>
                                                        <Col>
                                                            <H2 attrH2={{ className: 'text-start mt-3 mb-3' }}>{EnterOTP}</H2>
                                                            <Col >
                                                                <div className='otp-input'>
                                                                    <OTPInput
                                                                        value={OTP}
                                                                        onChange={setOTP}
                                                                        autoFocus
                                                                        OTPLength={6}
                                                                        otpType="number"
                                                                        disabled={false}
                                                                        inputStyles={{ borderRadius: '5px',border:'solid 1.5px #74737380', height: '40px', width: '30px' }}
                                                                        style={{ display: 'flex', justifyContent: 'center', paddingTop: '3px', paddingBottom: '10px', marginLeft: '20px' }}
                                                                    />
                                                                    {error && <span className="text-danger">{error}</span>}
                                                                    <ResendOTP onResendClick={handleEmail} className='resendOtpClass' />
                                                                </div>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <FormGroup className=''>
                                                        <div className='d-flex  justify-content-between'>
                                                            <div>

                                                                {/* <span className="reset-password-link">{Ifdontrecieveotp}
                                                                </span> */}
                                                                <span className='cursor btn-link' onClick={handleChangeEmail}>{ChnageEmail}</span>
                                                                {/* <button className="btn p-0 m-0 text-underline border-0" onClick={handleEmail} disabled={timer > 0}>  {Resend}</button> */}
                                                            </div>
                                                            <div>

                                                                {/* <span className="text-muted" > 00 : {timer}</span> */}
                                                            </div>
                                                        </div>
                                                        <div className=""  >
                                                        </div>
                                                        <div className=''>
                                                            <Btn attrBtn={{ color: 'primary', className: 'btn btn-block w-100 mt-3', type: 'submit', disabled: loading }} >{ConfirmOtp}</Btn>

                                                        </div>
                                                    </FormGroup>
                                                </Form>

                                            </FormGroup>
                                        </>
                                    )}
                                    {step === 3 && (
                                        <>
                                            <Form onSubmit={handleSubmit(handleSubmitPwd)}>
                                                <Row>
                                                    <H3 attrH3={{ className: 'mt-4' }}>{CreatenewPassword}</H3>
                                                    <FormGroup className='form-group'>
                                                        <Label className="col-form-label">{NewPassword}</Label>
                                                        <div className='form-input position-relative'>
                                                            <input className="form-control" type={togglePassword ? 'text' : 'password'} placeholder='*********'
                                                                {...register('password')} required />
                                                            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                                                <span className={togglePassword ? '' : 'show'}></span>
                                                            </div>
                                                            {watchPassword && watchPassword.length < 8 ? (
                                                                <span className="text-danger">password must be 8 charaters</span>) : null

                                                            }
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup className="form-group">
                                                        <Label className="col-form-label">{RetypePassword}</Label>
                                                        <input className="form-control" type="password"
                                                            {...register('password_repeat')}
                                                            placeholder="*********" required />
                                                        {watch("password_repeat") !== watch("password") &&
                                                            getValues("password_repeat") ? (
                                                            <span className="text-danger">password not match</span>
                                                        ) : null}
                                                        <p className="text-danger">{errors.password_repeat?.message}</p>
                                                    </FormGroup>
                                                    <FormGroup className="form-group">
                                                        {/* <div className="checkbox">
                                                        <Input id="checkbox1" type="checkbox" />
                                                        <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                                                    </div> */}
                                                        <Btn attrBtn={{ color: 'primary', className: 'btn btn-block w-100 mt-3', type: 'submit', disabled: loading }} >{Submit}</Btn>
                                                    </FormGroup>
                                                    <P attrPara={{ className: 'mt-4 mb-0 text-center' }}>{AlreadyhavePassword}
                                                        <Link className='ms-2  text-center' to={`${process.env.PUBLIC_URL}/SignIn`}>{SignIn}</Link>
                                                    </P>
                                                </Row>
                                            </Form>
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default ForgetPwd;
import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import PaymentMethodContainer from "./PaymentsMethodList";

const PaymentsMethod = () => {
    return (
        <Fragment>
            <PaymentMethodContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default PaymentsMethod;
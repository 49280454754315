import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Form, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, H6, H2, Btn } from '../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { Add, PaymentCollectionRep, PaymentCollectionList, Export, Search, Filter, Area, Account, Role, UserAgent, Agent } from '../../Constant';
import SearchList from '../../CommonElements/Search';
import { getCollectionReport, getCollectionReportRole, getArea, getAccount, getAreaRole, getAccountRole, getUsers, getUsersRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { PaymentCollectionColumns } from '../../Data/MockTable/ReportTableHeader';
import { EarningsSvg } from '../../Data/Widgets/General/Data';
import Progress from '../Common/Progress';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const PaymentCollectionReport = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const authState = useSelector((state) => state.auth);
    const [customerLists, setCustomerList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalsum, settotalsum] = useState("0");
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [apiArea, setapiArea] = useState([]);
    const [apiAccount, setapiAccount] = useState([]);
    const [apiRole, setapiRole] = useState([]);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("day");


    const StaticWidgetsData = [
        {
            id: 1,
            title: 'Paid',
            counter: totalsum,
            svg: <EarningsSvg />,
            class: 'progress-gradient-secondary',
            progress: '75%'
        },
    ]
    useEffect(() => {
        fetchCollectionRep(1);
        areaData()
        accountData()
        roleData()
    }, []);

    const handlePageChange = page => {
        fetchCollectionRep(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchCollectionRep(page, newPerPage);
        setPerPage(newPerPage);
    };

    const areaData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
        if (data.status === true) {
            setapiArea(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const accountData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getAccount : getAccountRole);
        if (data.status === true) {
            setapiAccount(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };
    const roleData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getUsers : getUsersRole);
        if (data.status === true) {
            setapiRole(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const onSubmit = async (data) => {
        let accountId = data.account;
        let areaId = data.area;
        let users_id = data.users;
        let search = data.search;

        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setSearchKeyword({ searches: data.search, area: areaId, start: start_date, end: end_date, account: accountId, usersId: users_id });
        fetchCollectionRep(1, perPage, search, start_date, end_date, areaId, accountId, users_id);
    };



    const fetchCollectionRep = async (page, size = perPage, search = '', startDate = '', endDate = '', areaId = '', accountId = '', usersId = '') => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCollectionReport +
            `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&area_id=${areaId}&account_id=${accountId}&user_id=${usersId}` :
            getCollectionReportRole+`?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&area_id=${areaId}&account_id=${accountId}&user_id=${usersId}`
        );
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            settotalsum(response.data.total_amount);
            const valuePackages = response.data.payments.data
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(Object.keys(valuePackages).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    InvoiceNo: valuePackages[e].invoice_no,
                    Name: valuePackages[e].customer_name,
                    Address: valuePackages[e].billing_address,
                    Area: valuePackages[e].area_name,
                    AmountCollected: authState.currency + valuePackages[e].collected_amount,
                    PaidDate: valuePackages[e].paid_date,
                    txnDate: valuePackages[e].transaction_id,
                    id: valuePackages[e].order_id,
                    CollectedBy: valuePackages[e].collected_by,
                    customer_id: valuePackages[e].customer_id,
                    Account: valuePackages[e].account_name,
                    Sub: valuePackages[e].subscriber_id,
                }
            }
            ))
            setTotalRows(response.data.total);

        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false)
    };

    const exportCollectionExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCollectionReport +
            `?export=agent_collection_data&search=${searchKeyword?.searches ?? ''}&area_id=${searchKeyword?.area ?? ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&user_id=${searchKeyword?.usersId ?? ''}` :
            getCollectionReportRole + `?export=agent_collection_data&search=${searchKeyword?.searches ?? ''}&area_id=${searchKeyword?.area ?? ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&user_id=${searchKeyword?.usersId ?? ''}`);
        const dataToExport = response.data.payments.map(({ invoice_no, customer_name, billing_address, area_name, collected_amount, paid_date, collected_by, account_name }, index) => ({ Sl: index + 1, Invoice_No: invoice_no, Name: customer_name, Address: billing_address, Area: area_name, Amount_Collected: authState.currency + collected_amount, Paid_Date: paid_date, Collected_By: collected_by, Account_Name: account_name }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'agent collection Data');
            XLSX.writeFile(wb, 'agent_collection-data.xlsx');
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    const exportCollectionPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCollectionReport +
            `?export=agent_collection_data&search=${searchKeyword?.searches ?? ''}&area_id=${searchKeyword?.area ?? ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&user_id=${searchKeyword?.usersId ?? ''}` :
            getCollectionReportRole + `?export=agent_collection_data&search=${searchKeyword?.searches ?? ''}&area_id=${searchKeyword?.area ?? ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}&user_id=${searchKeyword?.usersId ?? ''}`);
        const dataToExport = response.data.payments.map(({ invoice_no, customer_name, billing_address, area_name, collected_amount, paid_date, collected_by, account_name }, index) => ({ invoice_no, customer_name, billing_address, area_name, collected_amount, paid_date, collected_by, account_name }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Invoice No", "Name", "Address", "Area", "Amount Collected", "Paid Date", "Collected By", "Account Name"];
            const rows = dataToExport.map(({ invoice_no, customer_name, billing_address, area_name, collected_amount, paid_date, collected_by, account_name }, index) => [index + 1, invoice_no, customer_name, billing_address, area_name, collected_amount, paid_date, collected_by, account_name]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("agent_collection_data.pdf");
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={PaymentCollectionRep} parent="Reports" title={PaymentCollectionRep} />
            <Progress details={StaticWidgetsData} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{PaymentCollectionList}</H3>
                    <div>
                        <CardBody className="dropdown-basic">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportCollectionExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportCollectionPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element" >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'

                                />
                            </div>

                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select" {...register('area')}>
                                <option value="">{Area}</option>
                                {apiArea.map((values) => (
                                    <option key={values.id} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('account')}>
                                <option value="">{Account}</option>
                                {apiAccount.map((values) => (
                                    <option key={values.id} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('users')}>
                                <option value="">User</option>
                                {apiRole.map((values) => (
                                    <option key={values.user_id} value={values.user_id}>{values.user_name}</option>
                                ))}
                            </select>
                        </Col>


                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <Fragment>
                </Fragment>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={PaymentCollectionColumns}
                            data={customerLists}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default PaymentCollectionReport;
import React, { Fragment, useEffect } from 'react';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { AdminLogin } from '../../CommonUrl';
import { postDataWithoutResData } from '../../Utils/restUtils';
import * as authServices from "../../Utils/authServices";
import { useDispatch } from 'react-redux';
import { SetUserName, setApiUrl, setUserRole, signIn, setCurrency, setPermissions, setSubscription, setIndustryId, setCompanyName, setRole, setSubscriptionDetails, setDecimal, setSubStatus } from '../../Redux/Reducer';

function Loading() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("user_id");
    const param2 = params.get("token");
    useEffect(() => {
        logInData();
    }, []);

    const logInData = async () => {
        const loginResponse = await postDataWithoutResData(AdminLogin, {
            user_id: param1, otp: param2
        });
        if (loginResponse.data.status === true) {
            const { token, client_role, client_role_id, url, company, company_name, active_company, industry_type_id, master_subscription, package_name, package_expire_in, user_name, currency_symbol, permissions, master_token, decimal } = loginResponse.data.data;
            const packagetDetails = { packageName: package_name, packageExp: package_expire_in }
            authServices.setUser(token, client_role_id, master_token, master_subscription?.status);
            dispatch(setApiUrl(url));
            dispatch(signIn(company));
            dispatch(setCompanyName(company_name));
            dispatch(setIndustryId(industry_type_id));
            dispatch(setSubStatus(master_subscription))
            dispatch(setSubscription(master_subscription));
            dispatch(setSubscriptionDetails(packagetDetails));
            dispatch(setUserRole(client_role_id));
            dispatch(setRole(client_role));
            dispatch(SetUserName(user_name));
            dispatch(setCurrency(currency_symbol));
            dispatch(setPermissions(permissions));
            dispatch(setDecimal(decimal));
            toast.success(loginResponse.data.message);
            if (client_role_id === 1) {
                if (active_company === 0) {
                    navigate('/addCompany');
                } else if (master_subscription?.status === 'none' && master_subscription.company_type === 'multi') {
                    navigate('/subscriptionAddition', { state: { id: active_company } });
                } else if (master_subscription?.status === 'none' || (master_subscription?.status === 'Pending' && master_subscription?.plan_type === 'free')) {
                    navigate('/subscription', { state: { id: active_company } });
                } else if (master_subscription?.status === 'Pending' && master_subscription?.plan_type === 'paid') {
                    const response = await getMasterDataToken(getShareApi + `?order_id=${master_subscription.invoice_id}`);
                    if (response.status === true) {
                        const toReciept = `${process.env.PUBLIC_URL}/invoice?order_id=${response.data}`;
                        navigate(toReciept);
                    }
                    else {
                        HandleHomePageError(navigate, data.status)
                    }
                } else {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
                }
            } else {
                navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
            }
        } else {
            toast.error(loginResponse.data.message);
            navigate(`${process.env.PUBLIC_URL}/login/pages/error/error-page3`);
        }
    };
    return (
        <Fragment>
            <Transparentspinner />
        </Fragment>
    )
}

export default Loading
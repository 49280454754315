import React, { Fragment } from 'react';
import { SubscriberId, Stb_no } from '../../../../Constant';
import {Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const InvoiceDescription = (props) => {
    const invoiceData = props.invoice;
    const authState = useSelector(state => state.auth)

    return (
        <Fragment>
            <div className='row' >
                <div className="col-6 text-start">{SubscriberId}:
                {authState.userRole === 1 || authState.permissions.includes('show-customer-profile') ? 
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${invoiceData.customer_id}`}>
                    {invoiceData.subscriber_id}
                    </Link> 
                    : invoiceData.subscriber_id
                    }
                </div>
                {invoiceData.stb_number && (    
                    <div className="col-6 text-end"> {Stb_no}: {invoiceData.stb_number}</div>
                )}
            </div>
            <hr style={{ border: "1px solid rgba(83, 70, 134, 0.1019607843) ", marginBottom: "20px" }} />
        </Fragment >
    );
};

export default InvoiceDescription;

import React from 'react'
import ErrorBlack from '../../../assets/images/error/ops_black.png'
import { Spinner } from '../../../AbstractElements'
const OpsErrorBlack = () => {
  return (
    <>
    <div className='d-flex justify-content-center align-items-center' style={{height:'100%'}}>
        {/* <img src={ErrorBlack} alt="error"  style={{height:'100px'}}/> */}
        <div className="loader-box">
          <Spinner attrSpinner={{ className: "loader-38" }} />
        </div>
    </div>
    </>
  )
}

export default OpsErrorBlack
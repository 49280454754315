import React, { Fragment } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H5, H6 } from "../../../AbstractElements";
import { CancelledSvg, DeliveredSvg, NewOrderSvg, PendingSvg, SVG20, SVG50, SVG68, SVG98 } from "../../../Data/Widgets/General/Data";
import CountUp from 'react-countup';
import HeaderCard from "../../Common/Component/HeaderCard";
import { ExpireWith, Expired, ExpiringToday, ExpiringTomorrow } from "../../../Constant";
import { Link } from "react-router-dom";

const SubscriptionDetails = (props) => {
    const value = props.details;
    const OrderDetailsData = [
        {
            id: 1,
            iconClass: 'bg-light-primary',
            svg1: <NewOrderSvg />,
            title: <Link className="dashboardTableLinks" to={`${process.env.PUBLIC_URL}/billing/subscription?expiry=expired`}>
                {'Expired'}
            </Link>,

            // title: Expired,
            count: value.expired,
            fontClass: 'font-primary',
            // icon: 'icofont-arrow-up',
            // percent: '68%',
            svg2: <SVG68 />
        },
        {
            id: 2,
            iconClass: 'bg-light-warning',
            svg1: <PendingSvg />,
            title: <Link className="dashboardTableLinks" to={`${process.env.PUBLIC_URL}/billing/subscription?expiry=expiring_today`}>
                {'Expire Today'}
            </Link>,
            // title: ExpiringToday,
            fontClass: 'font-warning',
            count: value.today,
            // icon: 'icofont-arrow-down',
            // percent: '12%',
            svg2: <SVG98 />
        },
        {
            id: 3,
            iconClass: 'bg-light-success',
            svg1: <DeliveredSvg />,
            title: <Link className="dashboardTableLinks" to={`${process.env.PUBLIC_URL}/billing/subscription?expiry=expiring_tomorrow`}>
                {'Expire Tomorrow'}
            </Link>,
            // title: ExpiringTomorrow,
            fontClass: 'font-success',
            count: value.tomorrow,
            // icon: 'icofont-arrow-up',
            // percent: '50%',
            svg2: <SVG50 />
        },
        {
            id: 4,
            iconClass: 'bg-light-danger',
            svg1: <CancelledSvg />,
            title: <Link className="dashboardTableLinks" to={`${process.env.PUBLIC_URL}/billing/subscription?expiry=expire_with`}>
                {'Expire Within 5 Days'}
            </Link>,
            // title: ExpireWith,
            fontClass: 'font-danger',
            count: value.in_five_days,
            // icon: 'icofont-arrow-up',
            // percent: '20%',
            svg2: <SVG20 />
        },
    ]

    return (
        <Fragment>
            <Col xl="6" className="box-col-6">
                <Card className="widget-joins">
                    <HeaderCard title={'Subscription Details'} setting={true} />
                    <CardBody>
                        <Row className="gy-4">
                            {OrderDetailsData.map((data) => {
                                return (
                                    <Col sm="6" key={data.id}>
                                        <div className="widget-card">
                                            <div className="d-flex align-self-center">
                                                <div className={`widget-icon ${data.iconClass}`}>
                                                    {data.svg1}
                                                </div>
                                                <div className="flex-grow-1">
                                                    <H6>{data.title}</H6>
                                                    <H5> {data.title === 'Delivered Order' && '$'}
                                                        <span className="counter"><CountUp end={data.count} duration={5} className="m-0" /></span>
                                                        <span className={`font-roboto ${data.fontClass} f-w-700`}>
                                                            <i className={`icofont ${data.icon}`}></i> {data.percent}</span></H5>
                                                    <div className="icon-bg">
                                                        {data.svg2}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};

export default SubscriptionDetails;
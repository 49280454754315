export const SetLanguage = [
    { "value": "en", "label": "English" },
    { "value": "es", "label": "Spanish" },
    { "value": "fr", "label": "French" },
    { "value": "de", "label": "German" },
    { "value": "zh", "label": "Chinese" },
    { "value": "ja", "label": "Japanese" },
    { "value": "hi", "label": "Hindi" },
    { "value": "ar", "label": "Arabic" },
    { "value": "pt", "label": "Portuguese" },
    { "value": "ru", "label": "Russian" },
    { "value": "ko", "label": "Korean" },
    { "value": "it", "label": "Italian" },
    { "value": "tr", "label": "Turkish" },
    { "value": "nl", "label": "Dutch" },
    { "value": "sv", "label": "Swedish" },
    { "value": "fi", "label": "Finnish" },
    { "value": "da", "label": "Danish" },
    { "value": "no", "label": "Norwegian" },
    { "value": "pl", "label": "Polish" },
    { "value": "hu", "label": "Hungarian" },
    { "value": "cs", "label": "Czech" },
    { "value": "ro", "label": "Romanian" },
    { "value": "el", "label": "Greek" },
    { "value": "th", "label": "Thai" },
    { "value": "id", "label": "Indonesian" },
    { "value": "vi", "label": "Vietnamese" },
    { "value": "he", "label": "Hebrew" },
    { "value": "fa", "label": "Persian" },
    { "value": "ms", "label": "Malay" },
    { "value": "sw", "label": "Swahili" },
    { "value": "bg", "label": "Bulgarian" },
    { "value": "hr", "label": "Croatian" },
    { "value": "sk", "label": "Slovak" },
    { "value": "sl", "label": "Slovenian" },
    { "value": "et", "label": "Estonian" },
    { "value": "lv", "label": "Latvian" },
    { "value": "lt", "label": "Lithuanian" },
    { "value": "mk", "label": "Macedonian" },
    { "value": "sq", "label": "Albanian" },
    { "value": "sr", "label": "Serbian" },
    { "value": "bs", "label": "Bosnian" },
    { "value": "mt", "label": "Maltese" },
    { "value": "af", "label": "Afrikaans" },
    { "value": "xh", "label": "Xhosa" },
    { "value": "zu", "label": "Zulu" },
    { "value": "sw", "label": "Swahili" },
    { "value": "yo", "label": "Yoruba" },
    { "value": "ig", "label": "Igbo" },
    { "value": "ha", "label": "Hausa" },
    { "value": "am", "label": "Amharic" },
    { "value": "tg", "label": "Tajik" }
  ]
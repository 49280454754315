import React from 'react';
import { Image } from '../../AbstractElements';
import darklogo from '../../assets/images/logo/logo-icon-black.svg'
import whitelogo from '../../assets/images/logo/logo-icon-white.svg'


const SidebarIcon = () => {

  return (
    <div className="logo-icon-wrapper">
        <a href="index.html">
          <Image attrImage={{ className: "img-fluid for-light" , src: darklogo, alt: "" }} />
          <Image attrImage={{ className: "img-fluid for-dark", src: whitelogo, alt: "" }} />
        </a>
    </div>  
  );
};
export default SidebarIcon;
import React, { Fragment, useState } from 'react';
import { Col, Card, Row } from 'reactstrap';
import { H4, H6 } from '../../../../AbstractElements';
import defaultUserImage from '../../../../assets/images/user-profile/default-users.png';
import ProStaticWidgets from './ProStaticWidget';

const MasterProfile = (props) => {
    const personalDetails = props.details;
    const personalSingleDetail = props.personalData;
    const [userImage, setUserImage] = useState(''); 
        const first_name = personalSingleDetail.first_name;
        const parseFirstName = first_name?.charAt(0).toUpperCase() + first_name?.slice(1);
   
    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setUserImage(e.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    return (
        <Fragment>
            <Col sm="12" md='12'>
                <Card className="profile-header bg-size">
                    <Row>
                        <Col xl='5' sm="12" lg="5" md="12">
                            <div className="profile-img-wrrap">
                                {/* <Image attrImage={{ className: 'img-fluid bg-img-cover', src: `${require('../../../assets/images/user-profile/default-user.png')}`, alt: '' }} /> */}
                            </div>
                            <div className="userpro-box">
                                <div className="img-wrapper">
                                    <div className="master-avatar">
                                    <img className="step1" alt="default-image" src={userImage || defaultUserImage} data-intro="This is Profile image" />

                                    </div>
                                    <div className="master-icon-wrapper">
                                        <label htmlFor="fileInput" style={{paddingTop:'7px'}}>
                                            <i 
                                            className="icofont icofont-plus-circle step2" 
                                            data-intro="Change Profile image here">
                                            </i>
                                        </label>
                                        <input type="file" id="fileInput" style={{ display: 'none' }} accept="image/*" onChange={handleFileSelect} />
                                    </div>
                                </div>
                                <div className="user-designation">
                                    <div className="title" style={{ paddingBottom: '30px' }}>
                                        <H4>{parseFirstName} {personalSingleDetail.last_name}</H4>
                                        <H6>{personalSingleDetail.email}</H6>
                                        </div>
                                    {/* <div className="follow">
                                        <UL attrUL={{ className: 'follow-list flex-row simple-list' }}>
                                            <LI>
                                                <div className="follow-num counter">{personalDetails.active_connections}</div><span>{Connection}</span>
                                            </LI>
                                            <LI>
                                                <div className="follow-num counter">{personalDetails.active_customers}</div><span>{Customer}</span>
                                            </LI>
                                            <LI>
                                                <div className="follow-num counter">{personalDetails.industry_accounts}</div><span>{Account}</span>
                                            </LI>
                                        </UL>
                                    </div> */}
                                </div>
                            </div>
                        </Col>
                        <Col xs='12' sm="12" lg="7" className='personalBusinessDetails'>
                            <Row className='profileCards'>
                                <ProStaticWidgets details={personalDetails} />
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Fragment>
    );
};

export default MasterProfile;
import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../Constant";
import AreaForm from "./AreaForm";

const AreaAdd = () => {
    return (
        <Fragment>
            <AreaForm mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default AreaAdd;
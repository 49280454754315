import React from 'react'
import { Col, Row } from 'reactstrap'
import QRCode from "react-qr-code";


const SubCard = React.forwardRef((props, ref) => {
    const customer1 = {
        customerId: '9876543210000000000',
        lcoNo: '#000005268',
        name: 'Aravind CK',
        address: 'Cherukath.H\nMayanad, Med. College\nKozhikode',
        joinedDate: '01 May 2024',
        qrCode: '/path-to-qr-code.png',
        services: ['Broadband']
    };

    return (
        <>
            <div className='row' ref={ref}>
                <Col md={6} sm={12}>
                    <div className='sub-card'>
                        {/* <Col md={12} xs={12}> */}
                        <div className='sub-card-left'>
                            <div className="card-header p-0 m-0  ">
                                <img src="/path-to-logo.png" alt="" className="logo" />
                                <span>Broadband 24</span>
                            </div>
                            <div className='d-flex '>
                                <div className="card-body p-0 m-0 ">
                                    <span className='text'>Customer ID</span>
                                    <h4>{customer1.customerId}</h4>
                                    <span className='text'>LCO No</span>
                                    <h4>{customer1.lcoNo}</h4>
                                </div>
                                <div className="qr-code">
                                    <QRCode size={90} value="acdefghijk" />
                                </div>
                            </div>
                        </div>
                        {/* </Col> */}
                    </div>
                </Col>
                <Col md={6} sm={12} >
                    <div className='sub-card'>
                        {/* <Col md={12} xs={12}> */}
                        <div className='sub-card-right d-flex'>
                            <div className="card-body p-0 m-0 ">
                                <span className='text'>Customer Name</span>
                                <h4>{customer1.name}</h4>
                                <span className='text'>Address</span>
                                <h4>{customer1.address}</h4>
                                <span className='text'>Joined Date</span>
                                <h4>{customer1.joinedDate}</h4>
                                <div>
                                    <img src="/path-to-logo.png" alt="" className="logo" />
                                </div>
                            </div>
                            <div className="service-type">
                                <h6>Service Type</h6>
                                <div>
                                    <div className='input-box d-flex'>
                                        <label>Broadband</label>
                                        <input type="checkbox" />
                                    </div>
                                    <div className='input-box'>
                                        <label>Cable TV</label>
                                        <input type="checkbox" />
                                    </div>
                                    <div className='input-box'>
                                        <label>OTT</label>
                                        <input type="checkbox" />
                                    </div >
                                    <div className='input-box'>
                                        <label> IPTV</label>
                                        <input type="checkbox" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </Col> */}
                    </div >
                </Col>
            </div>
        </>
    )
});

export default SubCard
import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Input } from "reactstrap";
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import {
  Address, Area, AreaName, BillingAddress, BillingName, BirthDate, Currency, Customer, CustomerType, EditCustomer, Email,
  FirstName, LastName, LcoNo, MobileNo, MobileNoError, Reference, RouteNo, Samasabove, Save, SubscriberId, TaxIn
} from "../../../Constant";
import { editCustomer, editCustomerRole, getArea, getAreaRole, singleCustomer, singleCustomerRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import {
  getClientUrlDataToken,
  postClientUrlWithToken,
} from "../../../Utils/restUtils";
import { currencyOptions } from "../../Common/Currency/CurrencyList";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from 'react-datepicker';
import moment from "moment";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
  address: yup.string().required().label('Address'),
  areaName: yup.mixed().required().label('Area Name'),
  sub: yup.string().required().label('Subscription id'),
  fname: yup.string().required().label('First Name'),
  email: yup.string().email('Invalid email format').label('Email is required'),
  password: yup.string().label('Password'),
  password_repeat: yup.string().label('Confirm Password'),
  billingName: yup.string().required().label('Billing Name'),
  billingAddr: yup.string().required().label('Billing Address'),
});

const CustomerEdit = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema)
  });
  const location = useLocation();
  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);
  const param1 = params.get("id");
  const authState = useSelector((state) => state.auth);
  const [areaOption, setAreaOption] = useState([]);
  const [birthDate, setBirthDate] = useState(null);
  const options = [
    { value: "general", label: "General" },
    { value: "privileged", label: "Privileged" },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    getSingleCustomerData();
  }, []);

  const handleChangeDate = date => {
    setBirthDate(date);
  };
  const { fname, lname, address } = watch();


  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const fullName = `${fname || ''} ${lname || ''}`;
      setValue('billingName', fullName);
      setValue('billingAddr', address || '');
    } else {
      setValue('billingName', '');
      setValue('billingAddr', '');
    }
  };


  const getSingleCustomerData = async () => {
    setLoading(true);
    let data = await getClientUrlDataToken(
      authState.apiUrl, authState.userRole === 1 ?
      singleCustomer + `?customer_id=${param1}` : singleCustomerRole + `?customer_id=${param1}`
    );
    if (data.status === true) {
      const capitalizedString =
        data.data.customer_type.charAt(0).toUpperCase() +
        data.data.customer_type.slice(1);
      setValue("sub", data.data.subscriber_id);
      setValue("fname", data.data.first_name);
      setValue("lname", data.data.last_name);
      setValue("email", data.data.email);
      setValue("phonenumber", data.data.country_code + data.data.mobile);
      setValue("countryCode", data.data.country_code);
      setValue("lcono", data.data.lco_no);
      setValue("billingName", data.data.billing_name);
      setValue("billingAddr", data.data.billing_address.replace(/"/g, ""));
      const date_of_birth = data.data.dob;
      if (date_of_birth) {
        setBirthDate(new Date(date_of_birth));
      }
      setValue("latitude", data.data.latitude);
      setValue("longitude", data.data.longitude);
      setValue("route", data.data.route_no);
      setValue("tax", data.data.tax_in);
      setValue("address", data.data?.address.replace(/"/g, "") || "");
      setValue("message", data.data.reference || "");
      setValue("customerType", {
        label: capitalizedString,
        value: data.data.customer_type,
      });
      setLoading(false);

    } else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }

    let response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
    if (data.status === true) {
      const options = response.data.map((options) => ({
        value: options.id,
        label: options.name,
      }));
      setAreaOption(options);

      const selectedAreaOption = options.find(
        (option) => option.value === data.data.area_id
      );

      setValue("areaName", {
        value: selectedAreaOption?.value,
        label: selectedAreaOption?.label,
      });
    } else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }

  };

  const onSubmit = async (data) => {
    const countryCode = data.countryCode;
    const phoneNumber = data.phonenumber;
    if (phoneNumber.startsWith(countryCode)) {
      setLoading(true);
      const b = phoneNumber.slice(countryCode.length);
      const postRequest = {
        customer_id: param1,
        subscriber_id: data.sub,
        lco_no: data.lcono,
        first_name: data.fname,
        last_name: data.lname,
        email: data.email,
        country_code: countryCode,
        mobile: b,
        address: data.address,
        route_no: data.route,
        billing_name: data.billingName,
        billing_address: data.billingAddr,
        // password: data.password_repeat,
        area_id: data.areaName.value,
        // latitude: data.latitude,
        // longitude: data.longitude,
        dob: moment(birthDate),
        tax_in: data.tax,
        reference: data.message,
        customer_type: data.customerType.value,
      };

      const editCustomerResponse = await postClientUrlWithToken(
        authState.apiUrl,
        authState.userRole === 1 ? editCustomer : editCustomerRole,
        postRequest
      );
      if (editCustomerResponse.status === 200) {
        if (editCustomerResponse.data.status === true) {
          navigate(`${process.env.PUBLIC_URL}/customers/customer`);
          toast.success(editCustomerResponse.data.message);

        } else {
          toast.error(editCustomerResponse.data.message);

          setLoading(false);
        }
      } else if (editCustomerResponse.status >= 400 && editCustomerResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (editCustomerResponse.status >= 500 && editCustomerResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
      }
    }
  };
  return (
    <Fragment>
      {loading && <Transparentspinner />}

      <Breadcrumbs mainTitle={Customer} parent="Customers" title={Customer} />
      <Card>
        <CardHeader className="pb-0">
          <H3>{EditCustomer}</H3>
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="first_name">
                  {FirstName} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="first_name"
                  type="text"
                  {...register("fname", { required: true })}
                />
                <p className="text-danger">{errors.fname?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="last_name">
                  {LastName}
                </Label>
                <input
                  className="form-control"
                  id="last_name"
                  type="text"
                  {...register("lname", { required: true })}
                />
              </Col>
            </Row>
            <Row className="g-3">
              <Col md="6">
                <Label className="form-label" for="subscriber">
                  {SubscriberId} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="subscriber"
                  type="text"
                  {...register("sub", { required: true })}
                />
                <p className="text-danger">{errors.sub?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {Area} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="areaName"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={areaOption}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.areaName?.message}</p>
              </Col>
            </Row>

            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="email">
                  {Email} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  {...register("email")}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {MobileNo} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="phonenumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      country={"in"}
                      enableSearch={true}
                      onChange={(value, country) => {
                        setValue('countryCode', country.dialCode);
                        field.onChange(value);
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "46px",
                        padding: "10px",
                        paddingLeft: "50px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                      }}
                      searchClass="none"
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.mobile && MobileNoError}
                </span>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="lco_no">
                  {LcoNo} 
                </Label>
                <input
                  className="form-control"
                  id="lco_no"
                  type="text"
                  {...register("lcono")}
                />
                {/* <p className="text-danger">{errors.lcono?.message}</p> */}
              </Col>
              <Col>
                <Label className="form-label" for="address">{Address} <span className="requireStar">*</span></Label>
                <textarea
                  id="address"
                  className="form-control"
                  {...register("address")}

                />
                <p className="text-danger">{errors.address?.message}</p>
              </Col>
            </Row>
            <div className="bg-subscription mt-2">
              <Row className="g-3 pt-3">
                <div className="form-check checkbox checkbox-primary mb-0">
                  <Input id="checkbox-primary-1" type="checkbox" onChange={handleCheckboxChange} defaultChecked={true} />
                  <Label for="checkbox-primary-1">{Samasabove}</Label>
                </div>

              </Row>

              <Row className="g-3 pt-3 ">
                <Col md="6">
                  <Label className="form-label" for="billing_name">
                    {BillingName} <span className="requireStar">*</span>
                  </Label>
                  <input
                    className="form-control"
                    id="billing_name"
                    type="text"
                    {...register("billingName", { required: true })}
                  />
                  <p className="text-danger">{errors.billingName?.message}</p>
                </Col>
                <Col md="6">
                  <Label className="form-label" for="billingAddr">
                    {BillingAddress} <span className="requireStar">*</span>
                  </Label>
                  <textarea
                    className="form-control"
                    id="billingAddr"
                    // type="tel"
                    {...register("billingAddr", { required: true })}
                  />
                  <p className="text-danger">{errors.billingAddr?.message}</p>
                </Col>
              </Row>
            </div>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="tax_in">
                  {TaxIn}
                </Label>
                <input
                  className="form-control"
                  id="tax_in"
                  type="tel"
                  {...register("tax")}
                />
              </Col>
              <Col md="6">
                <Label className="form-label" for="route_no">
                  {RouteNo}
                </Label>
                <input
                  className="form-control"
                  id="route_no"
                  type="text"
                  {...register("route")}
                />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" >
                  {CustomerType}
                </Label>
                <Controller
                  name="customerType"
                  control={control}
                  defaultValue={options[0]}
                  render={({ field }) => (
                    <Typeahead
                      options={options}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-label" for="dob">{BirthDate} </Label>
                  <Col xl='12' md="12" sm='12'>
                    <DatePicker
                      id="dob"
                      style={{ padding: '12px 100px' }}
                      className="form-control "
                      dateFormat="yyyy-MM-dd"
                      placeholderText="dd/mm/yyyy"
                      selected={birthDate}
                      onChange={handleChangeDate}
                      showMonthDropdown
                      showYearDropdown

                      dropdownMode="select"

                      required
                    />

                  </Col>
                  {/* <p className="text-danger">{errors.invoice_date?.message}</p> */}
                </FormGroup>
              </Col>
            </Row>
            <Row className="g-3">
              <Col>
                <Label className="form-label pt-3">{Reference}</Label>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <textarea className="form-control" {...field} rows="3" />
                  )}
                />
              </Col>
            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">
              {Save}
            </Btn>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default CustomerEdit;

import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { H3, H5 } from "../../../AbstractElements";
import Chart from 'react-apexcharts';
// import { YearlyChart } from "../../../Data/Dashboard/Default/Chart";
import { Monday, MonthlyOverview, YearlyOverview } from "../../../Constant";
import ConfigDB from '../../../Config/ThemeConfig';
import { useSelector } from "react-redux";
import moment from "moment";

const YearlyView = ({ monthChart, error }) => {
    const authState = useSelector(state => state.auth);
    const [date, setdate] = useState('')
    const today = moment();
    const currdate = today.format('dddd Do MMMM, YYYY')

    const processChartData = (data) => {
        return data.map((value) => {
            if (typeof value === 'string') {
                return value.replace(/,/g, '');
            }
            return value;
        });
    };      

    const format_collection = monthChart && monthChart?.total_collection.replace(/,/g, '');
    const processedData = processChartData(monthChart && monthChart?.data);
    const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
    const YearlyChart = {
        badge: format_collection,
        series: [{
            name: 'series1',
            data: processedData
        }],
        options: {
            chart: {
                height: 230,
                type: 'area',
                toolbar: {
                    show: false
                },
            },
            stroke: {
                curve: 'smooth',
                width: [5, 1],
                dashArray: [0, 5]
            },
            xaxis: {
                show: false,
                labels: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                crosshairs: {
                    show: true,
                    width: 1,
                    position: 'back',
                    stroke: {
                        color: primary,
                        width: 1,
                        dashArray: 5,
                    },
                },
            },
            yaxis: {
                show: false
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.5,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },
            grid: {
                show: false,
            },
            markers: {
                size: 0,
                strokeColors: [primary],
                strokeWidth: [8, 0],
                strokeOpacity: 0.3,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: 7,
                    sizeOffset: 3
                }
            },
            tooltip: {
                marker: {
                    show: false,
                },
                fixed: {
                    enabled: false,
                    position: 'bottomRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: [primary]
        }
    };

    return (
        <Fragment>
            <Col xl="4" lg="3" md="6">
                <Card className="yearly-view" >
                    {error ? (<span>{error}</span>
                    ) : (
                        <>
                            <CardHeader className="pb-0">
                                <H3>{MonthlyOverview}<span className="badge badge-primary">{authState.currency} {YearlyChart.badge}</span></H3>
                                <H5 attrH5={{ className: 'mb-0  ' }} >{currdate}</H5>
                            </CardHeader>
                            <CardBody className="p-0" >
                                <div className="yearly-view" id="yearly-view">
                                    <Chart options={YearlyChart.options} series={YearlyChart.series} type="area" height={203} />
                                </div>
                            </CardBody>
                        </>
                    )}

                </Card>
            </Col>
        </Fragment >
    );
};

export default YearlyView;
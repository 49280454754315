import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { AddAddon, Addon, ConnectionType, Description, Category, Image, HsnNo, MRP, Name, Price, PurchasePrice, Save, Tax, DurationInDays } from "../../../Constant";
import { addAddon, addAddonRole, statusTax, statusTaxRole, getcategoryitems, getcategoryitemsRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../../CommonElements/TypeAhead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    price: yup.string().required().label('Price'),
    purchaseprice: yup.string().required().label('Purchase Price '),
    tax: yup.mixed().required().label('Tax'),
    connectiontype: yup.mixed().required().label('Connection type'),
    category: yup.mixed().required().label('Category'),
    duration: yup.string().required().label('Duration'),

})
const AddonForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [tax, setTax] = useState([]);
    const [image, setImage] = useState('');
    const [taxOption, setTaxOption] = useState([]);
    const [category, setCategory] = useState([])
    const [categoryOption, setCategoryOption] = useState([]);
    const form = new FormData()
    const navigate = useNavigate();

    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
        setImage(selectedFile);

    }
    const connectionOptions = [
        { value: "broadband", label: "Broadband" },
        { value: "cable", label: "Cable" },
        { value: "ip_tv", label: "IPTV" },
        { value: "ott", label: "OTT" },
        { value: "other", label: "Others" },

    ];

    useEffect(() => {
        categoryData();
        taxData();
    }, []);


    useEffect(() => {
        setTaxOption(
            tax.map((data) => ({
                value: data.id,
                label: data.title,
            }))
        );

        setCategoryOption(
            category.map((data) => ({
                value: data.id,
                label: data.name,
            }))
        );

    }, [tax, category]);

    const taxData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? statusTax : statusTaxRole);
        if (data.status === true) {
            setTax(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    };
    const categoryData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getcategoryitems : getcategoryitemsRole);
        if (data.status === true) {
            setCategory(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    };
    useEffect(() => {
        const inputElement = document.querySelectorAll('input[type="number"]');

        const preventScrollEvent = (event) => {
            event.preventDefault();
        };

        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

            return () => {
                inputElement.removeEventListener('wheel', preventScrollEvent);
            };
        });
    }, []);



    const onSubmit = async (data) => {
        setLoading(true);
        form.append("title", data.name)
        form.append("hsn_no", data.hsn_no)
        form.append("price", data.price)
        form.append("tax_group_id", data.tax.value)
        form.append("description", data.description ?? '')
        form.append("purchase_price", data.purchaseprice)
        form.append("category_id", data.category.value)
        form.append("connection_type", data.connectiontype.value)
        form.append("days", data.duration)

        if (data.image[0]) {
            form.append("image", data.image[0])
        }


        const addAddonResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addAddon : addAddonRole, form, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        if (addAddonResponse.status === 200) {
            if (addAddonResponse.data.status === true) {
                toast.success(addAddonResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/Services/addon`);
            } else {
                toast.error(addAddonResponse.data.message)
            }
        } else if (addAddonResponse.status >= 400 && addAddonResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addAddonResponse.status >= 500 && addAddonResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }

        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (
                <SpinnerLoader />
            )}
            <Breadcrumbs mainTitle={Addon} parent="Billing" title={Addon} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddAddon}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="hsn_no">{HsnNo} </Label>
                                <input className="form-control" id="hsn_no" type="number" min={0} {...register('hsn_no', { required: true })} />

                            </Col>
                        </Row>

                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="price">{Price} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="price" type="number" min={0} {...register('price', { required: true })} />
                                <p className="text-danger">{errors.price?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="purchaseprice">{PurchasePrice} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="purchaseprice" type="number" min={0} {...register('purchaseprice', { required: true })} />
                                <p className="text-danger">{errors.purchaseprice?.message}</p>
                            </Col>

                        </Row>
                        <Row className="pt-3">
                            <Col md="6">
                                <Label className="form-label" >{Tax} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="tax"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={taxOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.tax?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{ConnectionType} <span className="requireStar">*</span> </Label>
                                <Controller
                                    name="connectiontype"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={connectionOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.connectiontype?.message}</p>
                            </Col>

                        </Row>

                        <Row className="pt-3">
                            <Col md="6">
                                <Label className="form-label" >{Category} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={categoryOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.category?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="duration">{DurationInDays} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="duration" type="number" min={0} {...register('duration', { required: true })} />
                                <p className="text-danger">{errors.duration?.message}</p>
                            </Col>
                        </Row>

                        <Row className="pt-3">


                            <Col md="6">
                                <Label className="form-label">{Image}</Label>
                                <Controller
                                    name="image"
                                    control={control}
                                    render={({ field }) => (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Col md="8">
                                                <input
                                                    {...register("image")}
                                                    className="form-control fileInputStyle"
                                                    type="file"
                                                    onChange={handleImageChange}
                                                    accept=".png, .jpg, .jpeg"
                                                />
                                            </Col>
                                            <Col md='4'>
                                                <span className="imagestyle">
                                                    {image && <img src={URL.createObjectURL(image)} alt="logo" width="40px" height="40px" />}
                                                </span>
                                            </Col>
                                        </div>
                                    )}
                                />
                                <span className="text-danger">{errors.logo && "logo is required"}</span>
                            </Col>
                        </Row>

                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea className="form-control" value='' {...field} rows="3" />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default AddonForm;

import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { Account, Code, Description, Name, OfflinePayment, OfflinePaymentAdd, SortOrder, Save, SubmitForm, Type } from "../../../Constant";
import { addArea, addOfflinePayment, addOfflinePaymentRole, getAccount, getAccountRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../../CommonElements/TypeAhead";



import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({

    name: yup.string().required().label('Name'),
    code: yup.string().required().label('Code'),
    order: yup.string().required().label('Order'),
    account: yup.mixed().required().label('Account'),
    type: yup.mixed().required().label('Type'),

})
const PaymentForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    })
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState([]);
    const [accountOption, setAccountOption] = useState([]);
    const navigate = useNavigate();
    const typeOptions = [
        { value: "Online", label: "Online" },
        { value: "Offline", label: "Offline" },
    ];

    useEffect(() => {
        accountData();
    }, []);

    useEffect(() => {
        setAccountOption(
            account.map((item) => ({
                value: item.id,
                label: item.name,
            }))
        );
    }, [account]);

    const accountData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getAccount : getAccountRole);
        if (data.status === true) {
            setAccount(data.data);
      }
      else if (data.status >= 400 && data.status <= 405) {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (data.status >= 500 && data.status <= 505) {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      }
      else {
          navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    
      }
    };
    useEffect(() =>{
        const inputElement = document.querySelectorAll('input[type="number"]');
        
        const preventScrollEvent = (event) =>{
          event.preventDefault();
        };
        
        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);
      
            return () => {
              inputElement.removeEventListener('wheel', preventScrollEvent);
            };
          });        
        },[]);
        


    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { name: data.name, type: data.type.value, account_id: data.account.value, code: data.code, order: data.order }
        const addAccountResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addOfflinePayment : addOfflinePaymentRole, postRequest);
        if (addAccountResponse.status === 200) {
        if (addAccountResponse.data.status === true) {
            toast.success(addAccountResponse.data.message)
            navigate(`${process.env.PUBLIC_URL}/settings/payment`);
        } else {
            toast.error(addAccountResponse.data.message)
        }
    } else if (addAccountResponse.status >= 400 && addAccountResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (addAccountResponse.status >= 500 && addAccountResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={OfflinePayment} parent="Settings" title={OfflinePayment} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{OfflinePaymentAdd}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="code">{Code} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="code" type="text" {...register('code', { required: true })} />
                                <p className="text-danger">{errors.code?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="order">{SortOrder} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="order" type="number" min={0} {...register('order', { required: true })} />
                                <p className="text-danger">{errors.order?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{Account} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="account"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={accountOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.account?.message}</p>

                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" >{Type} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="type"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={typeOptions}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.type?.message}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default PaymentForm;
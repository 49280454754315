import React, { Fragment } from "react";
import { Footerlight, PageLayout } from "../../../Constant";
import SubscriptionContainer from "./SubscriptionList";

const Subscription = () => {
    return (
        <Fragment>
            <SubscriptionContainer mainTitle={Footerlight} title={Footerlight} parent={PageLayout} />  
        </Fragment>
    )
}
export default Subscription;
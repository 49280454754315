import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { Search, Status, Filter, Customer, Account, Export, bankTransactionList, Transactions } from '../../../Constant';
import { useForm } from 'react-hook-form';
import { ViewWallet, ViewWalletRole, getCustomers, getCustomersRole, viewAccount, viewAccountRole, viewTransaction } from '../../../CommonUrl';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const WalletListContainer = () => {
    const [spinnerTrans, setspinnerTrans] = useState(true)
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [transactionLists, setTransactionList] = useState([]);
    const [filters, setFilters] = useState({
        search: '',
        start: '',
        end: '',
        customerId: '',
        account_id: ''
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [dateRange, setDateRange] = useState([null, null]);
    const [currentPage, setCurrentPage] = useState(1);
    const [apiCustomer, setapiCustomer] = useState([]);
    const [apiAccount, setapiAccount] = useState([]);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [startDate, endDate] = dateRange;
    const searchstb = useRef(null)
    const isCustomer = useRef(null)
    const isAccount = useRef(null)

    useEffect(() => {
        fetchTransaction(1);
        customerData();
        accountData();
    }, []);

    const TransactionColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.si,
            sortable: true,
            center: false,
            width: '80px',
        },
        {
            name: <H4>Customer</H4>,
            selector: row => row.Customer,
            sortable: true,
            wrap: true,
            width: '220px',
        },
        {
            name: <H4>Transaction</H4>,
            selector: row => row.Transaction,
            sortable: true,
            center: true,
            width: '150px',
        },
        {
            name: <H4>To Invoice</H4>,
            selector: row => row.Inv,
            sortable: true,
            center: true,
            width: '130px',
        },
        {
            name: <H4>To Wallet</H4>,
            selector: row => row.Wallet,
            sortable: true,
            center: true,
            width: '130px',
        },
        {
            name: <H4>Amount</H4>,
            selector: row => row.Amount,
            sortable: true,
            center: true,
            width: '130px',
        },
        {
            name: <H4>Date</H4>,
            selector: row => row.Date,
            sortable: true,
            center: true,
            width: '150px',
        },
        {
            name: <H4>Account</H4>,
            selector: row => row.Account,
            sortable: true,
            Wrap: true,
            center: true,
            width: '140px',
        },
        {
            name: <H4>Description</H4>,
            selector: row => row.Des,
            center: true,
            sortable: true,

        },
    ];


    const fetchTransaction = async (page, size = perPage, searchKey = '', start_date = '', end_date = '', customerId = '', accountId = '') => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewTransaction +
            `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&customer_id=${customerId}&account_id=${accountId}` : ViewWalletRole +
        `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&customer_id=${customerId}&account_id=${accountId}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const SerialNo = (page - 1) * size + 1;
            setTransactionList(response?.data?.data.map((e, index) => {
                return {
                    si: SerialNo + index,
                    Des: e.description,
                    Date: e. paid_at,
                    Amount: `${authState.currency} ${e.amount}`,
                    Customer: e.customer_name,
                    Description: e.description,
                    Account: e.account_name,
                    Inv: e.to_invoice,
                    Wallet: e.to_wallet,
                    Transaction: e.transaction_id,
                }
            }))
            setTotalRows(response.data.total);
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)
    };

    const exportTransactionExcel = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            ViewWallet + `?export=transaction_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.account_id ? filters.account_id : ''}`
            : ViewWalletRole + `?export=transaction_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.account_id ? filters.account_id : ''}`);
        const dataToExport = response.data.map(({ customer_id, paid_at, amount, customer_name, description, account_name }) => ({ TXN_Id: customer_id, Date: paid_at, Amount: authState.currency + amount, Customer: customer_name, Description: description, Account: account_name }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Wallet Data');
            XLSX.writeFile(wb, 'Transaction_data.xlsx');
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)
    };
    const exportTransactionPdf = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            ViewWallet + `?export=transaction_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.account_id ? filters.account_id : ''}`
            : ViewWalletRole + `?export=transaction_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.account_id ? filters.account_id : ''}`);
        const dataToExport = response.data.map(({ customer_id, paid_at, amount, customer_name, description, account_name }, index) => ({ Sl: index + 1, customer_id, paid_at, amount, customer_name, description, account_name }));

        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "TXN Id", "Date", "Amount", "Customer", "Description", "Account",];
            const rows = dataToExport.map(({ customer_id, paid_at, amount, customer_name, description, account_name }, index) => [index + 1, customer_id, paid_at, amount, customer_name, description, account_name]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("Transaction_data.pdf");
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)
    };

    const handlePageChange = page => {
        fetchTransaction(page, perPage, filters.search, filters.start, filters.end, filters.customerId, filters.account_id);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchTransaction(page, newPerPage, filters.search, filters.start, filters.end, filters.customerId, filters.account_id);
        setPerPage(newPerPage);
    };

    const customerData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
        if (data.status === true) {
            setapiCustomer(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const accountData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAccount : viewAccountRole);
        if (data.status === true) {
            setapiAccount(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const handleFilter = async (e) => {
        e.preventDefault()
        const search = searchstb.current.value;
        const account_id = isAccount.current.value;
        const customer_id = isCustomer.current.value;
        const start = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setFilters({
            search,
            customer_id,
            account_id,
            start,
            end,
            account_id
        });
        fetchTransaction(1, perPage, search, start, end, customer_id, account_id);

    };


    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <Breadcrumbs mainTitle={Transactions} parent="Customers" title={Transactions} />
            <Card>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{bankTransactionList}</H3>
                    <div>
                        <CardBody className="dropdown-basic">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportTransactionExcel()}> <i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportTransactionPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className=''>
                        <Form className="needs-validation" noValidate onSubmit={handleFilter}>
                            <Row className="ps-3 gap-sm-3 gap-md-2 mb-4">
                                <Col sm="12" md='5' lg='2' className="form-element">
                                    <input className="form-control" placeholder={Search} type="text"
                                        ref={searchstb} />
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <div style={{ position: 'relative', zIndex: 2 }}>
                                        <DatePicker
                                            className="form-control digits w-100"
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            isClearable={true}
                                            monthsShown={2}
                                            popperPlacement="bottom"
                                            calendarClassName='parallel-calendar'
                                            placeholderText='Select Date Range'

                                        />
                                    </div>

                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select" ref={isCustomer}>
                                        <option value="">{Customer}</option>
                                        {apiCustomer.map((values, index) => (

                                            <option key={index} value={values.id}>{values.name}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select" ref={isAccount}>
                                        <option value="">{Account}</option>
                                        {apiAccount.map((values, index) => (

                                            <option key={index} value={values.id}>{values.name}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                    <Btn attrBtn={{ color: "primary", }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>

                    <div className="order-history table-responsive">
                        <DataTable
                            columns={TransactionColumns}
                            data={transactionLists}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default WalletListContainer;
import React, { useState } from 'react'
import { Save, Title, TaxIn, MobileNo, Email, Address, Description, TitleError } from '../../Constant'
import { Col, Form, Label, Row, FormGroup } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import { Btn } from '../../AbstractElements'
import { postClientUrlWithToken } from '../../Utils/restUtils'
import { useSelector } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import { addHeaderRole, addHeaders } from '../../CommonUrl'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from '../Common/Loader/Transparentspinner'

const schema = yup.object().shape({
    title: yup.string().required().label('Title'),
})

const HeaderAdd = ({ setHeaderModal, handleHeaderAdded }) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const authstate = useSelector((state) => state.auth);

    const onSubmit = async (data) => {
        setloading(true)
        const postResponce = {
            title: data.title,
            description: data.description

        }
        const addHeader = await postClientUrlWithToken(authstate.apiUrl, authstate.userRole === 1 ? addHeaders: addHeaderRole, postResponce);
        if (addHeader.status === 200) {
            if (addHeader.data.status === true) {
                handleHeaderAdded(data.title); // Call the callback function
                setHeaderModal(false);
                toast.success(addHeader.data.message);
            } else {
                setloading(false)
                toast.error(addHeader.data.message);
            }
        } else if (addHeader.status >= 400 && addHeader.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addHeader.status >= 500 && addHeader.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setloading(false)
    }

    return (
        <>
            {loading && <Transparentspinner />}
            <Form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >

                <Row className="g-3 pt-3">
                    <Col md="6">
                        <Label className="form-label" >
                            {Title} <span className="requireStar">*</span>
                        </Label>
                        <input
                            name="title"
                            className="form-control"
                            id="title"
                            type="text"
                            {...register("title")}
                        />
                        <p className="text-danger">{errors.title?.message}</p>
                    </Col>

                </Row>
                <Row className="g-3 pt-3">
                    <Col>
                        <Label className="form-label">{Description}</Label>
                        <textarea className="form-control"
                            {...register("description")}

                        />

                    </Col>
                </Row>
                <FormGroup></FormGroup>
                <span className='ps-2 spinner-enable'>
                    <Btn attrBtn={{ color: "primary", className: "btn" }} type="submit">
                        {Save}
                    </Btn>
                </span>

            </Form>
        </>
    )
}

export default HeaderAdd
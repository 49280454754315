import React, { Fragment, useState, useEffect } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import {
  AddSubscripHeader,
  AddSubscriptionHeader,
  CasProvider,
  ConnectionType,
  Customer,
  InstallDate,
  Save,
  SerialNo,
  StbModemMake,
  StbModemStatus,
  StbModemType,
  StbNo,
  SubscripHeader,
} from "../../../Constant";
import {
  addStb,
  addStbRole,
  getCustomers,
  getCustomersRole,
  getProvider,
  getProviderRole,
} from "../../../CommonUrl";
import { useSelector } from "react-redux";
import {
  getClientUrlDataToken,
  postClientUrlWithToken,
} from "../../../Utils/restUtils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from 'react-datepicker';
import moment from "moment";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
  customer: yup.mixed().required().label('Customer'),
  stbNo: yup.string().required().label('STB No'),
  serialNo: yup.string().required().label('Mac Id/Serial No'),
  stbType: yup.mixed().required().label('STB/Modem Type'),
  stbMake: yup.string().required().label('STB/Modem Make'),
  stbStatus: yup.mixed().required().label('STB/Modem Status '),
  connectionType: yup.mixed().required().label('Connection Type'),


})

const StbForm = () => {
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const authState = useSelector((state) => state.auth);
  const [customer, setCustomer] = useState([]);
  const [provider, setProvider] = useState([]);
  const [installDate, setInstallDate] = useState(new Date());
  const [providerOption, setProviderOption] = useState([]);
  const [customerOption, setCustomerOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultStbType] = useState({ value: "HD", label: "HD" });
  const options = [
    { value: "active", label: "Active" },
    { value: "suspended", label: "Suspend" },
    { value: "disconnected", label: "Disconnect" },
  ];
  const connectionOptions = [
    { value: "cable", label: "Cable" },
    { value: "broadband", label: "Broadband" },
    { value: "ip_tv", label: "IPTV" },
    { value: "ott", label: "OTT" },
    { value: "other", label: "Others" },

  ];

  const stbTypeOptions = [
    { value: "HD", label: "HD" },
    { value: "SD", label: "SD" },
    { value: "Other", label: "Other" },
  ];
  const navigate = useNavigate();

  const handleChangeDate = date => {
    setInstallDate(date);
  };

  useEffect(() => {
    customerData();
    providerData();
  }, []);

  useEffect(() => {
    setCustomerOption(
      customer.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
    setProviderOption(
      provider.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [customer, provider]);

  const customerData = async () => {
    let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
    if (data.status === true) {
      setCustomer(data.data);
    }
    else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }
  };

  const providerData = async () => {
    let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getProvider : getProviderRole);
    if (data.status === true) {
      setProvider(data.data);
    }
    else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }
  };

  const onSubmit = async (data) => {

    setLoading(true);
    const postRequest = {
      stb_no: data.stbNo,
      serial_no: data.serialNo,
      customer_id: data.customer.value,
      type: data.stbType.value,
      connection_type: data.connectionType.value,
      device_make: data.stbMake,
      provider_id: data.casProvider?.value ?? '',
      activated_at: moment(installDate).format("DD-MM-YYYY"),
      status: data.stbStatus?.value ?? '',
    };
    const addStbResponse = await postClientUrlWithToken(
      authState.apiUrl,
      authState.userRole === 1 ? addStb : addStbRole,
      postRequest
    );
    if (addStbResponse.status === 200) {
      if (addStbResponse.data.status === true) {
        navigate(`${process.env.PUBLIC_URL}/customers/stb-modem`);
        toast.success(addStbResponse.data.message);

      } else {
        toast.error(addStbResponse.data.message);
      }
    } else if (addStbResponse.status >= 400 && addStbResponse.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (addStbResponse.status >= 500 && addStbResponse.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }

    setLoading(false);
  };
  return (
    <Fragment>
      {loading && <Transparentspinner />}

      <Breadcrumbs
        mainTitle={AddSubscriptionHeader}
        parent="Customers"
        title={SubscripHeader}
      />
      <Card>
        <CardHeader className="pb-0">
          <H3>{AddSubscripHeader}</H3>
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="g-3">
              <Col md="6">
                <Label className="form-label" >
                  {Customer} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={customerOption}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.customer?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="stb_no">
                  {StbNo} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="stb_no"
                  type="text"
                  {...register("stbNo", { required: true })}
                />
                <p className="text-danger">{errors.stbNo?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="serial_no">
                  {SerialNo} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="serial_no"
                  type="text"
                  {...register("serialNo", { required: true })}
                />
                <p className="text-danger">{errors.serialNo?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {StbModemType} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="stbType"
                  control={control}
                  defaultValue={defaultStbType}
                  render={({ field }) => (
                    <Typeahead
                      options={stbTypeOptions}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.stbType?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="stb_modam_make">
                  {StbModemMake} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="stb_modam_make"
                  type="text"
                  {...register("stbMake", { required: true })}
                />
                <p className="text-danger">{errors.stbMake?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {ConnectionType} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="connectionType"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={connectionOptions}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.connectionType?.message}</p>
              </Col>

            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" >
                  {CasProvider}
                </Label>
                <Controller
                  name="casProvider"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={providerOption}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {StbModemStatus}
                </Label>
                <Controller
                  name="stbStatus"
                  control={control}
                  defaultValue={options[0]}
                  render={({ field }) => (
                    <Typeahead
                      options={options}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.stbStatus?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="insatall_date">
                  {InstallDate}
                </Label>
                <DatePicker
                id="insatall_date"
                  style={{ padding: '12px 100px' }}
                  className="form-control "
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  selected={installDate}
                  onChange={handleChangeDate}
                  required
                />
                {/* <input
                    className="form-control"
                    id="validationCustom02"
                    type="date"
                    defaultValue={new Date()}
                    {...register("installDate")}
                  /> */}

              </Col>

            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">
              {Save}
            </Btn>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default StbForm;

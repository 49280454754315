import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../../Constant";
import InvoicePaymentForm from "./InvoicePaymentForm";

const InvoicePaymentAdd = () => {
    return (
        <Fragment>
            <InvoicePaymentForm mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default InvoicePaymentAdd;
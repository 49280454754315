import React, { useState } from 'react'
import { Save, Title, TaxIn, Password, ConfirmPassword } from '../../../Constant'
import { Col, Form, Label, Row, FormGroup } from 'reactstrap'
import { useForm } from "react-hook-form"
import { Btn } from '../../../AbstractElements'
import { postClientUrlWithToken } from '../../../Utils/restUtils'
import { useSelector } from 'react-redux';
import { addVendor, addVendorRole, changePassword } from '../../../CommonUrl'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from '../../Common/Loader/Transparentspinner'

const schema = yup.object().shape({
    password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
    password_repeat: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
})

const PasswordChange = ({ setModal, userValue }) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleConPassword, setToggleConPassword] = useState(false);
    const authState = useSelector((state) => state.auth);


    const onSubmit = async (data) => {
        setLoading(true)
        const postRequest = {
            user_id: userValue,
            password: data.password_repeat,
        };

        const passwordApi = await postClientUrlWithToken(
            authState.apiUrl, changePassword, postRequest
        );
        if (passwordApi.status === 200) {
            if (passwordApi.data.status === true) {
                setModal(false);
                toast.success(passwordApi.data.message);
            } else {
                toast.error(passwordApi.data.message);
                setLoading(false);
            }
        } else if (passwordApi.status >= 400 && passwordApi.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (passwordApi.status >= 500 && passwordApi.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);

    };
    return (
        <>
            {loading && <Transparentspinner />}
            <Form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <Row className="g-3 pt-3">
                    <Col md="6">
                        <Label className="form-label">
                            {Password} <span className="requireStar">*</span>
                        </Label>
                        <div className='form-input position-relative'>
                            <input
                                className="form-control"
                                type={togglePassword ? 'text' : 'password'}
                                {...register("password", { required: true })}
                            />
                            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                <span className={togglePassword ? '' : 'show'}></span>
                            </div>
                        </div>

                        <p className="text-danger">{errors.password?.message}</p>
                    </Col>
                    <Col md="6">
                        <Label className="form-label" >
                            {ConfirmPassword} <span className="requireStar">*</span>
                        </Label>
                        <div className='form-input position-relative'>
                            <input
                                className="form-control"
                                type={toggleConPassword ? 'text' : 'password'}
                                {...register("password_repeat", { required: true })}
                            />
                            <div className="show-hide" onClick={() => setToggleConPassword(!toggleConPassword)}>
                                <span className={toggleConPassword ? '' : 'show'}></span>
                            </div>
                        </div>
                        <p className="text-danger">{errors.password_repeat?.message}</p>
                    </Col>
                </Row>
                <FormGroup></FormGroup>
                <span className='ps-2 spinner-enable'>
                    <Btn attrBtn={{ color: "primary", className: "btn" }} type="submit">
                        {Save}
                    </Btn>
                </span>

            </Form>
        </>
    )
}

export default PasswordChange
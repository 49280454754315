const asiaTimezones = [
  { value: "Asia/Kolkata", label: "Indian Standard Time (IST)" },
    { value: "Asia/Aden", label: "Arabian Standard Time (AST)" },
    { value: "Asia/Almaty", label: "Alma-Ata Time" },
    { value: "Asia/Amman", label: "Jordan Standard Time (JST)" },
    { value: "Asia/Anadyr", label: "Anadyr Time" },
    { value: "Asia/Aqtau", label: "Aqtau Time" },
    { value: "Asia/Aqtobe", label: "Aqtobe Time" },
    { value: "Asia/Ashgabat", label: "Turkmenistan Time (TMT)" },
    { value: "Asia/Atyrau", label: "Atyrau Time" },
    { value: "Asia/Baghdad", label: "Arabian Standard Time (AST)" },
    { value: "Asia/Baku", label: "Azerbaijan Standard Time (AZT)" },
    { value: "Asia/Bahrain", label: "Arabian Standard Time (AST)" },
    { value: "Asia/Baku", label: "Azerbaijan Standard Time (AZT)" },
    { value: "Asia/Bangkok", label: "Indochina Time (ICT)" },
    { value: "Asia/Barnaul", label: "Altai Time" },
    { value: "Asia/Beirut", label: "Eastern European Time (EET)" },
    { value: "Asia/Bishkek", label: "Kyrgyzstan Time (KGT)" },
    { value: "Asia/Brunei", label: "Brunei Time (BNT)" },
    { value: "Asia/Chita", label: "Yakutsk Time" },
    { value: "Asia/Choibalsan", label: "Choibalsan Time" },
    { value: "Asia/Damascus", label: "Eastern European Time (EET)" },
    { value: "Asia/Dhaka", label: "Bangladesh Standard Time (BST)" },
    { value: "Asia/Dili", label: "Timor Leste Time (TLT)" },
    { value: "Asia/Dubai", label: "Gulf Standard Time (GST)" },
    { value: "Asia/Dushanbe", label: "Tajikistan Time (TJT)" },
    { value: "Asia/Famagusta", label: "Eastern European Time (EET)" },
    { value: "Asia/Gaza", label: "Eastern European Time (EET)" },
    { value: "Asia/Hebron", label: "Eastern European Time (EET)" },
    { value: "Asia/Ho_Chi_Minh", label: "Indochina Time (ICT)" },
    { value: "Asia/Hong_Kong", label: "Hong Kong Time (HKT)" },
    { value: "Asia/Hovd", label: "Hovd Time" },
    { value: "Asia/Irkutsk", label: "Irkutsk Time" },
    { value: "Asia/Jakarta", label: "Western Indonesia Time (WIB)" },
    { value: "Asia/Jayapura", label: "Eastern Indonesia Time (WIT)" },
    { value: "Asia/Jerusalem", label: "Israel Standard Time (IST)" },
    { value: "Asia/Kabul", label: "Afghanistan Time (AFT)" },
    { value: "Asia/Kamchatka", label: "Kamchatka Time" },
    { value: "Asia/Karachi", label: "Pakistan Standard Time (PKT)" },
    { value: "Asia/Kathmandu", label: "Nepal Time (NPT)" },
    { value: "Asia/Khandyga", label: "Yakutsk Time" },
    { value: "Asia/Krasnoyarsk", label: "Krasnoyarsk Time" },
    { value: "Asia/Kuala_Lumpur", label: "Malaysia Time (MYT)" },
    { value: "Asia/Kuching", label: "Malaysia Time (MYT)" },
    { value: "Asia/Kuwait", label: "Arabian Standard Time (AST)" },
    { value: "Asia/Macau", label: "China Standard Time (CST)" },
    { value: "Asia/Magadan", label: "Magadan Time" },
    { value: "Asia/Makassar", label: "Central Indonesia Time (WITA)" },
    { value: "Asia/Manila", label: "Philippine Time (PHT)" },
    { value: "Asia/Muscat", label: "Gulf Standard Time (GST)" },
    { value: "Asia/Nicosia", label: "Eastern European Time (EET)" },
    { value: "Asia/Novokuznetsk", label: "Krasnoyarsk Time" },
    { value: "Asia/Novosibirsk", label: "Novosibirsk Time" },
    { value: "Asia/Omsk", label: "Omsk Time" },
    { value: "Asia/Oral", label: "Oral Time" },
    { value: "Asia/Phnom_Penh", label: "Indochina Time (ICT)" },
    { value: "Asia/Pontianak", label: "West Indonesia Time (WIB)" },
    { value: "Asia/Pyongyang", label: "Korea Standard Time (KST)" },
    { value: "Asia/Qatar", label: "Arabian Standard Time (AST)" },
    { value: "Asia/Qostanay", label: "Qostanay Time" },
    { value: "Asia/Qyzylorda", label: "Qyzylorda Time" },
    { value: "Asia/Riyadh", label: "Arabian Standard Time (AST)" },
    { value: "Asia/Sakhalin", label: "Sakhalin Time" },
    { value: "Asia/Samarkand", label: "Uzbekistan Time (UZT)" },
    { value: "Asia/Seoul", label: "Korea Standard Time (KST)" },
    { value: "Asia/Shanghai", label: "China Standard Time (CST)" },
    { value: "Asia/Singapore", label: "Singapore Time (SGT)" },
    { value: "Asia/Srednekolymsk", label: "Srednekolymsk Time" },
    { value: "Asia/Taipei", label: "Taipei Time (CST)" },
    { value: "Asia/Tashkent", label: "Uzbekistan Time (UZT)" },
    { value: "Asia/Tbilisi", label: "Georgia Standard Time (GET)" },
    { value: "Asia/Tehran", label: "Iran Standard Time (IRST)" },
    { value: "Asia/Thimphu", label: "Bhutan Time (BTT)" },
    { value: "Asia/Tokyo", label: "Japan Standard Time (JST)" },
    { value: "Asia/Tomsk", label: "Tomsk Time" },
    { value: "Asia/Ulaanbaatar", label: "Ulaanbaatar Time (ULAT)" },
    { value: "Asia/Urumqi", label: "Urumqi Time" },
    { value: "Asia/Ust-Nera", label: "Vladivostok Time" },
    { value: "Asia/Vientiane", label: "Indochina Time (ICT)" },
    { value: "Asia/Vladivostok", label: "Vladivostok Time" },
    { value: "Asia/Yakutsk", label: "Yakutsk Time" },
    { value: "Asia/Yangon", label: "Myanmar Time (MMT)" },
    { value: "Asia/Yekaterinburg", label: "Yekaterinburg Time" },
    { value: "Asia/Yerevan", label: "Armenia Time (AMT)" },
  ];
  
  export default asiaTimezones;
  
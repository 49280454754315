import React, { useState } from 'react';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { postDataWithoutResData } from '../../Utils/restUtils';
import { toast } from 'react-toastify';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';
import { resendUrlPhone } from '../../CommonUrl';

const PhoneOtp = ({ form }) => {
    const navigate = useNavigate()
    const [OTP, setOTP] = useState("");
    const [error, setError] = useState('');
    const [Transpinner, setTranspinner] = useState(false);

    const handleOtp = async () => {
        if (!OTP) {
            setError("Please enter the OTP.")
            return;
        }
        setTranspinner(true);
        const response = await postDataWithoutResData('check-otp', {
            mobile: form.mobile ? form.mobile.updatedForm.phone : form.updatedForm.phone,
            otp: OTP
        });
        if (response.data.status === true) {
            toast.success(response?.data?.message);
            navigate('/emailOtpSuccess');
            setTranspinner(false);

        } else {
            toast.error(response.data.message);
            setError("Incorrect OTP.")
            setOTP("")
            setTranspinner(false);

        }
    };
    const handleResndOtp = async () => {
        setTranspinner(true);
        const resendOtp = await postDataWithoutResData(resendUrlPhone, {
            mobile: form.mobile ? form.mobile.updatedForm.phone : form.updatedForm.phone,
        });
        setTranspinner(false);
        return resendOtp;

    }

    return (
        <div className="flex-containers">
            {Transpinner && <Transparentspinner />}
            <div className='form-sections'>

                <p className='step-text'>STEP 2 of 2</p>
                <div className='form-style p-4'>
                    <h3 className='otp-headings'>Enter Your OTP Here</h3>
                    <p className='otp-sub'>Enter the code that we send to your phone {form.mobile ? form.mobile.updatedForm.phone : form.updatedForm.phone}. Be careful not to share code with anyone. </p>
                    <div className='otp-input'>
                        <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            inputStyles={{ borderRadius: '5px', border: 'solid 1.5px #74737380', height: '40px', width: '30px' }}
                            style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px', paddingBottom: '30px' }}

                        />
                        {error && <span className="text-danger">{error}</span>}
                        <ResendOTP onResendClick={handleResndOtp} className='OtpClass' />
                    </div>
                </div>
                <div style={{ paddingBottom: '20px' }}></div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button onClick={handleOtp} className='btn w-50 signup-bg-color'>Verify OTP</button>
                </div>
            </div>
        </div>
    )
}

export default PhoneOtp
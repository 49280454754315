import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../Constant";
import TaxForm from "./TaxForm";

const TaxAdd = () => {
    return (
        <Fragment>
            <TaxForm mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default TaxAdd;

export const conditionalRowStyles = [
    {
        when: row => row.Status === "suspended",
        style: {
            borderBottom: '1.5px solid #b793d2',
            color: 'black',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    {
        when: row => row.Status === "disconnected",
        style: {
            borderBottom: '1.5px solid rgba(0, 0, 0, 0.87)',
            color: 'black',
            '&:hover': {
                cursor: 'not-allowed',
            },
        },
    },
];

export const conditionalRow = [
    {
        when: row => row.Status === "inactive",
        style: {
            borderBottom: '1.5px solid rgba(242, 38, 19, 0.9)',
            color: 'black',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    {
        when: row => row.Status === "suspended",
        style: {
            borderBottom: '1.5px solid #534686',
            color: 'black',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
];

export const conditionalRowComplaints = [
    {
        when: row => row.Status === "active",
        style: {
            borderBottom: '1.5px solid rgba(242, 38, 19, 0.9)',
            borderWidth: '1.5px',
            color: 'black',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    {
        when: row => row.Status === "in_progress",
        style: {
            borderBottom: '1.5px solid #0e1d6f',
            color: 'black',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    {
        when: row => row.Status === "open",
        style: {
            borderBottom: '1.5px solid red',
            color: 'black',
            '&:hover': {
                cursor: 'not-allowed',
            },
        },
    },
];
export const conditionalRowPayments = [
    {
        when: row => row.Status === "partially_paid",
        style: {
            borderBottom: '1.5px solid #0e1d6f',
            color: 'black',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    
];



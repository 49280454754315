import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Sign/otpSuccess.scss';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import 'react-phone-input-2/lib/style.css';
import NavSign from '../Sign/NavSign';
import { getMasterDataToken } from '../../Utils/restUtils';
import { getPaymentReceipt, getShareApi } from '../../CommonUrl';
import HandleHomePageError from '../../Components/Common/ErrorImport/SignError';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';

const BillSuccess = ({ }) => {
  const location = useLocation();
  const paymentId = location.state?.payment_id;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [priceDetails, setPriceDetails] = useState([]);

  useEffect(() => {
    invoiceDataApi();
  }, []);

  const invoiceDataApi = async () => {
    setLoading(true)
    const response = await getMasterDataToken(getPaymentReceipt + `?payment_id=${paymentId}`);
    if (response.status === true) {
      setPriceDetails(response.payment);
    }
    setLoading(false)
  }

  const redirectToInvoice = async () => {
    setLoading(true);
    let response = await getMasterDataToken(getShareApi + `?order_id=${priceDetails.order_id}`);
    if (response.status === true) {
      const toReciept = `${process.env.PUBLIC_URL}/invoice?order_id=${response.data}`;
      navigate(toReciept);
    }
    else {
      HandleHomePageError(navigate, data.status)
    }
    setLoading(false);
  };

  const handleAccount = async () => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
  };

  return (
    <div className='otp-main-container'>
      {loading && <Transparentspinner />}
      <NavSign />
      <div className="otp-container">
        <div className='otp-background'>
          <BsFillCheckCircleFill color='white' size={50} />
          <div style={{ color: 'white', width: '70%', fontSize: '22px', paddingTop: '20px', paddingBottom: '20px' }}>
            <h3 className='head-text' style={{ fontSize: '28px' }}>Your transaction has been completed successfully.</h3>
          </div>
          <div style={{ color: 'white', width: '60%', paddingBottom: '20px' }}>
            <p>Your payment to BillerQ has been processed, and you will receive a confirmation email shortly. If you have any questions or need further assistance, please contact our support team.</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <button onClick={handleAccount} className='button-signups text-link'>Continue</button>
            <button onClick={redirectToInvoice} className='button-viewinvoice text-link'>View invoice</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillSuccess;
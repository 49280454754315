import React, { Fragment, useEffect, useState } from 'react';
import { Image, LI, UL } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/dashboard/logo.png';
import { useSelector } from 'react-redux';
import { changeCompany, getCompany } from '../../CommonUrl';
import { postClientUrlWithToken } from '../../Utils/restUtils';
import { Managecompany } from '../../Constant';
import { toast } from 'react-toastify';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';

const CompanyBar = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [company, setCompany] = useState('');
    const [active, setActive] = useState('');
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        companyData();
    }, []);

    const companyData = async () => {
        let data = await postClientUrlWithToken(authState.apiUrl, getCompany);
        if (data.status === 200) {
            const companyData = data.data.data;
            if (companyData.length > 0) {
                setCompany(companyData);
                const activeCompany = companyData.find(item => item.status === 'active');
                if (activeCompany) {
                    setActive(activeCompany.name);
                    setLogoUrl(activeCompany.company_logo_url);
                }
            } else {
                setCompany("");
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const companyChangeData = async (id) => {
        setLoading(true);
        let data = await postClientUrlWithToken(authState.apiUrl, changeCompany, { company_id: id });
        if (data.status === 200) {
            if (data.data.status === true) {
                window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/`;
                const newActiveCompany = company.find(company => company.id === id);
                if (newActiveCompany) {
                    setActive(newActiveCompany.name);
                    setLogoUrl(newActiveCompany.company_logo_url);
                    toast.success(data.data.message)
                    companyData();
                }
            }else{
                toast.error(data.data.message);
                 setLoading(false);
            }
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    
   

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <LI attrLI={{ className: "profile-nav onhover-dropdown " }} >
                <div className="d-flex profile-media">
                    
                    <Image
                        attrImage={{
                            // className: 'mr-2 pr-2',
                            src: logoUrl ? logoUrl : logo ,
                            alt: '',
                            style: { 
                                width: '40px', 
                                height: '40px',
                                borderRadius: '50%',
                                marginRight:'5px'   
                            }
                        }}
                    />
                    <div className="flex-grow-1 wrap-text">
                        <span ><b>{active} </b> <i className="middle fa fa-angle-down" ></i></span>
                    </div >
                </div>
                <UL attrUL={{ className: "profile-dropdown onhover-show-div onhover-traslate role-popup" }}>
                    {Array.isArray(company) && company.length > 0 ? (
                        company.map((item, index) => (
                            <LI key={index}>
                                <Link to="#javascript" className={item.status === 'active' ? 'active-color fw-bold ' : 'disabled-color'} onClick={() => companyChangeData(item.id)}>
                                    <span className='border-bottom d-flex W-100  pt-2 pb-2 gap-2 role-name'>
                                        <i className={`fa fa-circle-o `}></i>
                                        {item.name}
                                    </span>
                                </Link>
                            </LI>
                        ))
                    ) : (
                        <p>Loading...</p>
                    )
                    }
                    <div className='add-company'>
                        <Link to={`/dashboard/company-list`} className="text-center">{Managecompany}</Link>
                    </div>

                </UL>
            </LI >
        </Fragment >
    );
};

export default CompanyBar;
import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AreaNameError, CasProvider, CasProviderEdit, Description, Name, Save } from "../../../Constant";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { editArea, editProvider, editProviderRole, viewProvider } from "../../../CommonUrl";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { useSelector } from "react-redux";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const ProviderEdit = () => {

    const location = useLocation();
    const authState = useSelector((state) => state.auth);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: {}
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getProviderData();
    }, []);

    const getProviderData = async () => {
        setLoading(true);
        let data = await getClientUrlDataToken(authState.apiUrl, viewProvider + `?provider_id=${param1}`);
       if (data.status === true) {
        setValue('name', data.data[0].name);
        setValue('message', data.data[0].description);
    }else if (data.status >= 400 && data.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    }else if (data.status >= 500 && data.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }
    setLoading(false);
}

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            provider_id: param1,
            name: data.name,
            description: data.message
        };
        const editProviderResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? editProvider : editProviderRole, postRequest);
        if (editProviderResponse.data.status === true) {
            if (editProviderResponse.status === 200) {
                toast.success(editProviderResponse.data.message);
                navigate(`${process.env.PUBLIC_URL}/settings/casProvider`);
        } else {
            toast.error(editProviderResponse.data.message)
        }
    } else if (editProviderResponse.status >= 400 && editProviderResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (editProviderResponse.status >= 500 && editProviderResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={CasProvider} parent="Settings" title={CasProvider} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{CasProviderEdit}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && AreaNameError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="description">{Description}</Label>
                                <input className="form-control" id="description" type="text" {...register('message')} style={{ width: '100%' }} />
                            </Col>

                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default ProviderEdit;

import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { Description, Name, NameError, Save, TaxRate, AddTax, TaxPercentage, TaxRatedit, SortOrder } from "../../../Constant";
import { addTax, taxRateedit, taxRateupdate } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.string().required().label('Name '),
    tax_percentage: yup.string().required().label('Percentage'),
    sort_order: yup.string().required().label('Sort order'),
});


const Taxedit = ({ data }) => {

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({ resolver: yupResolver(schema) });
    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const param1 = params.get("id");
    const param2 = params.get("tax_group_id");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        getTaxGroup()
    }, []);
    const getTaxGroup = async () => {
        setLoading(true);
        let data = await postClientUrlWithToken(authState.apiUrl, taxRateedit + `?tax_id=${param1}`);
        if (data.data.status === true) {
            const e = data.data.data.taxes;
            setValue('name', e.name);
            setValue('tax_percentage', e.tax_percentage);
            setValue('message', e.description);
            setValue('sort_order', e.sort_order);
        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false);

    }

    useEffect(() => {
        const inputElement = document.querySelectorAll('input[type="number"]');

        const preventScrollEvent = (event) => {
            event.preventDefault();
        };

        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

            return () => {
                inputElement.removeEventListener('wheel', preventScrollEvent);
            };
        });
    }, []);


    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            tax_id: param1,
            tax_group_id: param2,
            name: data.name,
            tax_percentage: data.tax_percentage,
            sort_order: data.sort_order,
            description: data.message
        }
        const addTaxGroupResponse = await postClientUrlWithToken(authState.apiUrl, taxRateupdate, postRequest);
        if (addTaxGroupResponse.status === 200) {
            if (addTaxGroupResponse.data.status === true) {
                toast.success(addTaxGroupResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/settings/tax-group`);
            } else {
                setLoading(false);
                toast.error(addTaxGroupResponse.data.message)
            }
        } else if (addTaxGroupResponse.status >= 400 && addTaxGroupResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addTaxGroupResponse.status >= 500 && addTaxGroupResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={TaxRate} parent="Settings" title={TaxRatedit} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{TaxRatedit}</H3>
                </CardHeader>
                <CardBody>  
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name?.message}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="tax_percentage">{TaxPercentage} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="tax_percentage" type="number" {...register('tax_percentage', { required: true })} />
                                <span className="text-danger">{errors.tax_percentage?.message}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 mt-3">
                            <Col md='6'>
                                <Label className="form-label" for="sort_order">{SortOrder} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="sort_order" type="number"  {...register('sort_order', { required: true })} />
                                <span className="text-danger">{errors.sort_order?.message}</span>
                            </Col>
                            <Col>
                                <Label className="form-label">{Description}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            className="form-control"
                                            rows="2"
                                            value={field.value}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default Taxedit;
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { AddOrder, AddSub, Invoice, InvoiceList, Search, orderStatus, Filter, Customer, Export, paymentStatus } from '../../../Constant';
import { viewInvoiceTable, viewInvoiceTableRole, getCustomers, getCustomersRole, deleteInvoice, viewCancelInvoice, restoreInvoice } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { conditionalRow } from '../../Common/Rowstyle/Rowstyle';
import moment from 'moment';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const CancelInvoiceContainer = () => {
    const [spinnerTrans, setspinnerTrans] = useState(false);
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };
    const [invoiceLists, setInvoiceList] = useState([]);
    const [filters, setFilters] = useState({
        search: '',
        customer: '',
        start: '',
        end: '',
        orderStatus: '',
        paymentStatus: ''
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [apiCustomer, setapiCustomer] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const isSearched = useRef(null);
    const isOrderstatus = useRef(null);
    const isPaymentstatus = useRef(null);
    const isCustomer = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const orderStatusOptions = [
        { value: "pending", label: "Pending" },
        { value: "processed", label: "Processed" }
    ]
    const payStatusOptions = [
        { value: "pending", label: "Pending" },
        { value: "partially_paid", label: "Partially Paid" },
        { value: "paid", label: "Paid" }

    ]

    useEffect(() => {
        fetchInvoice(1);
        customerData();
    }, []);

    const invoiceColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            width: '60px'

        },
        {
            name: <i className="fa fa-gears" style={{ fontSize: '15px' }}></i>,
            selector: row => row.action,
            cell: row => <ActionCell row={row} />,
            sortable: true,
            center: true,
            width: '55px'

        },
        {
            name: <H4>Invoice No</H4>,
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.id}&customer_id=${row.customer_id}`}>
                    {row.InvoiceNo}
                </Link>,
            sortable: true,
            width: '95px'

        },
        {
            name: <H4>Subscriber Id</H4>,
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                    {row.Sub}
                </Link>,
            sortable: true,
            width: '105px',

        },
        {
            name: <H4>Customer</H4>,
            selector: row => row.Customer,
            sortable: true,
            width: '100px',
            wrap: true
        },
        {
            name: <H4>Type</H4>,
            selector: row => row.con_type,
            sortable: true,
            width: '105px'
        },
        {
            name: <H4>Amount</H4>,
            selector: row => `${authState.currency} ${row.Amount}`,
            sortable: true,
            width: '105px',

        },
        {
            name: <H4>Balance</H4>,
            selector: row => <span className='text-danger' style={{ fontWeight: 'bold' }}>{authState.currency} {row.balance}</span>,
            sortable: true,
            width: '105px',
        },
        {
            name: <H4>Payment Status</H4>,
            selector: row => (<span
                className={`badge badge-${row.payment_status}`} >

                {row.payment_status}

            </span>
            ),
            sortable: true,
            width: '165px'

        },
        {
            name: <H4>Invoice Date</H4>,
            selector: row => row.InvoiceDate,
            sortable: true,
            width: '165px'
        },
        {
            name: <H4>Order Status</H4>,
            selector: row => (<span
                className={`badge badge-${row.order_status}`} >
                {row.order_status}
            </span>
            ),
            sortable: true,
            width: '165px'
        },
    ];

    const customerData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
        if (data.status === true) {
            setapiCustomer(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const ActionCell = ({ row }) => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

        // const handlePaymentClick = async () => {
        //     const orderIdRes = { order_id: row.id }
        //     const restoreApi = await postClientUrlWithToken(authState.apiUrl, restoreInvoice, orderIdRes);
        //     console.log('res',restoreApi)
        // }

        const handlePaymentClick = () => {
            SweetAlert.fire({
                title: 'Confirm deletion?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'cancel',
                reverseButtons: true
            }).then(async (result) => {
                setLoading(true);
                if (result.value) {
                    const orderIdRes = { order_id: row.id }
                    const restoreApi = await postClientUrlWithToken(authState.apiUrl, restoreInvoice, orderIdRes);
                    console.log('rest',restoreApi)
                    if (restoreApi.status === 200) {
                        if (restoreApi.data.status === true) {
                            SweetAlert.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            fetchInvoice(currentPage, perPage, filters.search, filters.customer, filters.start, filters.end, filters.orderStatus, filters.paymentStatus);
                        } else {
                            setLoading(false)
                            toast.error(restoreApi.data.message);
                            // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                        }
                    } else if (restoreApi.status >= 400 && restoreApi.status <= 405) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                    } else if (restoreApi.status >= 500 && restoreApi.status <= 505) {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                    }
                }
            });
        };

        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
                    <i className="fa fa-ellipsis-v"></i>
                </DropdownToggle>
                <DropdownMenu container="body">
                    <DropdownItem style={{ padding: '10px 20px', color: 'red', opacity: '0.8', fontSize: '14px' }} onClick={() => handlePaymentClick()}>Restore</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    };

    const fetchInvoice = async (page, size = perPage, searchKey = "", customer_id = '', start_date = '', end_date = '', orderStatus = '', payment_status = '') => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 && viewCancelInvoice +
            `?page=${page}&page_length=${size}&search=${searchKey}&customer_id=${customer_id}
            &start_date=${start_date}&end_date=${end_date}&status=${orderStatus}&payment_status=${payment_status}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const SerialNo = (page - 1) * size + 1;
            setInvoiceList(response.data.data.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    Sub: <span>{e.subscriber_id}</span>,
                    Customer: e.customer_name,
                    Amount: e.amount,
                    balance: e.balance,
                    InvoiceDate: e.invoice_date,
                    InvoiceNo: e.invoice_number,
                    Status: e.status,
                    order_status: e.order_status,
                    id: e.id,
                    customer_id: e.customer_id,
                    con_type: e.connection_type,
                    payment_status: e.payment_status,
                }
            }))
            setTotalRows(response.data.total);
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)
    };
    const exportInvoiceExcel = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 &&
            viewCancelInvoice + `?export=invoice-cancelled&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customer ? filters.customer : ''}
            &status=${filters?.orderStatus ? filters.orderStatus : ''}&paymentStatus=${filters?.paymentStatus ? filters.paymentStatus : ''}`);
        const dataToExport = response.data.map(({ invoice_number, subscriber_id, customer_name, connection_type, amount, balance, payment_status, invoice_date, order_status }) => ({ Invoice_No: invoice_number, Subscriber_Id: subscriber_id, Customer: customer_name, Type: connection_type, Amount: authState.currency + amount, Balance: authState.currency + balance, payment_status, Invoice_Date: invoice_date, Order_Status: order_status }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Invoice Data');
            XLSX.writeFile(wb, 'Invoice_cancelled.xlsx');
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)
    };

    const exportInvoicePdf = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 &&
            viewCancelInvoice + `?export=invoice-cancelled&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customer ? filters.customer : ''}
            &status=${filters?.orderStatus ? filters.orderStatus : ''}&paymentStatus=${filters?.paymentStatus ? filters.paymentStatus : ''}`);

        const dataToExport = response.data.map(({ invoice_number, subscriber_id, customer_name, connection_type, amount, balance, payment_status, invoice_date, order_status }) => ({ invoice_number, subscriber_id, customer_name, connection_type, amount, balance, payment_status, invoice_date, order_status }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Invoice No", "Subscriber Id", "Customer", "Type", "Amount", "Balance", "Payment Status", "Invoice Date", "Order Status"];
            const rows = dataToExport.map(({ invoice_number, subscriber_id, customer_name, connection_type, amount, balance, payment_status, invoice_date, order_status }) => [invoice_number, subscriber_id, customer_name, connection_type, amount, balance, payment_status, invoice_date, order_status]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("Invoice_Cancelled.pdf");
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false)
    };

    const handlePageChange = page => {
        fetchInvoice(page, perPage, filters.search, filters.start, filters.end, filters.customer, filters.orderStatus, filters.paymentStatus);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchInvoice(page, newPerPage, filters.search, filters.start, filters.end, filters.customer, filters.orderStatus, filters.paymentStatus);
        setPerPage(newPerPage);
    };

    const handleFilter = async (e) => {
        e.preventDefault();
        const search = isSearched.current.value;
        const customerId = isCustomer.current.value;
        const orderStatus = isOrderstatus.current.value;
        const paymentStatus = isPaymentstatus.current.value;
        const start = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setFilters({
            search,
            customerId,
            start,
            end,
            orderStatus,
            paymentStatus
        });
        fetchInvoice(1, perPage, search, customerId, start, end, orderStatus, paymentStatus);
    };


    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <Breadcrumbs mainTitle={Invoice} parent="Billing" title={Invoice} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -35px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                    <H3>{InvoiceList}</H3>
                    <div>
                        <CardBody className="dropdown-basic" style={{ borderRadius: '5px' }}>
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4 button-addsale">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportInvoiceExcel()}><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportInvoicePdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>

                </CardHeader>
                <CardBody>
                    <span className=''>
                        <Form className="needs-validation" noValidate onSubmit={handleFilter}>

                            <Row className="ps-3 gap-sm-3 gap-md-2 mb-4 filterGap">
                                <Col sm="12" md='5' lg='2'>
                                    <input name='search' className="form-control" placeholder={Search} id="validationCustom01" type="text" ref={isSearched}
                                    />
                                </Col>

                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <div style={{ position: 'relative', zIndex: 2 }}>
                                        <DatePicker
                                            className="form-control digits w-100"
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            isClearable={true}
                                            monthsShown={2}
                                            popperPlacement="bottom"
                                            calendarClassName='parallel-calendar'
                                            placeholderText='Select Date Range'
                                            dateFormat="dd/MM/yyyy"

                                        />
                                    </div>
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element" >
                                    <select className="form-select" ref={isCustomer}>
                                        <option value="">{Customer}</option>
                                        {apiCustomer.map((values, index) => (

                                            <option key={index} value={values.id}>{values.name}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select" ref={isOrderstatus}>
                                        <option value="">{orderStatus}</option>
                                        {orderStatusOptions.map((option, index) => (

                                            <option key={index} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select" ref={isPaymentstatus}>
                                        <option value="">{paymentStatus}</option>
                                        {payStatusOptions.map((option, index) => (
                                            <option key={index} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                    <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={invoiceColumns}
                            data={invoiceLists}
                            progressPending={loading}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment >
    );
};

export default CancelInvoiceContainer;
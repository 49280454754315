import React, { useEffect } from 'react';
import { useState } from 'react';
import NavSign from './NavSign';
import PersonalRegistration from './PersonalRegistration2';
import './style.scss';
import Features from './Features';
import { toast } from 'react-toastify';
import PhoneOtp from './PhoneOTP';
import { useLocation } from 'react-router';

const SignUp = () => {
    const location = useLocation();
    const mobile = location.state?.form; // Extract 'form' from the state

    const [step, setStep] = useState(1);
    const [form, setForm] = useState({});

    useEffect(() => {
        if (mobile) {
          setForm({ ...form, mobile });
          setStep(2); // Move to Phone OTP step
        }
      }, [mobile]);


    const handleNext = (isValid, data) => {
      if (isValid) {
          setForm(data);
          setStep(step + 1); 
      } else {
          toast.error("Please fill the form");
      }
  };
      
    const renderForm = () => {
        switch (step) {
              case 1:
                return <PersonalRegistration onNext={handleNext} form={form} setForm={setForm} />;
              case 2:
                return <PhoneOtp  form={form}/>;
              default:
                return null;
        }
    };
    return (
        <div>
            <NavSign />
            <div className="container">
                <div className="row" style={{ paddingTop: '100px' }}>
                    <div className="col-lg-7">
                        <div className='signup-section'>
                            <h3 className='signup-text'>Sign Up</h3>
                            {renderForm()}
                        </div>
                    </div>
                    <div className="services-container col-5">
                        <div className='service-form-container'>
                            <h3 className='head-text'>What You Get</h3>
                            <div>
                                <Features/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SignUp
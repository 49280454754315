import React, { Fragment } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H1, H2, H6 } from "../../../AbstractElements";
import CountUp from 'react-countup';

const Widgets = (props) => {
    const StaticWidgetsData = props.details;

    const getProgressBarColor = (status) => {
        let colorClass;
        switch (status) {
            case 'Active':
                colorClass = 'progress-gradient-success';
                break;
            case 'Inactive':
                colorClass = 'progress-gradient-danger';
                break;
            case 'Suspended':
                colorClass = 'progress-gradient-primary';
                break;
            case 'Disconnected':
                colorClass = 'progress-gradient-dark';
                break;
            case 'Open':
                colorClass = 'progress-gradient-danger';
                break;
            case 'Closed':
                colorClass = 'progress-gradient-success';
                break;
            case 'In Progress':
                colorClass = 'progress-gradient-primary';
                break;
            default:
                colorClass = 'progress-gradient-secondary';
        }
        return colorClass;
    };


    return (
        <Fragment>
            <div className="">
                <Row className="ps-3 mb-4">
                    {StaticWidgetsData.map((data, index) => {
                        return (
                            <Col key={index} sm='6' md='3' lg="3" className="p-md-3">
                                <Card className="o-hidden w-100">
                                    <CardBody>
                                        <div className="d-flex static-widget">
                                            <div className="flex-grow-1">
                                                <H6 className="font-roboto">{data.status}</H6>
                                            </div>
                                            <H1 attrH2={{ className: 'mb-0 counter' }}><CountUp end={data.count} duration={3} /></H1>
                                            {data.svg}
                                        </div>
                                        <div className="progress sm-progress-bar progress-animate">
                                            <div className={`progress sm-progress-bar progress-animate ${getProgressBarColor(data.status)}`} role="progressbar" style={{ width: '75%' }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                <span className="animate-circle"></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </Fragment>
    );
};

export default Widgets;

import React, { Fragment, useEffect, useState } from 'react';
import { LI, UL } from '../../../../AbstractElements';
import { Col } from 'reactstrap';
import { AmountCollected, InvoiceTotalNo, Prevdues, Subtotal, TotalDues, WalletMoney } from '../../../../Constant';

const InvoiceTotalAmount = (props) => {
    const [currency, setcurrency] = useState('')
    const [historyAmount, sethistoryAmount] = useState(0)
    const invoiceValue = props.item;
    useEffect(() => {
        setcurrency(invoiceValue.currency_symbol);
       const payment_hitory_amount = invoiceValue.payment_history.reduce((prev,cur) => prev + cur.collected_amount,0);
       sethistoryAmount(payment_hitory_amount)
    }, [])

    return (
        <Fragment>
            <Col>
                <div className="checkout-details d-flex justify-content-end" >
                    <div className="order-box">
                        <UL  >
                            <LI >
                                {Subtotal}<span>{currency} {invoiceValue.sub_total}</span>
                            </LI>
                            {invoiceValue.total_tax > 0 && (
                                invoiceValue.orderTaxDetails.map((e) => (
                                    e.total_amount != 0 && (
                                        <LI key={e.id}>{e.tax_name} {e.tax_percentage}%<span>{invoiceValue.currency_symbol}{e.total_amount}</span></LI>
                                    )
                                ))
                            )}
                            <LI >
                                {InvoiceTotalNo}<span>{currency} {invoiceValue.order_total}</span>
                            </LI>
                            <LI >{Prevdues} <span>{invoiceValue.currency_symbol}{invoiceValue.payment_due}</span></LI>
                            <LI >{TotalDues} <span classNamestyle='payable-dues'>{invoiceValue.currency_symbol}{invoiceValue.pending_amount}</span></LI>
                                <LI >{AmountCollected} <span>{invoiceValue.currency_symbol} {historyAmount}</span></LI>
                            <LI >{WalletMoney} <span>{invoiceValue.currency_symbol}{invoiceValue.wallet_money}</span></LI>
                        </UL>
                    </div>
                </div>
            </Col>
        </Fragment >
    );
};
export default InvoiceTotalAmount;
import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { Add, PackageSum, PackageSumList, Search, Filter, Export } from '../../Constant';
import { viewPackageReport, viewPackageReportRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { PackageSummaryColumns } from '../../Data/MockTable/ReportTableHeader';
import Progress from '../Common/Progress';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { EarningsSvg } from '../../Data/Widgets/General/Data';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const PackageSummary = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [customerLists, setCustomerList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [dateRange, setDateRange] = useState([null, null]);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, endDate] = dateRange
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };


  
    const [StaticWidgetsData, setStaticWidgetsData] = useState([
        {
            id: 1,
            title: 'Paid',
            counter: '0',
            svg: <EarningsSvg />,
            class: 'progress-gradient-secondary',
            progress: '70%'
        },
    ]);

    useEffect(() => {
        fetchPackageSum(1);
    }, []);

    const handlePageChange = page => {
        fetchPackageSum(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchPackageSum(page, newPerPage);
        setPerPage(newPerPage);
    };
    const onSubmit = async (data) => {
        let search = data.search;
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setSearchKeyword({ searches: search,start: start_date, end: end_date });
        fetchPackageSum(1, perPage,search, start_date,end_date);
    };


    const fetchPackageSum = async (page, size = perPage, search ='',startDate = '', endDate = '') => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? 
            viewPackageReport + `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}` : 
            viewPackageReportRole + `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}`);
        if (response.status === true) {
            const totalAmount = response.data.total_amount.replace(',', '');
            setStaticWidgetsData(prevData => [
                {   
                    ...prevData[0],
                    counter: totalAmount
                }
            ]);
            setResetPaginationToggle(!resetPaginationToggle);

            const valuePackages = response.data.packages.data
            const SerialNo = (page - 1) * size +1; 
            setCustomerList(Object.keys(valuePackages).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    Packages: valuePackages[e].package_name,
                    unitPrice: authState.currency+valuePackages[e].unit_price,
                    unitTax: authState.currency+valuePackages[e].unit_tax,
                    totalPrice: authState.currency+valuePackages[e].total_price,
                    totalTax: authState.currency+valuePackages[e].total_tax,
                    grandTotal: <span style={{ fontWeight: 'bold', color: '#534686' }}>{authState.currency} {valuePackages[e].grand_total}</span>,
                    invoiceNo: valuePackages[e].invoice_count,
                }
            }
            ))
            setTotalRows(response.data.total);
            setLoading(false);           

        }  else {
            handleApiError(response.status)            
        }
    };

    const exportPackageSummaryExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPackageReport + 
            `?export=package_summary_report&search=${searchKeyword?.searches ? searchKeyword.searches : ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}` :
            viewPackageReportRole + `?export=package_summary_report&search=${searchKeyword?.searches ? searchKeyword.searches : ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}`);
        const dataToExport = response.data.packages.map(({ package_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count }) => ({ Packages: package_name, Unit_Price: authState.currency + unit_price, Unit_Tax: authState.currency + unit_tax, Total_Price: authState.currency + total_price, Total_Tax: authState.currency + total_tax, Grand_Total: authState.currency + grand_total, No_of_Invoice: invoice_count }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Package Summary Data');
            XLSX.writeFile(wb, 'package_summary_data.xlsx');
        }  else {
            handleApiError(response.status)            
        }
        setLoading(false);
    };
    const exportPackageSummaryPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPackageReport +
            `?export=package_summary_report&search=${searchKeyword?.searches ? searchKeyword.searches : ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}` :
              viewPackageReportRole +  `?export=package_summary_report&search=${searchKeyword?.searches ? searchKeyword.searches : ''}&start_date=${searchKeyword?.start ?? ''}&end_date=${searchKeyword?.end ?? ''}`);
        const dataToExport = response.data.packages.map(({ package_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count }, index) => ({ Sl: index + 1, package_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Packages", "Unit Price", "Unit Tax", "Total Price", "Total Tax", "Grand Total", "Number of Invoice"];
            const rows = dataToExport.map(({ package_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count }, index) => [index + 1, package_name, unit_price, unit_tax, total_price, total_tax, grand_total, invoice_count]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("package_summary_data.pdf");
        }  else {
            handleApiError(response.status)            
        }
        setLoading(false);
    };



    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={PackageSum} parent="Reports" title={PackageSum} />
            <Progress details={StaticWidgetsData} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{PackageSumList}</H3>
                    <div >
                        <CardBody className="dropdown-basic">
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportPackageSummaryExcel()} ><i className="fa fa-file-excel-o me-2"></i>Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportPackageSummaryPdf()}><i className="fa fa-file-pdf-o me-2"></i>Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search}type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'

                                />
                            </div>

                        </Col>

                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={PackageSummaryColumns}
                            data={customerLists}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default PackageSummary;
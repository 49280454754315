import React, { Fragment, useEffect } from 'react';
import { Col, Container } from 'reactstrap';
import { BACK_TO_HOME_PAGE, ErrorPageContent, Pagenotfound } from '../../Constant'
import { Error5 } from '../../Data/Page/Svg'
import { H3 } from '../../AbstractElements';

const Error = () => {
  return (
    <Fragment>
      <div className='page-wrapper compact-wrapper' id='pageWrapper'>
        <div className='error-wrapper'>
          <Container>
            <div className='svg-wrapper error5'>
              <Error5 />
            </div>
            <Col md="8" className="offset-md-2">
              <H3>{Pagenotfound}</H3>
            </Col>
          </Container>
        </div>
      </div>
    </Fragment>
  )
}

export default Error
import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { AddCategory, Categories, Description, Name, NameError, Save, Type } from "../../../Constant";
import { addCategory, addCategoryRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../../CommonElements/TypeAhead";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    type: yup.mixed().required().label('Type'),
})

const CategoryForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    })
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const options = [
        { value: "expense", label: "Expense" },
        { value: "income", label: "Income" },
        { value: "item", label: "Item" },
        { value: "other", label: "Other" },
      
    ];
    const navigate = useNavigate();
    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { name: data.name, type: data.type.value }
        const addCategoryResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addCategory : addCategoryRole, postRequest);
        if (addCategoryResponse.status === 200) {
        if (addCategoryResponse.data.status === true) {
            toast.success(addCategoryResponse.data.message)
            navigate(`${process.env.PUBLIC_URL}/settings/categories`);
        } else {
            toast.error(addCategoryResponse.data.message)
            setLoading(false);
        }
    } else if (addCategoryResponse.status >= 400 && addCategoryResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (addCategoryResponse.status >= 500 && addCategoryResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={Categories} parent="Settings" title={Categories} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddCategory}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{Type} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={options}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.type?.message}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default CategoryForm;
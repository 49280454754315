import React, { Fragment, useState,useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import {
  AfterNo,
  BeforeNo,
  Comma,
  Dash,
  DateFive,
  DateFormate,
  DateFour,
  DateOne,
  DateSix,
  DateThree,
  DateTwo,
  Decimal,
  Dot,
  FinancialYearStart,
  Next,
  PerPosition,
  Separator,
  Slash,
  Space,
  TimeZone,
} from "../../../../Constant";
import Typeahead from "../../../../CommonElements/TypeAhead/index";
import asiaTimezones from "../../../Common/Timezone/Timezone";
import DatePicker from 'react-datepicker';
import moment from "moment";

const Formstep2 = ({ setSteps, setFormdata, formdata }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [financialDate, setFinancialDate] = useState(formdata.financial);

  const handleChangeDate = date => {
    setFinancialDate(date);
  };
  
  const dateFormat = [
    { value: "d M Y", label: DateOne },
    { value: "M d Y", label: DateTwo },
    { value: "Y M d", label: DateThree },
    { value: "d m Y", label: DateFour },
    { value: "m d Y", label: DateFive },
    { value: "Y m d", label: DateSix },
]


  const decimaloptions = [
    { value: "2", label: "0.00" },
    { value: "3", label: "0.000" },
    { value: "4", label: "0.0000" },
  ]


  const dateSeperator = [
    { value: "-", label: Dash },
    { value: "/", label: Slash },
    { value: ".", label: Dot },
    { value: ",", label: Comma },
    { value: " ", label: Space },
  ]
  const percentPosition = [
    { value: "after", label: AfterNo },
    { value: "before", label: BeforeNo },
  ]

  useEffect(() => {
    setValue("time", {
      value: formdata?.time?.value,
      label: formdata?.time?.label,
    });
  }, []);


  const onSubmit = (data) => {
    if (data) {
      setFormdata((prev) => ({ ...prev, ...data ,financial: financialDate }));
      setSteps((pre) => pre + 1);
    }
  };
  return (
    <Fragment>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="form-bookmark needs-validation"
      >
        <Row className="g-3 pt-3">
          <Col md="6">
            <FormGroup className="mb-3">
              <Label htmlFor="financial">{FinancialYearStart}</Label>
              <DatePicker
                id="financial"
                style={{ padding: '12px 100px' }}
                className="form-control "
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy"
                selected={financialDate}
                onChange={handleChangeDate}             
              />
                <input type="hidden" {...register("financialYearStartDate")} value={financialDate} />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-3">
              <Label>{TimeZone}</Label>
              <Controller
                name="timezone"
                control={control}
                defaultValue={formdata.timezone || ""}
                render={({ field }) => (
                  <Typeahead
                    options={asiaTimezones}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    value={field.value}

                  />
                )}
              />

            </FormGroup>
          </Col>
        </Row>
        <Row className="g-3">
          <Col md="6">
            <FormGroup className="mb-3">
              <Label >{DateFormate}</Label>
              <Controller
                name="dateFormate"
                control={control}
                defaultValue={formdata.dateFormate || ""}

                render={({ field }) => (
                  <Typeahead
                    options={dateFormat}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    value={field.value}

                  />
                )}
              />

            </FormGroup>
          </Col>
          <Col md="6">
            <Label >{Separator}</Label>
            <Controller
              name="separator"
              control={control}
              defaultValue={formdata.separator || ""}

              render={({ field }) => (
                <Typeahead
                  options={dateSeperator}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}

                />
              )}
            />

          </Col>
        </Row>
        <Row className="g-3 ">
          <Col md="6">
            <Label >{PerPosition}</Label>
            <Controller
              name="position"
              control={control}
              defaultValue={formdata.position || ""}

              render={({ field }) => (
                <Typeahead
                  options={percentPosition}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}

                />
              )}
            />

          </Col>
          <Col md="6">
            <Label >{Decimal}</Label>
            <Controller
              name="decimal"
              control={control}
              defaultValue={formdata.decimal || decimaloptions[0]}
              render={({ field }) => (
                <Typeahead
                  options={decimaloptions}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}

                />
              )}
            />

          </Col>


        </Row>
        <div className="text-end">
          <Btn attrBtn={{ color: "primary", type: "submit" }}>{Next}</Btn>
        </div>
      </Form>
    </Fragment>
  );
};

export default Formstep2;

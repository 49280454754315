import React from 'react';
import { H4 } from '../../AbstractElements';
import { Link } from 'react-router-dom';


export const StbColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: true,
        width: '45px',
    },
    {
        name: <H4>STB No</H4>,
        selector: row => row.StbNo,
        sortable: true,
        center: true,
        width: '95px',
        wrap: true,
    },
    {
        name: <H4>Serial No</H4>,
        selector: row => row.SerialNo,
        sortable: true,
        center: true,
        wrap: true,
        width: '120px',
    },
    {
        name: <H4>STB Type</H4>,
        selector: row => row.StbType,
        sortable: true,
        center: true,
        width: '110px',
        wrap: true,
    },
    // {
    //     name: <H4>STB Make</H4>,
    //     selector: row => row.StbMake,
    //     sortable: true,
    //     center: true,
    //     width:'80px',
    // },
    // {
    //     name: <H4>CAS Provider</H4>,
    //     selector: row => row.Provider,
    //     sortable: true,
    //     center:false,
    //     width:'90px',

    // },
    {
        name: <H4>Install Date</H4>,
        selector: row => row.InstallDate,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
        cell: row => (
            <span
                className={`badge badge-${row.Status}`}

            >
                {row.Status}
            </span>
        ),

    },
];

export const stbPackageplandata = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Sl,
        sortable: true,
        // center: true,
        width: '50px'
    },
    {
        name: <H4>Package</H4>,
        selector: row => row.package_name,
        sortable: true,
        width: '350px',
        wrap: true,
    },

    {
        name: <H4>Type</H4>,
        selector: row => row.type === "master" ? "Base plan" : "Addon",
        sortable: true,
        // center: true,
        wrap: true
    },
    {
        name: <H4>Recurring</H4>,
        selector: row => row.recur_status == "yes" ? "On" : "Off",
        sortable: true,
        // center: true,
        wrap: true,
        width: '105px',

    },
    {
        name: <H4>Start Date</H4>,
        selector: row => row.start_date,
        sortable: true,
        // center: true,
        wrap: true
    },
    {
        name: <H4>End Date</H4>,
        selector: row => row.end_date,
        sortable: true,
        // center: true,
        wrap: true
    },

];

export const SubscriptionUserColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: true,
        width: '50px'
    },
    {
        name: <H4>Company</H4>,
        selector: row => row.CompName,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>Plan</H4>,
        selector: row => row.PackName,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>Start Date</H4>,
        selector: row => row.StartDate,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>End Date</H4>,
        selector: row => row.EndDate,
        sortable: true,
        center: true,
        wrap: true
    },
    // {
    //     name: <H4>Payment Status</H4>,
    //     selector: row => row.PayStatus,
    //     sortable: true,
    //     // center: true,
    //     cell: row => (
    //         <span className={`badge badge-${row.PayStatus}`}>
    //             {row.PayStatus}
    //         </span>
    //     ),
    // },
    {
        name: <H4>Activation Status</H4>,
        selector: row => row.Status,
        sortable: true,
        // center: true,
        cell: row => (
            <span className={`badge badge-${row.Status}`}>
                {row.Status}
            </span>
        ),
    },
];

export const SubscriptionColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: true,
        width: '50px'
    },
    {
        name: <H4>STB No</H4>,
        selector: row => row.Stb_no,
        sortable: true,
        center: true,
        width: '70px',
        wrap: true,
    },

    {
        name: <H4>Plan</H4>,
        selector: row => row.PackName,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>Invoice No</H4>,
        // selector: row => row.InvoiceNo,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${row.customerid}`}>
                {row.InvoiceNo}
            </Link>,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Start Date</H4>,
        selector: row => row.StartDate,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>End Date</H4>,
        selector: row => row.EndDate,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>Recurring</H4>,
        selector: row => row.RecDate,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>Payment Status</H4>,
        selector: row => row.PayStatus,
        sortable: true,
        center: true,
        cell: row => (
            <span className={`badge badge-${row.PayStatus}`}>
                {row.PayStatus}
            </span>
        ),
    },
    {
        name: <H4>Created By</H4>,
        selector: row => row.CreateBy,
        sortable: true,
        center: true,
        wrap: true,
    },
    {
        name: <H4>Activation Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
        cell: row => (
            <span className={`badge badge-${row.Status}`}>
                {row.Status}
            </span>
        ),
    },
];
export const AddonColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: true,
        width: '50px'
    },
    {
        name: <H4>Stb No</H4>,
        selector: row => row.Stb_no,
        sortable: true,
        center: true,
        width: '70px',
        wrap: true,
    },
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: true,
        width: '90px',
        wrap: true
    },
    {
        name: <H4>Invoice No</H4>,
        // selector: row => row.InvoiceNo,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${row.customerid}`}>
                {row.InvoiceNo}
            </Link>,
        sortable: true,
        center: true,
        wrap: true,
    },
    {
        name: <H4>Start Date</H4>,
        selector: row => row.StartDate,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>End Date</H4>,
        selector: row => row.EndDate,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>Recurring</H4>,
        selector: row => row.RecDate,
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: <H4>Payment Status</H4>,
        selector: row => row.PayStatus,
        sortable: true,
        center: true,
        cell: row => (
            <span
                className={`badge badge-${row.PayStatus}`}
            >
                {row.PayStatus}
            </span>
        ),
    },
    {
        name: <H4>Created By</H4>,
        selector: row => row.CreateBy,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
        cell: row => (
            <span
                className={`badge badge-${row.Status}`}
            >
                {row.Status}
            </span>
        ),
    },
];
export const SubHistoryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: true,
        width: '50px'
    },
    // {
    //     name: <H4>Sub Id</H4>,
    //     selector: row => row.Subid,
    //     sortable: true,
    //     center: true,
    //     width:'120px'
    // },
    {
        name: <H4>Stb No</H4>,
        selector: row => row.Stb_no,
        sortable: true,
        center: true,
        width: '70px',
        wrap: true
    },
    {
        name: <H4>Plan date</H4>,
        selector: row => row.Planedate,
        sortable: true,
        center: true,
        width: '160px',
        wrap: true

    },
    {
        name: <H4>End date</H4>,
        selector: row => row.Enddate,
        sortable: true,
        center: true,
        width: '140px',
        wrap: true
    },
    {
        name: <H4>Invoice No</H4>,
        // selector: row => row.InvoiceNo,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${row.customerid}`}>
                {row.InvoiceNo}
            </Link>,
        sortable: true,
        center: true,
        width: '120px',
    },
    {
        name: <H4>Recurring Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true
    }
];
export const AddonHistoryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        width: '60px',

    },
    {
        name: <H4>Stb No</H4>,
        selector: row => row.Stb_no,
        sortable: true,
        center: true,
        width: '70px',
        wrap: true
    },
    // {
    //     name: <H4>Sub Id</H4>,
    //     selector: row => row.Subid,
    //     sortable: true,
    //     center:true,
    //     width:'80px',  

    // },
    {
        name: <H4>Plan date</H4>,
        selector: row => row.Planedate,
        sortable: true,
        center: true,
        width: '170px',
    },
    {
        name: <H4>End date</H4>,
        selector: row => row.Enddate,
        sortable: true,
        center: true,
        width: '150px',
    },
    {
        name: <H4>Invoice No</H4>,
        // selector: row => row.InvoiceNo,
        selector: row =>
            <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.order_id}&customer_id=${row.customerid}`}>
                {row.InvoiceNo}
            </Link>,
        sortable: true,
        center: true
    },
    {
        name: <H4>Recurring Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true
    }
];


export const InvoiceColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        width: '50px'
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        sortable: true,
        width: '120px'

    },
    {
        name: <H4>Subscriptions</H4>,
        selector: row => row.Subscription,
        sortable: true,
        wrap: true,
        width: '120px'

    },
    {
        name: <H4>Invoice no</H4>,
        selector: row => row.Invoice,
        sortable: true,
        center: true,

    },
    {
        name: <H4>Start Date</H4>,
        selector: row => row.InDate,
        sortable: true,
        wrap: true

    },
    {
        name: <H4>Billing Date</H4>,
        selector: row => row.BillingDate,
        sortable: true,
        wrap: true

    },
    {
        name: <H4>Amount Due</H4>,
        selector: row => row.AmountDue,
        sortable: true,

    },
    {
        name: <H4>Total</H4>,
        selector: row => row.Total,
        sortable: true,

    },
    {
        name: <H4>Customer</H4>,
        selector: row => row.Customer,
        sortable: true,
        wrap: true

    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
        cell: row => (
            <span
                //   className={row.Status === 'paid' ? 'badge-light-success' :row.Status === 'partially paid' ?  'badge-light-primary':  'badge-light-danger'}
                className={`badge badge-${row.Status}`}
            // style={{
            //     fontSize: '10px',
            //     fontWeight: '500',
            //     padding: '2px',
            //     borderRadius: '6px',
            // }}
            >
                {row.Status}
            </span>
        ),
    },
    {
        name: <H4>Unit Price</H4>,
        selector: row => row.UnitPrice,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Tax</H4>,
        selector: row => row.Tax,
        sortable: true,
    },

];

export const paymentColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        width: '50px'
        // center: true,
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.Action,
        sortable: true,
        // center: true,
    },
   
    {
        name: <H4>Paid On</H4>,
        selector: row => row.Paidat,
        sortable: true,
        // center: true,
        wrap:true
    },
    {
        name: <H4>Txn Id</H4>,
        selector: row => row.txnId,
        sortable: true,
        // center: true,
        wrap:true
      
   
    },
    {
        name: <H4>Amount Collected</H4>,
        selector: row => row.Amount,
        sortable: true,
        // center: true,
          wrap:true,
        width: '110px'
    },
    {
        name: <H4>To Invoice</H4>,
        selector: row => row.toInvoice,
        sortable: true,
        center: true,
        width: '165px'
    },
    {
        name: <H4>To Wallet</H4>,
        selector: row => row.toWallet,
        sortable: true,
        center: true,
        width: '135px'
    },
    {
        name: <H4>Payment Type</H4>,
        selector: row => row.payType,
        sortable: true,
        center: true,
    },
];

export const paymentsColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        width: '50px'
        // center: true,
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.Action,
        sortable: true,
        // center: true,
    },
    {
        name: <H4>Comany</H4>,
        selector: row => row.CompName,
        sortable: true,
        // center: true,
    },
    {
        name: <H4>Paid On</H4>,
        selector: row => row.Paidat,
        sortable: true,
        // center: true,
        wrap:true
    },
    {
        name: <H4>Txn Id</H4>,
        selector: row => row.txnId,
        sortable: true,
        // center: true,
        wrap:true
      
   
    },
    {
        name: <H4>Amount Collected</H4>,
        selector: row => row.Amount,
        sortable: true,
        // center: true,
          wrap:true,
        width: '120px'
    },
    {
        name: <H4>Industry Name</H4>,
        selector: row => row.IndustryName,
        sortable: true,
        center: true,
        width: '165px'
    },
    {
        name: <H4>Payment Type</H4>,
        selector: row => row.payType,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
        width: '135px'
    },
];

export const HistoryColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Date</H4>,
        selector: row => row.Date,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Description</H4>,
        selector: row => row.Description,
        sortable: true,
        center: false,
    },
]
export const PaymntColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width: '80px'
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.Action,
        sortable: true,
    },
    {
        name: <H4>Date</H4>,
        selector: row => row.Date,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Amount</H4>,
        selector: row => row.Amount,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Transaction Id</H4>,
        selector: row => row.TransactionId,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Account</H4>,
        selector: row => row.Account,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Description</H4>,
        selector: row => row.Description,
        sortable: true,
        center: false,
    },
    // {
    //     name: <H4>Action</H4>,
    //     selector: row => row.Action,
    //     sortable: false,
    //     center: true,
    // },

]
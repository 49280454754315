import React, { Fragment } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { H2, H6 } from "../../../AbstractElements";
import CountUp from 'react-countup';
import { EarningsSvg, MessagesSvg, NewUserSvg, ProductsSvg } from "../../../Data/Widgets/General/Data";

const StaticWidgets = (props) => {

    const personalDetails = props.details;

    const StaticWidgetsData = [
        {
            id: 1,
            title: 'Pending',
            counter: parseFloat(personalDetails.open_invoice_amount.replace(/,/g, '')),
            svg: <ProductsSvg />,
            class: 'progress-gradient-success',
            progress: '60%'
        },
        {
            id: 2,
            title: 'Overdue',
            counter: parseFloat(personalDetails.overdue_invoice_amount.replace(/,/g, '')),
            svg: <MessagesSvg />,
            class: 'progress-gradient-primary',
            progress: '48%'
        },
        {
            id: 3,
            title: 'Paid',
            counter: parseFloat(personalDetails.paid_amount.replace(/,/g, '')),
            svg: <EarningsSvg />,
            class: 'progress-gradient-secondary',
            progress: '75%'
        },
        {
            id: 4,
            title: 'Wallet',
            counter: parseFloat(personalDetails.wallet_money.replace(/,/g, '')),
            svg: <NewUserSvg />,
            class: 'progress-gradient-danger',
            progress: '48%'
        },
    ]


    return (
        <Fragment>
            {StaticWidgetsData.map((data) => {
                return (
                    <Col sm="6" lg="6" key={data.id}>
                        <Card className="o-hidden">
                            <CardBody>
                                <div className="d-flex static-widget">
                                    <div className="flex-grow-1">
                                        <H6 className="font-roboto">{data.title}</H6>
                                        <H2 attrH2={{ className: 'mb-0 counter' }}><CountUp end={data.counter} duration={0.8} decimals={2} /></H2>
                                    </div>
                                    {data.svg}
                                </div>
                                <div className="progress-widget">
                                    <div className="progress sm-progress-bar progress-animate">
                                        <div className={`${data.class}`} role="progressbar" style={{ width: `${data.progress}` }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                            <span className="animate-circle"></span>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )
            })}
        </Fragment>
    );
};

export default StaticWidgets;
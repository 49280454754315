import React from 'react';
import { H4 } from '../../AbstractElements';

export const AccountColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
        width:'50px',
    },
  
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        // center: true,
        width:'170px',
        wrap:true
    },
    {
        name: <H4>Account No.</H4>,
        selector: row => row.Number,
        sortable: true,
        width:'170px',
        wrap:true
     
    },
    {
        name: <H4>Opening Balance</H4>,
        selector: row => row.Balance,
        sortable: true,
        width:'150px',
     
    },
    {
        name: <H4>Current Balance</H4>,
        selector: row => row.Current_balance,
        sortable: true,
        width:'170px',
     
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        width:'130px',
     
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        sortable: true,
        center:true
     
    },
  
];

export const userAgentColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Email</H4>,
        selector: row => row.Email,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Roles</H4>,
        selector: row => row.Roles,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
    },
    {
        name: <i className="fa fa-gears" style={{ fontSize: '20px' }}></i>,
        selector: row => row.action,
        sortable: true,
        center: true,
    },
];

export const PackageColumns = [
    {
        name: <H4>Image</H4>,
        selector: row => row.Image,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Category</H4>,
        selector: row => row.Category,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Price</H4>,
        selector: row => row.Price,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        sortable: true,
        center: true,
    },
];

export const ItemColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
       
    },
    {
        name: <H4>Action</H4>,
        selector: row => row.action,
        sortable: true,
       
    },
    {
        name: <H4>Icons</H4>,
        selector: row => row.Icons,
        sortable: true,
       
    },
    {
        name: <H4>Name</H4>,
        selector: row => row.Name,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Category</H4>,
        selector: row => row.Category,
        sortable: true,
        center: false,
    },
    {
        name: <H4>Stock</H4>,
        selector: row => row.Quantity,
        sortable: true,
       
    },
    {
        name: <H4>Price</H4>,
        selector: row => row.Price,
        sortable: true,
       
    },
    {
        name: <H4>Purchase Price</H4>,
        selector: row => row.Purchase_price,
        sortable: true,
       
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
       
    },
   
];

export const StbColumns = [
    {
        name: <H4>Sl</H4>,
        selector: row => row.Si,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Serial No</H4>,
        selector: row => row.SerialNo,
        sortable: true,
        center: true,
    },
    {
        name: <H4>STB Type</H4>,
        selector: row => row.StbType,
        sortable: true,
        center: true,
    },
    {
        name: <H4>STB Make</H4>,
        selector: row => row.StbMake,
        sortable: true,
        center: true,
    },
    {
        name: <H4>CAS Provider</H4>,
        selector: row => row.Provider,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Install Date</H4>,
        selector: row => row.InstallDate,
        sortable: true,
        center: true,
    },
    {
        name: <H4>Status</H4>,
        selector: row => row.Status,
        sortable: true,
        center: true,
    },
];
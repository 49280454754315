import React, { useState } from 'react'
import { ConfirmPassword, Password, Save, currentPwd } from '../../../Constant'
import { Col, Form, Label, Row } from 'reactstrap'
import { useForm } from "react-hook-form"
import { Btn } from '../../../AbstractElements'
import { postClientUrlWithToken } from '../../../Utils/restUtils'
import { useSelector } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import { Changepassword } from '../../../CommonUrl'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import Transparentspinner from '../../Common/Loader/Transparentspinner'


const schema = yup.object().shape({
    password: yup.string().required('Password id required').min(8, 'Password must be at least 8 charecters long'),
    password_repeat: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
})
const ChangePwd = ({ customerId, fetchCustomer, setModal }) => {
    const navigate = useNavigate();

    const [loading, setloading] = useState(false);
    const authstate = useSelector((state) => state.auth);
    const [togglePassword, setTogglePassword] = useState(false);


    const { register, handleSubmit, watch, getValues, formState: { errors } } = useForm({ resolver: yupResolver(schema) })

    const onSubmit = async (data) => {
        setloading(true)
        const postResponce = {
            customer_id: customerId,
            new_password: data.password,
            confirm_new_password: data.password_repeat,

        }
        const postPassword = await postClientUrlWithToken(authstate.apiUrl, Changepassword, postResponce);
        if (postPassword.status === 200) {
            if (postPassword.data.status === true) {
                toast.success(postPassword.data.message);
                setModal(false)
            } else {
                setloading(false)
                toast.error(postPassword.data.message);
            }
        } else if (postPassword.status >= 400 && postPassword.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (postPassword.status >= 500 && postPassword.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setloading(false)
    }
    return (
        <>
        {loading && <Transparentspinner />}
            <Form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >

                <Row className="g-3 pt-3">
                    <Col md="6">
                        <Label className="form-label" for="password">
                            {Password}
                        </Label>
                        <div className='form-input position-relative'>

                            <input
                                className="form-control"
                                id="password"
                                type={togglePassword ? 'text' : 'password'} 
                                {...register("password")}
                            />
                            <div className="show-hide"  onClick={() => setTogglePassword(!togglePassword)}>
                                <span  className={togglePassword ? '' : 'show'}></span>
                            </div>
                        </div>


                        {errors.password && <p className="text-danger">{errors.password?.message}</p>}
                    </Col>
                    <Col md="6">
                        <Label className="form-label" for="repeat_pwd">
                            {ConfirmPassword}
                        </Label>

                        <input
                            className="form-control"
                            id="repeat_pwd"
                            type="password"
                            {...register("password_repeat", { required: true })}
                        />

                        {/* {watch("password_repeat") !== watch("password") &&
                            getValues("password_repeat") ? (
                            <span className="text-danger">password not match</span>
                        ) : null} */}
                        <p className="text-danger">{errors.password_repeat?.message}</p>
                    </Col>
                </Row>

                <span className='ps-2 spinner-enable'>
                    <Btn attrBtn={{ color: "primary", className: "btn" }} type="submit">
                        {loading ?
                            <span className="loader pt-2"></span> :
                            <span>

                                {Save}
                            </span>
                        }
                    </Btn>
                </span>

            </Form>
        </>
    )
}

export default ChangePwd
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Btn } from '../../../../AbstractElements';
import { AddPayment, Cancel, Print } from '../../../../Constant';
import ItemDescription from './ItemDescription';
import { Col } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getInvoiceData, getInvoiceDataRole } from '../../../../CommonUrl';
import { useSelector } from 'react-redux';
import { getClientUrlDataToken } from '../../../../Utils/restUtils';
import Transparentspinner from '../../../Common/Loader/Transparentspinner';

const PrintComponent = () => {
    const [spinnerTrans, setspinnerTrans] = useState(true)
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [invoiceDetail, setInvoiceDetail] = useState('');
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const paramInvoice = params.get("invoice_id");
    const paramCustomerId = params.get("customer_id");
    const componentRef = useRef(null);
    const status = invoiceDetail.payment_status;
    const invoiceno = invoiceDetail.order_id;
    const connectionType = invoiceDetail.connection_type;
    const stbNo = invoiceDetail.stb_id;

    useEffect(() => {
        invoiceData();
    }, [])

    const invoiceData = async () => {
        const response = await getClientUrlDataToken(authState.apiUrl,authState.userRole === 1 ? getInvoiceData + `?order_id=${paramInvoice}`:
        getInvoiceDataRole + `?order_id=${paramInvoice}`);
        if (response.status === true) {
            setInvoiceDetail(response.data);
        } else if (response.status >= 400 && response.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (response.status >= 500 && response.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setspinnerTrans(false);

    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            {Object.keys(invoiceDetail).length > 0 && (
                <ItemDescription ref={componentRef} invoiceData={invoiceDetail} />
            )}
            <Col sm="12" className="text-center my-3">
                <Btn attrBtn={{ color: 'primary', className: 'me-2', onClick: handlePrint }}>
                    {Print}
                </Btn>
                {status !== 'paid' && (
                    <Link to={`${process.env.PUBLIC_URL}/customers/customer/invoice/invoice-payment-add?invoice_id=${paramInvoice}&customer_id=${paramCustomerId}&invoice_no=${invoiceno}&con_type=${connectionType}&stb_id=${stbNo}`}>
                        <Btn attrBtn={{ color: 'secondary' }}>{AddPayment}</Btn>
                    </Link>
                )}
            </Col>
        </Fragment>
    );
};

export default PrintComponent;
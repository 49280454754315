import React from 'react'
import RoleList from './RoleList'
import { Footerlight, PageLayout } from '../../../Constant'

const Role = () => {
  return (
    
    <RoleList  mainTitle={Footerlight} title={Footerlight} parent={PageLayout}/>
  )
}

export default Role
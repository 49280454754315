import React, { Fragment } from 'react';
import { Col, Card, CardHeader, Table } from 'reactstrap';
import { Comments, HoverableRows, Id, Status, TeamLead, ThreadId, UpdatedAt, UpdatedBy } from '../../../../Constant';

const HoverableRowsClass = (props) => {
    const tableData = props.details;
    return (
        <Fragment>
            <Col sm="12">
                <div className="table-responsive">
                    <Table hover={true} >
                        <thead>
                            <tr>
                                {/* <th className='text-center' scope="col">{Id}</th> */}
                                <th scope="col">{ThreadId}</th>
                                <th scope="col">{Comments}</th>
                                <th scope="col">{UpdatedBy}</th>
                                <th scope="col">{UpdatedAt}</th>
                                <th scope="col">{Status}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.complaint_forum.map((item, id) =>
                                    <tr key={item.id}>
                                        {/* <th className='text-center' scope="row">{id + 1}</th> */}
                                        <td className='ps-4'>{item.id}</td>
                                        <td>{item.comments}</td>
                                        <td>{tableData.updated_by}</td>
                                        <td>{item.updated_at}</td>
                                        <td className=''>
                                            <span className={`text-${item.status === 'open' ? 'success' : item.status === 'closed' ? 'danger' : item.status === 'in_progress' ? 'warning' : 'secondary'} `}>{item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1).replace('_', ' ') : "open"}</span>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Fragment>
    );
};

export default HoverableRowsClass;
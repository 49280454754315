import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { AddPackage, Category, Description, DurationInDays, Logo, Image, Name, NameError, FeatureError, Package, Price, PurchasePrice, Save, Tax, hsn_sac, subscription_limit, features, mrp, setup_cost, SubscriptionError, MrpError, PriceErrors, TaxError, SetupCostError, PurchasePriceError, DurationError, HSNError, New, ConnectionType } from "../../../Constant";
import { addPackages, addPackagesRole, getCategory, getCategoryRole, getcategoryexpence, getcategoryitems, getcategoryitemsRole, statusTax, statusTaxRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../../CommonElements/TypeAhead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    price: yup.string().required().label('Price'),
    purchase_price: yup.string().required().label('Purchase Price'),
    tax: yup.mixed().required().label('Tax'),
    duration: yup.string().required().label('Duration In Days'),
    category: yup.mixed().required().label('Category'),
    connectiontype: yup.mixed().required().label('Connection type'),

})

const PackagesForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const authState = useSelector((state) => state.auth);
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState([])
    const [categoryOption, setCategoryOption] = useState([]);
    const [tax, setTax] = useState([]);
    const [taxOption, setTaxOption] = useState([]);
    const navigate = useNavigate();
    const form = new FormData();
    const handleImageChange = (event) => {

        const selectedFile = event.target.files[0];
        setImage(selectedFile);

    }

    const connectionOptions = [
        { value: "broadband", label: "Broadband" },
        { value: "cable", label: "Cable" },
        { value: "ip_tv", label: "IPTV" },
        { value: "ott", label: "OTT" },
        { value: "other", label: "Others" },

    ];



    useEffect(() => {
        categoryData();
        taxData();
    }, []);

    useEffect(() => {
        setTaxOption(
            tax.map((data) => ({
                value: data.id,
                label: data.title,
            }))
        );
        setCategoryOption(
            category.map((data) => ({
                value: data.id,
                label: data.name,
            }))
        );
    }, [tax, category]);

    const taxData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? statusTax : statusTaxRole);
        if (data.status === true) {
            setTax(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    };

    const categoryData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getcategoryitems : getcategoryitemsRole);
        if (data.status === true) {
            setCategory(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    };

    useEffect(() =>{
        const inputElement = document.querySelectorAll('input[type="number"]');
        
        const preventScrollEvent = (event) =>{
          event.preventDefault();
        };
        
        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);
      
            return () => {
              inputElement.removeEventListener('wheel', preventScrollEvent);
            };
          });        
        },[]);
        
        

    const onSubmit = async (data) => {
        setLoading(true);
        form.append("hsn_no", data.hsn_no ?? '')
        form.append("name", data.name)
        form.append("price", data.price)
        form.append("purchase_price", data.purchase_price)
        form.append("description", data.description)
        form.append("duration", data.duration)
        form.append("tax_group_id", data.tax.value)
        form.append("connection_type", data.connectiontype.value)
        form.append("category_id", data.category.value)
        if (data.image[0]) {
            form.append("image", data.image[0])
        }
        const addPackagesResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addPackages : addPackagesRole, form, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (addPackagesResponse.status === 200) {
            if (addPackagesResponse.data.status === true) {
                toast.success(addPackagesResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/Services/packages`);
            } else {
                toast.error(
                    Array.isArray(addPackagesResponse.data.message)
                        ? addPackagesResponse.data.message.map((e) => e)
                        : "Unexpected error occurred"
                );
            }
        } else if (addPackagesResponse.status >= 400 && addPackagesResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addPackagesResponse.status >= 500 && addPackagesResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }

        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={Package} parent="Settings" title={Package} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddPackage}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="hsn_no">{hsn_sac} </Label>
                                <input className="form-control" id="hsn_no" type="number" min="0"{...register('hsn_no')} />
                                </Col>

                            <Col md='6'>
                                <Label className="form-label" for="price">{Price} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="price" type="number" min="0" {...register('price', { required: true })} />
                                <p className="text-danger">{errors.price?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="purchase_price">{PurchasePrice} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="purchase_price" type="number" min="0"{...register('purchase_price', { required: true })} />
                                <p className="text-danger">{errors.purchase_price?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">

                            <Col md="6">
                                <Label className="form-label" >{Tax} <span className="requireStar">*</span> </Label>
                                <Controller
                                    name="tax"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={taxOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.tax?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{Category} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={categoryOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.category?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" >{ConnectionType} <span className="requireStar">*</span> </Label>
                                <Controller
                                    name="connectiontype"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={connectionOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.connectiontype?.message}</p>
                            </Col>

                            <Col md='6'>
                                <Label className="form-label" for="duration">{DurationInDays} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="duration" type="text" {...register('duration', { required: true })} />
                                <span className="text-danger">{errors.duration && DurationError}</span>
                            </Col>

                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label">{Image}</Label>
                                <Controller
                                    name="image"
                                    control={control}
                                    render={({ field }) => (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Col md="8">
                                                <input
                                                    {...register("image")}
                                                    className="form-control fileInputStyle"
                                                    type="file"
                                                    onChange={handleImageChange}
                                                    accept=".png, .jpg, .jpeg"
                                                />
                                            </Col>
                                            <Col md='4'>
                                                <span className="imagestyle">
                                                    {image && <img src={URL.createObjectURL(image)} alt="logo" width="40px" height="40px" />}
                                                </span>
                                            </Col>
                                        </div>
                                    )}
                                />
                                <span className="text-danger">{errors.logo && "logo is required"}</span>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Label className="form-label pt-3">{Description} </Label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <textarea
                                                className="form-control"
                                                {...field}
                                                rows="3"
                                                {...register('description')}

                                            />
                                            <p className="text-danger">{errors.description?.message}</p>
                                        </>

                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default PackagesForm;
import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Input } from "reactstrap";
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import {
  AddCustomer, Address, BillingAddress, BillingName, Customer, CustomerType, Email, FirstName, LastName,
  LcoNo, MobileNo, Reference, RouteNo, Save, SubscriberId, TaxIn, Samasabove, Area, DateText, BirthDate,
} from "../../../Constant";
import { addCustomer, addCustomerRole, getArea, getAreaRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import DatePicker from 'react-datepicker';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
  address: yup.string().required().label('Address'),
  areaName: yup.mixed().required().label('Area Name'),
  sub: yup.string().required().label('Subscription id'),
  fname: yup.string().required().label('First Name'),
  email: yup.string().email('Inavlid email format').required().label('Email'),
  phonenumber: yup.string().required().label("Phone number"),
  // lcono: yup.string().required().label("LCO Number"),
  billingName: yup.string().required().label('Billing Name'),
  billingAddr: yup.string().required().label('Billing Address'),
});

const CustomerForm = () => {
  const { register, handleSubmit, control, getValues, watch, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const authState = useSelector((state) => state.auth);
  const [area, setArea] = useState([]);
  const [areaOption, setAreaOption] = useState([]);
  const [birthDate, setBirthDate] = useState('');
  const [loading, setLoading] = useState(false);
  const options = [
    { value: "general", label: "General" },
    { value: "privileged", label: "Privileged" },
  ];
  const navigate = useNavigate();

  const { fname, lname, address } = watch();

  const handleChangeDate = date => {
    setBirthDate(date);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const fullName = `${fname || ''} ${lname || ''}`;
      setValue('billingName', fullName);
      setValue('billingAddr', address || '');
    } else {
      setValue('billingName', '');
      setValue('billingAddr', '');
    }
  };

  useEffect(() => {
    areaData();
  }, []);

  useEffect(() => {
    setAreaOption(
      area.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [area]);

  const areaData = async () => {
    let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
    if (data.status === true) {
      setArea(data.data);
    }
    else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }
  };

  const onSubmit = async (data) => {
    const countryCode = data.countryCode;
    const phoneNumber = data.phonenumber;

    if (phoneNumber.startsWith(countryCode)) {
      setLoading(true);
      const b = phoneNumber.slice(countryCode.length);
      const postRequest = {
        subscriber_id: data.sub,
        lco_no: data.lcono,
        first_name: data.fname,
        last_name: data.lname,
        email: data.email,
        country_code: data.countryCode,
        mobile: b,
        address: data.address,
        route_no: data.route,
        billing_name: data.billingName,
        billing_address: data.billingAddr,
        area_id: data.areaName.value,
        latitude: data.latitude,
        longitude: data.longitude,
        dob: moment(birthDate),
        tax_in: data.tax,
        reference: data.message,
        customer_type: data.customerType.value,
      };
      const addCustomerResponse = await postClientUrlWithToken(
        authState.apiUrl, authState.userRole === 1 ? addCustomer : addCustomerRole, postRequest
      );
      if (addCustomerResponse.status === 200) {
        if (addCustomerResponse.data.status === true) {
          navigate(`${process.env.PUBLIC_URL}/customers/customer`);
          toast.success(addCustomerResponse.data.message);
        } else {
          toast.error(addCustomerResponse.data.message);
          setLoading(false);
        }
      } else if (addCustomerResponse.status >= 400 && addCustomerResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
      } else if (addCustomerResponse.status >= 500 && addCustomerResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
      }
      setLoading(false);
    }
  };
  return (
    <Fragment>
      {loading && <Transparentspinner />}

      <Breadcrumbs mainTitle={Customer} parent="Customers" title={Customer} />
      <Card>
        <CardHeader className="pb-0">
          <H3>{AddCustomer}</H3>
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="firstname">
                  {FirstName} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="firstname"
                  type="text"
                  {...register("fname", { required: true })}
                />
                <p className="text-danger">{errors.fname?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="lastaname">
                  {LastName}
                </Label>
                <input
                  className="form-control"
                  id="lastaname"
                  type="text"
                  {...register("lname", { required: true })}
                />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="subid">
                  {SubscriberId} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="subid"
                  type="text"
                  {...register("sub", { required: true })}
                />
                <p className="text-danger">{errors.sub?.message}</p>

              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {Area} <span className="requireStar">*</span>
                </Label>
                <Controller
                  name="areaName"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      
                      options={areaOption}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.areaName?.message}</p>
              </Col>
            </Row>

            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="email">
                  {Email} <span className="requireStar">*</span>
                </Label>
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  {...register("email", { required: true })}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >
                  {MobileNo} <span className="requireStar">*</span> 
                </Label>
                <Controller
                  name="phonenumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      country={"in"}
                      enableSearch={true}
                      onChange={(value, country) => {
                        setValue("countryCode", country.dialCode);
                        field.onChange(value);
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "46px",
                        padding: "10px",
                        paddingLeft: "50px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                      }}
                      searchClass="none"
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.phonenumber && errors.phonenumber.message}
                </span>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="lco">
                  {LcoNo}
                </Label>
                <input
                  className="form-control"
                  id="lco"
                  type="text"
                  {...register("lcono")}
                />
                {/* <p className="text-danger">{errors.lcono?.message}</p> */}
              </Col>
              <Col>
                <Label className="form-label">{Address} <span className="requireStar">*</span></Label>
                <textarea className="form-control"
                  {...register("address", { required: true })}

                />
                <p className="text-danger">{errors.address?.message}</p>
              </Col>
            </Row>
            <div className="bg-subscription mt-2">

              <Row className="g-3 pt-3">
                <div className="form-check checkbox checkbox-primary mb-0">
                  <Input id="checkbox-primary-1" type="checkbox" onChange={handleCheckboxChange} />
                  <Label for="checkbox-primary-1">{Samasabove}</Label>
                </div>
              </Row>

              <Row className="g-3 pt-3 ">
                <Col md="6">
                  <Label className="form-label" for="billing_name">
                    {BillingName} <span className="requireStar">*</span>
                  </Label>
                  <input
                    className="form-control"
                    id="billing_name"
                    type="text"
                    {...register("billingName", { required: true })}
                  />
                  <p className="text-danger">{errors.billingName?.message}</p>
                </Col>
                <Col md="6">
                  <Label className="form-label" for="billingAddr">
                    {BillingAddress} <span className="requireStar">*</span>
                  </Label>
                  <textarea
                    className="form-control"
                    id="billingAddr"
                    // type="tel"
                    {...register("billingAddr", { required: true })}
                  />
                  <p className="text-danger">{errors.billingAddr?.message}</p>
                </Col>
              </Row>
            </div>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="tax">
                  {TaxIn}
                </Label>
                <input
                  className="form-control"
                  id="tax"
                  type="text"
                  {...register("tax", { required: true })}
                />
              </Col>
              <Col md="6">
                <Label className="form-label" for="route_no">
                  {RouteNo}
                </Label>
                <input
                  className="form-control"
                  id="route_no"
                  type="text"
                  {...register("route", { required: true })}
                />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">
                  {CustomerType}
                </Label>
                <Controller
                  name="customerType"
                  control={control}
                  defaultValue={options[0]}
                  render={({ field }) => (
                    <Typeahead
                      options={options}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-label" for="dob">{BirthDate}</Label>
                  <Col xl='12' md="12" sm='12'>
                    <DatePicker
                    id="dob"
                      style={{ padding: '12px 100px' }}
                      className="form-control "
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      onChange={handleChangeDate}
                      // peekNextMonth
                      selected={birthDate}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      required
                    />
                  </Col>
                  {/* <p className="text-danger">{errors.invoice_date?.message}</p> */}
                </FormGroup>
              </Col>
            </Row>
            <Row className="g-3">
              <Col>
                <Label className="form-label pt-3">{Reference}</Label>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <textarea className="form-control" {...field} rows="3" />
                  )}
                />
              </Col>
            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">
              {Save}
            </Btn>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default CustomerForm;

import React from 'react'
import './Dashboard.scss';


const Dashboard = () => {
  return (
 
    <div className="dashboard-container">
    {/* <div className="box">
      <img src="../Services/Home.svg" alt="Dashboard" />
    </div> */}
  </div>

  )
}

export default Dashboard
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Form, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { H3, Btn, Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { Add, Area, AreaList, OfflinePayment, OfflinePaymentMethod, Payment, PaymentList, Search, Filter } from '../../../Constant';
import SearchList from '../../../CommonElements/Search';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { deleteArea, deleteOfflinePayment, deleteOfflinePaymentRole, viewPayment, viewPaymentRole } from '../../../CommonUrl';
import { PaymentColumns } from '../../../Data/MockTable/SettingsTableHeader';
import Transparentspinner from '../../Common/Loader/Transparentspinner'

const PaymentMethodContainer = () => {
    const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
    const authState = useSelector((state) => state.auth);
    const [paymentList, setPaymentList] = useState([])
    const [paymentDataList, setPaymentListData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (searchKeyword == '') {
            setLoading(true);
        offlinePaymentData();
        }
    }, [searchKeyword]);

    const filteredItems = paymentDataList.filter(
        (item) => item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    
    const handleSearchInputChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        offlinePaymentData();
    };

    const redirectToEditForm = (data) => {
        const editFormURL = `${process.env.PUBLIC_URL}/settings/payment-edit?id=${data.id}`;
        navigate(editFormURL);
    };

    const deletePaymentList = (userId) => {
        SweetAlert.fire({
            title: 'Confirm deletion?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then(async (result) => {
            setLoading(true)
            if (result.value) {
                const deleteRes = { payment_method_id: userId }
                let areaDelete = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? deleteOfflinePayment : deleteOfflinePaymentRole, deleteRes);
                if (areaDelete.status === 200) {
                    SweetAlert.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    );
                    offlinePaymentData();
                    setLoading(false)
                } else if (areaDelete.status >= 400 && areaDelete.status <= 405) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
                } else if (areaDelete.status >= 500 && areaDelete.status <= 505) {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
                } else {
                    navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
                }
            }
        });
    };

    const offlinePaymentData = async () => {
        setLoading(true)
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPayment : viewPaymentRole);
        if (data.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            setLoading(true)
            setPaymentListData(data.data)
            if (Array.isArray(data.data)) {
                const filteredPayment = data.data.filter((item) => {
                    const nameMatch = item.name && item.name.toLowerCase().includes(searchKeyword.toLowerCase());
                    const CodeMatch = item.code && item.code.toString().toLowerCase().includes(searchKeyword.toLowerCase());
                    return nameMatch  || CodeMatch ;
                });


                setPaymentList(filteredPayment.map((e, index) => {
                    return {
                        Si: index + 1,
                        Name: <span>{e.name}</span>,
                        Code: <span>{e.code}</span>,
                        Order: e.order,
                        action:
                            <div>
                                <span>
                                    <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                                </span>
                                <span>
                                    <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deletePaymentList(e.id) } }}>Delete</Btn>
                                </span>
                            </div >
                    }
                }))
                setLoading(false)
            } else {
                setPaymentList(filteredPayment.map((e, index) => {
                    return {
                        Si: index + 1,
                        Name: <span>{e.name}</span>,
                        Code: <span>{e.code}</span>,
                        Order: e.order,
                        action:
                            <div>
                                <span>
                                    <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit </Btn>
                                </span>
                                <span>
                                    <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deletePaymentList(e.id) } }}>Delete</Btn>
                                </span>
                            </div >
                    }
                }))
            }

        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    };

    const onSubmit = async (data) => {

    };
    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={OfflinePayment} parent="Settings" title={OfflinePayment} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{OfflinePaymentMethod}</H3>
                    <div>
                        <Link to={`${process.env.PUBLIC_URL}/settings/payment-add`} className="btn btn-primary">{Add}</Link>
                    </div>
                </CardHeader>

                <CardBody>
                    <span className='custom-span'>
                        <Form className="needs-validation" noValidate onSubmit={handleFilterSubmit}>
                            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
                                <Col xs="6" sm="4" md="2" lg="2">
                                    <input className="form-control" placeholder={Search} id="validationCustom01" type="text" value={searchKeyword} onChange={handleSearchInputChange}
                                         />
                                </Col>
                                <Col xs="6" sm="6" md="4" lg="3">
                                    <Btn className="custom-filter-btn"  attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>
                    <div className="order-history table-responsive">
                        <DataTable
                            data={paymentList}
                            columns={PaymentColumns}
                            pagination
                            style={{ border: '1px solid' }}
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default PaymentMethodContainer;
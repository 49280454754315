import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { Description, Name, NameError, Save, TaxGroup, TaxGroupAdd } from "../../../Constant";
import { addTaxGroup } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const TaxGroupForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { title: data.name, description: data.message }
        const addTaxGroupResponse = await postClientUrlWithToken(authState.apiUrl, addTaxGroup, postRequest);
        if (addTaxGroupResponse.status === 200) {
        if (addTaxGroupResponse.data.status === true) {
            toast.success(addTaxGroupResponse.data.message)
            navigate(`${process.env.PUBLIC_URL}/settings/tax-group`);
        } else {
            toast.error(addTaxGroupResponse.data.message)
            setLoading(false);
            
        }
    } else if (addTaxGroupResponse.status >= 400 && addTaxGroupResponse.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (addTaxGroupResponse.status >= 500 && addTaxGroupResponse.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }

        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={TaxGroup} parent="Settings" title={TaxGroup} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{TaxGroupAdd}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default TaxGroupForm;
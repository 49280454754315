import React, { Fragment } from 'react';
import { H3, H2, P ,Image} from '../../AbstractElements';
import { PaymentDue, BillerQ, Invoice, Issued, Txn, BillingTo, MobileNo, Email, TaxIn, IssuedMay } from '../../Constant';
import { Col, Media, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import login from '../../assets/images/logo/logo-icon.png'
import moment from 'moment';

const InvoiceHeader = (props) => {
    const e = props.prop1;
    const invoice_date = moment(e.invoice_date).format('DD-MM-YYYY');;
    const due_date = moment(e.due_date).format('DD-MM-YYYY');
    return (
        <Fragment>
            <div>
                <Row className="invo-header">
                    <Col sm="4">
                        <Media className='d-flex'>
                            <div className="d-flex-left">
                                <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
                                    <Image attrImage={{ className: 'd-flex-object img-60', src: `${login}`, alt: '' }}
                                    />
                                </Link>
                            </div>
                            <Media className="flex-grow-1 m-l-20">
                                <H2 attrH2={{ className: 'media-heading f-w-600' }}>{BillerQ}</H2>
                                <P> quadleo@gmail.com
                                    <br />
                                    {/* www.billerq.com */}
                                    <a href="https://billerq.com/" className="">www.billerq.com</a>
                                    {/* <P>+91 70127 33393</P> */}
                                </P>
                            </Media>
                        </Media>
                    </Col>
                    <Col sm="4">
                        <Media className='d-flex'>
                            <Media className="flex-grow-1 m-l-20">
                                <P>Bill To:</P>
                                <P> {e.customer_name}
                                    <br/>
                                    <span style={{fontWeight:'600'}}>{e.company_name}</span>
                                    <br />
                                    {e.billing_address}
                                    <br/>
                                    {MobileNo}: +{e.user_mobile}
                                    <br />
                                    {Email}: {e.email}
                                    <br/>
                                    {TaxIn}: {e.transaction_id}
                                </P>
                            </Media>
                        </Media>
                    </Col>
                    <Col sm="4">
                        <div className="text-md-end text-xs-center">
                            <H3>
                                <span className="digits counter">#{e.invoice_number}</span>
                            </H3>
                            <P>
                            <strong>{Txn}: {e.transaction_id}</strong>
                            <br />
                                {IssuedMay}
                                <span className="digits"> {invoice_date}</span>
                                <br />
                                {PaymentDue}
                                <span className="digits">{due_date}</span>
                            </P>
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment >
    );
};
export default InvoiceHeader;
import React, { Fragment, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, P, Btn } from "../../../../AbstractElements";
import {
  Company,
  Defaults,
  Invoice,
  Localisation,
  LogOut,
  Step1,
  Step2,
  Step3,
  Step4,
} from "../../../../Constant";
import HeaderCard from "../../../Common/Component/HeaderCard";
import Formstep1 from "./Formstep1";
import Formstep2 from "./Formstep2";
import Formstep3 from "./Formstep3";
import Formstep4 from "./Formstep4";
import { useSelector } from "react-redux";
import NavSign from "../../../../Billerq/Sign/NavSign";

const Formwizard2 = () => {
  const [steps, setSteps] = useState(1);
  const [formdata, setFormdata] = useState({});
  const authState = useSelector((state) => state.auth);
  return (

    <div

      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#eff3f9",
      }}

    >
      <Fragment>
        <NavSign style={{ marginBottom: "auto" }} showProfile={true} buttonText={LogOut}/>
        <Container style={{ flexGrow: 1 }}>
          <Row>
            <Col sm="12">
              <Card style={{marginTop:'40px'}}>
                <HeaderCard title={"Create New Company"} />
                <CardBody>
                  <div> 
                    <div className="stepwizard">
                      <div className="stepwizard-row setup-panel">
                        <div className="stepwizard-step">
                          <Btn
                            attrBtn={{
                              color: `${steps === 1 ? "primary" : "light"}`,
                              onClick: () => {
                                setSteps(1);
                              },
                            }}
                          >
                            1
                          </Btn>
                          <P>{Company}</P>
                        </div>
                        <div className="stepwizard-step">
                          <Btn
                            attrBtn={{
                              color: `${steps === 2 ? "primary" : "light"}`,
                              onClick: () => {
                                setSteps(2);
                              },
                            }}
                          >
                            2
                          </Btn>
                          <P>{Localisation}</P>
                        </div>
                        <div className="stepwizard-step">
                          <Btn
                            attrBtn={{
                              color: `${steps === 3 ? "primary" : "light"}`,
                              onClick: () => {
                                setSteps(3);
                              },
                            }}
                          >
                            3
                          </Btn>
                          <P>{Invoice}</P>
                        </div>
                        <div className="stepwizard-step">
                          <Btn
                            attrBtn={{
                              color: `${steps === 4 ? "primary" : "light"}`,
                              onClick: () => {
                                setSteps(4);
                              },
                            }}
                          >
                            4
                          </Btn>
                          <P>{Defaults}</P>
                        </div>
                      </div>
                    </div>

                    {steps === 1 && (
                      <Formstep1
                        setSteps={setSteps}
                        setFormdata={setFormdata}
                        formdata={formdata}
                        clientUrl={authState.apiUrl}
                      />
                    )}
                    {steps === 2 && (
                      <Formstep2
                        setSteps={setSteps}
                        setFormdata={setFormdata}
                        formdata={formdata}
                      />
                    )}
                    {steps === 3 && (
                      <Formstep3
                        setSteps={setSteps}
                        setFormdata={setFormdata}
                        formdata={formdata}
                      />
                    )}
                    {steps === 4 && (
                      <Formstep4
                        setSteps={setSteps}
                        setFormdata={setFormdata}
                        formdata={formdata}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </div >
  );
};

export default Formwizard2;
